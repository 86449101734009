import React from "react";
import { Account } from "../types";
import { ApolloError } from "@apollo/client";
import { CloseoutTab } from "./CloseoutLogContext";

export type TeamHomeTabs = "projects" | "users" | "automations" | "closeout_log_templates";

export type AccountProjectState = {
  loading: boolean;
  accounts: Account[];
  error: ApolloError;
  accountId: string | null;
  internal: boolean;
  closeoutLogEnabled: boolean;
  configuredAutomations: boolean;
  closeoutLogFileRequestEnabled: boolean;
  automaticExportsEnabled: boolean;
  procoreCompanyServerId: number | null;
  procoreProjectServerId: number | null;
  procoreCompanyServerIdToSelect: number | null;
  procoreProjectServerIdToSelect: number | null;
  closeoutLogIdToSelect?: number;
  closeoutLogSelectedTab: CloseoutTab;
  timeZone?: string;
  dateFormat: string;
  detailDateFormat: string;
  locale: string;
  projectName?: string;
  licensed: boolean;
  licenseType: string;
  licenseEndDate?: string;
  appBaseUrl?: string;
  selectedTab: TeamHomeTabs;
  selectedAccount: string;
}

export const initialState: AccountProjectState = {
  loading: false,
  accounts: [],
  error: null,
  accountId: null,
  internal: false,
  closeoutLogEnabled: false,
  configuredAutomations: false,
  closeoutLogFileRequestEnabled: false,
  automaticExportsEnabled: false,
  procoreCompanyServerId: null,
  procoreProjectServerId: null,
  procoreCompanyServerIdToSelect: null,
  procoreProjectServerIdToSelect: null,
  closeoutLogIdToSelect: null,
  closeoutLogSelectedTab: 'log',
  timeZone: null,
  dateFormat: 'MM/dd/yyyy',
  detailDateFormat: 'MM/dd/yyyy at hh:mm a',
  locale: 'en',
  projectName: '',
  licensed: false,
  licenseType: '',
  licenseEndDate: null,
  appBaseUrl: null,
  selectedTab: "projects",
  selectedAccount: "",
};

export type AccountProject = {
  accountId: string;
  internal: boolean;
  closeoutLogEnabled: boolean;
  configuredAutomations: boolean;
  closeoutLogFileRequestEnabled: boolean;
  automaticExportsEnabled: boolean;
  procoreCompanyServerId: number;
  procoreProjectServerId: number;
  timeZone?: string;
  dateFormat: string;
  detailDateFormat: string;
  projectName?: string;
  licensed: boolean;
  licenseType: string;
  licenseEndDate?: string;
  appBaseUrl: string;
}

export type Action =
  | { type: 'SET_ACCOUNT_PROJECT'; value: { accountProject: AccountProject } }
  | { type: 'RESET_ACCOUNT_PROJECT'; value: '' }
  | { type: 'RESET_SELECTED_CLOSEOUT_LOG_ID'; }
  | { type: 'SET_ACCOUNTS'; value: { accounts: Account[], selectedTab?: TeamHomeTabs } }
  | { type: 'SET_LOADING'; value: boolean }
  | { type: 'SET_ERROR'; value: ApolloError }
  | { type: 'SET_SELECTED_TAB'; value: TeamHomeTabs }
  | { type: 'SET_SELECTED_ACCOUNT'; value: string }

export const rootReducer: React.Reducer<AccountProjectState, Action> = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_ACCOUNT': {
      return {
        ...state,
        selectedAccount: action.value
      }
    }

    case 'RESET_SELECTED_CLOSEOUT_LOG_ID': {
      return {
        ...state,
        closeoutLogSelectedTab: 'log',
        closeoutLogIdToSelect: null
      }
    }

    case 'SET_ACCOUNT_PROJECT':
      return {
        ...state,
        accountId: action.value.accountProject.accountId,
        internal: action.value.accountProject.internal,
        closeoutLogEnabled: action.value.accountProject.closeoutLogEnabled,
        configuredAutomations: action.value.accountProject.configuredAutomations,
        closeoutLogFileRequestEnabled: action.value.accountProject.closeoutLogFileRequestEnabled,
        automaticExportsEnabled: action.value.accountProject.automaticExportsEnabled,
        procoreCompanyServerId: action.value.accountProject.procoreCompanyServerId,
        procoreProjectServerId: action.value.accountProject.procoreProjectServerId,
        projectName: action.value.accountProject.projectName,
        timeZone: action.value.accountProject.timeZone,
        licensed: action.value.accountProject.licensed,
        licenseType: action.value.accountProject.licenseType,
        licenseEndDate: action.value.accountProject.licenseEndDate,
        appBaseUrl: action.value.accountProject.appBaseUrl,
        selectedTab: "projects",
        selectedAccount: action.value.accountProject.accountId,
        dateFormat: action.value.accountProject.dateFormat,
        detailDateFormat: action.value.accountProject.detailDateFormat
      };

    case 'RESET_ACCOUNT_PROJECT':
      return {
        ...state,
        accountId: null,
        internal: false,
        closeoutLogEnabled: false,
        configuredAutomations: false,
        automaticExportsEnabled: false,
        procoreCompanyServerId: null,
        procoreProjectServerId: null,
        procoreCompanyServerIdToSelect: null,
        procoreProjectServerIdToSelect: null,
        closeoutLogSelectedTab: 'log',
        closeoutLogIdToSelect: null,
        timeZone: null,
        dateFormat: 'MM/dd/yyyy',
        detailDateFormat: 'MM/dd/yyyy at hh:mm a',
        locale: 'en',
        projectName: '',
        licensed: false,
        licenseType: '',
        licenseEndDate: null
      }

    case 'SET_ACCOUNTS': {
      return {
        ...state,
        accounts: action.value.accounts,
        selectedTab: action.value.selectedTab || "projects"
      }
    }

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.value
      }
    }

    case 'SET_ERROR': {
      return {
        ...state,
        error: action.value
      }
    }

    case 'SET_SELECTED_TAB': {
      return {
        ...state,
        selectedTab: action.value
      }
    }

    default:
      return state;
  }
};