import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { LogFileRequest, LogRequirementItem } from "../../../types";

export interface CreateLogFileRequestResponse {
  logFileRequest?: LogFileRequest;
  logRequirementItems: LogRequirementItem[];
  success: boolean;
}

export interface CreateLogFileRequestResponseData {
  createLogFileRequestResponse: CreateLogFileRequestResponse | null;
}

export interface CreateLogFileRequestResponseAttributes {
  status: "accepted" | 'revise_and_resubmit' | 'submit_manually';
  markRequirementAsComplete?: boolean;
  dueDate?: string;
  notes?: string;
  attachments: Record<string, any>[];
}

export interface CreateLogFileRequestResponseVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  id: number;
  createResponseAttributes: CreateLogFileRequestResponseAttributes;
}

export const useCreateLogFileRequestResponse = createMutationHook<
  CreateLogFileRequestResponseData,
  CreateLogFileRequestResponseVariables
>(gql`
  mutation CreateLogFileRequestResponse(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $id: ID!,
    $createResponseAttributes: CreateLogFileRequestResponseAttributes!
  ) {
    createLogFileRequestResponse(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, id: $id, createResponseAttributes: $createResponseAttributes }
    ) {
      logFileRequest {
        id
        title
        logRequirementId
        description
        internalNotes
        dueDate
        complete
        status
        translatedStatus
        createdAt
        updatedAt
        assignees {
          id
          logFileRequestId
          procoreServerId
          procoreUserName
          procoreUserEmail
        }
        closeoutManager {
          id
          procoreUserName
        }
        responsibleContractor {
          id
          name
          procoreServerId
        }
        logRequirementTrade {
          id
          name
        }
        specSection {
          id
          name
          divisionName
          procoreServerId
        }
        logRequirementDescription
        vendorWorkflowRequest {
          id
          logFileRequestId
          status
          translatedStatus
          comment
          title
          vendorWorkflowResponse {
            id
            logFileRequestId
            status
            translatedStatus
            comment
            vendorWorkflowRequestId
            title
            attachments {
              id
              procoreFileServerId
              filename
              vendorWorkflowResponseId
              procoreCreatedAt
              procoreRecordUrl
              procoreDownloadUrl
              procoreDocumentViewerUrl
              viewableInProcore
              omitted
            }
          }
        }
      }
      logRequirementItems {
        id
        recordType
        recordServerId
        engineName
        logRequirementId
        payload
      }
      success
    }
  }
`);
