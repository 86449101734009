
import Close from "@mui/icons-material/Close";
import { Box, Breakpoint, Dialog, IconButton, PaperProps } from "@mui/material";
import React from "react";
import { Typography } from "./Typography";
import { Button, LoadingButton } from "./Button";

const ExtractsProDialog = (props: {
  title: string,
  open: boolean,
  maxWidth?:  false | Breakpoint,
  children: React.ReactNode,
  PaperProps?: Partial<PaperProps>,
  closeButton: {
    onClick: () => void,
    disabled: boolean,
  }
  primaryButton?: {
    text: string,
    onClick: () => void,
    disabled: boolean,
    loading?: boolean,
    primarycolorbackground?: string,
    primarycolor?: string,
  },
  secondaryButton?: {
    text: string,
    onClick: () => void,
    disabled: boolean,
  },
}) => {
  const {
    title,
    open,
    closeButton,
    maxWidth,
    children,
    primaryButton,
    secondaryButton,
    PaperProps,
  } = props;

  return (
    <Dialog
      maxWidth={maxWidth}
      disableScrollLock
      onClose={closeButton.disabled ? undefined : closeButton.onClick}
      aria-labelledby="extracts-pro-dialog"
      open={open}
      PaperProps={PaperProps ?? {
        sx: {
          height: 'calc(100% - 25vh)',
          width: "705px",
        }
      }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '28px 32px 0px 32px',
        justifyContent: 'space-between'
      }}>
        <Typography typestyle="xl">
          {title}
        </Typography>
        <IconButton disabled={closeButton.disabled} onClick={closeButton.onClick}>
          <Close sx={{fontSize: '18px'}}/>
        </IconButton>
      </Box>

      <Box display="flex" height={1} padding={'28px 32px'} gap="20px" flexDirection={"column"} flex={1} sx={{ overflowY: 'auto' }}>
        {children}
      </Box>

      <Box display="flex" flexDirection="column" height={1} width={1} padding={'0px 32px 28px 32px'} flex={0}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"8px"}
        >
          {secondaryButton && (
            <Button
              disabled={secondaryButton.disabled}
              variant="outlined"
              buttonborderstyle="pill"
              size="medium"
              onClick={() => secondaryButton.onClick()}
            >
              {secondaryButton.text}
            </Button>
          )}

          {primaryButton && (
            <LoadingButton
              startIcon={primaryButton.loading && <div style={{ height: '20px', width: '20px' }} />}
              disabled={primaryButton.disabled}
              variant="contained"
              loadingPosition="start"
              loading={primaryButton.loading}
              buttonborderstyle="pill"
              onClick={() => primaryButton.onClick()}
              primarycolorbackground={primaryButton.primarycolorbackground}
              primarycolor={primaryButton.primarycolor}
              size="medium">
              {primaryButton.text}
            </LoadingButton>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ExtractsProDialog;