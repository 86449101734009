import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { LogFileRequest } from "../../../types";

export interface LogFileRequestUpdate {
  logFileRequest: LogFileRequest;
  success: boolean;
}

export interface LogFileRequestUpdateData {
  updateLogFileRequest: LogFileRequestUpdate | null;
}

export interface LogFileRequestUpdateVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  logFileRequestId: number;
  attributes: Record<string, any>;
}

export const useUpdateLogFileRequest = createMutationHook<
LogFileRequestUpdateData,
  LogFileRequestUpdateVariables
>(gql`
  mutation UpdateLogFileRequest(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $logFileRequestId: ID!,
    $attributes: JSON!
  ) {
    updateLogFileRequest(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, logFileRequestId: $logFileRequestId, attributes: $attributes }
    ) {
      logFileRequest {
        id
        title
        logRequirementId
        description
        internalNotes
        dueDate
        complete
        status
        translatedStatus
        createdAt
        updatedAt
        assignees {
          id
          logFileRequestId
          procoreServerId
          procoreUserName
          procoreUserEmail
        }
        closeoutManager {
          id
          procoreUserName
        }
        responsibleContractor {
          id
          name
          procoreServerId
        }
        logRequirementTrade {
          id
          name
        }
        specSection {
          id
          name
          divisionName
          procoreServerId
        }
        logRequirementDescription
        vendorWorkflowRequest {
          id
          logFileRequestId
          status
          translatedStatus
          comment
          title
          vendorWorkflowResponse {
            id
            logFileRequestId
            status
            translatedStatus
            comment
            vendorWorkflowRequestId
            title
            attachments {
              id
              procoreFileServerId
              filename
              vendorWorkflowResponseId
              procoreCreatedAt
              procoreRecordUrl
              procoreDownloadUrl
              procoreDocumentViewerUrl
              viewableInProcore
              omitted
            }
          }
        }
      }
      success
    }
  }
`);
