import { Button as MuiButton, ButtonProps, styled } from "@mui/material";
import { LoadingButton as MuiLoadingButton, LoadingButtonProps } from "@mui/lab";
import { Colors } from "../../styles";

type OtherProps = {
  buttonborderstyle?: 'pill' | 'rounded';
  primarycolorbackground?: string;
  primarycolor?: string;
};

export const Button = styled(MuiButton)<ButtonProps & OtherProps>(
  ({ buttonborderstyle }) => ({
    '&.MuiButtonBase-root': {
      whiteSpace: 'nowrap',
    },
    '&.MuiButton-sizeLarge': {
      height: '44px',
      borderRadius: buttonborderstyle === 'pill' ? '22px' : '6px',
    },
    '&.MuiButton-sizeMedium': {
      height: '40px',
      borderRadius: buttonborderstyle === 'pill' ? '20px' : '6px',
    },
    '&.MuiButton-sizeSmall': {
      height: '36px',
      borderRadius: buttonborderstyle === 'pill' ? '18px' : '6px',
    },
    '&.MuiButton-contained': {
      backgroundColor: Colors.darkerGray,
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: Colors.darkGray,
      },
      '&:focus': {
        boxShadow: ` 0px 0px 0px 1px ${Colors.white}, 0px 0px 0px 4px ${Colors.lightAccentBlue}`,
      },
      '&:disabled': {
        backgroundColor: Colors.lightishGray,
        color: Colors.mediumDarkGray,
      },
    },
    '&.MuiButton-outlined': {
      border: `1px solid ${Colors.mediumGray}`,
      '&:hover': {
        border: `1px solid ${Colors.mediumDarkGray}`,
        backgroundColor: Colors.white,
      },
      '&:focus': {
        boxShadow: ` 0px 0px 0px 1px ${Colors.white}, 0px 0px 0px 4px ${Colors.lightAccentBlue}`,
      },
      '&:disabled': {
        color: Colors.reallyBlueGray,
        border: `1px solid ${Colors.mediumLightGray}`,
      },
    }
  })
);

export const LoadingButton = styled(MuiLoadingButton)<LoadingButtonProps & OtherProps>(
  ({ buttonborderstyle, primarycolorbackground, primarycolor }) => ({
    '&.MuiButtonBase-root': {
      whiteSpace: 'nowrap',
      color: primarycolor ? primarycolor : 'default',
    },
    '&.MuiButton-sizeLarge': {
      height: '44px',
      borderRadius: buttonborderstyle === 'pill' ? '22px' : '6px',
    },
    '&.MuiButton-sizeMedium': {
      height: '40px',
      borderRadius: buttonborderstyle === 'pill' ? '20px' : '6px',
    },
    '&.MuiButton-sizeSmall': {
      height: '36px',
      borderRadius: buttonborderstyle === 'pill' ? '18px' : '6px',
    },
    '&.MuiButton-contained': {
      backgroundColor: primarycolorbackground ? primarycolorbackground : Colors.darkerGray,
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: primarycolorbackground ? primarycolorbackground : Colors.darkGray,
      },
      '&:focus': {
        boxShadow: ` 0px 0px 0px 1px ${Colors.white}, 0px 0px 0px 4px ${Colors.lightAccentBlue}`,
      },
      '&:disabled': {
        backgroundColor: primarycolorbackground ? primarycolorbackground : Colors.lightishGray,
        color: primarycolor ? primarycolor : Colors.mediumDarkGray,
        opacity: 0.8,
      },
    },
    '&.MuiLoadingButton-loading': {
      boxShadow: 'none',
      '&:focus': {
        boxShadow: ` 0px 0px 0px 1px ${Colors.white}, 0px 0px 0px 4px ${Colors.lightAccentBlue}`,
      },
      '&:disabled': {
        backgroundColor: primarycolorbackground ? primarycolorbackground : Colors.lightishGray,
        color: primarycolor ? primarycolor : Colors.mediumDarkGray,
        opacity: 0.8,
      },
    },
    '&.MuiButton-outlined': {
      border: `1px solid ${Colors.mediumGray}`,
      '&:hover': {
        border: `1px solid ${Colors.mediumDarkGray}`,
        backgroundColor: primarycolorbackground ? primarycolorbackground : Colors.white,
      },
      '&:focus': {
        boxShadow: ` 0px 0px 0px 1px ${Colors.white}, 0px 0px 0px 4px ${Colors.lightAccentBlue}`,
      },
      '&:disabled': {
        color: primarycolor ? primarycolor : Colors.reallyBlueGray,
        border: `1px solid ${Colors.mediumLightGray}`,
        opacity: 0.8,
      },
    }
  })
);