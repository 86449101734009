import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';
import { LogRequirementItem } from '../../types';

export interface CreateLogRequirementProcoreProjectFile {
  success: boolean;
  logRequirementItem: LogRequirementItem;
}

export interface CreateLogRequirementProcoreProjectFileData {
  createLogRequirementProcoreProjectFile: CreateLogRequirementProcoreProjectFile
}

export interface CreateLogRequirementProcoreProjectFileVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  logRequirementId: number;
  filename: string;
  uploadUuid: string;
}

export const useCreateLogRequirementProcoreProjectFile = createMutationHook<CreateLogRequirementProcoreProjectFileData, CreateLogRequirementProcoreProjectFileVariables>(gql`
mutation CreateLogRequirementProcoreProjectFile($accountId: ID!, $procoreProjectServerId: ID!, $closeoutLogId: ID!, $logRequirementId: ID!, $filename: String!, $uploadUuid: String!) {
  createLogRequirementProcoreProjectFile(input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, logRequirementId: $logRequirementId, filename: $filename, uploadUuid: $uploadUuid }) {
    success
    logRequirementItem {
      id
      recordType
      recordServerId
      engineName
      payload
    }
  }
}
`);