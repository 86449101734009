import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { TemplateLogRequirement } from "../../../types";

export interface TemplateLogRequirementsCreate {
  templateLogRequirements: TemplateLogRequirement[];
  success: boolean;
}

export interface TemplateLogRequirementsCreateData {
  createTemplateLogRequirements: TemplateLogRequirementsCreate | null;
}

export interface TemplateLogRequirementCreateAttributes {
  templateLogRequirementTypeId?: number;
  templateLogRequirementTypeName: string;
  description?: string;
  templateLogRequirementTradeId?: number;
}

export interface TemplateLogRequirementsCreateVariables {
  accountId: string;
  templateCloseoutLogId: number;
  templateLogRequirementAttributes: TemplateLogRequirementCreateAttributes[];
}

export const useCreateTemplateLogRequirements = createMutationHook<
  TemplateLogRequirementsCreateData,
  TemplateLogRequirementsCreateVariables
>(gql`
  mutation CreateTemplateLogRequirements(
    $accountId: ID!,
    $templateCloseoutLogId: ID!,
    $templateLogRequirementAttributes: [TemplateLogRequirementCreateAttributes!]!
  ) {
    createTemplateLogRequirements(
      input: { accountId: $accountId, templateCloseoutLogId: $templateCloseoutLogId, templateLogRequirementAttributes: $templateLogRequirementAttributes }
    ) {
      templateLogRequirements {
        id
        description
        templateLogRequirementType {
          id
          name
        }
        templateLogRequirementTrade {
          id
          name
        }
        templateLogCustomFields {
          id
          key
          fieldType
          value {
            id
            value
          }
        }
      }
      success
    }
  }
`);
