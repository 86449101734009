import { gql } from "@apollo/client";
import { createMutationHook } from "../GraphqlHelpers";
import { CloseoutLogColumnDefinition, DataGridView } from "../../types";
import { GridFilterItem } from "@mui/x-data-grid-premium";

export interface DataGridViewUpdateData {
  updateDataGridView: {
    success: boolean;
    dataGridView: DataGridView;
  }
}

interface DataGridViewUpdateAttributes {
  columnDefinitions: CloseoutLogColumnDefinition[];
  sortModel: { field: string; sort: string; }[];
  columnVisibility: Record<string, boolean>;
  groupings: string[];
  groupingColumnDefinition: CloseoutLogColumnDefinition;
  filters: GridFilterItem[];
}

export interface DataGridViewUpdateVariables {
  accountId: string;
  id: number;
  attributes: DataGridViewUpdateAttributes;
}

export const useUpdateDataGridView = createMutationHook<
DataGridViewUpdateData,
DataGridViewUpdateVariables
>(gql`
  mutation UpdateDataGridView(
    $accountId: ID!,
    $id: ID!,
    $attributes: DataGridViewUpdateAttributes!
  ) {
    updateDataGridView(
      input: { accountId: $accountId, id: $id, attributes: $attributes }
    ) {
      success
      dataGridView {
        id
        name
        columnDefinitions
        groupingColumnDefinition
        sortModel
        groupings
        columnVisibility
        filters
      }
    }
  }
`);
