import React from 'react';
import Box from '@mui/material/Box';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { Colors } from '../../styles';
import { Typography } from './Typography';
import { CheckBoxOutlined } from '@mui/icons-material';
import Link from '@mui/material/Link';
import { Divider, ListItemIcon, ListItemText, MenuItem, Popover, Stack, styled, Tooltip } from '@mui/material';
import { Button } from './Button';
import EditOutlined from '@mui/icons-material/EditOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';

interface ICloseoutLogBulkEditSelectionPopupProps {
  open: boolean;
  onDeselect: () => void;
  onRequestFiles: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onDuplicate: () => void;
  onDownloadCsv: () => void;
  onExtract: () => void;
  rowSelectionModel: GridRowSelectionModel;
  closeoutLogFileRequestEnabled: boolean;
}

const BlueButton = styled(Button)(() => ({
  '&.MuiButton-outlined': {
    border: `1px solid ${Colors.blue3}`,
    backgroundColor: Colors.white,
    '&:hover': {
      border: `1px solid ${Colors.mediumDarkGray}`,
      backgroundColor: Colors.white,
    },
    '&:focus': {
      boxShadow: ` 0px 0px 0px 1px ${Colors.white}, 0px 0px 0px 4px ${Colors.lightAccentBlue}`,
    },
    '&:disabled': {
      color: Colors.reallyBlueGray,
      border: `1px solid ${Colors.mediumLightGray}`,
    },
  }
}));

export const CloseoutLogBulkEditSelectionPopup = ({
  open,
  onDeselect,
  onRequestFiles,
  onEdit,
  onDelete,
  onDuplicate,
  onDownloadCsv,
  onExtract,
  rowSelectionModel,
  closeoutLogFileRequestEnabled,
}: ICloseoutLogBulkEditSelectionPopupProps) => {
  const [overflowMenuAnchorEl, setOverflowMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <>
      <Popover
        open={Boolean(overflowMenuAnchorEl)}
        anchorEl={overflowMenuAnchorEl}
        onClose={()=> setOverflowMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {
            sx: {
              padding: '8px',
            }
          }
        }}>
        <MenuItem
          onClick={()=> {
            setOverflowMenuAnchorEl(null);
            onExtract();
          }}
        >
          <ListItemIcon>
            <ArchiveOutlinedIcon fontSize='small' sx={{ color: Colors.darkerGray }} />
          </ListItemIcon>
          <ListItemText sx={{ color: Colors.darkerGray }} primary="Extract" />
        </MenuItem>
        <MenuItem
          onClick={()=> {
            setOverflowMenuAnchorEl(null);
            onDelete();
          }}
        >
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize='small' sx={{ color: Colors.warningRed }} />
          </ListItemIcon>
          <ListItemText sx={{ color: Colors.warningRed }} primary="Delete" />
        </MenuItem>
      </Popover>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          position: 'fixed',
          bottom: open ? '44px' : '24px',
          transition: 'all 0.2s ease-out',
          opacity: open ? 1 : 0,
          left: 0,
          right: 0,
          width: 'fit-content',
          margin: '0 auto',
          zIndex: '20',
        }}
      >
        <Box
          sx={{
            display: open ? 'flex' : 'none',
            alignItems: 'center',
            borderRadius: '8px',
            backgroundColor: Colors.blue1,
            boxShadow:
              '0px 6px 16px 1px rgba(36, 105, 158, 0.20), 0px 4px 4px -1px rgba(0, 130, 229, 0.20)',
            padding: 0  ,
          }}
        >
          <Box height={1} width={'8px'} sx={{
            backgroundColor: Colors.newAccentBlue,
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
          }}></Box>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} padding={'12px 16px 12px 20px'} gap={'20px'}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <CheckBoxOutlined sx={{ fontSize: '20px' }} />
                <Typography typestyle="s bold">
                  {`${rowSelectionModel.length} items selected`}
                </Typography>
              </Box>
              <Link
                underline="hover"
                onClick={onDeselect}
                sx={{ cursor: 'pointer', color: Colors.newAccentBlue }}
              >
                <Typography typestyle="xs">Deselect All</Typography>
              </Link>
            </Box>
            <Divider
              sx={{
                height: '20px',
                borderWidth: '1px',
                borderColor: Colors.blue3,
              }}
            />
            <Stack direction="row" gap="8px" alignItems={'center'}>
              {
                closeoutLogFileRequestEnabled && (
                  <BlueButton
                    onClick={onRequestFiles}
                    size="small"
                    variant="outlined"
                    buttonborderstyle="pill"
                    startIcon={<AddCircleOutlinedIcon fontSize='small' />}
                  >
                    Request Files
                  </BlueButton>
                )
              }
              <BlueButton
                onClick={onEdit}
                size='small'
                variant="outlined"
                buttonborderstyle="pill"
                startIcon={<EditOutlined fontSize='small' />}
              >
                Edit
              </BlueButton>
              <Tooltip title="Duplicate" placement='top'>
                <BlueButton
                  sx={{
                    minWidth: '40px',
                  }}
                  onClick={onDuplicate}
                  variant="outlined"
                  size="small"
                  buttonborderstyle="pill"
                >
                  <ContentCopyIcon fontSize='small' />
                </BlueButton>
              </Tooltip>
              <Tooltip title="Download CSV" placement='top'>
                <BlueButton
                  sx={{
                    minWidth: '40px',
                  }}
                  onClick={onDownloadCsv}
                  variant="outlined"
                  size="small"
                  buttonborderstyle="pill">
                  <DownloadOutlinedIcon fontSize='small' />
                </BlueButton>
              </Tooltip>
              <Tooltip title="Delete" placement='top'>
                <BlueButton
                  sx={{
                    minWidth: '40px',
                  }}
                  onClick={(evt) => {
                    setOverflowMenuAnchorEl(evt.currentTarget);
                  }}
                  variant="outlined"
                  size="small"
                  buttonborderstyle="pill"
                >
                  <MoreHoriz fontSize='small' />
                </BlueButton>
              </Tooltip>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};
