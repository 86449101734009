import React from 'react';


import { DataGridCheckbox, StyledDataGridPremium } from '../styles';
import { ResponsibleContractor } from '../types';
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef, GridLogicOperator, GridRowsProp } from '@mui/x-data-grid-premium';
import { VendorListEmptyState } from './VendorListEmptyState';
import { Typography } from './shared/Typography';
import Box from '@mui/material/Box';

interface IVendorListDataGrid {
  selectedCompanies: Record<string, boolean>;
  onSelectectedCompaniesChanged: (selectedCompanies: Record<string, boolean>) => void;
  responsibleContractors: ResponsibleContractor[];
  filterValue: string;
}

export const VendorListDataGrid = ({
  responsibleContractors,
  onSelectectedCompaniesChanged,
  selectedCompanies,
  filterValue,
}: IVendorListDataGrid): JSX.Element => {
  const transformContractorsToRows = (): GridRowsProp[] => {
    return responsibleContractors.map((responsibleContractor: ResponsibleContractor): GridRowsProp => {
      return {
        id: responsibleContractor.id,
        name: responsibleContractor.name,
        businessPhone: responsibleContractor.businessPhone,
        emailAddress: responsibleContractor.emailAddress,
        city: responsibleContractor.city,
        countryCode: responsibleContractor.countryCode,
        stateCode: responsibleContractor.stateCode,
        zip: responsibleContractor.zip,
        address: responsibleContractor.address,
      } as unknown as GridRowsProp;
    });
  };

  const columns = React.useMemo(() => {
    return [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        width: 60,
        maxWidth: 60,
        align: 'center',
      },
      {
        field: 'name',
        headerName: 'Company',
        type: 'string',
        editable: false,
        minWidth: 50,
        maxWidth: Infinity,
        width: 300,
        resizable: true,
        sortable: true,
        groupable: false
      },
      {
        field: 'contact',
        headerName: 'Contact',
        editable: false,
        minWidth: 50,
        maxWidth: Infinity,
        width: 300,
        type: 'string',
        resizable: true,
        sortable: false,
        groupable: false,
        renderCell: (params) => (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            whiteSpace: 'normal'
          }}>
            <Typography noWrap>
              {params.row.businessPhone}
            </Typography>
            <Typography noWrap>
              {params.row.emailAddress}
            </Typography>
         </Box>
        )
      },
      {
        field: 'address',
        headerName: 'Address',
        editable: false,
        minWidth: 50,
        maxWidth: Infinity,
        width: 300,
        type: 'string',
        resizable: true,
        sortable: false,
        groupable: false,
        renderCell: (params) => {
          return (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              whiteSpace: 'normal'
            }}>
              <Typography noWrap>
                {params.row.address}
              </Typography>
              <Typography noWrap>
                {`${params.row.city || ''}` + (params.row.stateCode ? `, ${params.row.stateCode}` : '') + (params.row.zip ? ` ${params.row.zip}` : '')}
              </Typography>
              <Typography noWrap>
                {params.row.countryCode}
              </Typography>
            </Box>
          )
        }
      }
    ] as GridColDef[];
  }, []);

  return (
    <StyledDataGridPremium
      filterModel={{
        items: filterValue.split(' ').map( (val, i) => {
          return {
            id: i,
            value: val,
            field: 'name',
            operator: 'contains',
          }
        }),
        logicOperator: GridLogicOperator.And,
      }}
      columns={columns}
      rows={transformContractorsToRows()}
      hideFooter={true}
      rowSelectionModel={Object.keys(selectedCompanies)}
      hideFooterRowCount={true}
      hideFooterSelectedRowCount={true}
      hideFooterPagination={true}
      onRowSelectionModelChange={(rowSelectionModel) => {
        onSelectectedCompaniesChanged(rowSelectionModel.reduce((acc, curr) => {
          acc[curr] = true;

          return acc;
        }, {}));
      }}
      slots={{
        baseCheckbox: DataGridCheckbox,
        noRowsOverlay: VendorListEmptyState,
      }}
      disableColumnMenu={true}
      getRowId={(row) => row.id}
      getRowHeight={() => 72}
      checkboxSelection={true}
      disableRowSelectionOnClick={true}
      />
  );
};
