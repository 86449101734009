import { Box } from '@mui/material';
import React from 'react';
import { NewPackageContext } from '../../contexts/NewPackageContextProvider';
import { ToolTabData } from '../../contexts/NewPackageContext';
import { CorrespondenceExtractOptions } from '../../types/correspondence';
import ExportOptionToggle from './ExportOptionToggle';

const CorrespondenceOptions = (props: { toolTabIndex: number, toolTabData: ToolTabData }): JSX.Element => {
  const { toolTabIndex, toolTabData } = props;

  const { dispatch } = React.useContext(NewPackageContext);

  const correspondenceExtractOptions = toolTabData.extractOptions as CorrespondenceExtractOptions;


  return (
    <Box display="flex" flexDirection="column" width={1} gap={'24px'} height={1} padding={'20px 40px'} style={{ overflowY: 'auto' }}>
      <h3 style={{ padding: 0, margin: 0 }}>General</h3>
      <ExportOptionToggle
        label={'Include official Correspondence responses only'}
        checked={(toolTabData.extractOptions as CorrespondenceExtractOptions).response_option == 'official_only'}
        onChange={(event) => {
          dispatch({
            type: 'SET_TOOL_OPTIONS',
            value: {
              toolTabIndex: toolTabIndex,
              option: {
                ...toolTabData.extractOptions,
                response_option: event.target.checked ? 'official_only' : 'all'
              }
            }
          });
        }} />
      <ExportOptionToggle
        label={'Include Linked Records'}
        checked={correspondenceExtractOptions.include_linked_records}
        onChange={(event) => {
          dispatch({
            type: 'SET_TOOL_OPTIONS',
            value: {
              toolTabIndex: toolTabIndex,
              option: {
                ...correspondenceExtractOptions,
                include_linked_records: event.target.checked,
                include_origin_correspondence: event.target.checked ? correspondenceExtractOptions.include_origin_correspondence : false,
                include_origin_rfi: event.target.checked ? correspondenceExtractOptions.include_origin_rfi : false,
                include_linked_correspondences: event.target.checked ? correspondenceExtractOptions.include_linked_correspondences : false,
                include_linked_rfis: event.target.checked ? correspondenceExtractOptions.include_linked_rfis : false,
                include_linked_drawings: event.target.checked ? correspondenceExtractOptions.include_linked_drawings : false
              }
            }
          });
        }} />

      {
        correspondenceExtractOptions.include_linked_records && (
          <Box display={"flex"} gap={'24px'} flexDirection={'column'} paddingLeft={'12px'}>
            <ExportOptionToggle
              label={'Include Origin Correspondence'}
              checked={correspondenceExtractOptions.include_origin_correspondence}
              onChange={(event) => {
                dispatch({
                  type: 'SET_TOOL_OPTIONS',
                  value: {
                    toolTabIndex: toolTabIndex,
                    option: {
                      ...correspondenceExtractOptions,
                      include_origin_correspondence: event.target.checked
                    }
                  }
                });
              }} />
            <ExportOptionToggle
              label={'Include Origin RFI'}
              checked={correspondenceExtractOptions.include_origin_rfi}
              onChange={(event) => {
                dispatch({
                  type: 'SET_TOOL_OPTIONS',
                  value: {
                    toolTabIndex: toolTabIndex,
                    option: {
                      ...correspondenceExtractOptions,
                      include_origin_rfi: event.target.checked
                    }
                  }
                });
              }} />
            <ExportOptionToggle
              label={'Include Linked Correspondences'}
              checked={correspondenceExtractOptions.include_linked_correspondences}
              onChange={(event) => {
                dispatch({
                  type: 'SET_TOOL_OPTIONS',
                  value: {
                    toolTabIndex: toolTabIndex,
                    option: {
                      ...correspondenceExtractOptions,
                      include_linked_correspondences: event.target.checked
                    }
                  }
                });
              }} />
            <ExportOptionToggle
              label={'Include Linked RFIs'}
              checked={correspondenceExtractOptions.include_linked_rfis}
              onChange={(event) => {
                dispatch({
                  type: 'SET_TOOL_OPTIONS',
                  value: {
                    toolTabIndex: toolTabIndex,
                    option: {
                      ...correspondenceExtractOptions,
                      include_linked_rfis: event.target.checked
                    }
                  }
                });
              }} />
            <ExportOptionToggle
              label={'Include Linked Drawings'}
              checked={correspondenceExtractOptions.include_linked_drawings}
              onChange={(event) => {
                dispatch({
                  type: 'SET_TOOL_OPTIONS',
                  value: {
                    toolTabIndex: toolTabIndex,
                    option: {
                      ...correspondenceExtractOptions,
                      include_linked_drawings: event.target.checked
                    }
                  }
                });
              }} />
          </Box>
        )
      }
    </Box>
  )
};

export default CorrespondenceOptions;