import React from 'react';
import ExtractsProDialog from './ExtractsProDialog';
import { Typography } from './Typography';
import { Colors } from '../../styles';
import { Box } from '@mui/material';

export const DeactiveProcoreProjectLicenseModal = (props: {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  onDeactivateProject: () => void;
}) => {

  return (
    <ExtractsProDialog
      title='Deactive Project'
      maxWidth="sm"
      open={props.open}
      PaperProps={{}}
      closeButton={{
        onClick: props.onClose,
        disabled: props.loading
      }}
      secondaryButton={{
        text: 'Cancel',
        onClick: props.onClose,
        disabled: props.loading
      }}
      primaryButton={{
        text: 'Deactivate',
        onClick: props.onDeactivateProject,
        disabled: props.loading,
        loading: props.loading,
        primarycolorbackground: Colors.red,
        primarycolor: Colors.white
      }}
    >
      <Box display="flex" flexDirection="column" gap={'12px'}>
        <Typography typestyle="m">Once you deactivate this project license, it will be permanently revoked and cannot be reassigned to another project. Are you sure you want to proceed?</Typography>
      </Box>
    </ExtractsProDialog>
  );
};