import Box from '@mui/material/Box';
import React, { useState, useContext } from 'react';
import { Colors, ExtractsCheckbox, FieldContainer, FieldLabel } from '../styles';
import { BodyCardHeaderBar } from './shared/BodyCardHeaderBar';
import { BodyCard } from './shared/BodyCard';
import { Typography } from './shared/Typography';
import { InfoOutlined } from '@mui/icons-material';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {
  NewLogRequirementTrade,
  NewLogRequirementType,
  TemplateLogCustomFieldLovEntry
} from '../types';
import AddCircle from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import { ModalFooter } from './shared/ModalFooter';
import { Button, LoadingButton } from './shared/Button';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useBulkUpdateTemplateLogRequirements } from '../graphql/mutations/closeout/UpdateTemplateLogRequrements';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { ConfirmationModal } from './shared/ConfirmationModal';
import { useCreateTemplateLogRequirementType } from '../graphql/mutations/closeout/CreateTemplateLogRequirementType';
import { CircularProgress } from '@mui/material';
import { useCreateTemplateLogRequirementTrade } from '../graphql/mutations/closeout/CreateTemplateLogRequirementTrade';
import { TemplateCloseoutLogContext } from '../contexts/TemplateCloseoutLogContextProvider';

const ShadowScrollBox = styled(Box)(() => {
  return {
    overflow: 'auto',
    background:
      'linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top, linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom, radial-gradient(farthest-side at 50% 0,rgba(0, 0, 0, 0.15),rgba(0, 0, 0, 0)) center top, radial-gradient(farthest-side at 50% 100%,rgba(0, 0, 0, 0.15),rgba(0, 0, 0, 0)) center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 40px, 100% 40px, 100% 10px, 100% 10px',
    backgroundAttachment: 'local, local, scroll, scroll',
  };
});

interface IBulkEditLogRequirementsProps {
  rowSelectionModel: GridRowSelectionModel;
  open: boolean;
  accountId: string;
  templateCloseoutLogId: number;
  close: () => void;
  clearSelection: () => void;
}

const filter = createFilterOptions<NewLogRequirementType>();

export const BulkEditTemplateLogRequirements = ({
  rowSelectionModel,
  open,
  accountId,
  templateCloseoutLogId,
  close,
  clearSelection,
}: IBulkEditLogRequirementsProps) => {
  const { state: templateCloseoutLogState, dispatch } = useContext(TemplateCloseoutLogContext);
  const [selectedLogReqType, setSelectedLogReqType] = useState<NewLogRequirementType>(null);
  const [selectedLogReqTrade, setSelectedLogReqTrade] = useState<NewLogRequirementTrade>(null);
  const [logRequirementDescription, setLogRequirementDescription] = useState<string>('');
  const [selectedFields, setSelectedFields] = useState<Record<string, boolean>>({});
  const [emptyFieldsModalIsOpen, setEmptyFieldsModalIsOpen] = useState<boolean>(false);
  const [selectedCustomFieldValues, setSelectedCustomFieldValues] = useState<Record<string, TemplateLogCustomFieldLovEntry>>({});

  const [bulkUpdateLogRequirements, { loading }] = useBulkUpdateTemplateLogRequirements({
    accountId: accountId,
    templateCloseoutLogId: templateCloseoutLogId,
    templateLogRequirementIds: [],
    attributes: {},
  });

  const [ createLogRequirementType, { loading: loadingCreateLogRequirementType } ] = useCreateTemplateLogRequirementType({
    accountId: accountId,
    templateCloseoutLogId: templateCloseoutLogId,
    name: ''
  });

  const [ createLogRequirementTrade, { loading: loadingCreateLogRequirementTrade } ] = useCreateTemplateLogRequirementTrade({
    accountId: accountId,
    templateCloseoutLogId: templateCloseoutLogId,
    name: ''
  });

  const createAndSelectLogRequirementType = (name: string) => {
    createLogRequirementType({
      variables: {
        accountId: accountId,
        templateCloseoutLogId: templateCloseoutLogId,
        name: name,
      }
    }).then(({ data }) => {
      if (data.createTemplateLogRequirementType.success) {
        setSelectedLogReqType(data.createTemplateLogRequirementType.templateLogRequirementType);

        dispatch({
          type: 'ADD_AND_SORT_TEMPLATE_LOG_REQUIREMENT_TYPE',
          value: data.createTemplateLogRequirementType.templateLogRequirementType
        });
      }
    });
  }

  const createAndSelectLogRequirementTrade = (name: string) => {
    createLogRequirementTrade({
      variables: {
        accountId: accountId,
        templateCloseoutLogId: templateCloseoutLogId,
        name: name,
      }
    }).then(({ data }) => {
      if (data.createTemplateLogRequirementTrade.success) {
        setSelectedLogReqTrade(data.createTemplateLogRequirementTrade.templateLogRequirementTrade);

        dispatch({
          type: 'ADD_AND_SORT_TEMPLATE_LOG_REQUIREMENT_TRADE',
          value: data.createTemplateLogRequirementTrade.templateLogRequirementTrade
        });
      }
    });
  }

  const getAttributes = () => {
    const acc = {};

    if (selectedFields['type']) {
      acc['template_log_requirement_type_id'] = selectedLogReqType?.id || null;
    }
    if (selectedFields['trade']) {
      acc['template_log_requirement_trade_id'] = selectedLogReqTrade?.id || null;
    }
    if (selectedFields['description']) {
      acc['description'] = logRequirementDescription;
    }

    Object.keys(selectedFields).filter((key) => {
      return key.startsWith('custom_field_')
    }).forEach((key) => {
      acc[key] = selectedCustomFieldValues[key]?.id || null;
    });

    return acc;
  };

  const anyFieldsActiveButEmpty = () => {
    return (
      (selectedFields['type'] && !selectedLogReqType) ||
      (selectedFields['trade'] && !selectedLogReqTrade) ||
      (selectedFields['description'] && !logRequirementDescription) ||
        Object.keys(selectedFields).filter((key) => {
          return key.startsWith('custom_field_')
        }).some((key) => {
          return !selectedCustomFieldValues[key];
        })
    );
  };

  const resetState = () => {
    setSelectedFields({});
    setSelectedLogReqType(null);
    setSelectedLogReqTrade(null);
    setSelectedCustomFieldValues({});
    setLogRequirementDescription('');
  }

  const handleApplyAll = () => {
    bulkUpdateLogRequirements({
      variables: {
        accountId: accountId,
        templateCloseoutLogId: templateCloseoutLogId,
        templateLogRequirementIds: rowSelectionModel as number[],
        attributes: getAttributes(),
      },
      fetchPolicy: 'network-only',
    })
      .then((result) => {
        dispatch({
          type: 'UPDATE_TEMPLATE_LOG_REQUIREMENTS',
          value: {
            templateLogRequirements: result.data.bulkUpdateTemplateLogRequirements.templateLogRequirements
          },
        });
        close();
        resetState();
        clearSelection();
      })
      .catch((err) => {
        console.log(err);
        close();
        dispatch({
          type: 'SET_DISPLAY_FAILURE_TOAST',
          value: {
            isOpen: true,
            message: 'Failed to bulk edit requirements.',
          },
        });
      });
  };

  return (
    <>
      <ConfirmationModal
        open={emptyFieldsModalIsOpen}
        headerText="Empty Fields"
        bodyText={`Enabling a field but leaving the value blank will result in that field being cleared. Do you wish to continue anyway?`}
        submitText="Yes, Apply Changes"
        onSubmit={() => {
          setEmptyFieldsModalIsOpen(false);
          handleApplyAll();
        }}
        submitLoading={loading}
        onClose={() => setEmptyFieldsModalIsOpen(false)}
      />
      <Box
        sx={{
          position: open ? 'static' : 'absolute',
          display: 'flex',
          transition: 'all 0.25s ease-out',
          opacity: open ? 1 : 0.7,
          marginBottom: '20px',
          width: open ? '400px' : '240px',
        }}
      >
        <BodyCard
          sx={{
            display: open ? 'flex' : 'none',
            backgroundColor: `${Colors.white}`,
            height: 'auto',
            minWidth: '400px',
          }}
        >
          <BodyCardHeaderBar title="Bulk Edit">
            <IconButton onClick={close}>
              <Close sx={{ fontSize: '20px' }} />
            </IconButton>
          </BodyCardHeaderBar>
          <ShadowScrollBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: '1 1 0',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                padding: '4px 28px 16px 28px',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  padding: '18px 16px',
                  alignItems: 'center',
                  gap: '12px',
                  borderRadius: '8px',
                  border: `1px solid ${Colors.lightishGray}`,
                  background: Colors.blue0,
                  boxShadow:
                    '0px 2px 4px 1px rgba(26, 32, 36, 0.04), 0px 1px 10px 0px rgba(26, 32, 36, 0.06)',
                }}
              >
                <InfoOutlined sx={{ color: Colors.newAccentBlue }} />
                <Typography typestyle="xs" sx={{ lineHeight: '1rem' }}>
                  Select the fields {`you’d`} like to edit. Changes to the
                  fields will apply to all <b>{rowSelectionModel.length}</b>{' '}
                  selected items.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                paddingX: '28px',
                flexGrow: 1,
              }}
            >
              <FieldContainer>
                <FieldLabel
                  control={
                    <ExtractsCheckbox
                      checked={selectedFields['type'] || false}
                      onChange={(evt) => {
                        setSelectedFields({
                          ...selectedFields,
                          type: evt.target.checked,
                        });
                      }}
                    />
                  }
                  label="Type"
                />
                <Autocomplete
                  size="small"
                  loading={loadingCreateLogRequirementType}
                  value={selectedLogReqType}
                  isOptionEqualToValue={(option, value) =>
                    (option.name || '').toLowerCase() ===
                    (value.name || '').toLowerCase()
                  }
                  disabled={!selectedFields['type']}
                  renderOption={(props, option) => {
                    const isNew = !templateCloseoutLogState.templateLogRequirementTypes.some(
                      (type) =>
                        type.name.localeCompare(option.name, undefined, {
                          numeric: true,
                          sensitivity: 'base',
                        }) === 0
                    );
                    if (isNew) {
                      return (
                        <li {...props}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                            }}
                          >
                            <AddCircle fontSize="small" />
                            {`Add new type: "${option.name}"`}
                          </Box>
                        </li>
                      );
                    } else {
                      return <li {...props}>{option.name}</li>;
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value if not blank and no exact match
                    const isExisting = options.some(
                      (option) =>
                        inputValue.localeCompare(option.name, undefined, {
                          numeric: true,
                          sensitivity: 'base',
                        }) === 0
                    );
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        name: inputValue,
                        id: undefined,
                      } as NewLogRequirementType);
                    }

                    return filtered;
                  }}
                  onChange={(evt, value, reason) => {
                    switch (reason) {
                      case 'clear': {
                        setSelectedLogReqType(null);
                        break;
                      }

                      case 'createOption':
                      case 'removeOption':
                      case 'selectOption': {
                        if (typeof value === 'string') {
                          createAndSelectLogRequirementType(value);
                        } else if (value.id === undefined) {
                          createAndSelectLogRequirementType(value.name);
                        } else {
                          setSelectedLogReqType(value);
                        }

                        break;
                      }

                      default:
                        break;
                    }
                  }}
                  clearOnBlur
                  handleHomeEndKeys
                  slotProps={{
                    listbox: {
                      sx: {
                        maxHeight: '200px',
                      },
                    }
                  }}
                  renderInput={(params) =>{
                    return <TextField
                      {...params}
                      autoFocus
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingCreateLogRequirementType ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }
                      }} />
                  }}
                  getOptionLabel={(option) => {
                    return (option as NewLogRequirementType).name;
                  }}
                  options={templateCloseoutLogState.templateLogRequirementTypes}
                />
              </FieldContainer>
              <FieldContainer>
                <FieldLabel
                  control={
                    <ExtractsCheckbox
                      checked={selectedFields['trade'] || false}
                      onChange={(evt) => {
                        setSelectedFields({
                          ...selectedFields,
                          trade: evt.target.checked,
                        });
                      }}
                    />
                  }
                  label="Trade"
                />
                <Autocomplete
                  size="small"
                  loading={loadingCreateLogRequirementTrade}
                  value={selectedLogReqTrade}
                  isOptionEqualToValue={(option, value) =>
                    (option.name || '').toLowerCase() ===
                    (value.name || '').toLowerCase()
                  }
                  disabled={!selectedFields['trade']}
                  renderOption={(props, option) => {
                    const isNew = !templateCloseoutLogState.templateLogRequirementTrades.some(
                      (trade) =>
                        trade.name.localeCompare(option.name, undefined, {
                          numeric: true,
                          sensitivity: 'base',
                        }) === 0
                    );
                    if (isNew) {
                      return (
                        <li {...props}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                            }}
                          >
                            <AddCircle fontSize="small" />
                            {`Add new trade: "${option.name}"`}
                          </Box>
                        </li>
                      );
                    } else {
                      return <li {...props}>{option.name}</li>;
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value if not blank and no exact match
                    const isExisting = options.some(
                      (option) =>
                        inputValue.localeCompare(option.name, undefined, {
                          numeric: true,
                          sensitivity: 'base',
                        }) === 0
                    );
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        name: inputValue,
                        id: undefined,
                      } as NewLogRequirementTrade);
                    }

                    return filtered;
                  }}
                  onChange={(evt, value, reason) => {
                    switch (reason) {
                      case 'clear': {
                        setSelectedLogReqTrade(null);
                        break;
                      }

                      case 'createOption':
                      case 'removeOption':
                      case 'selectOption': {
                        if (typeof value === 'string') {
                          createAndSelectLogRequirementTrade(value);
                        } else if (value.id === undefined) {
                          createAndSelectLogRequirementTrade(value.name);
                        } else {
                          setSelectedLogReqTrade(value);
                        }

                        break;
                      }

                      default:
                        break;
                    }
                  }}
                  clearOnBlur
                  handleHomeEndKeys
                  slotProps={{
                    listbox: {
                      sx: {
                        maxHeight: '200px',
                      },
                    }
                  }}
                  renderInput={(params) =>{
                    return <TextField
                      {...params}
                      autoFocus
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingCreateLogRequirementTrade ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }
                      }} />
                  }}
                  getOptionLabel={(option) => {
                    return (option as NewLogRequirementTrade).name;
                  }}
                  options={templateCloseoutLogState.templateLogRequirementTrades}
                />
              </FieldContainer>
              {
                templateCloseoutLogState.templateLogCustomFields.map((customField) => {
                  return (
                    <FieldContainer key={customField.key}>
                      <FieldLabel
                        control={
                          <ExtractsCheckbox
                            checked={selectedFields[customField.key] || false}
                            onChange={(evt) => {
                              setSelectedFields({
                                ...selectedFields,
                                [customField.key]: evt.target.checked,
                              });
                            }}
                          />
                        }
                        label={customField.name}
                      />
                      <Autocomplete
                        size="small"
                        disabled={!selectedFields[customField.key]}
                        value={selectedCustomFieldValues[customField.key] || null}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(evt, value, reason) => {
                          switch (reason) {
                            case 'clear':
                              setSelectedCustomFieldValues({
                                ...selectedCustomFieldValues,
                                [customField.key]: null,
                              });
                              break;
                            case 'removeOption':
                            case 'selectOption':
                              setSelectedCustomFieldValues({
                                ...selectedCustomFieldValues,
                                [customField.key]: value,
                              });
                              break;

                            default:
                              break;
                          }
                        }}
                        clearOnBlur
                        renderInput={(params) => <TextField {...params} />}
                        getOptionLabel={(option) => {
                          return (option as TemplateLogCustomFieldLovEntry).value;
                        }}
                        options={customField.values}
                      />
                    </FieldContainer>
                  )
                })
              }
              <FieldContainer>
                <FieldLabel
                  control={
                    <ExtractsCheckbox
                      checked={selectedFields['description'] || false}
                      onChange={(evt) => {
                        setSelectedFields({
                          ...selectedFields,
                          description: evt.target.checked,
                        });
                      }}
                    />
                  }
                  label="Description"
                />
                <TextField
                  disabled={!selectedFields['description']}
                  size="medium"
                  multiline
                  rows={8}
                  autoComplete="off"
                  value={logRequirementDescription}
                  onChange={(event) =>
                    setLogRequirementDescription(event.currentTarget.value)
                  }
                />
              </FieldContainer>
            </Box>
          </ShadowScrollBox>
          <ModalFooter>
            <Button variant="outlined" buttonborderstyle="pill" onClick={() => {
              resetState();
              close();
            }}>
              Cancel
            </Button>
            <Tooltip
              title="At least one field has to be checked to bulk edit"
              disableHoverListener={Object.values(selectedFields).includes(
                true
              )}
              placement="top"
            >
              <span>
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  buttonborderstyle="pill"
                  disabled={!Object.values(selectedFields).includes(true)}
                  onClick={() => {
                    if (anyFieldsActiveButEmpty()) {
                      setEmptyFieldsModalIsOpen(true);
                    } else {
                      handleApplyAll();
                    }
                  }}
                >
                  Apply to All
                </LoadingButton>
              </span>
            </Tooltip>
          </ModalFooter>
        </BodyCard>
      </Box>
    </>
  );
};
