import React from 'react';
import { Box, IconButton, ListItemText, MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { Typography } from './Typography';
import { Colors } from '../../styles';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import Close from '@mui/icons-material/Close';
import { ProcoreToolGroupings } from '../../types';

export interface ICloseoutGroupByProps {
  groupings: ProcoreToolGroupings[];
  selectedGroupings: string[];
  setSelectedGroupings: (selectedGroupings: string[]) => void;
}

export const CloseoutGroupBy = ({
  groupings,
  selectedGroupings,
  setSelectedGroupings,
}: ICloseoutGroupByProps) => {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value: newGroupings },
    } = event;

    setSelectedGroupings(newGroupings as string[]);
  };

  const [hoverState, setHoverState] = React.useState<Record<string, boolean>>({});

  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap="8px">
      {
        selectedGroupings.map((groupingAttribute, index) => {
          const grouping = groupings.find(g => g.attribute === groupingAttribute);
          return (
            <Box key={`selected-groupings-${index}`}
                display="flex"
                flexDirection="row"
                alignItems="center"
                onBlur={() => setHoverState({ ...hoverState, [groupingAttribute]: false })}
                onMouseEnter={() => setHoverState({ ...hoverState, [groupingAttribute]: true })}
                onMouseLeave={() => setHoverState({ ...hoverState, [groupingAttribute]: false })}
                gap="8px">
              {
                (hoverState[groupingAttribute] || false) ? (
                  <IconButton sx={{padding: '2px'}} size='small' disableFocusRipple disableRipple disableTouchRipple onClick={() => {
                    setSelectedGroupings(selectedGroupings.filter(g => g !== groupingAttribute));
                    setHoverState({ ...hoverState, [groupingAttribute]: false });
                  }}>
                    <Close sx={{fontSize: '14px'}}/>
                  </IconButton>
                ) : <FolderOpenOutlinedIcon fontSize='small' />
              }
              <Typography typestyle='m'>{grouping.label}</Typography>
              <Typography typestyle='m' color={Colors.mediumGray}>&nbsp;/&nbsp;</Typography>
            </Box>
          );
        })
      }
      <TextField
        select
        size='small'
        value={selectedGroupings}
        slotProps={{
          select: {
            onChange: handleChange,
            multiple: true,
            renderValue: () => "Group By",
            MenuProps: {
              PaperProps: {
                sx: {
                  padding: 0,
                }
              },
              sx: {
                padding: 0,
                height: '250px',
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
              }
            },
            displayEmpty: true,
          }
        }}
        disabled={groupings.length === 0}

      >
        {groupings.map((group) => {
          const indexOf = selectedGroupings.indexOf(group.attribute);
          const exists = indexOf !== -1;

          return (
            <MenuItem
              disabled={exists}
              key={`grouping-menu-item-${group.label}`}
              value={group.attribute}
            >
              <ListItemText primary={group.label} />
            </MenuItem>
          );
        })}
      </TextField>
    </Box>
  );
};
