import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CloseoutLogDataGridView, LogFileRequest } from '../types';
import { Accordion, AccordionDetails, AccordionSummary, Box, styled } from '@mui/material';
import { Colors } from '../styles';
import { Typography } from './shared/Typography';
import { CloseoutLogContext } from '../contexts/CloseoutLogContextProvider';
import { endOfToday, format, isPast, isSameDay, parse } from 'date-fns';
import ErrorIcon from '@mui/icons-material/Error';

interface IFileRequestDetailProps {
  logFileRequest: LogFileRequest;
  dateFormat: string;
  onExpansionChanged: (expansionState: Record<string, boolean>) => void;
}

const FieldContainer = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
  };
});

const AccordionLabel = styled(Typography)(() => {
  return {
    flex: '0 0 35%',
  };
});

const AccordionValue = styled(Typography)(() => {
  return {
    flex: 1,
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
  };
});

const CustomAccordion = styled(Accordion)(() => {
  return {
    '&.Mui-expanded': {
      margin: 0,
      boxShadow: 'none',
      border: 'none',
      borderRadius: '0',
    },
    '&.MuiAccordion-root': {
      boxShadow: 'none',
      border: 'none',
      borderRadius: '0',
    },
    '&.MuiAccordion-root::before': {
      display: 'none',
      boxShadow: 'none',
      borderRadius: '0',
    },
  };
});

const CustomAccordionSummary = styled(AccordionSummary)(() => {
  return {
    '&.MuiAccordionSummary-root': {
      backgroundColor: Colors.lightestGray,
    },
    '&.Mui-expanded': {
      minHeight: '48px'
    },
    '& .MuiAccordionSummary-content': {
      margin: 0, // Removes the default margin
    },
    '&.Mui-expanded .MuiAccordionSummary-content': {
      margin: 0, // Ensures no margin when expanded
    },
  }
});

const CustomAccordionDetails = styled(AccordionDetails)(() => {
  return {
    '&.MuiAccordionDetails-root': {
      padding: '24px 12px',
    },
  }
});

const getValues = (dataGridView?: CloseoutLogDataGridView): Record<string, boolean> => {
  const general = dataGridView?.detailViewOptions['file_request_sidebar_tab_general_expanded'] || false;
  const workflow = dataGridView?.detailViewOptions['file_request_sidebar_tab_workflow_expanded'] || false;
  const details = dataGridView?.detailViewOptions['file_request_sidebar_tab_details_expanded'] || false;
  return {
    'file_request_sidebar_tab_general_expanded': general,
    'file_request_sidebar_tab_workflow_expanded': workflow,
    'file_request_sidebar_tab_details_expanded': details,
  }
}

export const FileRequestDetail = ({
  logFileRequest,
  dateFormat,
  onExpansionChanged,
}: IFileRequestDetailProps) => {
  const { state: closeoutLogState } = React.useContext(CloseoutLogContext);

  const [fileRequestSidebarExpanded, setFileRequestSidebarExpanded] = React.useState(getValues(closeoutLogState.currentCloseoutLogFileRequestDataGridView));

  React.useEffect(() => {
    if (closeoutLogState.currentCloseoutLogFileRequestDataGridView) {
      setFileRequestSidebarExpanded(getValues(closeoutLogState.currentCloseoutLogFileRequestDataGridView));
    }
  }, [closeoutLogState.currentCloseoutLogFileRequestDataGridView]);

  const dueDate = parse(logFileRequest.dueDate, 'yyyy-MM-dd', new Date());

  return (
    <Box display={'flex'} flexDirection={'column'} width={1} height={1} padding={'28px'} gap={'12px'}>
      <CustomAccordion sx={{
        '&.Mui-expanded': {
          margin: 0
        }
      }} expanded={fileRequestSidebarExpanded['file_request_sidebar_tab_general_expanded']} onChange={() => {
        const newState= {
          ...fileRequestSidebarExpanded,
          ['file_request_sidebar_tab_general_expanded']: !fileRequestSidebarExpanded['file_request_sidebar_tab_general_expanded'],
        }

        setFileRequestSidebarExpanded(newState);

        onExpansionChanged(newState);
      }}>
        <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography typestyle='l'>General</Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Box display={'flex'} flexDirection={'column'} gap={'24px'}>
            <FieldContainer>
              <AccordionLabel typestyle='s bold'>Title</AccordionLabel>
              <AccordionValue>{logFileRequest.title}</AccordionValue>
            </FieldContainer>

            <FieldContainer>
              <AccordionLabel typestyle='s bold'>Description</AccordionLabel>
              <AccordionValue>{logFileRequest.logRequirementDescription}</AccordionValue>
            </FieldContainer>

            <FieldContainer>
              <AccordionLabel typestyle='s bold'>Status</AccordionLabel>
              <AccordionValue>{logFileRequest.vendorWorkflowRequest.vendorWorkflowResponse?.translatedStatus || logFileRequest.vendorWorkflowRequest.translatedStatus}</AccordionValue>
            </FieldContainer>

            <FieldContainer>
              <AccordionLabel typestyle='s bold'>Due Date</AccordionLabel>
              {
                !logFileRequest.complete && (!isSameDay(dueDate, endOfToday()) && isPast(dueDate)) ? (
                  <Box display={'flex'} flexDirection={'row'} gap={'4px'} justifyContent={'center'} alignItems={'center'}>
                    <Typography typestyle='s' color={Colors.warningRed}>{format(dueDate, dateFormat)}</Typography>
                    <ErrorIcon sx={{
                      color: Colors.warningRed,
                      height: '16px',
                      width: '16px'
                    }} />
                  </Box>
                ) : (
                  <AccordionValue>{logFileRequest.dueDate}</AccordionValue>
                )
              }
            </FieldContainer>

            {/* TODO: Reference attachments */}
          </Box>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={fileRequestSidebarExpanded['file_request_sidebar_tab_workflow_expanded']} onChange={() => {
        const newState = {
          ...fileRequestSidebarExpanded,
          ['file_request_sidebar_tab_workflow_expanded']: !fileRequestSidebarExpanded['file_request_sidebar_tab_workflow_expanded'],
        };

        setFileRequestSidebarExpanded(newState);

        onExpansionChanged(newState);
      }}>
        <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography typestyle='l'>Workflow</Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Box display={'flex'} flexDirection={'column'} gap={'24px'}>
            <FieldContainer>
              <AccordionLabel typestyle='s bold'>Responsible Contractor</AccordionLabel>
              <AccordionValue>{logFileRequest.responsibleContractor.name}</AccordionValue>
            </FieldContainer>

            <FieldContainer>
              <AccordionLabel typestyle='s bold'>Submitters</AccordionLabel>
              <AccordionValue>{logFileRequest.assignees.map(a => a.procoreUserName).join(', ')}</AccordionValue>
            </FieldContainer>
          </Box>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={fileRequestSidebarExpanded['file_request_sidebar_tab_details_expanded']} onChange={() => {
        const newState = {
          ...fileRequestSidebarExpanded,
          ['file_request_sidebar_tab_details_expanded']: !fileRequestSidebarExpanded['file_request_sidebar_tab_details_expanded'],
        };

        setFileRequestSidebarExpanded(newState);

        onExpansionChanged(newState);
      }}>
        <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography typestyle='l'>Additional Details</Typography>
        </CustomAccordionSummary>

        <CustomAccordionDetails>
          <Box display={'flex'} flexDirection={'column'} gap={'24px'}>
            <FieldContainer>
              <AccordionLabel typestyle='s bold'>Closeout Manager</AccordionLabel>
              <AccordionValue>{logFileRequest.closeoutManager.procoreUserName}</AccordionValue>
            </FieldContainer>

            <FieldContainer>
              <AccordionLabel typestyle='s bold'>Trade</AccordionLabel>
              <AccordionValue>{logFileRequest.logRequirementTrade?.name}</AccordionValue>
            </FieldContainer>

            <FieldContainer>
              <AccordionLabel typestyle='s bold'>Spec Section</AccordionLabel>
              <AccordionValue>{logFileRequest.specSection?.name}</AccordionValue>
            </FieldContainer>

            <FieldContainer>
              <AccordionLabel typestyle='s bold'>Created At</AccordionLabel>
              <AccordionValue>{format(new Date(logFileRequest.createdAt), dateFormat)}</AccordionValue>
            </FieldContainer>

            <FieldContainer>
              <AccordionLabel typestyle='s bold'>Updated At</AccordionLabel>
              <AccordionValue>{format(new Date(logFileRequest.updatedAt), dateFormat)}</AccordionValue>
            </FieldContainer>
          </Box>
        </CustomAccordionDetails>
      </CustomAccordion>
    </Box>
  );
};
