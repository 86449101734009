import {
  ListItemText,
  ListItem,
  Divider,
  Box,
  Typography,
  MenuItem,
  ListItemIcon,
  IconButton,
  TextField,
  SelectChangeEvent,
  styled,
  IconButtonProps,
  ListItemIconProps,
  ListItemTextProps,
  TextFieldProps,
} from "@mui/material";
import React from "react";
import { NewPackageContext } from "../../contexts/NewPackageContextProvider";
import Loading from "../shared/Loading";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { AddFilterSelect, BlueSwitch, PrimarySelectInput } from "./styles";
import { Colors } from "../../styles";
import UnfoldLessOutlinedIcon from '@mui/icons-material/UnfoldLessOutlined';
import { FixedSizeList, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  transformNodesToHierarchy,
  transformProcoreItemsToNodes,
  Node,
  flatten,
} from "../../utils/multi-tier-grouping";
import { BaseProcoreType, ProcoreToolGroupings } from "../../types";
import { TOOL_ENGINE_NAMES, groupingLabelToAttribute, isEmpty, selectedItemKey } from "../../utils/utils";
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { InspectionExtractOptions, OrganizeGrouping } from "../../types/inspection";

const CollapseAllAsPdfIconButton = styled(IconButton)<IconButtonProps>(() => {
  return {
    height: "24px",
    width: "24px",
    background: Colors.white,
    color: Colors.darkerGray,
    borderRadius: '0%',
    margin: 0,
    padding: 0,
    "&:hover": {
      background: Colors.white,
    }
  };
});

const OutlineWithLabelTextField = styled(TextField)<TextFieldProps>(() => {
  return {
    padding: 0,
    margin: 0,
    '& label.Mui-focused': {
      color: Colors.darkGray,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: Colors.darkerGray,
    },
    '& .MuiOutlinedInput-input': {
      padding: "7px 14px",
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
      '& .MuiButtonBase-root': {
        padding: 0,
        border: "none",
        '&:hover': {
          border: "none"
        }
      }
    },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      // Source: https://stackoverflow.com/a/58985002
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      transform: "translate(14px, 16px) scale(1);"
    },
    '& .MuiOutlinedInput-root': {
      color: Colors.darkGray,
      fontSize: 15,
      fontWeight: 500,
      '& fieldset': {
        borderColor: Colors.mediumGray,
      },
      '&:hover fieldset': {
        borderColor: Colors.mediumGray,
      },
      '&.Mui-focused fieldset': {
        borderColor: '#89BFE8',
      },
    },
  }
});


const GroupingListItemIcon = styled(ListItemIcon)<ListItemIconProps>(() => {
  return {
    minWidth: "unset",
    marginRight: "12px",
  }
});

const CheckedIconButton = styled(IconButton)<IconButtonProps>(() => {
  return {
    height: '18px',
    width: '18px',
    background: '#007BFF',
    color: 'white',
    borderRadius: '2px',
    marginRight: '8px',
    padding: 0,
    '&:hover': {
      background: '#007BFF'
    }
  }
});

const UncheckedIconButton = styled(IconButton)<IconButtonProps>(() => {
  return {
    height: '18px',
    width: '18px',
    background: 'white',
    border: '2px solid #CED4DA',
    marginRight: '8px',
    borderRadius: '2px',
    padding: 0,
    '&:hover': {
      background: 'white'
    }
  };
});

export interface GroupByOptionsProps {
  groupings: ProcoreToolGroupings[];
  selectedGroupings: string[];
  setSelectedGroupings: (selectedGroupings: string[]) => void;
}

const GroupByOptions = ({
  groupings,
  selectedGroupings,
  setSelectedGroupings,
}: GroupByOptionsProps) => {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value: newGroupings },
    } = event;

    setSelectedGroupings(newGroupings as string[]);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      margin={2}
    >
      <AddFilterSelect
        value={selectedGroupings}
        onChange={handleChange}
        multiple
        autoWidth={true}
        input={<PrimarySelectInput />}
        displayEmpty={true}
        disabled={groupings.length === 0}
        renderValue={(value: string[]) =>
          value.length === 0
            ? "Group Items By"
            : `Group Items By (${value.length})`
        }
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
      >
        {groupings.map((group) => {
          const indexOf = selectedGroupings.indexOf(group.attribute);
          const exists = indexOf !== -1;

          if (exists) {
            return (
              <MenuItem
                key={`grouping-menu-item-${group.label}`}
                value={group.attribute}
              >
                <CheckedIconButton
                  key={`grouping-icon-button-${group.label}`}
                  disableFocusRipple={true}
                  disableRipple={true}>
                  <Typography variant="body2">{indexOf + 1}</Typography>
                </CheckedIconButton>
                <ListItemText primary={group.label} />
              </MenuItem>
            );
          } else {
            return (
              <MenuItem
                key={`grouping-menu-item-${group.label}`}
                value={group.attribute}
              >
                <UncheckedIconButton
                  key={`grouping-icon-button-${group.label}`}
                  disableFocusRipple={true}
                  disableRipple={true}>
                </UncheckedIconButton>
                <ListItemText primary={group.label} />
              </MenuItem>
            );
          }
        })}
      </AddFilterSelect>
    </Box>
  );
};

const CustomListItemText = styled(ListItemText)<ListItemTextProps>(() => {
  return {
    color: Colors.darkGray,
    fontSize: "1.125em",
    fontWeight: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }
});

const OrganizeInspections = (props: { toolTabIndex: number }): JSX.Element => {
  const { toolTabIndex } = props;
  const { state, dispatch } = React.useContext(NewPackageContext);
  const toolTabData = state.toolTabs[toolTabIndex];
  const [selectedGroupings, setSelectedGroupings] = React.useState<string[]>(
    toolTabData.selectedGroupings.map((g) => g.attribute)
  );
  const [openCollapse, setOpenCollapse] = React.useState<
    Record<string, boolean>
  >({});
  const [singlePdfGrouping, setSinglePdfGrouping] = React.useState<
    Record<string, boolean>
  >(Object.keys((toolTabData.extractOptions as InspectionExtractOptions).grouping_options).reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {} as Record<string, boolean>));

  const dispatchSetSelectedGroupings = (selectedGroupings: string[]) => {
    const newSelectingGroupings: ProcoreToolGroupings[] = selectedGroupings.map(groupingAttribute => {
      return toolTabData.groupings.find(g => g.attribute === groupingAttribute);
    }).filter(g => g !== undefined);

    dispatch({
      type: "SET_SELECTED_GROUPINGS",
      value: {
        toolTabIndex: toolTabIndex,
        selectedGroupings: newSelectingGroupings,
      },
    });

    setSinglePdfGrouping({});

    dispatch({
      type: 'SET_TOOL_OPTIONS',
      value: {
        toolTabIndex: toolTabIndex,
        option: {
          ...toolTabData.extractOptions,
          grouping_options: {}
        }
      }
    });
  };

  const handleCategoryClick = (key: string) => {
    const open = openCollapse[key] || false;

    setOpenCollapse({
      ...openCollapse,
      [key]: !open,
    });
  };

  const handleSetSelectedGroupings = (items: string[]) => {
    setSelectedGroupings(items);
    dispatchSetSelectedGroupings(items);
  };

  function renderUngroupedRowItem(props: ListChildComponentProps<BaseProcoreType[]>) {
    const { index, style, data } = props;

    const procoreItem = data[index] as BaseProcoreType;

    return (
      <div style={style}>
        <ListItem key={`organize-list-item-${procoreItem.id}`}>
          <CustomListItemText
            disableTypography={true}
            key={`organize-list-item-text-${procoreItem.id}`}
          >
            {procoreItem.formattedTitle}
          </CustomListItemText>
        </ListItem>
        <Divider key={`organize-list-item-divider-${procoreItem.id}`} />
      </div>
    );
  }

  const getProcoreItemChildren = (node: Node): Node[] => {
    const result = [];

    node.children.forEach(child => {
      if (child.type === 'procore_item') {
        result.push(child)
      } else {
        result.push(...getProcoreItemChildren(child));
      }
    })

    return result;
  }

  const getNodeChildren = (node: Node): Node[] => {
    const result = [];

    node.children.forEach(child => {
      if (child.type === 'node') {
        result.push(child)
      }

      result.push(...getNodeChildren(child));
    })

    return result;
  }

  const handleGroupedNodeNameChange = (node: Node, filename: string): void => {
    const inspectionExtractOptions = toolTabData.extractOptions as InspectionExtractOptions;

    const organizeGroupingConfig = inspectionExtractOptions.grouping_options[node.id]

    if (organizeGroupingConfig) {
      dispatch({
        type: 'SET_TOOL_OPTIONS',
        value: {
          toolTabIndex: toolTabIndex,
          option: {
            ...inspectionExtractOptions,
            grouping_options: {
              ...inspectionExtractOptions.grouping_options,
              [node.id]: {
                ...organizeGroupingConfig,
                filename: isEmpty(filename) ? node.id : filename
              }
            }
          }
        }
      });
    }
  }

  const handleToggleAll = (nodes: Node[]): void => {
    const inspectionExtractOptions = toolTabData.extractOptions as InspectionExtractOptions;
    const groupingOptions = { ...inspectionExtractOptions.grouping_options };

    const groupings = nodes.reduce((acc, next) => {
      acc[next.id] = {
        procore_server_ids: getProcoreItemChildren(next).map((item => item.data.procoreServerId)),
        filename: next.label,
        level: next.level
      } as OrganizeGrouping;

      return acc;
    }, {});

    const nodeIds = nodes.map(node => node.id);
    const result = Object.entries(groupingOptions).reduce((acc, [key, value]) => {
      const notADescendant = nodeIds.every(id => !key.startsWith(`${id} >`));
      if (notADescendant) {
        acc[key] = value;
      }

      return acc;
    }, {});

    dispatch({
      type: 'SET_TOOL_OPTIONS',
      value: {
        toolTabIndex: toolTabIndex,
        option: {
          ...inspectionExtractOptions,
          grouping_options: {
            ...result,
            ...groupings,
          }
        }
      }
    });

    const singlePdfGroupingHash = {};
    const openCollapseHash = {};

    const collapsedChildren = nodes.reduce((acc, next) => {
      const nodeChildren = getNodeChildren(next);

      nodeChildren.forEach(childNode => {
        acc[childNode.id] = false
      });

      singlePdfGroupingHash[next.id] = true;
      openCollapseHash[next.id] = false

      return acc;
    }, {} as Record<string, boolean>);

    setSinglePdfGrouping({
      ...singlePdfGrouping,
      ...collapsedChildren,
      ...singlePdfGroupingHash,
    });

    setOpenCollapse({
      ...openCollapse,
      ...collapsedChildren,
      ...openCollapseHash,
    });
  }

  const handlePdfToggle = (node: Node, checked: boolean): void => {
    const inspectionExtractOptions = toolTabData.extractOptions as InspectionExtractOptions;
    const groupingOptions = { ...inspectionExtractOptions.grouping_options };

    if (checked) {
      const grouping: OrganizeGrouping = {
        procore_server_ids: getProcoreItemChildren(node).map((item => item.data.procoreServerId)),
        filename: node.label,
        level: node.level
      };

      const result = Object.entries(groupingOptions).reduce((acc, [key, value]) => {
        if (!key.startsWith(`${node.id} >`)) {
          acc[key] = value;
        }

        return acc;
      }, {});

      dispatch({
        type: 'SET_TOOL_OPTIONS',
        value: {
          toolTabIndex: toolTabIndex,
          option: {
            ...inspectionExtractOptions,
            grouping_options: {
              ...result,
              [node.id]: grouping
            }
          }
        }
      });
    } else {
      const result = Object.entries(groupingOptions).reduce((acc, [key, value]) => {
        if (key !== node.id) {
          acc[key] = value
        }
        return acc;
      }, {});

      dispatch({
        type: 'SET_TOOL_OPTIONS',
        value: {
          toolTabIndex: toolTabIndex,
          option: {
            ...inspectionExtractOptions,
            grouping_options: result
          }
        }
      });
    }

    const nodeChildren = getNodeChildren(node);

    const collapsedChildren = nodeChildren.reduce((acc, next) => {
      acc[next.id] = false;
      return acc;
    }, {} as Record<string, boolean>);

    setSinglePdfGrouping({
      ...singlePdfGrouping,
      ...collapsedChildren,
      [node.id]: checked
    });

    setOpenCollapse({
      ...openCollapse,
      ...collapsedChildren,
      [node.id]: false,
    });
  }

  function renderGroupedRowItem(props: ListChildComponentProps<Node[]>) {
    const { index, style, data } = props;

    const options = toolTabData.extractOptions as InspectionExtractOptions;

    const node = data[index] as Node;

    switch (node.type) {
      case 'node': {
        const pdfGrouping = singlePdfGrouping[node.id];
        return (
          <div style={style}>
            <ListItem
              style={{ paddingLeft: `${node.level * 16}px` }}
              key={`grouping-category-${node.id}`}
              onClick={() => {
                if (!pdfGrouping) {
                  handleCategoryClick(node.id)
                }
              }}
            >
              <GroupingListItemIcon>
                { pdfGrouping ? <PictureAsPdfOutlinedIcon htmlColor="#1A2024" /> : <FolderOpenIcon htmlColor="#1A2024" /> }
              </GroupingListItemIcon>
              {
                pdfGrouping ? (
                  <OutlineWithLabelTextField
                    fullWidth={true}
                    id="outlined-basic"
                    variant="outlined"
                    autoComplete={'off'}
                    defaultValue={options.grouping_options[node.id]?.filename || node.label}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <p style={{ margin: 0, padding: 0}}>.pdf</p>
                        )
                      }
                    }}
                    type={"text"}
                    onBlur={(evt) => {
                      handleGroupedNodeNameChange(node, evt.target.value);
                    }}
                    placeholder="Enter a filename" />
                ) : (
                  <ListItemText primary={node.label} />
                )
              }
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"} style={{
                borderRight: `1px solid ${Colors.mediumLightGray}`,
                padding: 0,
                margin: 0,
                paddingRight: '4px',
                marginRight: '12px',
                marginLeft: '12px'
              }}>
                <p style={{ padding: 0, margin: 0}}>PDF</p>
                <BlueSwitch
                  size="small"
                  checked={pdfGrouping}
                  onChange={(event) => {
                    event.stopPropagation();

                    handlePdfToggle(node, event.target.checked);
                  }}
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                {
                  (selectedGroupings.length > 0 && !pdfGrouping)  && (
                    <CollapseAllAsPdfIconButton
                      aria-label="close"
                      onClick={(event) => {
                        event.stopPropagation();

                        handleToggleAll(data.filter(n => n.level === node.level));
                      }}>
                      <UnfoldLessOutlinedIcon />
                    </CollapseAllAsPdfIconButton>
                  )
                }
              </Box>
              {
                pdfGrouping ? (
                  <ExpandMore htmlColor={Colors.mediumLightGray} />
                ) : (openCollapse[node.id] || false ? <ExpandLess /> : <ExpandMore />)
              }
            </ListItem>
            <Divider key={`grouping-list-item-divider-${node.id}`} />
          </div>
        );
      }

      case 'procore_item':
        return (
          <div style={style}>
            <ListItem
              style={{ paddingLeft: `${node.level * 16}px` }}
              key={`organize-list-item-${node.id}`}>
              <CustomListItemText
                disableTypography={true}
                key={`organize-list-item-text-${node.id}`}
              >
                {node.label}
              </CustomListItemText>
            </ListItem>
            <Divider key={`organize-list-item-divider-${node.id}`} />
          </div>
        );
    }
  }

  const getFixedSizeListData = (): { itemData: BaseProcoreType[] | Node[], itemCount: number } => {
    if (toolTabData.loading) { return { itemData: [], itemCount: 0 } }

    // TODO: Fix me
    const onlyCheckedSelectedItems = Object.values(toolTabData.selectedItems).reduce((acc, nextItem) => {

      if (nextItem.state === 'checked') {
        acc[selectedItemKey(TOOL_ENGINE_NAMES.INSPECTIONS, nextItem.itemId)] = nextItem
      }

      return acc;
    }, {});
    const selectedItemKeys = Object.keys(onlyCheckedSelectedItems)
    const selectedProcoreItems = toolTabData.procoreItems.filter(item => {
      return selectedItemKeys.includes(`inspection_${item.procoreServerId}`)
    });
    if (selectedGroupings.length === 0) {
      return {
        itemData: selectedProcoreItems,
        itemCount: selectedProcoreItems.length
      };
    } else {
      const { nodes, procoreItemMap } = transformProcoreItemsToNodes(
        selectedProcoreItems,
        selectedGroupings.map((grouping) => {
          return groupingLabelToAttribute(toolTabData.selectedGroupings, toolTabData.procoreTool.engineName, grouping);
        })
      );
      const tree = transformNodesToHierarchy(nodes, procoreItemMap, undefined);
      const flatResult = flatten(tree, true).filter(node => {
        if (node.parentId === undefined) { return true; }

        return openCollapse[node.parentId as string] || false;
      });

      return { itemData: flatResult, itemCount: flatResult.length };
    }
  }

  const renderItems = (itemData: BaseProcoreType[] | Node[], itemCount: number): JSX.Element => {
    const renderRow = selectedGroupings.length === 0 ? renderUngroupedRowItem : renderGroupedRowItem;

    return (
      <div style={{ display: "flex", height: "100%", width: "100%" }}>
        <div style={{ flex: "1 1 1px" }}>
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                height={height}
                width={width}
                itemData={itemData}
                itemCount={itemCount}
                itemSize={46}
              >
                {renderRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }

  const { itemData, itemCount } = getFixedSizeListData();

  return (
    <Box
      style={{
        border: "solid 1px #E4EBF0",
        borderRadius: "6px",
      }}
      display="flex"
      flexDirection="column"
      height={1}
      width={1}
    >
      <GroupByOptions
        groupings={toolTabData.groupings}
        selectedGroupings={selectedGroupings}
        setSelectedGroupings={handleSetSelectedGroupings}
      />
      <Divider />
      {toolTabData.loading ? (
          <Loading loadingLabel={"Loading items..."} />
        ) : renderItems(itemData, itemCount)
      }
    </Box>
  );
};

export default OrganizeInspections;
