import React, { useState } from "react";
import { Colors } from "../styles";
import Box from "@mui/material/Box";
import { Typography } from "./shared/Typography";
import CircularProgressWithLabel from "./shared/CircularProgressWithLabel";
import { calculateProgress, CloseoutLogProgressGroupByAttribute, progressBy } from "../utils/utils";
import ListItem from "@mui/material/ListItem";
import CircularProgressOverlay from "./shared/CircularProgressOverlay";
import List from "@mui/material/List";
import { LogRequirement } from "../types";
import Check from "@mui/icons-material/Check";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { GridFilterItem } from "@mui/x-data-grid-premium";
import { Rainbow } from "../utils/rainbow-util";
import { MenuItem } from "@mui/material";
import { FilterSelect, FilterSelectInput } from "./package/styles";

const getProgressColor = (value: number) => {
  const rainbow = new Rainbow();
  rainbow.setSpectrum(Colors.blue5, Colors.newAccentBlue);
  return rainbow.colorAt(value);
};

interface IChartModuleProps {
  logRequirements: LogRequirement[];
  loading: boolean;
  filters: GridFilterItem[];
  groupedBy: string;
  onGroupByChanged: (groupedBy: string) => void;
  onSelectGroupedProgressItem: (groupedBy: string, items: { id: number, value: string, selected: boolean }[]) => void;
  minimized: boolean,
  toggleMinimized: ()=>void;
}

const CloseoutProgressChart = (props: IChartModuleProps) => {
  const closeoutLogProgress = React.useMemo(() => {
    return progressBy(props.logRequirements, props.groupedBy as CloseoutLogProgressGroupByAttribute);
  }, [props.logRequirements, props.groupedBy]);

  const [selectedFilterValues, setSelectedFilterValues] = useState<Record<string, Record<number, { id: number; value: string, selected: boolean }>>>({});

  React.useEffect(() => {
    if (!props.filters) { return; }

    setSelectedFilterValues(
      props.filters.reduce((acc, filter) => {

        acc[filter.field] ||= {};

        if (filter && filter.value) {
          const innerRecord = acc[filter.field];

          filter.value.reduce((innerAcc, value) => {
            innerAcc[value.id] = { id: value.id, value: value.name, selected: true };
            return innerAcc;
          }, innerRecord);
        }

        return acc;
      }, {})
    );
  }, [props.filters]);

  const anySelected = Object.values(selectedFilterValues[props.groupedBy] || {}).some((contractor) => contractor.selected);

  return (
    (<Box sx={{
      transition: 'all 0.25s ease-out',
      display: 'flex',
      alignSelf: 'stretch',
      overflow: 'hidden',
      width: props.minimized ? '26px' : '300px',
      marginBottom: '20px',
      borderRadius: '8px',
      boxShadow:
        "0px 1px 10px rgba(26, 32, 36, 0.06), 0px 2px 4px 1px rgba(26, 32, 36, 0.04)",
      backgroundColor: Colors.white,
    }}>
      {
        props.minimized ? (
          <Box
            display="flex"
            flexDirection="column"
            minWidth="26px"
            alignItems="center"
            justifyContent={"center"}
            padding={"28px 13px"}
            onClick={() => {props.toggleMinimized()}}
            sx={{
              backgroundColor: Colors.white,
              '&:hover': {
                cursor: 'pointer',
                borderRadius: "8px",
                backgroundColor: Colors.lightestGray2
              }
            }}>
              <KeyboardDoubleArrowLeftIcon fontSize="small" />
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            minWidth="300px"
            alignItems="center"
            sx={{
              backgroundColor: Colors.white,
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={1}
              alignItems={"center"}
              flex={1}
              gap='12px'
              padding={"28px 24px 0px 24px"}>
              <Box
                width={1}
                gap="20px"
                alignItems="center"
                display="flex"
                flexDirection="column"
              >
                <Typography typestyle="l">Progress</Typography>
                <CircularProgressWithLabel
                  thickness={3}
                  value={calculateProgress(props.logRequirements)}
                  size={140}
                  progressColor={`#${getProgressColor(calculateProgress(props.logRequirements))}`}
                />
              </Box>

              <FilterSelect disabled={props.loading} size="small" input={<FilterSelectInput />} value={props.groupedBy} onChange={(e) => {
                const newValue =  e.target.value as CloseoutLogProgressGroupByAttribute;

                props.onGroupByChanged(newValue);
              }} fullWidth={true} label="" variant="outlined">
                <MenuItem value={'responsibleContractor'}>By Responsible Contractor</MenuItem>
                <MenuItem value={'logRequirementType'}>By Type</MenuItem>
                <MenuItem value={'logRequirementTrade'}>By Trade</MenuItem>
                <MenuItem value={'specSection'}>By Spec Section</MenuItem>
              </FilterSelect>

              {closeoutLogProgress.length > 0 && (
                <List
                  sx={{
                    overflowY: "auto",
                    flex: "1 1 1px",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                  disablePadding
                >
                  {closeoutLogProgress.map(
                    ({ id, name, progress }, index) => {
                      return (
                        (<ListItem
                          onClick={() => {
                            const newSelected = {
                              ...selectedFilterValues,
                              [props.groupedBy]: {
                                ...(selectedFilterValues[props.groupedBy] || {}),
                                [id]: {
                                  id: id,
                                  selected: !((selectedFilterValues[props.groupedBy] || {})[id]?.selected || false),
                                  value: name,
                                },
                              },
                            };

                            setSelectedFilterValues(newSelected);

                            props.onSelectGroupedProgressItem(
                              props.groupedBy,
                              Object.values(newSelected[props.groupedBy] || {}).filter((c) => c.selected)
                            );
                          }}
                          key={`closeout-log-progress-${index}`}
                          sx={{
                            borderBottom: `1px solid ${Colors.lightishGray}`,
                            padding: "16px 4px",
                            opacity: anySelected && ((selectedFilterValues[props.groupedBy] || {})[id]?.selected || false) === false ? 0.5 : 1,
                            '&:hover': {
                              cursor: 'pointer',
                            }
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            width={1}
                            alignItems="center"
                            gap='12px'
                          >
                            <Box display="flex" flexDirection="column" width={1} gap="4px">
                              <Typography typestyle="s bold">{name}</Typography>
                              <Typography
                                typestyle="xs"
                                sx={{ color: Colors.darkishGray }}
                              >
                                {progress}% complete
                              </Typography>
                            </Box>
                            {progress === 100 ? (
                              <Box sx={{
                                backgroundColor: Colors.newAccentBlue,
                                minWidth: '20px',
                                maxWidth: '20px',
                                height: '20px',
                                borderRadius: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}>
                                <Check sx={{
                                  color: Colors.white,
                                  fontSize: '12px'
                                }}/>
                              </Box>
                            )
                            : (<CircularProgressOverlay
                              size={20}
                              thickness={5.5}
                              value={progress}
                              progressColor={`#${getProgressColor(progress)}`}
                            />)}
                          </Box>
                        </ListItem>)
                      );
                    }
                  )}
                </List>
              )}
            </Box>

            <Box
              width={1}
              height={'40px'}
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
              gap='4px'
              sx={{
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: Colors.lightestGray2
                }
              }}
              onClick={() => {props.toggleMinimized()}}>
              <Typography typestyle="m">
                {'Minimize Progress'}
              </Typography>
              <KeyboardDoubleArrowRightIcon fontSize="small" />
            </Box>
          </Box>
        )
      }
    </Box>)
  );
};

export default CloseoutProgressChart;
