import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";

export interface TemplateLogCustomFieldDeleteData {
  deleteTemplateLogCustomField: {
    success: boolean;
  }
}

export interface TemplateLogCustomFieldDeleteVariables {
  accountId: string;
  templateCloseoutLogId: number;
  id: number;
}

export const useDeleteTemplateLogCustomField = createMutationHook<
  TemplateLogCustomFieldDeleteData,
  TemplateLogCustomFieldDeleteVariables
>(gql`
  mutation DeleteTemplateLogCustomField(
    $accountId: ID!,
    $templateCloseoutLogId: ID!,
    $id: ID!
  ) {
    deleteTemplateLogCustomField(
      input: { accountId: $accountId, templateCloseoutLogId: $templateCloseoutLogId, id: $id }
    ) {
      success
    }
  }
`);
