import Box from '@mui/material/Box';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { useState, useContext } from 'react';
import { Typography } from './shared/Typography';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { Colors, FieldContainer } from '../styles';
import { useUpdateCloseoutLogDataGridView } from '../graphql/mutations/closeout/UpdateCloseoutLogDataGridView';
import { CloseoutLogContext } from '../contexts/CloseoutLogContextProvider';
import { CloseoutLogColumnDefinition, LogFileRequest, VendorWorkflowResponseAttachment } from '../types';
import { BlueSwitch } from './package/styles';
import Check from '@mui/icons-material/Check';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { Divider, ListItemIcon, ListItemText, MenuItem, Popover, TextField, Tooltip } from '@mui/material';
import { Button } from './shared/Button';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { parseDate } from '../utils/utils';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

interface IFileRequestResponseModal {
  accountId: string;
  procoreProjectServerId: number;
  loading: boolean;
  closeoutLogId: number;
  logFileRequest: LogFileRequest;
  onReviseAndResubmit: (logFileRequestId: number, dueDate: Date, comment: string) => void;
  onApprove: (logFileRequestId: number, comment: string, attachments: VendorWorkflowResponseAttachment[]) => void;
  onClose: () => void;
}

export const FileRequestResponseModal = ({
  accountId,
  procoreProjectServerId,
  closeoutLogId,
  loading,
  onReviseAndResubmit,
  onApprove,
  logFileRequest,
  onClose,
  ...rest
}: IFileRequestResponseModal & DialogProps): JSX.Element => {
  const { state, dispatch } = useContext(CloseoutLogContext);

  const [responseStatus, setResponseStatus] = useState<'accepted' | 'revise_and_resubmit'>('accepted');
  const [comment, setComment] = useState<string>('');

  const containerRef = React.useRef(null);

  const [newDueDate, setNewDueDate] = useState<Date | null>(parseDate(logFileRequest.dueDate)); // TODO: Set default to log file request due date
  const [attachments, setAttachments] = useState<VendorWorkflowResponseAttachment[]>(logFileRequest.vendorWorkflowRequest.vendorWorkflowResponse?.attachments || [])
  const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState<HTMLButtonElement>(null);
  const [overflowAnchorEl, setOverflowAnchorEl] = useState<HTMLButtonElement>(null);
  const [currentAttachment, setCurrentAttachment] = useState(null);

  const [updateCloseoutLogDataGridView] = useUpdateCloseoutLogDataGridView({
    accountId: accountId,
    procoreProjectServerId: procoreProjectServerId,
    closeoutLogId: closeoutLogId,
    id: state.currentCloseoutLogFileRequestDataGridView?.id,
    attributes: {
      columnDefinitions: [],
      sortModel: [],
      groupings: [],
      columnVisibility: {},
      detailViewOptions: {},
      groupingColumnDefinition: {} as CloseoutLogColumnDefinition,
      filters: [],
      markRequirementCompleteOnFileUpload: false,
      markRequirementCompleteOnApprovedFileRequest: false,
      progressGroupBy: null,
      showProgressSidebar: false,
    },
  });

  const resetAnchorEl = () => {
    setOverflowAnchorEl(null);
    setCurrentAttachment(null);
  }

  const handleOnClose = () => {
    onClose();
  }

  const handleStatusButtonClicked = (evt: React.MouseEvent<HTMLButtonElement>) => {
    setStatusMenuAnchorEl(evt.currentTarget);
  };

  const updateResponseStatus = (status: 'accepted' | 'revise_and_resubmit') => {
    setResponseStatus(status);
    setStatusMenuAnchorEl(null);
  }

  return (
    <Dialog
      fullWidth
      ref={containerRef}
      maxWidth="xs"
      disableScrollLock
      {...rest}
      PaperProps={{
        sx: {
          maxWidth: '790px',
          maxHeight: '740px',
        },
      }}
    >
      <Popover open={Boolean(overflowAnchorEl)} anchorEl={overflowAnchorEl} onClose={() => resetAnchorEl()}>
        <MenuItem onClick={() => {
          setAttachments(attachments.reduce((acc, attachment) => {
            if (attachment.id == currentAttachment.id) {
              acc.push({
                ...attachment,
                omitted: !attachment.omitted,
              })
            } else {
              acc.push(attachment);
            }

            return acc;
          }, []));

          resetAnchorEl();
        }}>
          <ListItemIcon>
            {
              (currentAttachment && currentAttachment.omitted) ? <AddCircleOutlineOutlinedIcon fontSize='small' sx={{ color: Colors.darkerGray }} /> : <CancelOutlinedIcon fontSize='small' sx={{ color: Colors.darkerGray }} />
            }
          </ListItemIcon>
          <ListItemText sx={{ color: Colors.darkerGray }} primary={(currentAttachment && currentAttachment.omitted) ? "Include" : "Omit"} />
        </MenuItem>
      </Popover>

      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          padding: '24px 28px 32px 28px',
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" borderBottom={`1px solid ${Colors.lightishGray}`} paddingBottom={'16px'}>
          <Typography typestyle="xl">Review & Respond</Typography>

          <Box display={"flex"} flexDirection={"row"} gap={'20px'}>
            <Button buttonborderstyle="pill" variant="outlined" fullWidth={false} onClick={() => {
              const urls = attachments.map((attachment) => attachment.procoreDownloadUrl);
              urls.forEach((url) => {
                window.open(url, "_blank");
              });
            }}>
              Download All
            </Button>
            <Divider orientation="vertical" flexItem />
            <IconButton onClick={() => handleOnClose()}>
              <Close sx={{ fontSize: '18px' }} />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '16px 20px',
            alignItems: 'center',
            gap: '12px',
            borderRadius: '8px',
            border: `1px solid ${Colors.lightishGray}`,
            boxShadow:
              '0px 2px 4px 1px rgba(26, 32, 36, 0.04), 0px 1px 10px 0px rgba(26, 32, 36, 0.06)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              minWidth: '22px',
              minHeight: '22px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '11px',
              backgroundColor: Colors.green,
            }}
          >
            <Check sx={{ color: Colors.white, fontSize: '14px' }} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: '1', gap: '4px' }}>
            <Typography typestyle="s bold">Automatic Completion</Typography>
            <Typography typestyle="xs" color={Colors.darkishGray}>
              Set the closeout requirement to “Complete” when file request approved.
            </Typography>
          </Box>
          <BlueSwitch
            size={'small'}
            checked={
              state.currentCloseoutLogFileRequestDataGridView?.markRequirementCompleteOnApprovedFileRequest
            }
            onChange={(evt) => {
              dispatch({
                type: 'SET_AUTO_MARK_COMPLETE_ON_APPROVE_FILE_REQUEST',
                value: evt.target.checked,
              });
              updateCloseoutLogDataGridView({
                variables: {
                  accountId: accountId,
                  procoreProjectServerId: procoreProjectServerId,
                  closeoutLogId: closeoutLogId,
                  id: state.currentCloseoutLogFileRequestDataGridView?.id,
                  attributes: {
                    columnDefinitions:
                      state.currentCloseoutLogFileRequestDataGridView?.columnDefinitions,
                    sortModel: state.currentCloseoutLogFileRequestDataGridView?.sortModel,
                    columnVisibility:
                      state.currentCloseoutLogFileRequestDataGridView?.columnVisibility,
                    detailViewOptions: state.currentCloseoutLogFileRequestDataGridView?.detailViewOptions || {},
                    groupings: state.currentCloseoutLogFileRequestDataGridView?.groupings,
                    groupingColumnDefinition:
                      state.currentCloseoutLogFileRequestDataGridView
                        ?.groupingColumnDefinition,
                    filters: state.currentCloseoutLogFileRequestDataGridView?.filters,
                    markRequirementCompleteOnFileUpload: state.currentCloseoutLogFileRequestDataGridView?.markRequirementCompleteOnFileUpload,
                    markRequirementCompleteOnApprovedFileRequest: evt.target.checked,
                    progressGroupBy: state.currentCloseoutLogFileRequestDataGridView?.progressGroupBy,
                    showProgressSidebar: state.currentCloseoutLogFileRequestDataGridView?.showProgressSidebar,
                  },
                },
              })
            }}
          />
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'24px'} sx={{
          overflowY: 'auto',
          padding: '4px'
        }}>
          {attachments.length > 0 && (
            attachments.map((attachment, index) => {
              return (
                <Box key={`attachment-${index}`}
                      sx={{
                        opacity: attachment.omitted ? 0.5 : 1,
                      }} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} gap={'4px'} width={1}>
                      <Typography typestyle='m'>{attachment.filename}</Typography>
                    </Box>
                    <Typography typestyle={'xs'} color={Colors.darkishGray}>{attachment.procoreCreatedAt}</Typography>
                  </Box>

                  <Box display={'flex'} flexDirection={'row'} gap={'4px'}>
                    <Tooltip placement="top" title="Download">
                      <Button
                        variant="outlined"
                        buttonborderstyle="pill"
                        size='small'
                        disabled={attachment.omitted}
                        startIcon={<DownloadOutlinedIcon fontSize="small" />}
                        sx={{
                          '&.MuiButton-outlined': {
                            border: '1px solid transparent',
                            minWidth: '32px',
                          },
                          '& .MuiButton-startIcon': {
                            margin: '0px',
                          },
                        }}
                        onClick={() => {
                          window.open(attachment.procoreDownloadUrl, "_blank");
                        }}
                      />
                    </Tooltip>

                    <Tooltip placement='top' title="View in Procore">
                      <Button
                        variant="outlined"
                        buttonborderstyle="pill"
                        size='small'
                        disabled={attachment.omitted}
                        startIcon={<OpenInNewOutlinedIcon fontSize="small" />}
                        sx={{
                          '&.MuiButton-outlined': {
                            border: '1px solid transparent',
                            minWidth: '32px',
                          },
                          '& .MuiButton-startIcon': {
                            margin: '0px',
                          },
                        }}
                        onClick={() => {
                          if (attachment.viewableInProcore) {
                            window.open(attachment.procoreDocumentViewerUrl, "_blank");
                          } else {
                            window.open(attachment.procoreRecordUrl, "_blank");
                          }
                        }}
                      />
                    </Tooltip>
                    <Button
                      variant="outlined"
                      buttonborderstyle="pill"
                      size='small'
                      startIcon={<MoreHoriz fontSize="small" />}
                      sx={{
                        '&.MuiButton-outlined': {
                          border: '1px solid transparent',
                          minWidth: '32px',
                        },
                        '& .MuiButton-startIcon': {
                          margin: '0px',
                        },
                      }}
                      onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                        setCurrentAttachment(attachment);
                        setOverflowAnchorEl(evt.currentTarget);
                      }}
                    />
                  </Box>
                </Box>
              )
            })
          )}
        </Box>

        <Divider />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            height: '100%',
          }}
        >
          <FieldContainer sx={{
            alignItems: 'flex-start'
          }}>
            <Typography typestyle="m">{'Status'}</Typography>
            {
              responseStatus === 'accepted' && (
                <Button
                  buttonborderstyle="pill"
                  fullWidth={false}
                  onClick={handleStatusButtonClicked}
                  startIcon={<CheckCircleOutlinedIcon fontSize="small" />}
                  endIcon={<ArrowDropDown fontSize="small" />}
                  sx={{
                    backgroundColor: Colors.lightGreen,
                    color: Colors.darkGreen,
                    boxShadow: 'none',
                    marginBottom: '10px',
                    '&:hover': {
                      boxShadow: 'none',
                      opacity: 0.8,
                      backgroundColor: Colors.lightGreen,
                    },
                    '&:focus': {
                      boxShadow: ` 0px 0px 0px 1px ${Colors.white}, 0px 0px 0px 4px ${Colors.lightAccentBlue}`,
                    },
                  }}>
                  {'Approved'}
                </Button>
              )
            }
            {
              responseStatus === 'revise_and_resubmit' && (
                <Button
                  fullWidth={false}
                  buttonborderstyle="pill"
                  onClick={handleStatusButtonClicked}
                  startIcon={<CancelOutlinedIcon fontSize="small" />}
                  endIcon={<ArrowDropDown fontSize="small" />}
                  sx={{
                    backgroundColor: Colors.lightRed,
                    color: Colors.darkerRed,
                    boxShadow: 'none',
                    '&:hover': {
                      boxShadow: 'none',
                      opacity: 0.8,
                      backgroundColor: Colors.lightRed,
                    },
                    '&:focus': {
                      boxShadow: ` 0px 0px 0px 1px ${Colors.white}, 0px 0px 0px 4px ${Colors.lightAccentBlue}`,
                    },
                  }}>
                  {'Revise and Resubmit'}
                </Button>
              )
            }
          </FieldContainer>
          {
            responseStatus === 'revise_and_resubmit' && (
              <FieldContainer sx={{
                alignItems: 'flex-start'
              }}>
                <Typography typestyle="m">{'Revised Due Date'}</Typography>
                <DatePicker value={newDueDate}
                  onChange={(value) => {
                    setNewDueDate(value);
                  }} />
              </FieldContainer>
            )
          }
          <FieldContainer>
            <Typography typestyle="m">{'Comment'}</Typography>
            <TextField
              id="outlined-multiline-static"
              multiline={true}
              fullWidth={true}
              minRows={3}
              maxRows={10}
              value={comment}
              onChange={(evt) => setComment(evt.target.value)}
              variant="outlined"
              sx={{
                '& .MuiInputBase-root': {
                  display: 'flex',
                },
              }}
            />
          </FieldContainer>

          <Box display={'flex'} width={1} justifyContent={'flex-end'} alignContent={'center'} gap={'12px'}>
            <Button
              variant="outlined"
              buttonborderstyle="pill"
              size="medium"
              onClick={() => handleOnClose()}
            >
              Cancel
            </Button>
            <Button
              disabled={!logFileRequest.dueDate || loading || attachments.every((attachment) => attachment.omitted)}
              variant="contained"
              onClick={() => {
                if (responseStatus === 'accepted') {
                  onApprove(logFileRequest.id, comment, attachments);
                } else {
                  onReviseAndResubmit(logFileRequest.id, newDueDate, comment);
                }
              }}
              buttonborderstyle="pill"
              size="medium"
            >
              Submit
            </Button>
          </Box>
        </Box>

        <Popover
            id="update-response-status-menu"
            open={Boolean(statusMenuAnchorEl)}
            onClose={() => setStatusMenuAnchorEl(null)}
            anchorEl={statusMenuAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            slotProps={{
              paper: {
                sx: {
                  padding: '16px 12px',
                }
              }
            }}
          >
            <MenuItem onClick={() => {
              updateResponseStatus('accepted');
            }}>Approved</MenuItem>
            <MenuItem onClick={() => {
              updateResponseStatus('revise_and_resubmit');
            }}>Revise and Resubmit</MenuItem>
          </Popover>
      </DialogContent>
    </Dialog>
  );
};
