import React, { useState, useContext } from 'react';
import {
  Account,
  TemplateCloseoutLog,
} from '../../types';
import { Colors, StyledDataGridPremium } from '../../styles';
import { GridCell, GridColDef, GridColumnHeaders, GridRow, GridRowId, useGridApiRef } from '@mui/x-data-grid-premium';
import useNavigationHook from '../../hooks/UseNavigationHook';
import { Box, IconButton, InputBase, MenuItem, Popover, Tooltip } from '@mui/material';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { ConfirmationModal } from '../shared/ConfirmationModal';
import AddCircle from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import { SearchCloseIcon, SearchCloseIconButton } from "./styles";
import { TemplateCloseoutLogContext } from '../../contexts/TemplateCloseoutLogContextProvider';
import { useDeleteTemplateCloseoutLog } from '../../graphql/mutations/closeout/DeleteTemplateCloseoutLog';
import { useDuplicateTemplateCloseoutLog } from '../../graphql/mutations/closeout/DuplicateTemplateCloseoutLog';
import { Button } from '../shared/Button';
import { useCreateTemplateCloseoutLog } from '../../graphql/mutations/closeout/CreateTemplateCloseoutLog';
import { isEmpty } from '../../utils/utils';
interface ITemplateCloseoutLogListProps {
  account: Account;
  templateCloseoutLogs: TemplateCloseoutLog[];
  refetchTemplateCloseoutLogs: () => void;
}

const DEFAULT_COL_DEF = {
  editable: false,
  resizable: false,
  disableReorder: true,
  sortable: false,
  groupable: false,
  flex: 1,
};

export const TemplateCloseoutLogList = ({
  account,
  templateCloseoutLogs,
  refetchTemplateCloseoutLogs
}: ITemplateCloseoutLogListProps): JSX.Element => {
  const { dispatch } = useContext(TemplateCloseoutLogContext);
  const navigateTo = useNavigationHook();
  const [searchTemplatesValue, setSearchTemplatesValue] = useState("");
  const [hoveredRowId, setHoveredRowId] = useState<string>(null);
  const [overflowAnchorEl, setOverflowAnchorEl] = useState<HTMLButtonElement>(null);
  const [currentContextTemplateCloseoutLog, setCurrentContextTemplateCloseoutLog] = useState<TemplateCloseoutLog>(null);
  const [deleteTemplateLogModal, setDeleteTemplateLogModal] = useState<{isOpen: boolean, templateLogId: number}>({ isOpen: false, templateLogId: -1 });
  const [templateLogsRowSelectionModel, setTemplateLogsRowSelectionModel] =  useState<GridRowId[]>([]);

  const templateCloseoutLogDataGridApiRef = useGridApiRef();

  const [deleteTemplateLog, { loading: loadingDeleteTemplateLog }] =
    useDeleteTemplateCloseoutLog({
      accountId: account.id,
      templateCloseoutLogId: -1
  });

  const [duplicateTemplateLog, { loading: loadingDuplicateTemplateLog }] =
    useDuplicateTemplateCloseoutLog({
      accountId: account.id,
      id: -1
    });

  const [createTemplateLog, { loading: loadingCreateTemplateLog }] = useCreateTemplateCloseoutLog({
    templateCloseoutLogAttributes: {
      accountId: account.id,
      name: 'Template Closeout Log'
    }
  })

  const dataGridColumnDefinitions: GridColDef[] = React.useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'Name',
        ...DEFAULT_COL_DEF,
        type: 'string',
      },
      {
        field: 'templateLogRequirementsCount',
        headerName: '# of Requirements',
        ...DEFAULT_COL_DEF,
        type: 'string',
      },
      {
        ...DEFAULT_COL_DEF,
        field: 'actions',
        type: 'actions',
        headerName: '',
        flex: 0,
        width: 120,
        editable: false,
        resizable: false,
        sortable: false,
        groupable: false,
        renderCell: (params) => {
          return (
            <Box display="flex" alignItems={'center'} height={1} width={1}>
              {params.row.id === hoveredRowId && (
                <Tooltip title="Edit Template Log" placement="top">
                  <IconButton
                    onClick={() => {
                      const templateCloseoutLog: TemplateCloseoutLog = params.row;
                      dispatch({
                        type: 'SET_TEMPLATE_CLOSEOUT_LOG',
                        value: {
                          id: templateCloseoutLog.id,
                          name: templateCloseoutLog.name
                        },
                      });
                      navigateTo(`/teams/${account.id}/template_logs/${templateCloseoutLog.id}/edit`);
                    }}
                  >
                    <EditOutlined sx={{ fontSize: '20px' }} />
                  </IconButton>
                </Tooltip>
              )}

              {params.row.id === hoveredRowId && (
                <IconButton onClick={(evt) => {
                  setCurrentContextTemplateCloseoutLog(params.row);
                  setOverflowAnchorEl(evt.currentTarget);
                }}>
                  <MoreHoriz sx={{ fontSize: '20px' }} />
                </IconButton>
              )}
            </Box>
          );
        },
      },
    ];
  }, [hoveredRowId, templateLogsRowSelectionModel]);

  const MemoizedRow = React.memo(GridRow);
  const MemoizedCell = React.memo(GridCell);
  const MemoizedColumnHeaders = React.memo(GridColumnHeaders);

  const slots = React.useMemo(() => {
    return {
      row: MemoizedRow,
      cell: MemoizedCell,
      columnHeaders: MemoizedColumnHeaders,
    };
  }, []);

  const filteredTemplateCloseoutLogs = isEmpty(searchTemplatesValue) ? templateCloseoutLogs : templateCloseoutLogs.filter((templateCloseoutLog) => {
    return templateCloseoutLog.name.toLowerCase().includes(searchTemplatesValue.toLowerCase());
  });

  return (
    <>
      <ConfirmationModal
        open={deleteTemplateLogModal.isOpen}
        headerText='Confirm Delete Template Log'
        bodyText={`Are you sure you want to delete this template log(s)?`}
        submitText="Delete"
        onSubmit={() => {
          if (deleteTemplateLogModal.templateLogId > 0) {
            deleteTemplateLog({
              variables: {
                accountId: account.id,
                templateCloseoutLogId: deleteTemplateLogModal.templateLogId
              }
            }).then((data) => {
              refetchTemplateCloseoutLogs();
            })
            setTemplateLogsRowSelectionModel([]);
            setDeleteTemplateLogModal({isOpen: false, templateLogId: -1 });
          }
        }}
        onClose={ ()=> setDeleteTemplateLogModal({ isOpen: false, templateLogId: -1 })}
        submitLoading={loadingDeleteTemplateLog}
      />
      <Box
        height={1}
        width={1}
        display={"flex"}
        flexDirection={"column"}
      >
        <Box display="flex" gap="8px" flexDirection={"row"} alignItems={"center"} justifyContent={'flex-end'} borderTop={`1px solid ${Colors.lightishGray}`} padding={'20px 0px'}>
          <Box display={'flex'} flexDirection={'row'} gap={'8px'} alignItems={'center'} justifyContent={'flex-start'}>
            <Box
                sx={{
                  minHeight: '40px',
                  maxHeight: '40px',
                  borderRadius: '20px',
                  paddingRight: '4px',
                  border: `1px solid ${Colors.mediumGray}`,
                  '&:hover': {
                    border: `1px solid ${Colors.mediumDarkGray}`,
                  },
                  marginLeft: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingLeft: '12px'
                }}>
                <SearchIcon sx={{fontSize: '18px'}}/>
                <InputBase
                  sx={{
                    ml: 1,
                    flex: 1,
                    fontSize: '0.875rem',
                  }}
                  fullWidth={false}
                  placeholder="Search..."
                  value={searchTemplatesValue}
                  endAdornment={
                    (searchTemplatesValue || "").trim() ? (
                      <SearchCloseIconButton
                        size={"small"}
                        onClick={() => {
                          setSearchTemplatesValue("");
                        }}
                        style={{
                          visibility: (searchTemplatesValue || "").trim()
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        <SearchCloseIcon />
                      </SearchCloseIconButton>
                    ) : null
                  }
                  onChange={(evt) => {
                    setSearchTemplatesValue(evt.target.value);
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
            </Box>
          </Box>
          <Box display={'flex'} flexDirection={'row'} gap={'8px'} alignItems={'center'} justifyContent={'flex-end'}>
            <Button
              buttonborderstyle='pill'
              startIcon={<AddCircle/>}
              variant="outlined"
              disabled={loadingCreateTemplateLog}
              onClick={() => {
                createTemplateLog().then(({ data }) => {
                  if (data?.createTemplateCloseoutLog?.success) {
                    refetchTemplateCloseoutLogs();

                    const templateCloseoutLog = data.createTemplateCloseoutLog.templateCloseoutLog;
                    dispatch({
                      type: 'SET_TEMPLATE_CLOSEOUT_LOG',
                      value: {
                        id: templateCloseoutLog.id,
                        name: templateCloseoutLog.name
                      },
                    });
                    navigateTo(`/teams/${account.id}/template_logs/${templateCloseoutLog.id}/edit`);
                  }
                })
              }}
              disableElevation={true}
            >
              New Template
            </Button>
          </Box>
        </Box>
        <StyledDataGridPremium
          apiRef={templateCloseoutLogDataGridApiRef}
          columns={dataGridColumnDefinitions}
          disableColumnMenu
          slotProps={{
            row: {
              onMouseEnter: (
                event: React.MouseEvent<HTMLDivElement, MouseEvent>
              ) => setHoveredRowId(event.currentTarget.dataset.id),
              onMouseLeave: () => setHoveredRowId(null),
            },
          }}
          slots={slots}
          rowSelectionModel={templateLogsRowSelectionModel}
          rows={filteredTemplateCloseoutLogs}
          rowSelection={false}
          hideFooter={true}
          hideFooterRowCount={true}
          initialState={{
            density: 'standard',
          }}
          columnHeaderHeight={40}
          sx={{
            '&.MuiDataGrid-root': {
              borderRadius: '8px 8px 0px 0px',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'inherit',
            },
            '& .MuiDataGrid-columnHeaderCheckbox': {
              padding: '0px',
            },
          }}
        />
      </Box>
      <Popover
        id="automations-list-overflow-menu"
        disableScrollLock={true}
        slotProps={{ paper: { sx: { padding: '12px 0px' } } }}
        open={Boolean(overflowAnchorEl)}
        onClose={() => setOverflowAnchorEl(null)}
        anchorEl={overflowAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            setOverflowAnchorEl(null);
            duplicateTemplateLog({
              variables: {
                accountId: account.id,
                id: currentContextTemplateCloseoutLog.id
              }
            }).then((data) => {
              if (data?.data?.duplicateTemplateCloseoutLog?.success) {
                refetchTemplateCloseoutLogs();
                setCurrentContextTemplateCloseoutLog(null);
              }
            })
          }}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOverflowAnchorEl(null);
            setDeleteTemplateLogModal({ isOpen: true, templateLogId: currentContextTemplateCloseoutLog.id });
            setCurrentContextTemplateCloseoutLog(null);
          }}
        >
          Delete
        </MenuItem>
      </Popover>
    </>
  );
};
