import React from 'react';
import { LogFileRequest } from '../types';
import { Box, Divider, TextField } from '@mui/material';
import { FieldContainer } from '../styles';
import { Typography } from './shared/Typography';

interface IFileRequestNotesProps {
  logFileRequest: LogFileRequest;
  onUpdateInternalNotes: (internalNotes: string) => void;
  onUpdatePublicNotes: (publicNotes: string) => void;
}

export const FileRequestNotes = ({
  logFileRequest,
  onUpdateInternalNotes,
  onUpdatePublicNotes,
}: IFileRequestNotesProps) => {

  const [internalNotes, setInternalNotes] = React.useState<string>(logFileRequest.internalNotes);
  const [publicNotes, setPublicNotes] = React.useState<string>(logFileRequest.description);

  return (
    <Box display={'flex'} flexDirection={'column'} width={1} height={1} padding={'28px'} gap={'24px'}>
      <FieldContainer sx={{
        gap: '12px',
      }}>
        <Typography typestyle='l'>Internal Notes</Typography>
        <TextField
          size="medium"
          multiline
          rows={8}
          autoComplete="off"
          value={internalNotes}
          onChange={(event) =>{
            setInternalNotes(event.target.value);
          }}
          onBlur={() => {
            onUpdateInternalNotes(internalNotes);
          }}
        />
      </FieldContainer>

      <Divider />

      <FieldContainer sx={{
        gap: '12px',
      }}>
        <Typography typestyle='l'>Public Notes</Typography>
        <TextField
          size="medium"
          multiline
          rows={8}
          autoComplete="off"
          value={publicNotes}
          onChange={(event) =>{
            setPublicNotes(event.target.value);
          }}
          onBlur={() => {
            onUpdatePublicNotes(publicNotes);
          }}
        />
      </FieldContainer>
    </Box>
  );
};
