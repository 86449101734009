import React from 'react';
import { Popover, Box, PopoverProps, MenuItem } from '@mui/material';
import { Colors, ExtractsCheckbox } from '../styles';
import { CloseoutLogColumnDefinition, LogCustomField } from '../types';
import { GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid-premium';
import { Button } from './shared/Button';
import { Search, SearchCloseIcon, SearchCloseIconButton, SearchIconWrapper, StyledInputBase } from './package/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from './shared/Typography';

interface IManageDataGridColumnsPopoverProps {
  columnDefinitions: CloseoutLogColumnDefinition[];
  columnVisibility: Record<string, boolean>;
  logCustomFields: LogCustomField[];
  onVisibilityChange: (column: string, isVisible: boolean) => void;
  onVisibilityChangeAll: (columns: string[], visible: boolean) => void;
  onResetColumns: () => void;
  onCloseModal: () => void;
}

const toExclude = [
  GRID_CHECKBOX_SELECTION_FIELD,
  'actions'
];

export const ManageDataGridColumnsPopover = ({
  onCloseModal,
  columnDefinitions,
  onVisibilityChange,
  logCustomFields,
  onResetColumns,
  onVisibilityChangeAll,
  columnVisibility,
  ...props
}: PopoverProps & IManageDataGridColumnsPopoverProps): JSX.Element => {

  const inputBaseRef = React.useRef(null);
  const [searchValue, setSearchValue] = React.useState('');

  const validSearchValue = searchValue.trim().toLowerCase();

  const columnVisibilityValues = Object.values(columnVisibility);
  const allColumnsChecked = columnVisibilityValues.length === 0 || columnVisibilityValues.every(t => t === true);
  const someColumnsChecked = columnVisibilityValues.length > 0 && !allColumnsChecked && columnVisibilityValues.some(t => t === true);
  return (
    <Popover {...props} onClose={onCloseModal}
    slotProps={{
      paper: {
        style: {
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '300px',
          gap: '10px',
        }
      }
    }}>
      <Search sx={{
        marginLeft: '0 !important',
        flex: '0 0 auto',
        display: 'flex',
      }}>
        <SearchIconWrapper disabled={false}>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          isopen={(props.open || Boolean((searchValue || '').trim())).toString()}
          inputRef={inputBaseRef}
          autoFocus={true}
          fullWidth={true}
          placeholder="Search..."
          value={searchValue}
          onKeyDown={(evt) => {
            if (evt.key.toLowerCase() === "enter") {
              inputBaseRef?.current?.blur();
            }
          }}
          endAdornment={
            (((searchValue || '').trim())) ? (
              <SearchCloseIconButton
                  size={'small'}
                  onClick={() => {
                    setSearchValue('');
                  }}
                  style={{ visibility: ((searchValue || '').trim()) ? 'visible' : 'hidden' }}>
                <SearchCloseIcon />
              </SearchCloseIconButton>
            ) : null
          }
          onChange={(evt) => {
            setSearchValue(evt.target.value);
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </Search>
      <Box display='flex' flexDirection={'column'} sx={{
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
        minHeight: '100px',
      }}>
        {
          columnDefinitions.filter((c) => {
            if (toExclude.includes(c.field)) {
              return false;
            } else if (c.field.startsWith('custom_field_')) {
              const customField = logCustomFields.find((customField) => customField.key === c.field);
              return customField && customField.name.toLowerCase().includes(validSearchValue);
            } else {
              return c.headerName.toLowerCase().includes(validSearchValue);
            }
          }).map((columnDefinition) => {
            let isVisible = columnVisibility[columnDefinition.field];
            isVisible = typeof isVisible === 'boolean' ? isVisible : true;

            let customField = null;
            if (columnDefinition.field.startsWith('custom_field_')) {
              customField = logCustomFields.find((customField) => customField.key === columnDefinition.field);
            }

            {
              return (
                <MenuItem key={columnDefinition.field} onClick={() => {
                  onVisibilityChange(columnDefinition.field, !isVisible);
                }} sx={{
                  margin: 0,
                  padding: 0,
                }}>
                  <Box key={columnDefinition.field} display="flex" flexDirection={"row"} alignItems={'center'} justifyContent={'space-between'}>
                    <ExtractsCheckbox checked={typeof isVisible === 'boolean' ? isVisible : true} />
                    <Typography sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "200px",
                    }} typestyle='m'>{columnDefinition.field.startsWith('custom_field_') ? customField.name : columnDefinition.headerName}</Typography>
                  </Box>
                </MenuItem>
              );
            }
          })
        }
      </Box>
      <Box display="flex"
        flexDirection={"row"}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          borderTop: `1px solid ${Colors.mediumLightGray}`,
          flex: '0 0 auto',
        }}
      >
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <ExtractsCheckbox
            onClick={() => {
              onVisibilityChangeAll(
                columnDefinitions.filter((c) => {
                  return !toExclude.includes(c.field);
                }).map((c) => c.field),
                !allColumnsChecked
              )
            }}
            indeterminate={someColumnsChecked}
            checked={allColumnsChecked} />
          <Typography typestyle='m'>Show/Hide All</Typography>
        </Box>

        <Button size="small" onClick={() => {
          onResetColumns();
        }}>RESET</Button>
      </Box>
    </Popover>
  );
};
