import * as React from "react";
import {
  Paper,
  Typography,
  styled,
  Box,
} from "@mui/material";
import {
  getDate,
  isSameMonth,
  isToday,
  format,
  isWithinInterval,
} from "date-fns";
import {
  chunks,
  getDaysInMonth,
  isStartOfRange,
  isEndOfRange,
  inDateRange,
  isRangeSameDay,
} from "../utils";
import Header from "./Header";
import Day from "./Day";
import { NavigationAction, DateRange } from "../../../types";

const WEEK_DAYS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

interface MonthProps {
  value: Date;
  marker: symbol;
  dateRange: DateRange;
  minDate: Date;
  maxDate: Date;
  navState: [boolean, boolean];
  setValue: (date: Date) => void;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
  };
  onSelectOpen: () => void;
  onSelectClosed: () => void;
}

const Month: React.FunctionComponent<MonthProps> = (props) => {
  const {
    helpers,
    handlers,
    value: date,
    dateRange,
    marker,
    setValue: setDate,
    minDate,
    maxDate,
    onSelectOpen,
    onSelectClosed,
  } = props;

  const [back, forward] = props.navState;
  return (
    <Paper square elevation={0} style={{ width: 290 }}>
      <Box display={'flex'} justifyContent={'center'} sx={{
        boxSizing: 'border-box',
        flexFlow: 'wrap',
        width: '100%',
      }}>
        <Header
          date={date}
          setDate={setDate}
          nextDisabled={!forward}
          prevDisabled={!back}
          onClickPrevious={() =>
            handlers.onMonthNavigate(marker, NavigationAction.Previous)
          }
          onClickNext={() =>
            handlers.onMonthNavigate(marker, NavigationAction.Next)
          }
          onSelectOpen={onSelectOpen}
          onSelectClosed={onSelectClosed}
        />

        <Box
          sx={{
            boxSizing: 'border-box',
            display: 'flex',
            flexFlow: 'wrap',
            width: '100%',
            margin: '10px 0px 0px',
            justifyContent: 'space-between',
            paddingLeft: '30px',
            paddingRight: '30px',
          }}
          flexDirection="row"
        >
          {WEEK_DAYS.map((day) => (
            <Typography color="textSecondary" key={day} variant="caption">
              {day}
            </Typography>
          ))}
        </Box>

        <Box
          sx={{
            boxSizing: 'border-box',
            display: 'flex',
            flexFlow: 'column wrap',
            width: '100%',
            margin: '15px 0px 20px',
            justifyContent: 'space-between',
            paddingLeft: '15px',
            paddingRight: '15px',
          }}
          flexDirection="column"
        >
          {chunks(getDaysInMonth(date), 7).map((week, idx) => (
            <Box key={idx} sx={{
              boxSizing: 'border-box',
              display: 'flex',
              flexFlow: 'wrap',
              width: '100%',
              justifyContent: 'center',
              flexDirection: 'row',
            }}>
              {week.map((day) => {
                const isStart = isStartOfRange(dateRange, day);
                const isEnd = isEndOfRange(dateRange, day);
                const isRangeOneDay = isRangeSameDay(dateRange);
                const highlighted =
                  inDateRange(dateRange, day) || helpers.inHoverRange(day);

                const _isSameMonth = isSameMonth(date, day);

                return (
                  <Day
                    key={format(day, "mm-dd-yyyy")}
                    filled={isStart || isEnd}
                    outlined={isToday(day)}
                    highlighted={highlighted && !isRangeOneDay}
                    disabled={
                      !_isSameMonth ||
                      !isWithinInterval(day, {
                        start: minDate,
                        end: maxDate,
                      })
                    }
                    startOfRange={isStart && !isRangeOneDay}
                    endOfRange={isEnd && !isRangeOneDay}
                    onClick={() => handlers.onDayClick(day)}
                    onHover={() => handlers.onDayHover(day)}
                    value={_isSameMonth ? getDate(day) : ''}
                  />
                );
              })}
            </Box>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default Month;
