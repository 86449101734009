import React from 'react';


import { VendorWorkflowRequest, VendorWorkflowResponse } from '../types';
import { Box, List, MenuItem, Popover, styled, Tooltip } from '@mui/material';
import { Colors } from '../styles';
import { Typography } from './shared/Typography';
import { format } from 'date-fns';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { isEmpty } from '../utils/utils';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { Button } from './shared/Button';

interface IFileRequestWorkflowProps {
  vendorWorkflowRequests: VendorWorkflowRequest[];
  detailDateFormat: string;
}

const IconBox = styled(Box)(() => {
  return {
    width: '36px',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  }
});

export const WorkflowRequest = ({
  vendorWorkflowRequest,
  detailDateFormat,
}: { vendorWorkflowRequest: VendorWorkflowRequest, detailDateFormat: string }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={'16px'}>
      <Box display={"flex"} flexDirection={"row"} gap={'12px'} alignItems={'center'}>
        {vendorWorkflowRequest.status === "pending" ? (
          <IconBox sx={{
            backgroundColor: Colors.lightGray
          }}>
            <EmailOutlinedIcon fontSize='small' />
          </IconBox>
        ) : (
          <IconBox sx={{
            backgroundColor: Colors.lightYellow
          }}>
            <CreateOutlinedIcon fontSize='small' />
          </IconBox>
        )}
        <Box display={"flex"} flexDirection={"column"} flexGrow={1} gap={'4px'}>
          <Typography typestyle='s bold'>{vendorWorkflowRequest.title}</Typography>
          <Typography typestyle='xs'>{format(new Date(vendorWorkflowRequest.createdAt), detailDateFormat)}</Typography>
        </Box>
      </Box>
      {
        vendorWorkflowRequest.status !== 'pending' && !isEmpty(vendorWorkflowRequest.comment) && (
          <Box display={'flex'} sx={{
            background: Colors.lightestGray,
            borderRadius: '12px',
            padding: '20px'
          }}>
            <Typography typestyle='s'>{vendorWorkflowRequest.comment}</Typography>
          </Box>
        )
      }
    </Box>
  )
};

export const WorkflowResponse = ({
  vendorWorkflowResponse,
  onOverflowMenuClick,
  detailDateFormat,
}: { vendorWorkflowResponse: VendorWorkflowResponse, onOverflowMenuClick: (vendorWorkflowResponse: VendorWorkflowResponse, target: any) => void, detailDateFormat: string }) => {
  const [hoveredIndex, setHoveredIndex] = React.useState(null);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={'16px'} sx={{
      borderBottom: `1px solid ${Colors.mediumLightGray}`,
      paddingBottom: '28px'
    }}>
      <Box display={"flex"} flexDirection={"row"} gap={'12px'} alignItems={'center'}>
        {vendorWorkflowResponse.status === "ready_for_review" ? (
          <IconBox sx={{
            backgroundColor: Colors.blue2
          }}>
            <UploadOutlinedIcon fontSize='small' />
          </IconBox>
        ) : (
          <IconBox sx={{
            backgroundColor: Colors.lightGreen
          }}>
            <CheckOutlinedIcon fontSize='small' />
          </IconBox>
        )}
        <Box display={"flex"} flexDirection={"column"} flexGrow={1} gap={'4px'}>
          <Typography typestyle='s bold'>{vendorWorkflowResponse.title}</Typography>
          <Typography typestyle='xs'>{format(new Date(vendorWorkflowResponse.createdAt), detailDateFormat)}</Typography>
        </Box>
        <Button
          variant="outlined"
          buttonborderstyle="pill"
          size='small'
          startIcon={<MoreHoriz fontSize="small" />}
          sx={{
            '&.MuiButton-outlined': {
              border: '1px solid transparent',
              minWidth: '36px',
            },
            '& .MuiButton-startIcon': {
              margin: '0px',
            },
          }}
          onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
            onOverflowMenuClick(vendorWorkflowResponse, evt.currentTarget);
          }}
        />
      </Box>

      {!isEmpty(vendorWorkflowResponse.comment) && (
        <Box display={'flex'} flexDirection={'column'} gap={'12px'} sx={{
          background: Colors.lightestGray,
          borderRadius: '12px',
          padding: '20px'
        }}>
          <Typography typestyle='s'>{vendorWorkflowResponse.comment}</Typography>
        </Box>
      )}
      <Box display={'flex'} flexDirection={'column'} gap={'12px'} sx={{
          background: Colors.lightestGray,
          borderRadius: '12px',
          padding: '20px'
        }}>
        {(vendorWorkflowResponse.attachments || []).filter(a => !a.omitted).map((attachment, index) => {
          return (
            <Box key={`response-attachment-${attachment.id}`} display={'flex'} flexDirection={'column'} gap={'6px'}>
              <Box display={'flex'} flexDirection={'row'} gap={'6px'} alignItems={'center'} width={1}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}>
                <Typography typestyle='s' sx={{
                    textDecoration: hoveredIndex === index ? 'underline' : 'none',
                  }}>
                  {attachment.filename}
                </Typography>

                {hoveredIndex === index && (
                  <>
                    <Tooltip title="Download">
                      <DownloadOutlinedIcon
                        onClick={() => {
                          window.open(attachment.procoreDownloadUrl, "_blank");
                        }}
                        sx={{
                          fontSize: '16px',
                          '&:hover': {
                            cursor: 'pointer',
                          }
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="View in Procore">
                      <OpenInNewOutlinedIcon
                        onClick={() => {
                          if (attachment.viewableInProcore) {
                            window.open(attachment.procoreDocumentViewerUrl, "_blank");
                          } else {
                            window.open(attachment.procoreRecordUrl, "_blank");
                          }
                        }}
                        sx={{
                          fontSize: '16px',
                          '&:hover': {
                            cursor: 'pointer',
                          }
                        }}
                      />
                    </Tooltip>
                  </>

                )}
              </Box>
              <Typography typestyle='xs' color={Colors.mediumDarkerGray}>{`Uploaded ${format(new Date(attachment.procoreCreatedAt), detailDateFormat)}`}</Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  );
};

export const FileRequestWorkflow = ({
  vendorWorkflowRequests,
  detailDateFormat,
}: IFileRequestWorkflowProps) => {
  const [overflowAnchorEl, setOverflowAnchorEl] = React.useState<HTMLButtonElement>(null);
  const [currentVendorWorkflowResponse, setCurrentVendorWorkflowResponse] = React.useState<VendorWorkflowResponse | null>(null);

  const resetAnchorEl = () => {
    setOverflowAnchorEl(null);
    setCurrentVendorWorkflowResponse(null);
  }

  return (
    <>
      <Popover
        open={Boolean(overflowAnchorEl)}
        anchorEl={overflowAnchorEl}
        onClose={() => resetAnchorEl()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <MenuItem onClick={() => {
          currentVendorWorkflowResponse.attachments.filter(a => !a.omitted).forEach((attachment) => {
            window.open(attachment.procoreDownloadUrl, "_blank");
          });

          resetAnchorEl();
        }}>
          Download All
        </MenuItem>
      </Popover>
      <List sx={{ padding: 0, width: '100%' }}>
        {vendorWorkflowRequests.map((vendorWorkflowRequest) => (
          <Box
            sx={{
              paddingX: '28px',
              paddingY: '24px',
            }}
            borderBottom={`1px solid ${Colors.mediumLightGray}`}
            gap={'20px'}
            width={1}
            key={`workflow-request-${vendorWorkflowRequest.id}`} display={"flex"} flexDirection={"column"}>
            {
              vendorWorkflowRequest.vendorWorkflowResponse && (
                <WorkflowResponse
                  vendorWorkflowResponse={vendorWorkflowRequest.vendorWorkflowResponse}
                  detailDateFormat={detailDateFormat}
                  onOverflowMenuClick={(vendorWorkflowResponse, target) => {
                    setCurrentVendorWorkflowResponse(vendorWorkflowResponse);
                    setOverflowAnchorEl(target);
                  }} />
              )
            }

            <WorkflowRequest detailDateFormat={detailDateFormat} vendorWorkflowRequest={vendorWorkflowRequest} />
          </Box>
        ))}
      </List>
    </>
  );
};
