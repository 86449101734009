import React from "react";
import {
  Paper,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import { format, differenceInCalendarMonths } from "date-fns";
import ArrowRightAlt from "@mui/icons-material/ArrowRightAlt";
import Month from "./Month";
import {
  DateRange,
  DefinedRange,
  Setter,
  NavigationAction,
} from "../../../types";
import { MARKERS } from "..";

interface DateRangeMenuProps {
  dateRange: DateRange;
  ranges: DefinedRange[];
  minDate: Date;
  maxDate: Date;
  firstMonth: Date;
  secondMonth: Date;
  setFirstMonth: Setter<Date>;
  setSecondMonth: Setter<Date>;
  setDateRange: Setter<DateRange>;
  helpers: {
    inHoverRange: (day: Date) => boolean;
  };
  handlers: {
    onDayClick: (day: Date) => void;
    onDayHover: (day: Date) => void;
    onMonthNavigate: (marker: symbol, action: NavigationAction) => void;
  };
  onSelectOpen: () => void;
  onSelectClosed: () => void;
}

const DateRangeMenu: React.FunctionComponent<DateRangeMenuProps> = (props) => {
  const {
    ranges,
    dateRange,
    minDate,
    maxDate,
    firstMonth,
    setFirstMonth,
    secondMonth,
    setSecondMonth,
    setDateRange,
    onSelectClosed,
    onSelectOpen,
    helpers,
    handlers,
  } = props;
  const { startDate, endDate } = dateRange;
  const canNavigateCloser =
    differenceInCalendarMonths(secondMonth, firstMonth) >= 2;
  const commonProps = { dateRange, minDate, maxDate, helpers, handlers };
  return (
    <Paper
      sx={{
        boxShadow:
          "0px 1px 10px rgba(26, 32, 36, 0.06), 0px 4px 5px rgba(26, 32, 36, 0.07), 0px 2px 4px 1px rgba(26, 32, 36, 0.04)",
        borderRadius: "6px",
        overflow: "hidden",
      }}
    >
      <Box display={'flex'} flexDirection="row" justifyContent={'space-between'} sx={{
        flexWrap: 'nowrap'
      }}>
        <Box>
          <Box display={'flex'} padding={"20px 70px"} alignItems="center">
            <Box display={'flex'} style={{ textAlign: "center", flex: 1 }} justifyContent={'center'}>
              <Typography variant="subtitle1">
                {startDate ? format(startDate, "MMMM d, yyyy") : "Start Date"}
              </Typography>
            </Box>
            <Box display={'flex'} style={{ textAlign: "center" }}>
              <ArrowRightAlt color="action" />
            </Box>
            <Box display={'flex'} style={{ textAlign: "center", flex: 1 }} justifyContent={'center'}>
              <Typography variant="subtitle1">
                {endDate ? format(endDate, "MMMM d, yyyy") : "End Date"}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box display={'flex'} flexDirection="row" justifyContent="center" sx={{
            flexWrap: 'nowrap'
          }}>
            <Month
              {...commonProps}
              value={firstMonth}
              setValue={setFirstMonth}
              navState={[true, canNavigateCloser]}
              marker={MARKERS.FIRST_MONTH}
              onSelectOpen={onSelectOpen}
              onSelectClosed={onSelectClosed}
            />
            <div style={{
              borderLeft: `solid rgba(0, 0, 0, 0.04)`,
              marginBottom: '20px'}} />
            <Month
              {...commonProps}
              value={secondMonth}
              setValue={setSecondMonth}
              navState={[canNavigateCloser, true]}
              marker={MARKERS.SECOND_MONTH}
              onSelectOpen={onSelectOpen}
              onSelectClosed={onSelectClosed}
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default DateRangeMenu;
