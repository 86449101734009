import React from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Typography,
  InputBase,
  ListItemButton,
  alpha,
  Tooltip,
} from "@mui/material";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import idx from "idx";
import { Colors } from "../../styles";
import {
  CheckedCheckBox,
  ManualSelectCheckbox,
  UncheckedCheckBox,
  IndeterminateCheckBox,
  SearchCloseIconButton,
  SearchCloseIcon,
} from "./styles";
import { Account, Project } from "../../types";
import SearchIcon from '@mui/icons-material/Search';
import { useGetAccountProjects } from "../../graphql/queries/GetAccountProjects";
import InfoIcon from '@mui/icons-material/Info';
import { useUseProjectLicenses } from "../../graphql/mutations/UseProjectLicenses";
import { Button } from "../shared/Button";
import FilterList from "@mui/icons-material/FilterList";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import AutoSizer from "react-virtualized-auto-sizer";
import ExtractsProDialog from "../shared/ExtractsProDialog";
import { searchAndSortProjects } from "../../utils/utils";
import { EnterpriseProjectListFilterPopover } from "../EnterpriseProjectListFilterPopover";
import { SelectFilterValue } from "../../types/submittal";
import { useGetProcoreProjectFilters } from "../../graphql/queries/GetProcoreProjectFilters";

const AddProjectsAnnualLicenseModal = (props: {
  account: Account;
  handleOnClose: (refresh: boolean, numberOfLicenses: number, licensedProcoreProjectServerIds: number[]) => void;
  open: boolean;
}): JSX.Element => {
  const { account, handleOnClose, open } = props;

  const { loading, data } = useGetAccountProjects(account.id, false);

  const [
    useProjectLicenses,
    { loading: useProjectLicensesLoading, data: useProjectLicensesData },
  ] = useUseProjectLicenses({
    accountId: account.id,
    procoreProjectServerIds: [],
  });

  const [projectsToLicense, setProjectsToLicense] = React.useState<Record<number, boolean>>({});
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [filterPopoverAnchorEl, setFilterPopoverAnchorEl] = React.useState<HTMLButtonElement>(null);
  const [pendingFilterValues, setPendingFilterValues] = React.useState<Record<string, SelectFilterValue[]>>({});
  const [selectedFilterValues, setSelectedFilterValues] = React.useState<Record<string, SelectFilterValue[]>>({});

  const handleProjectClick = (procoreServerId: number) => {
    const add = projectsToLicense[procoreServerId] || false;

    setProjectsToLicense({
      ...projectsToLicense,
      [procoreServerId]: !add,
    });
  };
  const [
      loadProcoreProjectFilters,
      { data: procoreProjectFiltersData }
    ] = useGetProcoreProjectFilters({
      accountId: account.id,
    });

  React.useEffect(() => {
    if (open) {
      loadProcoreProjectFilters({
        fetchPolicy: 'network-only',
      });
    }
  }, [open]);

  const filters = (procoreProjectFiltersData && procoreProjectFiltersData.filters || []).filter((f) => {
    return (f.values || []).length > 0
  });

  function renderRow(props: ListChildComponentProps<Project[]>) {
    const { index, style, data } = props;

    const project = data[index] as Project;

    const checked = projectsToLicense[project.procoreServerId] || false;
    return (
      <div style={style}>
        <ListItemButton
          sx={{
            paddingLeft: '16px'
          }}
          key={`user-list-item-${project.procoreServerId}`}
          disabled={!checked && disableMoreLicenses}
          onClick={() => handleProjectClick(project.procoreServerId)}
        >
          <ManualSelectCheckbox
            disableFocusRipple
            disableRipple
            disableTouchRipple
            color="primary"
            disabled={!checked && disableMoreLicenses}
            indeterminateIcon={<IndeterminateCheckBox />}
            icon={<UncheckedCheckBox />}
            checkedIcon={<CheckedCheckBox />}
            checked={checked}
          />
          <Box display={"flex"} flexDirection={"column"} width={1}>
            <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
              {project.displayName || project.name}
            </Typography>
            <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
              {project.procoreCompanyName}
            </Typography>
          </Box>
        </ListItemButton>
        <Divider key={`grouping-list-item-divider-${project.procoreServerId}`} />
      </div>
    );
  }

  const itemData: Project[] = idx(data, data => data.procoreProjects) || [];

  const filteredItemData = searchAndSortProjects(itemData, searchValue, selectedFilterValues);

  const appliedLicenseCount = Object.values(projectsToLicense).filter(v => v).length;
  const disableMoreLicenses = account.numberOfLicenses - appliedLicenseCount <= 0;

  const licensesToUseCount = Object.values(projectsToLicense).filter((v) => v).length
  const useLicenseText = licensesToUseCount === 0 ? "Apply Licenses" : `Apply ${licensesToUseCount} License(s)`;

  return (
    <ExtractsProDialog
      title="Add Projects"
      open={open}
      closeButton={{
        onClick: () => handleOnClose(false, 0, []),
        disabled: loading || useProjectLicensesLoading,
      }}
      maxWidth={"md"}
      secondaryButton={{
        text: "Cancel",
        onClick: () => handleOnClose(false, 0, []),
        disabled: loading || useProjectLicensesLoading,
      }}
      primaryButton={{
        text: useLicenseText,
        onClick: () => {
          useProjectLicenses({
            variables: {
              accountId: account.id,
              procoreProjectServerIds: Object.keys(projectsToLicense).map((v) => parseInt(v)),
            },
          }).then((response) => {
            const data = response.data;

            if (data) {
              handleOnClose(
                data.useProjectLicenses.success,
                data.useProjectLicenses.numberOfLicenses,
                data.useProjectLicenses.procoreProjectServerIds
              );
            }
          }).catch((err) => {
            console.log(err);

            handleOnClose(true, account.numberOfLicenses, []);
          });
        },
        disabled: loading || useProjectLicensesLoading || licensesToUseCount === 0,
        loading: useProjectLicensesLoading,
      }}
      >
         {loading ? (
          <Box
            display={"flex"}
            flex={1}
            sx={{ border: `1px solid ${Colors.mediumLightGray}` }}
            borderRadius={"4px"}
            flexDirection="row"
            alignItems={"center"}
            justifyContent="center"
          >
            <CircularProgress size={12} style={{ margin: "5px" }} />
            <Typography>Loading projects...</Typography>
          </Box>
        ) : (
          <>
            <Box display={"flex"} flexDirection={"row"} flex={0} alignItems={"center"} style={{
              borderRadius: '8px',
              padding: '16px 20px',
              border: `1px solid ${Colors.lightishGray}`,
              boxShadow: '0px 1px 10px 0px rgba(26, 32, 36, 0.06), 0px 2px 4px 1px rgba(26, 32, 36, 0.04)'
            }}>
              <InfoIcon sx={{ color: account.numberOfLicenses > 0 ? Colors.newAccentBlue : Colors.yellow }} />
              <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} marginLeft={"10px"}>
                {
                  (account.numberOfLicenses > 0) ? (
                    <>
                      <p style={{ padding: 0, margin: 0, color: Colors.darkerGray, fontWeight: 700, fontSize: '13px' }}>{`You’ve got ${Math.max(0, account.numberOfLicenses - appliedLicenseCount)} project licenses ready to use.`}</p>
                      <p style={{ padding: 0, margin: 0, color: Colors.darkerGray, fontWeight: 400, fontSize: '12px' }}>Select the projects you want to add a license to.</p>
                    </>
                  ) : (
                    <>
                      <p style={{ padding: 0, margin: 0, color: Colors.darkerGray, fontWeight: 700, fontSize: '13px' }}>{`Oops! Your team doesn’t have any more available licenses.`}</p>
                      <p style={{ padding: 0, margin: 0, color: Colors.darkerGray, fontWeight: 400, fontSize: '12px' }}>Contact your Extracts Pro representative to add more licenses to your team.</p>
                    </>
                  )
                }
              </Box>
            </Box>
            <Box display="flex" flexDirection={"row"} flex={0} alignContent="center" gap={'8px'}>
              <Button
                disabled={loading || account.numberOfLicenses === 0}
                onClick={() => {
                  if (licensesToUseCount > 0) {
                    setProjectsToLicense({});
                  } else {
                    setProjectsToLicense(filteredItemData.slice(0, account.numberOfLicenses).reduce((acc, nextItem) => {
                      acc[nextItem.procoreServerId] = true;

                      return acc;
                    }, {} as Record<number, boolean>))
                  }
                }}
                buttonborderstyle="pill"
                variant="outlined"
                disableElevation={true}
                size="medium"
                style={{
                  minHeight: '39px',
                }}
              >
                {licensesToUseCount > 0 ? "Deselect All" : "Select All"}
              </Button>
              <Box sx={{
                position: 'relative',
                minHeight: '39px',
                maxHeight: 'unset',
                flex: '1 0',
                borderRadius: '50vh',
                paddingRight: '2px',
                border: '1px solid #D5DFE6',
                backgroundColor: alpha('#FFFFFF', 0.15),
                '&:hover': {
                  backgroundColor: alpha('#FFFFFF', 0.25),
                },
                marginLeft: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: '12px'
              }}>
                <SearchIcon />
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  fullWidth={true}
                  placeholder="Search..."
                  autoFocus={true}
                  value={searchValue}
                  disabled={account.numberOfLicenses === 0}
                  endAdornment={
                    (((searchValue || '').trim())) ? (
                      <SearchCloseIconButton
                          size={'small'}
                          onClick={() => {
                            setSearchValue('');
                          }}
                          style={{ visibility: ((searchValue || '').trim()) ? 'visible' : 'hidden' }}>
                        <SearchCloseIcon />
                      </SearchCloseIconButton>
                    ) : null
                  }
                  onChange={(evt) => {
                    setSearchValue(evt.target.value)
                  }}
                  inputProps={{ 'aria-label': 'search users' }}
                />
              </Box>
              <Tooltip title="Filter" placement='top'>
                <Button
                  buttonborderstyle="pill"
                  variant="outlined"
                  disabled={account.numberOfLicenses === 0}
                  startIcon={<FilterList fontSize="small" />}
                  endIcon={<ArrowDropDown fontSize="small" />}
                  onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                    setFilterPopoverAnchorEl(evt.currentTarget)
                  }}>Filter</Button>
              </Tooltip>
            </Box>
            <div
              style={{
                display: "flex",
                flex: "1",
                width: "100%",
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: "6px",
                borderColor: Colors.mediumLightGray,
              }}
            >
              <AutoSizer>
                {({ height, width }) => (
                  <FixedSizeList
                    height={height}
                    width={width}
                    itemData={filteredItemData}
                    itemCount={filteredItemData.length}
                    itemSize={55}
                  >
                    {renderRow}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </div>
          </>
        )}
      <EnterpriseProjectListFilterPopover
        allFilters={filters}
        selectedFilterValues={pendingFilterValues}
        onResetFilters={() => {
          setPendingFilterValues({});
        }}
        onRemoveFilter={(key) => {
          setPendingFilterValues((prev) => {
            const newFilters = { ...prev };
            delete newFilters[key];
            return newFilters;
          });
        }}
        onChangeSelectedFilter={(oldKey, newKey) => {
          setPendingFilterValues((prev) => {
            const newFilters = { ...prev };
            delete newFilters[oldKey];
            newFilters[newKey] = [];
            return newFilters;
          });
        }}
        onAppendNewFilter={(key) => {
          setPendingFilterValues((prev) => {
            return {
              ...prev,
              [key]: [],
            }
          });
        }}
        onUpdateFilterValues={(key, values) => {
          setPendingFilterValues((prev) => {
            return {
              ...prev,
              [key]: values,
            }
          });
        }}
        open={Boolean(filterPopoverAnchorEl)}
        onClose={() => {
          setFilterPopoverAnchorEl(null);
          setSelectedFilterValues(pendingFilterValues);
        }}
        anchorEl={filterPopoverAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
    </ExtractsProDialog>
  );
};

export default AddProjectsAnnualLicenseModal;
