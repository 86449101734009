import React from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  MenuItem,
  Popover,
} from '@mui/material';
import AutomationPanel from './AutomationPanel';
import { NewAutomationContext } from '../../contexts/EditAutomationContextProvider';
import { Colors, StyledVerticalTabs } from '../../styles';
import { BodyCardHeaderBar } from '../shared/BodyCardHeaderBar';
import { BodyCard } from '../shared/BodyCard';
import { Typography } from '../shared/Typography';
import { TabPanel, TabContext } from '@mui/lab';
import { AddCircle, ArrowDropDown } from '@mui/icons-material';
import { Button, LoadingButton } from '../shared/Button';
import { VerticalTabWithRemove } from '../shared/VerticalTabWithRemove';
import {
  useGetExtractAutomationData,
} from '../../graphql/queries/automation/GetExtractAutomationData';
import { ModalFooter } from '../shared/ModalFooter';
import useNavigationHook from '../../hooks/UseNavigationHook';
import {
  ExtractToolAutomationUpsertAttributes,
  useUpsertExtractAutomation,
} from '../../graphql/mutations/automation/UpsertExtractAutomation';
import { ToolAutomation } from '../../contexts/EditAutomationContext';
import { booleanValueOrFallback, getExtractOptionsForToolEngineName } from '../../utils/utils';
import { ExtractOptions, ProcoreToolFilter } from '../../contexts/NewPackageContext';
import { AutomationDestination, ProcoreToolGroupings } from '../../types';

export default function NewAutomation() {
  const { state, dispatch } = React.useContext(NewAutomationContext);
  const [selectedTab, setSelectedTab] = React.useState<string>(
    state.toolAutomations[0]?.procoreToolEngineName || ''
  );
  const navigateTo = useNavigationHook();
  const [addToolAnchorEl, setAddToolAnchorEl] =
    React.useState<HTMLButtonElement>(null);

  if (state.toolAutomations.length > 0 && selectedTab === '') {
    setSelectedTab(state.toolAutomations[0].procoreToolEngineName);
  }

  const { loading: loadingExtractAutomationData, data: extractAutomationData } = useGetExtractAutomationData(
    {
      accountId: state.accountId,
      extractAutomationId: state.id,
      procoreToolEngineName: 'projects'
    },
    false,
    'network-only'
  );

  const [upsertExtractAutomation, { loading: upsertLoading }] =
    useUpsertExtractAutomation({
      attributes: {
        id: state.id,
        name: state.name,
        accountId: state.accountId,
        extractToolAutomations: [],
      },
    });

  const transformToolAutomations = (
    toolAutomations: ToolAutomation[]
  ): ExtractToolAutomationUpsertAttributes[] => {
    return toolAutomations.map((toolAutomation) => {
      return {
        procoreToolEngineName: toolAutomation.procoreToolEngineName,
        cloudStorageSystemId: toolAutomation.cloudStorageSystem.id,
        documentStorageSystem: toolAutomation.cloudStorageSystem.documentStorageSystem,
        conditions: toolAutomation.selectedFilters,
        groupings: toolAutomation.selectedGroupings.map((grouping) => {
          {
            return {
              id: grouping.id,
              label: grouping.label,
              attribute: grouping.attribute,
              attribute_source: grouping.attributeSource,
              default_value: grouping.defaultValue,
              grouping_label: grouping.groupingLabel,
              operator: grouping.operator,
              conditional: grouping.conditional,
            };
          }
        }),
        destination: toolAutomation.destination,
        exportOptions: toolAutomation.extractOptions,
      };
    });
  };

  const handleSave = () => {
    upsertExtractAutomation({
      variables: {
        attributes: {
          id: state.id,
          name: state.name,
          accountId: state.accountId,
          extractToolAutomations: transformToolAutomations(
            state.toolAutomations
          ),
        },
      },
    }).then((result) => {
      navigateTo('/teams');
    });
  };

  React.useEffect(() => {
    if (loadingExtractAutomationData || !extractAutomationData) { return; }

    dispatch({
      type: 'SET_TOOL_AUTOMATIONS',
      value: {
        cloudStorageSystems: extractAutomationData.extractAutomationCloudStorageSystems,
        sharedGroupings: extractAutomationData.extractAutomationToolGroupings as ProcoreToolGroupings[],
        toolAutomations: extractAutomationData.extractToolAutomations.map((extractToolAutomation) => {
          return {
            id: extractToolAutomation.id,
            procoreToolEngineName: extractToolAutomation.procoreToolEngineName,
            title: extractToolAutomation.title,
            selectedFilters: extractToolAutomation.conditions as ProcoreToolFilter[],
            filters: [],
            groupings: [],
            cloudStorageSystem: extractToolAutomation.cloudStorageSystem,
            documentStorageSystem: extractToolAutomation.cloudStorageSystem.documentStorageSystem,
            selectedGroupings: extractToolAutomation.groupings.map((groupingJson) => {
              return {
                id: groupingJson["id"],
                label: groupingJson["label"],
                attribute: groupingJson["attribute"],
                attributeSource: groupingJson["attribute_source"],
                defaultValue: groupingJson["default_value"],
                groupingLabel: groupingJson["grouping_label"],
                operator:  groupingJson["operator"] || 'eq',
                conditional: booleanValueOrFallback(groupingJson["conditional"], false),
              }
            }) as ProcoreToolGroupings[],
            destination: extractToolAutomation.destination as AutomationDestination,
            extractOptions: extractToolAutomation.exportOptions as ExtractOptions,
          } as ToolAutomation;
          }
        ),
      }
    });
  }, [extractAutomationData, loadingExtractAutomationData]);

  return (
    (<Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        padding: '20px 24px 0px 24px',
        flex: '1 1 0',
      }}
    >
      <BodyCard
        sx={{
          borderRadius: '8px 8px 0px 0px',
          maxWidth: '1100px'
        }}
      >
        <BodyCardHeaderBar
          title={state.name}
          onEditTitle={(value: string) => {
            dispatch({ type: 'SET_NAME', value: { name: value } });
          }}
        />
        <TabContext value={selectedTab}>
          <Box
            sx={{
              flex: '1 1 0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginX: '28px',
              borderTop: `1px solid ${Colors.mediumLightGray}`,
              gap: '24px',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                alignSelf: 'stretch',
                width: '300px',
                borderRight: `1px solid ${Colors.mediumLightGray}`,
                padding: '20px 24px 24px 6px',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                disabled={loadingExtractAutomationData}
                startIcon={<AddCircle />}
                endIcon={
                  loadingExtractAutomationData ? (
                    <CircularProgress size="16px" />
                  ) : (
                    <ArrowDropDown />
                  )
                }
                onClick={(evt) => setAddToolAnchorEl(evt.currentTarget)}
              >
                <Typography
                  typestyle="s"
                  sx={{ flex: '1 1 0', textAlign: 'start' }}
                >
                  Add a tool
                </Typography>
              </Button>
              <Popover
                id="overflow-menu"
                slotProps={{
                  paper: {
                    sx: {
                      padding: '12px 0px',
                      minWidth: addToolAnchorEl
                        ? addToolAnchorEl.offsetWidth
                        : '0px',
                    },
                  }
                }}
                open={Boolean(addToolAnchorEl)}
                onClose={() => setAddToolAnchorEl(null)}
                anchorEl={addToolAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {extractAutomationData?.extractAutomationToolTypes.map(
                  (toolType, i) => {
                    return (
                      //@ts-ignore - necessary to load object into value
                      (<MenuItem
                        key={`tool-menu-item-${i}`}
                        value={toolType}
                        onClick={() => {
                          const cloudStorageSystem = state.toolAutomations.length === 0 ? state.cloudStorageSystems.find(f => f.documentStorageSystem === 'procore') : state.toolAutomations[0].cloudStorageSystem;

                          dispatch({
                            type: 'APPEND_TOOL_AUTOMATION',
                            value: {
                              id: null,
                              procoreToolEngineName: toolType.procoreToolEngineName,
                              title: toolType.title,
                              filters: [],
                              groupings: [],
                              selectedFilters: [],
                              cloudStorageSystem: cloudStorageSystem,
                              documentStorageSystem: cloudStorageSystem.documentStorageSystem,
                              destination: { document_storage_system: cloudStorageSystem.documentStorageSystem },
                              selectedGroupings: [],
                              extractOptions: getExtractOptionsForToolEngineName(
                                [],
                                toolType.procoreToolEngineName,
                                {}
                              ),
                            },
                          });
                          setSelectedTab(toolType.procoreToolEngineName);
                          setAddToolAnchorEl(null);
                        }}
                        disabled={
                          state.toolAutomations.filter(
                            (toolAutomation) =>
                              toolAutomation.procoreToolEngineName ===
                              toolType.procoreToolEngineName
                          ).length > 0
                        }
                      >
                        {toolType.title}
                      </MenuItem>)
                    );
                  }
                )}
              </Popover>
              <Divider sx={{ borderColor: Colors.mediumLightGray }} />
              {state.toolAutomations.length > 0 && (
                <StyledVerticalTabs
                  value={selectedTab}
                  orientation="vertical"
                  onChange={(_evt, value) => {
                    setSelectedTab(value);
                  }}
                >
                  {state.toolAutomations.map((toolAutomation, key) => (
                    <VerticalTabWithRemove
                      value={toolAutomation.procoreToolEngineName}
                      key={`tool-tab-${key}`}
                      label={toolAutomation.title}
                      onRemove={() => {
                        dispatch({
                          type: 'REMOVE_TOOL_AUTOMATION',
                          value: {
                            procoreToolEngineName:
                              toolAutomation.procoreToolEngineName,
                          },
                        });
                        if (
                          selectedTab === toolAutomation.procoreToolEngineName
                        ) {
                          setSelectedTab('');
                        }
                      }}
                    />
                  ))}
                </StyledVerticalTabs>
              )}
            </Box>
            {state.toolAutomations &&
              state.toolAutomations.map((toolAutomation, i) => {
                return (
                  <TabPanel
                    sx={{
                      flex: '1 1 0',
                      alignSelf: 'stretch',
                      padding: '20px 0px 0px 0px',
                      overflow: 'scroll',
                    }}
                    value={toolAutomation.procoreToolEngineName}
                    key={`tool-automation-panel-${i}`}
                  >
                    <AutomationPanel toolAutomation={toolAutomation} />
                  </TabPanel>
                );
              })}
            {state.toolAutomations.length === 0 && (
              <Box
                sx={{
                  flex: '1 1 0',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <Typography typestyle="l">Start by picking a tool</Typography>
                  <Typography
                    typestyle="s"
                    sx={{
                      color: Colors.darkishGray,
                      lineHeight: '20px',
                      textAlign: 'center',
                    }}
                  >
                    The first step in creating an automation is to choose a tool
                    you want to automate
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </TabContext>
        <ModalFooter
          borderTop= {`1px solid ${Colors.mediumLightGray}`}
        >
          <Button
            variant="outlined"
            buttonborderstyle="pill"
            onClick={() => {
              dispatch({ type: 'RESET_AUTOMATION' });
              navigateTo('/teams');
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            buttonborderstyle="pill"
            disabled={state.toolAutomations.length === 0}
            onClick={handleSave}
            loading={upsertLoading}
          >
            Save
          </LoadingButton>
        </ModalFooter>
      </BodyCard>
    </Box>)
  );
}
