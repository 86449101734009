import React from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  InputBase,
  ListItemButton,
  styled,
  IconButtonProps,
  DialogProps,
  DialogTitleProps,
  alpha,
} from "@mui/material";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import idx from "idx";
import { Colors } from "../../styles";
import {
  CheckedCheckBox,
  ManualSelectCheckbox,
  UncheckedCheckBox,
  IndeterminateCheckBox,
  SearchCloseIconButton,
  SearchCloseIcon,
} from "./styles";
import { Account, Project } from "../../types";
import SearchIcon from "@mui/icons-material/Search";
import { useGetAccountProjects } from "../../graphql/queries/GetAccountProjects";
import { getTotalCost, isIframe, isWindowReadyForClose, searchAndSortProjects } from "../../utils/utils";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { TableRowCell } from "../packages/PackageList";
import { useBuyTrialProjectLicense } from "../../graphql/mutations/BuyFreeTrialProjectLicense";
import {
  BuyProjectLicenseAttributes,
  useBuyProjectLicenses,
} from "../../graphql/mutations/BuyProjectLicenses";
import { Button } from "../shared/Button";

const AddLicenseIconButton = styled(IconButton)<IconButtonProps>(() => {
  return {
    padding: "2px",
    backgroundColor: Colors.mediumGray,
    "&:hover": {
      backgroundColor: Colors.mediumGray,
    },
  }
});

export const FeedbackDialog = styled(Dialog)<DialogProps>(() => {
  return {
    "& .MuiDialog-paper": {
      width: "705px",
      height: "675px",
    }
  };
});

export const ModalDialogTitle = styled(DialogTitle)<DialogTitleProps>(() => {
  return {
    padding: "16px 32px 0px 32px"
  };
});

const AddProjectsProjectLicenseModal = (props: {
  account: Account;
  handleOnClose: (refresh: boolean) => void;
  open: boolean;
}): JSX.Element => {
  const { account, handleOnClose, open } = props;

  const { loading, data } = useGetAccountProjects(account.id, false);
  const [
    buyTrialProjectLicense,
    {
      loading: loadingBuyTrialProjectLicense,
      data: buyTrialProjectLicenseData,
    },
  ] = useBuyTrialProjectLicense({
    accountId: account.id,
    procoreProjectServerId: -1,
  });
  const [
    buyProjectLicenses,
    { loading: loadingBuyProjectLicenses, data: buyProjectLicensesData },
  ] = useBuyProjectLicenses({
    accountId: account.id,
    projectLicenses: [],
  });
  const [projectsToLicense, setProjectsToLicense] = React.useState<
    Record<number, number>
  >({});
  const [showCheckoutProjectLicense, setShowCheckoutProjectLicense] =
    React.useState(false);

  const [searchValue, setSearchValue] = React.useState<string>("");

  React.useEffect(() => {
    if (
      loadingBuyProjectLicenses === undefined ||
      loadingBuyProjectLicenses === true ||
      !buyProjectLicensesData
    ) {
      return;
    }

    if (buyProjectLicensesData.buyProjectLicenses.success) {
      const buyProjectLicenseWindow = window.open(
        buyProjectLicensesData.buyProjectLicenses.checkoutUrl,
        isIframe ? "_blank" : "_top"
      );

      if (isIframe && buyProjectLicenseWindow) {
        const loop = setInterval(function () {
          if (isWindowReadyForClose(buyProjectLicenseWindow)) {
            try {
              buyProjectLicenseWindow.close();
            } catch (err) {
              console.log("error closing window", err);
            } finally {
              clearInterval(loop);
              handleOnClose(true);
            }
          }
        }, 1000);
      }
    }
  }, [loadingBuyProjectLicenses]);

  const handleProjectClick = (procoreServerId: number) => {
    const add = projectsToLicense[procoreServerId] || 0;

    setProjectsToLicense({
      ...projectsToLicense,
      [procoreServerId]: add === 0 ? 1 : 0,
    });
  };

  function renderRow(props: ListChildComponentProps<Project[]>) {
    const { index, style, data } = props;

    const project = data[index] as Project;

    const checked = (projectsToLicense[project.procoreServerId] || 0) > 0;
    return (
      <div style={style}>
        <ListItemButton
          sx={{
            paddingLeft: '16px'
          }}
          key={`user-list-item-${project.procoreServerId}`}
          onClick={() => handleProjectClick(project.procoreServerId)}
        >
          <ManualSelectCheckbox
            disableFocusRipple
            disableRipple
            disableTouchRipple
            color="primary"
            indeterminateIcon={<IndeterminateCheckBox />}
            icon={<UncheckedCheckBox />}
            checkedIcon={<CheckedCheckBox />}
            checked={checked}
          />
          <Box display={"flex"} flexDirection={"column"} width={1}>
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              {project.displayName || project.name}
            </Typography>
            <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
              {project.procoreCompanyName}
            </Typography>
          </Box>
        </ListItemButton>
        <Divider
          key={`grouping-list-item-divider-${project.procoreServerId}`}
        />
      </div>
    );
  }

  const itemData: Project[] = (idx(data, (data) => data.procoreProjects) || [])
  const filteredItemData = searchAndSortProjects(itemData, searchValue, {});

  const licensesToPurchaseCount = Object.values(projectsToLicense).filter(
    (v) => v > 0
  ).length;
  const buyLicensesText =
    licensesToPurchaseCount === 0
      ? "Buy License(s)"
      : `Buy ${licensesToPurchaseCount} License(s)`;

  const totalCost = Object.values(projectsToLicense)
    .filter((v) => v > 0)
    .reduce((acc, next) => {
      acc = acc + getTotalCost(next);
      return acc;
    }, 0);
  return (
    <FeedbackDialog
      maxWidth={"sm"}
      aria-labelledby="help-dialog-title"
      open={open}
    >
      <ModalDialogTitle>
        {"Add Projects"}
      </ModalDialogTitle>
      <DialogContent
        dividers={false}
        style={{
          marginBottom: "20px",
          height: "475px",
          padding: "16px 32px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading ? (
          <Box
            display={"flex"}
            flex={"1 0"}
            sx={{ border: `1px solid ${Colors.mediumLightGray}` }}
            borderRadius={"4px"}
            flexDirection="row"
            alignItems={"center"}
            justifyContent="center"
          >
            <CircularProgress size={12} style={{ margin: "5px" }} />
            <Typography>Loading projects...</Typography>
          </Box>
        ) : showCheckoutProjectLicense ? (
          <Box
            height={1}
            width={1}
            display={"flex"}
            flexDirection={"column"}
            overflow={"hidden"}
          >
            <TableContainer
              style={{
                background: "white",
                border: `1px solid ${Colors.lightishGray}`,
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                flex: "1 1 1px",
                minHeight: "100px",
              }}
            >
              <Table aria-label="project-license-modal" size="medium">
                <TableBody>
                  {itemData
                    .filter((project) => {
                      return (
                        (projectsToLicense[project.procoreServerId] || 0) > 0
                      );
                    })
                    .map((project) => {
                      const numberOfMonths =
                        projectsToLicense[project.procoreServerId];
                      return (
                        <TableRow
                          key={`project-to-license-${project.procoreServerId}`}
                        >
                          <TableRowCell>
                            <Box
                              display="flex"
                              flexDirection={"row"}
                              width={1}
                              alignItems="center"
                              justifyContent={"space-between"}
                            >
                              <Box display={"flex"} flexDirection={"column"}>
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "250px",
                                  }}
                                >
                                  {project.displayName || project.name}
                                </Typography>
                                <Typography
                                  style={{ fontSize: "12px", fontWeight: 400 }}
                                >
                                  {project.procoreCompanyName}
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                flexDirection={"row"}
                                alignItems="center"
                                style={{ gap: "20px" }}
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center"
                                  style={{ gap: "6px" }}
                                >
                                  <AddLicenseIconButton
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                    disableTouchRipple={true}
                                    onClick={() => {
                                      const newMonths = Math.max(
                                        1,
                                        numberOfMonths - 1
                                      );
                                      setProjectsToLicense({
                                        ...projectsToLicense,
                                        [project.procoreServerId]: newMonths,
                                      });
                                    }}
                                  >
                                    <RemoveIcon style={{ fontSize: "14px" }} />
                                  </AddLicenseIconButton>
                                  <Typography
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {`${numberOfMonths} month(s)`}
                                  </Typography>
                                  <AddLicenseIconButton
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                    disableTouchRipple={true}
                                    onClick={() => {
                                      setProjectsToLicense({
                                        ...projectsToLicense,
                                        [project.procoreServerId]:
                                          numberOfMonths + 1,
                                      });
                                    }}
                                  >
                                    <AddIcon style={{ fontSize: "14px" }} />
                                  </AddLicenseIconButton>
                                </Box>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 500,
                                    color: Colors.darkerGray,
                                  }}
                                >
                                  =
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 500,
                                    color: Colors.darkerGray,
                                  }}
                                >{`$${getTotalCost(
                                  numberOfMonths
                                )}`}</Typography>
                              </Box>
                            </Box>
                          </TableRowCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <>
            <Typography
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: Colors.darkishGray,
                marginBottom: "16px",
              }}
            >
              Select the projects you want to add a license to.
            </Typography>
            <Box
              display="flex"
              flexDirection={"row"}
              alignContent="center"
              marginBottom={"10px"}
            >
              <Button
                disabled={loading || loadingBuyTrialProjectLicense}
                onClick={() => {
                  if (licensesToPurchaseCount > 0) {
                    setProjectsToLicense({});
                  } else {
                    setProjectsToLicense(
                      filteredItemData.reduce((acc, nextItem) => {
                        acc[nextItem.procoreServerId] = 1;

                        return acc;
                      }, {} as Record<number, number>)
                    );
                  }
                }}
                variant="outlined"
                disableElevation={true}
                size="medium"
                style={{
                  minHeight: "39px",
                  marginRight: "12px",
                }}
              >
                {licensesToPurchaseCount > 0 ? "Deselect All" : "Select All"}
              </Button>
              <Box sx={{
                position: 'relative',
                minHeight: '39px',
                maxHeight: 'unset',
                flex: '1 0',
                borderRadius: '6px',
                paddingRight: '2px',
                border: '1px solid #D5DFE6',
                backgroundColor: alpha('#FFFFFF', 0.15),
                '&:hover': {
                  backgroundColor: alpha('#FFFFFF', 0.25),
                },
                marginLeft: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: '12px'
              }}>
                <SearchIcon />
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  fullWidth={true}
                  placeholder="Search..."
                  autoFocus={true}
                  value={searchValue}
                  endAdornment={
                    (((searchValue || '').trim())) ? (
                      <SearchCloseIconButton
                          size={'small'}
                          onClick={() => {
                            setSearchValue('');
                          }}
                          style={{ visibility: ((searchValue || '').trim()) ? 'visible' : 'hidden' }}>
                        <SearchCloseIcon />
                      </SearchCloseIconButton>
                    ) : null
                  }
                  onChange={(evt) => {
                    setSearchValue(evt.target.value)
                  }}
                  inputProps={{ 'aria-label': 'search users' }}
                />
              </Box>
            </Box>
            <div
              style={{
                display: "flex",
                height: "425px",
                width: "100%",
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: "6px",
                borderColor: Colors.mediumLightGray,
              }}
            >
              <FixedSizeList
                height={425}
                width={"100%"}
                itemData={filteredItemData}
                itemCount={filteredItemData.length}
                itemSize={55}
              >
                {renderRow}
              </FixedSizeList>
            </div>
          </>
        )}
        {showCheckoutProjectLicense ? (
          <Box
            display={"flex"}
            flexDirection="row"
            alignItems={"center"}
            justifyContent="flex-end"
            paddingTop={"10px"}
          >
            <Button
              disabled={loadingBuyProjectLicenses}
              onClick={() => setShowCheckoutProjectLicense(false)}
              variant="outlined"
              disableElevation={true}
              size="medium"
              style={{
                minHeight: "39px",
                marginRight: "12px",
              }}
            >
              {"Cancel"}
            </Button>
            <Button
              size="medium"
              disabled={loadingBuyProjectLicenses}
              variant="contained"
              onClick={() => {
                buyProjectLicenses({
                  variables: {
                    accountId: account.id,
                    projectLicenses: Object.entries(projectsToLicense).map(
                      ([key, value]) => {
                        return {
                          procoreProjectServerId: Number.parseInt(key),
                          numberOfMonths: value,
                        } as BuyProjectLicenseAttributes;
                      }
                    ),
                    redirectUrl: isIframe
                      ? `${window.location.origin}/close`
                      : "",
                  },
                });
              }}
            >
              {loadingBuyProjectLicenses ? (
                <Box
                  display={"flex"}
                  flexDirection="row"
                  alignItems={"center"}
                  justifyContent="center"
                >
                  <CircularProgress size={12} style={{ margin: "5px" }} />
                  <Typography style={{ fontWeight: 700, fontSize: "12px" }}>
                    {`Checkout ($${totalCost})`}
                  </Typography>
                </Box>
              ) : (
                `Checkout ($${totalCost})`
              )}
            </Button>
          </Box>
        ) : (
          <Box
            display={"flex"}
            flexDirection="row"
            alignItems={"center"}
            justifyContent="flex-end"
            paddingTop={"10px"}
          >
            <Button
              disabled={loading || loadingBuyTrialProjectLicense}
              onClick={() => handleOnClose(false)}
              variant="outlined"
              disableElevation={true}
              size="medium"
              style={{
                minHeight: "39px",
                marginRight: "12px",
              }}
            >
              {"Cancel"}
            </Button>
            {
              account.freeTrialAvailable && (
                <Button
                  variant="outlined"
                  disableElevation={true}
                  size="small"
                  disabled={
                    loading ||
                    loadingBuyTrialProjectLicense ||
                    licensesToPurchaseCount <= 0 ||
                    licensesToPurchaseCount > 1
                  }
                  title="Start Free Trial"
                  style={{
                    minHeight: "39px",
                    marginRight: "12px",
                  }}
                  onClick={() => {
                    buyTrialProjectLicense({
                      variables: {
                        accountId: account.id,
                        procoreProjectServerId: Number.parseInt(
                          Object.entries(projectsToLicense).filter(
                            ([key, value]) => value > 0
                          )[0]?.[0]
                        ),
                      },
                    }).then(() => {
                      handleOnClose(true);
                    });
                  }}
                >
                  {loadingBuyTrialProjectLicense ? (
                    <Box
                      display={"flex"}
                      flexDirection="row"
                      alignItems={"center"}
                      justifyContent="center"
                    >
                      <CircularProgress size={12} style={{ margin: "5px" }} />
                      <Typography style={{ fontWeight: 700, fontSize: "12px" }}>
                        Start Free Trial
                      </Typography>
                    </Box>
                  ) : (
                    "Start Free Trial"
                  )}
                </Button>
              )
            }
            <Button
              size="medium"
              variant="contained"
              disabled={
                licensesToPurchaseCount === 0 ||
                loading ||
                loadingBuyTrialProjectLicense
              }
              onClick={() => {
                setShowCheckoutProjectLicense(true);
              }}
            >
              {buyLicensesText}
            </Button>
          </Box>
        )}
      </DialogContent>
    </FeedbackDialog>
  );
};

export default AddProjectsProjectLicenseModal;
