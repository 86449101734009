import React from 'react';
import { BodyCardHeaderBar } from '../shared/BodyCardHeaderBar';
import { Colors, StyledDataGridPremium, VertGrayDivider } from '../../styles';
import {
  ExtractAutomation,
  ProcoreProjectExtractAutomation,
  Project,
} from '../../types';
import { ModalFooter } from '../shared/ModalFooter';
import { Button, LoadingButton } from '../shared/Button';
import { BlueSwitch } from '../package/styles';
import { GridColDef } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import { InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useGetProcoreProjectExtractAutomations } from '../../graphql/queries/automation/GetProcoreProjectExtractAutomations';
import { useUpsertProcoreProjectExtractAutomation } from '../../graphql/mutations/automation/UpsertProcoreProjectExtractAutomation';
import { ConfirmationModal } from '../shared/ConfirmationModal';
import idx from 'idx';
import { useGetAccountProjects } from '../../graphql/queries/GetAccountProjects';
import StatusPill from '../shared/StatusPill';
import { Typography } from '../shared/Typography';

interface IConfigureProjectsExtractAutomationModalProps {
  accountId: string;
  automation: ExtractAutomation;
  close: (refetchAutomations: boolean) => void;
}

const DEFAULT_COL_DEF = {
  editable: false,
  resizable: false,
  sortable: false,
  groupable: false,
  disableColumnMenu: true,
  flex: 1,
};

declare module '@mui/x-data-grid' {
  interface LoadingOverlayPropsOverrides {
    title: string;
  }
}

export const ConfigureProjectsExtractAutomationModal = ({
  accountId,
  automation,
  close,
}: IConfigureProjectsExtractAutomationModalProps): JSX.Element => {
  const [
    procoreProjectExtractAutomationsMap,
    setProcoreProjectExtractAutomationsMap,
  ] = React.useState<Record<string, ProcoreProjectExtractAutomation>>({});

  const [filteredProjects, setFilteredProjects] = React.useState<Project[]>([]);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = React.useState(false);

  const dataGridColumnDefinitions: GridColDef[] = [
    {
      field: 'procoreCompanyName',
      type: 'string',
      headerName: 'Company Name',
      sortable: true,
      ...DEFAULT_COL_DEF,
    },
    {
      field: 'displayName',
      type: 'string',
      headerName: 'Name',
      sortable: true,
      ...DEFAULT_COL_DEF,
    },
    {
      field: 'stage',
      type: 'string',
      headerName: 'Project Stage',
      sortable: true,
      ...DEFAULT_COL_DEF,
    },
    {
      field: 'active',
      type: 'string',
      headerName: 'Project Status',
      sortable: true,
      ...DEFAULT_COL_DEF,
      valueFormatter: (value, row, column, apiRef) => {
        return value ? 'Active' : 'Inactive';
      }
    },
    {
      field: 'procoreServerId',
      headerName: 'Automation Status',
      ...DEFAULT_COL_DEF,
      sortable: true,
      type: 'custom',
      renderCell: (params) => {
        const result = procoreProjectExtractAutomationsMap[
          params.value
        ] || { automationType: 'manual', enabled: false }

        return (
          <Box display={'flex'}>
            <StatusPill status={result.enabled ? 'on' : 'off'}>
              <Typography typestyle="xs bold">{result.enabled ? 'On' : 'Off'}</Typography>
            </StatusPill>
          </Box>
        );
      }
    },
    {
      field: 'actions',
      headerName: '',
      ...DEFAULT_COL_DEF,
      flex: 0,
      width: 60,
      renderCell: (params) => {
        return (
          <Box display={'flex'} width={1} height={1} alignItems={'center'} justifyContent={'center'}>
            <BlueSwitch
              size="small"
              checked={(procoreProjectExtractAutomationsMap[params.row.procoreServerId] || { enabled: false }).enabled}
              onChange={(event) => {
                const item = procoreProjectExtractAutomationsMap[params.row.procoreServerId] || {};

                setProcoreProjectExtractAutomationsMap({
                  ...procoreProjectExtractAutomationsMap,
                  [params.row.procoreServerId]: {
                    ...item,
                    enabled: event.target.checked,
                    automationType: 'manual',
                    procoreCompanyServerId: params.row.procoreCompanyServerId,
                    procoreProjectServerId: params.row.procoreServerId,
                  },
                });
              }}
            />
          </Box>
        );
      },
    }
  ];

  const {loading, data } = useGetProcoreProjectExtractAutomations(accountId, automation.id.toString());
  const { loading: loadingGetAccountProjects, data: licensedAccountProjectsData } = useGetAccountProjects(accountId, true, true);

  const [upsertProcoreProjectExtractAutomation, { loading: loadingUpsert }] =
    useUpsertProcoreProjectExtractAutomation({
      accountId: accountId,
      extractAutomationId: automation.id.toString(),
      attributes: [],
    });

  const modalIsLoading = loading || loadingGetAccountProjects || loadingUpsert;

  React.useEffect(() => {
    if (!loadingGetAccountProjects && licensedAccountProjectsData) {
      setFilteredProjects(licensedAccountProjectsData.procoreProjects);
    }
  }, [loadingGetAccountProjects, licensedAccountProjectsData]);

  React.useEffect(() => {
    if (!loading && data) {
      setProcoreProjectExtractAutomationsMap(
        data.procoreProjectExtractAutomations.reduce((acc, curr) => {
          if (curr.enabled) {
            acc[curr.procoreProjectServerId] = curr;
          }
          return acc;
        }, {})
      );
    }
  }, [data]);

  const onSave = (promptConfirmation: boolean) => {
    if (promptConfirmation) {
      const dirty = data.procoreProjectExtractAutomations.some((procoreProjectExtractAutomation) => {
        if (procoreProjectExtractAutomation.enabled) {
          const state = procoreProjectExtractAutomationsMap[procoreProjectExtractAutomation.procoreProjectServerId];
          return !state.enabled;
        } else {
          return false;
        }
      })

      if (dirty) {
        setConfirmationModalIsOpen(true);
        return;
      }
    }

    const attributes = Object.keys(procoreProjectExtractAutomationsMap).map((procoreProjectServerId) => {
      const state = procoreProjectExtractAutomationsMap[procoreProjectServerId];

      return {
        procoreProjectServerId: procoreProjectServerId,
        automationType: state.automationType,
        enabled: state.enabled,
      };
    });

    upsertProcoreProjectExtractAutomation({
      variables: {
        accountId: accountId,
        extractAutomationId: automation.id.toString(),
        attributes: attributes,
      },
    }).then((data) => {
      close(true);
    });
  };

  return (
    <>
    <ConfirmationModal
      open={confirmationModalIsOpen}
      headerText="Disabled Project Automations"
      bodyText={`You have selected to disable an automation on some of your projects. Do you wish to continue anyway?`}
      submitText="Yes, Apply Changes"
      onSubmit={() => {
        setConfirmationModalIsOpen(false);
        onSave(false);
      }}
      submitLoading={loadingUpsert}
      onClose={() => setConfirmationModalIsOpen(false)}
    />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 1px',
          overflow: 'hidden',
        }}
      >
        <BodyCardHeaderBar title="Enabled Projects" disabled={modalIsLoading} onClose={() => close(false)}>
          <Box display="flex" alignItems="center" gap="20px" paddingRight="4px">
            <TextField
              size="small"
              disabled={modalIsLoading}
              placeholder="Search..."
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: '20px',
                },
              }}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search sx={{ fontSize: '20px', color: Colors.darkerGray }} />
                    </InputAdornment>
                  ),
                }
              }}
              onChange={(evt) => {
                setFilteredProjects(
                  idx(licensedAccountProjectsData, (data) => data.procoreProjects || []).filter((project) =>
                    project.displayName
                      .toLowerCase()
                      .includes(evt.target.value.toLowerCase())
                  )
                );
              }}
            />
            <VertGrayDivider orientation="vertical" sx={{ height: '24px' }} />
          </Box>
        </BodyCardHeaderBar>
        <StyledDataGridPremium
          columns={dataGridColumnDefinitions}
          slotProps={{
            loadingOverlay: {
              title: 'Loading projects...',
            },
          }}
          columnHeaderHeight={40}
          loading={loading || loadingGetAccountProjects}
          rows={filteredProjects}
          hideFooter={true}
          disableRowSelectionOnClick
          getRowId={(row: Project) => row.procoreServerId}
          sx={{
            marginX: '28px',
          }}
        />
        <ModalFooter>
          <Button variant="outlined" buttonborderstyle="pill" disabled={modalIsLoading} onClick={() => close(false)}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            buttonborderstyle="pill"
            disabled={modalIsLoading}
            onClick={() => onSave(true)}
            loading={loadingUpsert}
          >
            Save
          </LoadingButton>
        </ModalFooter>
      </Box>
    </>
  );
};
