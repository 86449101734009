import React from 'react';
import ExtractsProDialog from './ExtractsProDialog';
import { Company } from '../../types';
import Loading from './Loading';
import { Box, List, ListItemText } from '@mui/material';
import { ManageCompaniesListIcon, ManageCompaniesListItem } from '../package/styles';
import { Colors, ExtractsCheckbox } from '../../styles';
import { Typography } from './Typography';
import InfoIcon from '@mui/icons-material/Info';

const initialCompanyState = (companies: Company[]): Record<number, boolean> => {
  return companies.reduce((acc, current) => {
    acc[current.procoreServerId] = current.enabled;
    return acc;
  }, {})
}
export const ImportProjectsModal = (props: {
  open: boolean;
  procoreCompanies: Company[];
  loadingCompanies: boolean;
  onClose: () => void;
  onImportProjects: (companiesToEnable: Record<number, boolean>) => void;
}) => {
  const [companiesToEnable, setCompaniesToEnable] = React.useState<Record<number, boolean>>(initialCompanyState(props.procoreCompanies));

  React.useEffect(() => {
    if (props.procoreCompanies.length > 0) {
      setCompaniesToEnable({
        ...companiesToEnable,
        ...initialCompanyState(props.procoreCompanies)
      }
      )
    }

  }, [props.procoreCompanies]);

  const sortedProcoreCompanies = props.procoreCompanies.sort((a, b) => {
    const aName = a.name;
    const bName = b.name;
    return aName.localeCompare(bName, undefined, { numeric: true })
  });

  return (
    <ExtractsProDialog
      title='Load Projects'
      maxWidth="md"
      open={props.open}
      closeButton={{
        onClick: props.onClose,
        disabled: props.loadingCompanies
      }}
      secondaryButton={{
        text: 'Cancel',
        onClick: props.onClose,
        disabled: props.loadingCompanies
      }}
      primaryButton={{
        text: 'Load Projects',
        onClick: () => {
          props.onImportProjects(companiesToEnable);
        },
        disabled: props.loadingCompanies || Object.keys(companiesToEnable).filter((key) => companiesToEnable[key] || false).length === 0,
        loading: props.loadingCompanies,
      }}
    >
      <Box display={"flex"} flexDirection={"row"} flex={0} alignItems={"center"} style={{
        borderRadius: '8px',
        padding: '16px 20px',
        border: `1px solid ${Colors.lightishGray}`,
        boxShadow: '0px 1px 10px 0px rgba(26, 32, 36, 0.06), 0px 2px 4px 1px rgba(26, 32, 36, 0.04)'
      }}>
        <InfoIcon sx={{ color: Colors.newAccentBlue }} />
        <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} marginLeft={"10px"} gap={'4px'}>
          <Typography typestyle="m bold">Extracts Pro requires loading your Procore projects.</Typography>
          <Typography typestyle="s">{`Select which companies' projects would you like to extract data from then click "Load Projects".`}</Typography>
        </Box>
      </Box>
    { props.loadingCompanies ? (<Loading loadingLabel="Loading companies..." />) : (
        <List sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          border: `1px solid ${Colors.mediumLightGray}`,
          borderRadius: '4px',
          overflowY: 'auto',
          padding: 0,
          margin: 0,
        }}>
          {sortedProcoreCompanies.map((company) => {
            const checked = companiesToEnable[company.procoreServerId] || false;
            return (
              <ManageCompaniesListItem
                key={`company-list-item-${company.procoreServerId}`}
                onClick={() => {
                  setCompaniesToEnable({
                    ...companiesToEnable,
                    [company.procoreServerId]: !checked
                  });
                }}
              >
                <ManageCompaniesListIcon>
                  <ExtractsCheckbox
                    edge="start"
                    checked={checked}
                    disableRipple
                  />
                </ManageCompaniesListIcon>
                <ListItemText key={`company-${company.procoreServerId}`}>
                  {company.name}
                </ListItemText>
              </ManageCompaniesListItem>
            );
          })}
        </List>
      )}
    </ExtractsProDialog>
  );
};