import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';

export interface CreateProcoreProjectFile {
  success: boolean;
  procoreFileServerId?: number;
  filename?: string;
  createdAt?: string;
  createData?: Record<string, any>;
}

export interface CreateProcoreProjectFileData {
  createProcoreProjectFile: CreateProcoreProjectFile
}

export interface CreateProcoreProjectFileVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  filename: string;
  uploadUuid: string;
}

export const useCreateProcoreProjectFile = createMutationHook<CreateProcoreProjectFileData, CreateProcoreProjectFileVariables>(gql`
mutation CreateProcoreProjectFile($accountId: ID!, $procoreProjectServerId: ID!, $closeoutLogId: ID!, $filename: String!, $uploadUuid: String!) {
  createProcoreProjectFile(input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, filename: $filename, uploadUuid: $uploadUuid }) {
    success
    procoreFileServerId
    filename
    createdAt
    createData
  }
}
`);