import React from 'react';
import Box from '@mui/material/Box';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { Colors } from '../../styles';
import { Typography } from './Typography';
import { CheckBoxOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import Link from '@mui/material/Link';
import { Divider, Stack, styled } from '@mui/material';
import { Button } from './Button';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

interface IFileRequestLogBulkEditSelectionPopupProps {
  open: boolean;
  onDeselect: () => void;
  onDelete: () => void;
  onDownloadCsv: () => void;
  onExitSelectMode: () => void;
  rowSelectionModel: GridRowSelectionModel;
}

const BlueButton = styled(Button)(() => ({
  '&.MuiButton-outlined': {
    border: `1px solid ${Colors.blue3}`,
    backgroundColor: Colors.white,
    '&:hover': {
      border: `1px solid ${Colors.mediumDarkGray}`,
      backgroundColor: Colors.white,
    },
    '&:focus': {
      boxShadow: ` 0px 0px 0px 1px ${Colors.white}, 0px 0px 0px 4px ${Colors.lightAccentBlue}`,
    },
    '&:disabled': {
      color: Colors.reallyBlueGray,
      border: `1px solid ${Colors.mediumLightGray}`,
    },
  }
}));

export const FileRequestLogBulkEditSelectionPopup = ({
  open,
  onDeselect,
  onDelete,
  onDownloadCsv,
  onExitSelectMode,
  rowSelectionModel,
}: IFileRequestLogBulkEditSelectionPopupProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        bottom: open ? '44px' : '24px',
        transition: 'all 0.2s ease-out',
        opacity: open ? 1 : 0,
        left: 0,
        right: 0,
        width: 'fit-content',
        margin: '0 auto',
        zIndex: '20',
      }}
    >
      <Box
        sx={{
          display: open ? 'flex' : 'none',
          alignItems: 'center',
          borderRadius: '8px',
          backgroundColor: Colors.blue1,
          boxShadow: '0px 4px 4px -1px rgba(36, 105, 158, 0.10), 0px 6px 16px 1px rgba(36, 105, 158, 0.17)',
          padding: 0,
        }}
      >
        <Box height={1} width={'8px'} sx={{
          backgroundColor: Colors.newAccentBlue,
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
        }}></Box>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} padding={'12px 16px 12px 20px'} gap={'20px'}>
          {
            rowSelectionModel.length > 0 ? (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                    <CheckBoxOutlined sx={{ fontSize: '20px' }} />
                    <Typography typestyle="s bold">
                      {`${rowSelectionModel.length} items selected`}
                    </Typography>
                  </Box>
                  <Link
                    underline="hover"
                    onClick={onDeselect}
                    sx={{ cursor: 'pointer', color: Colors.newAccentBlue }}
                  >
                    <Typography typestyle="xs">Deselect All</Typography>
                  </Link>
                </Box>
                <Divider
                  sx={{
                    height: '20px',
                    borderWidth: '1px',
                    borderColor: Colors.blue3,
                  }}
                />
                <Stack direction="row" gap="8px">
                  <BlueButton
                    onClick={onDelete}
                    variant="outlined"
                    buttonborderstyle="pill"
                    size='small'
                    sx={{
                      backgroundColor: Colors.white,
                    }}
                    startIcon={<DeleteOutlineOutlined />}
                  >
                    Delete
                  </BlueButton>
                  <BlueButton
                    onClick={onDownloadCsv}
                    variant="outlined"
                    buttonborderstyle="pill"
                    size='small'
                    sx={{
                      backgroundColor: Colors.white,
                    }}
                    startIcon={<DownloadOutlinedIcon />}
                  >
                    CSV
                  </BlueButton>
                </Stack>
              </>
            ) : (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                    <CheckBoxOutlined sx={{ fontSize: '20px' }} />
                    <Typography typestyle="s bold">
                      Select items to perform a bulk action
                  </Typography>
                  </Box>
                </Box>
                <Divider
                  sx={{
                    height: '20px',
                    borderWidth: '1px',
                    borderColor: Colors.blue3,
                  }}
                />
                <BlueButton buttonborderstyle="pill" size='small' sx={{
                  backgroundColor: Colors.white,
                  borderColor: Colors.blue3
                }} variant="outlined" fullWidth={false} onClick={onExitSelectMode}>
                  Exit
                </BlueButton>
              </>
            )
          }
        </Box>
      </Box>
    </Box>
  );
};
