import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";

export interface TemplateCloseoutLogExportOptionUpdateData {
  updateTemplateCloseoutLogExportOption: {
    success: boolean;
  }
}

interface TemplateCloseoutLogExportOptionUpdateAttributes {
  includeTableOfContents: boolean;
  includeProjectPhotoOnCoverSheet: boolean;
  includeCustomCoverSheet: boolean;
  groupings: string[];
  toolExtractOptions: Record<any, any>;
}

export interface TemplateCloseoutLogExportOptionUpdateVariables {
  accountId: string;
  templateCloseoutLogId: number;
  attributes: TemplateCloseoutLogExportOptionUpdateAttributes;
}

export const useUpdateTemplateCloseoutLogExportOption = createMutationHook<
  TemplateCloseoutLogExportOptionUpdateData,
  TemplateCloseoutLogExportOptionUpdateVariables
>(gql`
  mutation UpdateTemplateCloseoutLogExportOption(
    $accountId: ID!,
    $templateCloseoutLogId: ID!,
    $attributes: TemplateCloseoutLogExportOptionUpdateAttributes!
  ) {
    updateTemplateCloseoutLogExportOption(
      input: { accountId: $accountId, templateCloseoutLogId: $templateCloseoutLogId, attributes: $attributes }
    ) {
      success
    }
  }
`);
