import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";

import { Box, DialogContent } from "@mui/material";
import { Typography } from "./shared/Typography";
import { Colors } from "../styles";
import { Button } from "./shared/Button";
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import RefreshIcon from '@mui/icons-material/Refresh';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { useUpdateProcoreIntegration } from "../graphql/mutations/UpdateProcoreIntegration";

interface IExtractsProIntegrationPermissionModalProps {
  accountId: string;
  procoreProjectServerIds: number[];
  openModal: boolean;
  closeModal: () => void;
}

const ExtractsProIntegrationPermissionModal = (props: IExtractsProIntegrationPermissionModalProps): JSX.Element => {
  const [insufficientAccess, setInsufficientAccess] = useState(false);

  console.log('props', props)
  const [updateProcoreIntegration, { loading }] = useUpdateProcoreIntegration({
    accountId: props.accountId,
    procoreProjectServerIds: props.procoreProjectServerIds
  });

  return (
    <Dialog
      disableScrollLock
      open={props.openModal}
      PaperProps={{
        sx: {
          maxHeight: "400px",
          maxWidth: "450px",
          padding: 0,
        },
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '400px',
          padding: 0
        }}
      >
        {
          insufficientAccess && (
            <Box display={"flex"} flexDirection={"column"} width={1} height={1} padding={"40px"} gap="20px">
              <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} width={1} height={1} gap="20px">
                <WarningAmberOutlinedIcon fontSize="large" htmlColor={Colors.red} />

                <Typography typestyle="xl">Insufficient Access</Typography>

                <Typography typestyle="s" sx={{ color: Colors.darkishGray }}>
                  {'Unfortunately, Procore requires you to be a project admin to grant the DMSA permission to this project.'}
                </Typography>

                <Typography typestyle="s" sx={{ color: Colors.darkishGray }}>
                  {'Have your Procore project admin complete this action or adjust your project permissions.'}
                </Typography>
              </Box>

              <Box display="flex" flexDirection={"row"} justifyContent={"center"} alignItems={"center"} gap="8px">
                <Button startIcon={<RefreshIcon fontSize="small" />} variant="outlined" buttonborderstyle="pill">Retry</Button>
                <Button variant="contained" buttonborderstyle="pill" onClick={() => props.closeModal()}>Exit</Button>
              </Box>
            </Box>
          )
        }
        {
          !insufficientAccess && (
            <Box display={"flex"} flexDirection={"column"} width={1} height={1} padding={"70px 20px"} gap="20px" justifyContent={"space-evenly"}>
              <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"} alignItems={"center"} width={1} height={1} gap="20px" padding="0px 50px">
                <Typography typestyle="xl">Permission Needed</Typography>

                <Typography typestyle="s" sx={{ color: Colors.darkishGray }}>
                  {'Extracts Pro uses a Developer Managed Service Account to make sure your closeout logs always reference the most current project information. Procore requires an admin to explicitly enable this for each project using Closeout.'}
                </Typography>

                <Typography typestyle="s" sx={{ color: Colors.darkishGray }}>
                  {'By allowing access below, you agree to our terms and conditions and will grant our DMSA access to the projects you’re licensing.'}
                </Typography>
              </Box>

              <Box display="flex" flexDirection={"row"} justifyContent={"center"} alignItems={"center"} gap="8px">
                <Button
                  disabled={loading}
                  buttonborderstyle="pill"
                  variant="outlined"
                  startIcon={<BlockIcon fontSize="small" />}
                  onClick={() => props.closeModal()}>
                    {'Skip for Now'}
                </Button>
                <Button
                  disabled={loading}
                  buttonborderstyle="pill"
                  onClick={() => {
                    updateProcoreIntegration().then(({ data }) => {
                      if (data.updateProcoreIntegration.success) {
                        props.closeModal();
                      } else {
                        setInsufficientAccess(true);
                      }
                    })
                  }}
                  startIcon={<CheckIcon fontSize="small" />}
                  sx={{
                    backgroundColor: Colors.green,
                    color: Colors.white,
                    boxShadow: 'none',
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: Colors.green,
                      opacity: 0.8,
                    },
                    '&:focus': {
                      boxShadow: ` 0px 0px 0px 1px ${Colors.white}, 0px 0px 0px 4px ${Colors.lightAccentBlue}`,
                    },
                  }}>
                  {'Allow Access'}
                </Button>
              </Box>
            </Box>
          )
        }
      </DialogContent>
    </Dialog>
  );
};

export default ExtractsProIntegrationPermissionModal;
