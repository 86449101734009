import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import React, {MouseEvent} from "react";
import PackageList from "./PackageList";
import { Colors } from "../../styles";
import useNavigationHook from "../../hooks/UseNavigationHook";
import { AccountProjectContext } from "../../contexts/AccountProjectContextProvider";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { PackageHistoryContext } from "../../contexts/PackageContextProvider";
import { useParams } from "react-router";
import { getDaysRemaining } from "../../utils/utils";
import FreeTrialBanner from "../shared/FreeTrialBanner";
import { IconButton, Box, Popover, Snackbar, Alert } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button } from "../shared/Button";
import { BodyCard } from "../shared/BodyCard";
import { Typography } from "../shared/Typography";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import {Tab} from "@mui/material";
import { CloseoutLogList } from "./CloseoutLogList";
import { useCreateCloseoutLog } from "../../graphql/mutations/closeout/CreateCloseoutLog";
import BoltIcon from '@mui/icons-material/Bolt';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { SnazzyMenuItem } from "../shared/SnazzyMenuItem";
import CloseoutLogCreateFromModal from "../package/CloseoutLogCreateFromModal";
import { CloseoutLogContext } from "../../contexts/CloseoutLogContextProvider";
import idx from "idx";
import { TemplateCloseoutLog } from "../../types";

interface IPackageActionBarProps {
  setTabValue: (tabValue: string) => void;
  openCreateFromCloseoutLogModal: () => void;
}

const PackageActionBar = (props: IPackageActionBarProps): JSX.Element => {
  const { accountId, procoreProjectId } =
    useParams<{ accountId: string; procoreProjectId: string }>();
  const { state } = React.useContext(AccountProjectContext);
  const { state: packageHistoryState, fetchPackages } = React.useContext(
    PackageHistoryContext
  );
  const navigateTo = useNavigationHook();

  function createNewPackage() {
    navigateTo(`/teams/${accountId}/projects/${procoreProjectId}/packages/new`);
  }

  const [createMenuAnchorEl, setCreateMenuAnchorEl] = React.useState<HTMLButtonElement>(null);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      minHeight="80px"
      sx={{ borderBottom: `1px solid ${Colors.mediumLightGray}` }}
    >
      {!packageHistoryState.loading && (
        <>
          <Popover
            open={Boolean(createMenuAnchorEl)}
            onClose={() => setCreateMenuAnchorEl(null)}
            anchorEl={createMenuAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            slotProps={{
              paper: {
                sx: {
                  padding: '12px 0px',
                }
              }
            }}
          >
            <SnazzyMenuItem icon={<BoltIcon fontSize='small'/>} title='Instant Extract' subtitle='Extract anything you need, the moment you need it' onClick={createNewPackage}/>
            <SnazzyMenuItem
                icon={<ListAltIcon fontSize='small'/>}
                title='Closeout Log'
                subtitle='Track and extract items required for project closeout'
                onClick={() => {
                  props.openCreateFromCloseoutLogModal();
                  setCreateMenuAnchorEl(null);
                }}/>
          </Popover>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            width={1}
            gap="16px"
          >
            {!state.closeoutLogEnabled && (
              <Typography typestyle="xl">{state.projectName}</Typography>
            )}
            {!state.closeoutLogEnabled && (
              <ChevronRightIcon htmlColor="#A0B0BA" />
            )}
            <Typography typestyle="xl">Extracts</Typography>
            {state.closeoutLogEnabled && (
              <TabList
                onChange={(evt: React.SyntheticEvent, value: string) =>
                  props.setTabValue(value)
                }
              >
                <Tab label="Closeout Logs" value="closeout_logs" />
                <Tab label="Completed Extracts" value="history" />
              </TabList>
            )}
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            style={{ gap: '12px' }}
          >
            <IconButton
              onClick={() => {
                fetchPackages();
              }}
            >
              <RefreshIcon />
            </IconButton>
            {!state.closeoutLogEnabled && (
              <Button
                size="medium"
                variant="contained"
                buttonborderstyle="pill"
                startIcon={<AddCircleOutlineOutlined fontSize="small" />}
                onClick={createNewPackage}
              >
                New
              </Button>
            )}
            {state.closeoutLogEnabled && (
              <Button
                size="medium"
                variant="contained"
                buttonborderstyle="pill"
                endIcon={<ArrowDropDownIcon fontSize="small" />}
                onClick={(evt: MouseEvent<HTMLButtonElement>) =>
                  setCreateMenuAnchorEl(evt.currentTarget)
                }
              >
                New
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

const PackageHistory = (): JSX.Element => {
  const { state } = React.useContext(AccountProjectContext);
  const [isCloseoutCreateModalOpen, setIsCloseoutCreateModalOpen] = React.useState(false);
  const { state: packageHistoryState, dispatch } = React.useContext(PackageHistoryContext);
  const { dispatch: closeoutLogContextDispatch } = React.useContext(CloseoutLogContext);
  const navigateTo = useNavigationHook();

  const [createCloseoutLog, { loading: createCloseoutLogLoading }] = useCreateCloseoutLog({
    closeoutLogAttributes: {
      accountId: state.accountId,
      name: 'Closeout Log',
      procoreProjectServerId: state.procoreProjectServerId,
      templateCloseoutLogId: null
    },
  });

  const createNewCloseoutLog = (templateCloseoutLogId) => {
    createCloseoutLog({
      variables: {
        closeoutLogAttributes: {
          accountId: state.accountId,
          name: 'Closeout Log',
          procoreProjectServerId: state.procoreProjectServerId,
          templateCloseoutLogId: templateCloseoutLogId
        },
      }
    }).then(({ data }) => {
      if (data.createCloseoutLog.success) {
        setIsCloseoutCreateModalOpen(false);

        const closeoutLog = data.createCloseoutLog.closeoutLog;
        dispatch({
          type: 'APPEND_CLOSEOUT_PACKAGE',
          value: closeoutLog,
        });

        closeoutLogContextDispatch({
          type: 'SET_CLOSEOUT_LOG',
          value: closeoutLog,
        });

        navigateTo(
          `/teams/${state.accountId}/projects/${state.procoreProjectServerId}/closeout_logs/${closeoutLog.id}`
        );
      }
    });
  };

  React.useEffect(() => {
    if (state.closeoutLogEnabled && !packageHistoryState.closeoutLogEnabled) {
      dispatch({
        type: 'SET_CLOSEOUT_LOG_ENABLED',
        value: true
      });
    }
  }, [state.closeoutLogEnabled]);

  const isTrial = state.licenseType === 'trial';

  const daysRemaining = () => {
    return getDaysRemaining(state.licenseEndDate);
  };

  const handleSuccessToastClose = () => {
    dispatch({type: 'SET_DISPLAY_SUCCESS_TOAST', value: {isOpen: false, message: ''}})
  }

  const templateCloseoutLogs = (idx(packageHistoryState.data, (data) => data.templateCloseoutLogs) || []) as TemplateCloseoutLog[];

  return (
    <>
      <Snackbar
        open={packageHistoryState.displaySuccessToast.isOpen}
        onClose={handleSuccessToastClose}
        autoHideDuration={6000}
      >
        <Alert
          severity="success"
          elevation={6}
          onClose={handleSuccessToastClose}
        >
          {packageHistoryState.displaySuccessToast.message}
        </Alert>
      </Snackbar>
      <Box display="flex" flexDirection="column" height={1} width={1}>
        <TabContext value={packageHistoryState.tabValue}>
          {isTrial && <FreeTrialBanner daysRemaining={daysRemaining()} />}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            height={1}
            padding="16px 24px 0px 24px"
          >
            <BodyCard sx={{ maxWidth: '1100px', padding: '0px 28px' }}>
              <Box display="flex" flexDirection="column" height={1} width={1}>
                <PackageActionBar setTabValue={(tabValue) => {
                  dispatch({
                    type: 'SET_PACKAGES_TAB_VALUE',
                    value: tabValue
                  });
                }} openCreateFromCloseoutLogModal={() => {
                  setIsCloseoutCreateModalOpen(true);
                }} />
                <TabPanel
                  value="history"
                  sx={{
                    height: '100%',
                    padding: '0px',
                  }}
                >
                  <PackageList />
                </TabPanel>
                <TabPanel
                  value="closeout_logs"
                  sx={{ height: '100%', padding: '0px' }}
                >
                  <CloseoutLogList
                    accountId={state.accountId}
                    procoreProjectServerId={state.procoreProjectServerId} />
                </TabPanel>
              </Box>
            </BodyCard>
          </Box>
        </TabContext>
      </Box>

      <CloseoutLogCreateFromModal
        templateCloseoutLogs={templateCloseoutLogs}
        handleOnClose={() => {
          setIsCloseoutCreateModalOpen(false);
        }}
        disabled={createCloseoutLogLoading}
        createCloseoutLog={(templateCloseoutLogId) => {
          createNewCloseoutLog(templateCloseoutLogId);
        }}
        open={isCloseoutCreateModalOpen} />
    </>
  );
};

export default PackageHistory;
