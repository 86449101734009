import Box from '@mui/material/Box';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { useContext } from 'react';
import { Typography } from './shared/Typography';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { Colors } from '../styles';
import { CloseoutLogContext } from '../contexts/CloseoutLogContextProvider';
import { LogFileRequest } from '../types';
import { Chip, CircularProgress, Divider, Link } from '@mui/material';
import { Button, LoadingButton } from './shared/Button';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import { useCreateProcoreProjectUpload } from '../graphql/mutations/CreateProcoreProjectUpload';
import { v4 as uuidv4 } from 'uuid';
import { CreateProcoreProjectFile, useCreateProcoreProjectFile } from '../graphql/mutations/CreateProcoreProjectFile';

interface IFileRequestSubmitManuallyModal {
  accountId: string;
  procoreProjectServerId: number;
  loading: boolean;
  closeoutLogId: number;
  logFileRequest: LogFileRequest;
  onClose: () => void;
  onSubmitClick: (logFileRequestId: number, attachments: Record<string, any>[]) => void;
}

const CONCURRENT_UPLOADS = 3;

type FileUploadStatus =
  | 'uploading'
  | 'failed' // Either uploading to cloud storage or creating file failed
  | 'queued' // Waiting to be uploaded to cloud storage
  | 'complete' // File has been uploaded to cloud storage and created in Procore
  | 'canceled';

export type FileRecord = {
  id: string;
  file: File;
  logFileRequestId: number;
  progress: number;
  status: FileUploadStatus;
  result?: CreateProcoreProjectFile;
}

export const FileRequestSubmitManuallyModal = ({
  accountId,
  procoreProjectServerId,
  closeoutLogId,
  logFileRequest,
  loading,
  onClose,
  onSubmitClick,
  ...rest
}: IFileRequestSubmitManuallyModal & DialogProps): JSX.Element => {
  const { state, dispatch } = useContext(CloseoutLogContext);
  const hiddenFileInputRef = React.useRef(null);
  const [files, setFiles] = React.useState<Record<string, FileRecord>>({});

  const containerRef = React.useRef(null);

  const [{ isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop: (data: DataTransfer) => {
      setFiles({
        ...files,
        ...Array.from(data.files || []).reduce((acc, file) => {
          const id = uuidv4();
          acc[id] = {
            id: id,
            file: file,
            logFileRequestId: logFileRequest.id,
            progress: 0,
            status: 'queued'
          }

          return acc;
        }, {})
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [createProcoreProjectUpload] = useCreateProcoreProjectUpload({
    accountId: accountId,
    procoreProjectServerId: procoreProjectServerId,
    filename: ''
  });

  const [createProcoreProjectFile] = useCreateProcoreProjectFile({
    accountId: accountId,
    procoreProjectServerId: procoreProjectServerId,
    closeoutLogId: closeoutLogId,
    filename: '',
    uploadUuid: ''
  })

  const uploadFileToCloudStorageAndCreateFile = (fileRecord: FileRecord, procoreUploadPayload: Record<string, any>): Promise<{ success: boolean, result: CreateProcoreProjectFile }> => {
    return new Promise((resolve, reject) => {
        // Setup XMLHttpRequest
        const xhr = new XMLHttpRequest();

        xhr.open("POST", procoreUploadPayload['url'], true);

        xhr.upload.addEventListener('progress', function(event) {
          if (event.lengthComputable) {
            const percentComplete = (event.loaded / event.total) * 100;

            setFiles((files) => {
              return {
                ...files,
                [fileRecord.id]: {
                  ...fileRecord,
                  status: 'uploading',
                  progress: percentComplete
                }
              }
            });
          }
        });

        // Handle successful upload
        xhr.addEventListener('load', async () => {
          if (xhr.status < 200 || xhr.status >= 300) {
            reject('Failed to upload file to cloud storage');
          } else {
            try {
              const createFileResponse = await createProcoreProjectFile({
                variables: {
                  accountId: accountId,
                  procoreProjectServerId: procoreProjectServerId,
                  closeoutLogId: closeoutLogId,
                  filename: fileRecord.file.name,
                  uploadUuid: procoreUploadPayload['uuid']
                }
              })

              if (createFileResponse.data.createProcoreProjectFile.success) {
                resolve({ success: createFileResponse.data.createProcoreProjectFile.success, result: createFileResponse.data.createProcoreProjectFile });
              } else {
                reject('Failed to create Procore Project File');
              }
            } catch (error) {
              console.log('error', error);
              reject('Failed to create Procore Project File');
            }
          }
        });

        // Handle error
        xhr.addEventListener('error', function (error) {
          console.log('error', error);
          reject('Failed to upload file to cloud storage');
        });

        // Build the FormData
        const formData = new FormData();

        const directUploadFields = procoreUploadPayload['fields'] || {};

        Object.keys(directUploadFields).forEach((key) => {
          formData.append(key, directUploadFields[key]);
        });

        formData.append("file", fileRecord.file);

        // Send the request
        xhr.send(formData);
    });
  }

  const uploadFile = async (fileRecord: FileRecord): Promise<void> => {
    try {
      const response = await createProcoreProjectUpload({
        variables: {
          accountId: accountId,
          procoreProjectServerId: procoreProjectServerId,
          filename: fileRecord.file.name
        }
      });

      if (response.data.createProcoreProjectUpload.success) {
        const { result } = await uploadFileToCloudStorageAndCreateFile(
          fileRecord,
          response.data.createProcoreProjectUpload.procoreUploadPayload
        );

        setFiles((files) => {
          return {
            ...files,
            [fileRecord.id]: {
              ...fileRecord,
              result: result,
              status: 'complete'
            }
          }
        });
      }
    } catch (error) {
      console.log('error', error);

      setFiles((files) => {
        return {
          ...files,
          [fileRecord.id]: {
            ...fileRecord,
            status: 'failed'
          }
        }
      });
    }
  }

  React.useEffect(() => {
    const fileRecords = Object.values(files);

    if (Object.keys(files).length > 0) {
      if (fileRecords.every((fileRecord) => fileRecord.status === 'complete' || fileRecord.status === 'canceled')) {
        console.log('do nothing')
      } else {
        const uploadingRecords = fileRecords.filter((fileRecords) => fileRecords.status === 'uploading');
        const count = uploadingRecords.length < CONCURRENT_UPLOADS ? CONCURRENT_UPLOADS - uploadingRecords.length : 0;
        const queuedRecords = fileRecords.filter((fileRecord) => fileRecord.status === 'queued').slice(0, count);

        if (queuedRecords.length > 0) {
          setFiles((files) => {
            return {
              ...files,
              ...queuedRecords.reduce((acc, nextItem) => {
                acc[nextItem.id] = {
                  ...nextItem,
                  status: 'uploading'
                }

                return acc;
              }, {})
            }
          });

          queuedRecords.map((fileRecord) => {
            uploadFile({ ...fileRecord, status: 'uploading' });
          });
        }
      }
    }
  }, [files]);

  const handleOnClose = () => {
    onClose();
  }

  const fileList = Object.values(files);

  const disabled = Object.values(files).length === 0 || Object.values(files).some((fileRecord) => fileRecord.status !== 'complete');

  return (
    <Dialog
      fullWidth
      ref={containerRef}
      maxWidth="xs"
      disableScrollLock
      {...rest}
      PaperProps={{
        sx: {
          maxWidth: '790px',
          maxHeight: '740px',
        },
      }}
    >
      <input type="file"
        multiple={true}
        ref={hiddenFileInputRef}
        onChange={(event) => {
          setFiles({
            ...files,
            ...Array.from(event.target.files || []).reduce((acc, file) => {
              const id = uuidv4();
              acc[id] = {
                id: id,
                file: file,
                logFileRequestId: logFileRequest.id,
                progress: 0,
                status: 'queued'
              }

              return acc;
            }, {})
          });
          event.target.value = "";
        }}
        style={{display:'none'}}
      />
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          padding: '24px 28px 32px 28px',
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" borderBottom={`1px solid ${Colors.lightishGray}`} paddingBottom={'16px'}>
          <Typography typestyle="xl">Submit Files</Typography>

          <Box display={"flex"} flexDirection={"row"} gap={'20px'}>
            <Divider orientation="vertical" flexItem />
            <IconButton disabled={loading} onClick={() => handleOnClose()}>
              <Close sx={{ fontSize: '18px' }} />
            </IconButton>
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
          <Box
            ref={drop}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '8px',
              height: fileList.length === 0 ? '200px' : '150px',
              borderColor: isOver ? Colors.newAccentBlue : Colors.reallyBlueGray,
              borderStyle: 'dashed',
              borderWidth: isOver ? '1.5px' : '1px',
              backgroundColor: isOver ? Colors.blue0 : Colors.white,
              transition:
                'borderColor 0.2s, borderWidth 0.2s, backgroundColor 0.3s',
              transitionTimingFunction: 'ease-in-out',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '6px',
                alignItems: 'center',
              }}
            >
              <Typography
                typestyle="ml"
                color={isOver ? Colors.newAccentBlue : Colors.darkerGray}
              >
                {isOver
                  ? 'Drop files to start uploading'
                  : 'Drag & drop files here'}
              </Typography>
              <Link
                underline="hover"
                color={isOver ? Colors.mediumDarkGray : Colors.newAccentBlue}
                onClick={() => {
                  hiddenFileInputRef.current.click();
                }}
                sx={{ cursor: 'pointer' }}
              >
                <Typography typestyle="xs">
                  or click here to browse for a file
                </Typography>
              </Link>
            </Box>
          </Box>
          <Box display={'flex'} flexDirection={'row'} gap={'8px'} flexWrap={'wrap'}>
            {
              fileList.map((fileRecord, index) => {
                return (
                  <Chip
                    avatar={(fileRecord.status === 'queued' || fileRecord.status === 'uploading') && <CircularProgress sx={{ color: Colors.blue5 }} size={16} variant="indeterminate" />}
                    key={`file-${index}`}
                    sx={{
                      backgroundColor: Colors.blue1,
                      color: Colors.newAccentBlue,
                    }}
                    label={fileRecord.file.name}
                    onDelete={fileRecord.status === 'complete'  ? () => {
                      setFiles((files) => {
                        const newFiles = { ...files };
                        delete newFiles[fileRecord.id];
                        return newFiles;
                      });
                    } : undefined}
                  >
                  </Chip>
                );
              })
            }
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            height: '100%',
          }}
        >
          <Box display={'flex'} width={1} justifyContent={'flex-end'} alignContent={'center'} gap={'12px'}>
            <Button
              disabled={loading}
              variant="outlined"
              buttonborderstyle="pill"
              size="medium"
              onClick={() => handleOnClose()}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              onClick={() => {
                onSubmitClick(
                  logFileRequest.id,
                  Object.values(files).map((fileRecord) => {
                    return {
                      'procore_file_server_id': fileRecord.result?.procoreFileServerId,
                      'filename': fileRecord.result?.filename,
                      'procore_created_at': fileRecord.result?.createdAt,
                      'created_at': fileRecord.result?.createdAt,
                      'create_data': fileRecord.result?.createData
                    }
                  })
                )
              }}
              loading={loading}
              disabled={disabled || loading}
              buttonborderstyle="pill"
              size="medium"
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
