import Box from '@mui/material/Box';
import React, { useContext } from 'react';
import { Colors, FieldContainer } from '../styles';
import { CloseoutLogContext } from '../contexts/CloseoutLogContextProvider';
import { styled } from '@mui/material/styles';
import { Autocomplete, TextField } from '@mui/material';
import { Typography } from './shared/Typography';
import { LogFileRequest, LogFileRequestAssignee } from '../types';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { formatISO, parse } from 'date-fns';
import { ModalFooter } from './shared/ModalFooter';
import { Button } from './shared/Button';
import { useGetProcoreProjectUsers } from '../graphql/queries/GetProcoreProjectUsers';
import idx from 'idx';

const ShadowScrollBox = styled(Box)(() => {
  return {
    overflow: 'auto',
    background:
      'linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top, linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom, radial-gradient(farthest-side at 50% 0,rgba(0, 0, 0, 0.15),rgba(0, 0, 0, 0)) center top, radial-gradient(farthest-side at 50% 100%,rgba(0, 0, 0, 0.15),rgba(0, 0, 0, 0)) center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 40px, 100% 40px, 100% 10px, 100% 10px',
    backgroundAttachment: 'local, local, scroll, scroll',
    marginBottom: '12px'
  };
});

interface IEditFileRequestDetailSidebarProps {
  accountId: string;
  procoreProjectServerId: number;
  logFileRequest: LogFileRequest;
  onCancelClick: () => void;
  onSaveClick: (attributes: Record<string, any>) => void;
  loading: boolean;
}

export const EditFileRequestDetailSidebar = ({
  logFileRequest,
  loading,
  onCancelClick,
  onSaveClick,
  accountId,
  procoreProjectServerId,
}: IEditFileRequestDetailSidebarProps) => {
  const { state: closeoutLogState, dispatch } = useContext(CloseoutLogContext);

  const [newTitle, setNewTitle] = React.useState(logFileRequest.title);
  const [newSubmitters, setNewSubmitters] = React.useState<LogFileRequestAssignee[]>([...(logFileRequest.assignees ?? [])]);
  const [newManage, setNewManager] = React.useState(logFileRequest.closeoutManager);
  const [newDueDate, setNewDueDate] = React.useState<Date | null>(
    typeof logFileRequest.dueDate === 'string' ? parse(logFileRequest.dueDate, 'yyyy-MM-dd', new Date()) : logFileRequest.dueDate
  );

  const { loading: loadingGetProjectUsers, data: projectUsersData } = useGetProcoreProjectUsers({
    accountId: accountId,
    procoreProjectServerId: procoreProjectServerId,
    closeoutLogId: closeoutLogState.id,
    vendorIds: [logFileRequest.responsibleContractor.procoreServerId],
  }, false, 'network-only');

  const submitters: LogFileRequestAssignee[] = (idx(projectUsersData, (data) => data.projectUsers) || []).map(d => {
    return {
      id: d.id,
      logFileRequestId: logFileRequest.id,
      procoreServerId: d.procoreServerId,
      procoreUserName: d.name,
      procoreUserEmail: d.emailAddress,
    } as LogFileRequestAssignee
  });

  const newSubmitterProcoreServerIds = newSubmitters.map((d) => d.procoreServerId);

  return (
    <Box height={1} sx={{
      display: 'flex',
      flexDirection: 'column',
      borderBottom: `1px solid ${Colors.mediumLightGray}`,
      borderTop: `1px solid ${Colors.mediumLightGray}`,
    }}>
      <ShadowScrollBox sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0',
        padding: '28px',
        gap: '28px',
      }}>
        <Typography typestyle='l'>General</Typography>
        <FieldContainer>
          <Typography typestyle="m">{'Title'}</Typography>
          <TextField
            disabled={loading || loadingGetProjectUsers}
            value={newTitle}
            autoComplete="off"
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
              setNewTitle(evt.target.value)
            }
          />
        </FieldContainer>
        <Typography typestyle='l'>Workflow</Typography>
        <FieldContainer>
          <Typography typestyle="m">{'Submitters'}</Typography>
          <Autocomplete
            disabled={loading || loadingGetProjectUsers}
            clearOnBlur
            multiple={true}
            value={newSubmitters}
            onChange={(evt, newValue, reason) => {
              switch (reason) {
                case 'clear': {
                  setNewSubmitters([]);
                  break;
                }

                case 'removeOption':
                case 'selectOption': {
                  setNewSubmitters(newValue);
                  break;
                }

                default:
                  break;
              }
            }}
            renderInput={(params) => <TextField {...params} />}
            getOptionLabel={(option) => option.procoreUserName}
            options={submitters.filter(s => {
              return !newSubmitterProcoreServerIds.includes(s.procoreServerId);
            })}
          />
        </FieldContainer>
        <FieldContainer>
          <Typography typestyle="m">{'Due Date'}</Typography>
          <DatePicker value={newDueDate}
            disabled={loading || loadingGetProjectUsers}
            onChange={(value) => {
              setNewDueDate(value);
            }} />
        </FieldContainer>
        {/* <Typography typestyle='l'>Additional Details</Typography> */}
      </ShadowScrollBox>

      <ModalFooter sx={{
        boxShadow: '0px -1px 10px 0px rgba(26, 32, 36, 0.06), 0px -4px 15px 0px rgba(26, 32, 36, 0.06)',
        paddingX: '28px',
        paddingY: '24px',
        display: 'flex',
        flexDirection: 'row',
        gap: '12px',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }}>
        <Button
          variant="outlined"
          disabled={loading || !logFileRequest || loadingGetProjectUsers}
          buttonborderstyle="pill"
          onClick={() => {
            onCancelClick();
        }}>Cancel</Button>
        <Button
          variant="contained"
          buttonborderstyle="pill"
          disabled={(loading || loadingGetProjectUsers || newSubmitters.length <= 0) || (logFileRequest && logFileRequest?.complete)}
          onClick={() => {
            onSaveClick({
              'title': newTitle,
              'due_date': formatISO(newDueDate),
              'assignee_procore_server_ids': newSubmitters.map(d => d.procoreServerId)
            })
        }}>
          Save
        </Button>
      </ModalFooter>
    </Box>
  );
};
