import React from 'react';
import { Dialog, DialogContent, Box, Radio, TextField, MenuItem, ListItemText, ListSubheader } from '@mui/material';
import { Typography } from './shared/Typography';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { Colors } from '../styles';
import { ProcoreToolGroupings } from '../types/submittal';
import { Button } from './shared/Button';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from '../utils/utils';
import { GroupingOperator } from '../types';

interface IAutomationAddToFolderPathModalProps {
  usingBox: boolean;
  groupingToEdit: ProcoreToolGroupings | undefined;
  isOpen: boolean;
  closeModal: () => void;
  groupings: ProcoreToolGroupings[];
  updatePath: (grouping: ProcoreToolGroupings) => void;
  addToPath: (grouping: ProcoreToolGroupings) => void;
}

const groupingOperators: GroupingOperator[] = [
  {
    label: 'Starts With',
    value: 'startsWith',
  }
]

export const AutomationAddToFolderPathModal = ({
  usingBox,
  groupingToEdit,
  isOpen,
  closeModal,
  groupings,
  updatePath,
  addToPath,
}: IAutomationAddToFolderPathModalProps): JSX.Element => {
  const [folderPathType, setFolderPathType] = React.useState<'named' | 'automatic' | 'conditional'>((groupingToEdit && groupingToEdit.conditional) ? 'conditional' : (groupingToEdit?.attributeSource == "user_input" ? 'named' : 'automatic'));
  const [selectedGrouping, setSelectedGrouping] = React.useState<ProcoreToolGroupings | undefined>(groupingToEdit);
  const [selectedOperator, setSelectedOperator] = React.useState<GroupingOperator>(groupingOperators[0]);

  const groupingsGroupedByLabel = groupings.reduce((acc, nextItem) => {
    acc[nextItem.groupingLabel] ||= [];

    acc[nextItem.groupingLabel].push(nextItem);

    return acc;
  }, {});

  return (
    <Dialog disableScrollLock open={isOpen} onClose={closeModal}>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '27px', padding: '28px', minWidth: '550px' }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <Typography typestyle="xl">Add to Folder Path</Typography>
          <IconButton onClick={closeModal}>
            <Close sx={{fontSize: '18px'}}/>
          </IconButton>
        </Box>

        <Box display="flex" gap="28px" flexDirection={'column'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'12px'} sx={{
              '&:hover': {
                cursor: 'pointer',
              }
            }} onClick={() => {
              setSelectedGrouping(undefined);
              setFolderPathType('named');
            }}>
              <Radio sx={{
                padding: '0px',
                color: Colors.mediumGray,
                '&.Mui-checked': {
                  color: Colors.blue7point1,
                },
              }} size='small' disableRipple disableFocusRipple disableTouchRipple checked={folderPathType === 'named'} onChange={(evt, checked) => {
                if (checked) {
                  setSelectedGrouping(undefined);
                  setFolderPathType('named');
                }
              }} />
              <Typography typestyle="m bold" sx={{ color: Colors.darkerGray }}>Named Folder</Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'} paddingLeft={'32px'} gap={'12px'} marginTop={'4px'}>
              <Typography typestyle="xs" sx={{ color: Colors.darkishGray}}>Specify the exact folder name</Typography>
              <TextField
                size="small"
                disabled={folderPathType !== 'named'}
                fullWidth
                value={folderPathType === 'named' ? selectedGrouping?.label : ''}
                onChange={(evt) => {
                  if (isEmpty(evt.currentTarget.value)) {
                    setSelectedGrouping(undefined);
                  } else {
                    setSelectedGrouping({
                      id: uuidv4(),
                      label: evt.currentTarget.value,
                      attribute: '',
                      attributeSource: 'user_input',
                      defaultValue: '',
                      groupingLabel: 'User Input',
                      operator: 'eq',
                      conditional: false
                    })
                  }
                }}
              />
            </Box>
          </Box>

          {
            usingBox && (
              <Box display={'flex'} flexDirection={'column'}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'12px'} sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  }
                }} onClick={() => {
                  setSelectedGrouping(undefined);
                  setFolderPathType('conditional');
                }}>
                  <Radio sx={{
                    padding: '0px',
                    color: Colors.mediumGray,
                    '&.Mui-checked': {
                      color: Colors.blue7point1,
                    },
                  }} size='small' disableRipple disableFocusRipple disableTouchRipple checked={folderPathType === 'conditional'} onChange={(evt, checked) => {
                    if (checked) {
                      setSelectedGrouping(undefined);
                      setFolderPathType('conditional');
                    }
                  }} />
                  <Typography typestyle="m bold" sx={{ color: Colors.darkerGray }}>Conditional</Typography>
                </Box>
                <Box display={'flex'} flexDirection={'column'} paddingLeft={'32px'} gap={'12px'} marginTop={'4px'}>
                  <Typography typestyle="xs" sx={{ color: Colors.darkishGray }}>The folder name will match based on the condition and a folder will be created if no match is found.</Typography>

                  <Box display={'flex'} flexDirection={'row'} gap={'16px'} alignItems={'center'}>
                    <TextField
                      select
                      fullWidth={false}
                      size='small'
                      value={(folderPathType === 'conditional' && selectedOperator) ? selectedOperator.value : ''}
                      sx={{
                        maxWidth: '200px'
                      }}
                      slotProps={{
                        select: {
                          disabled: folderPathType !== 'conditional',
                          onChange: (evt) => {
                            console.log('evt', evt.target.value);
                            // setSelectedOperator(evt.target.value);
                            // TODO: Update selected grouping with operator
                          },
                          multiple: false,
                          renderValue: () => (folderPathType === 'conditional' && selectedOperator) ? selectedOperator.label : 'Select Operator',
                          MenuProps: {
                            PaperProps: {
                              sx: {
                                padding: 0,
                              }
                            },
                            MenuListProps: {
                              sx: {
                                padding: '16p 0px 16px 0px',
                              }
                            },
                            sx: {
                              padding: 0,
                              height: '250px',
                            },
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'center'
                            }
                          },
                          displayEmpty: true,
                        }
                      }}
                    >
                      {
                        groupingOperators.map((operator) => {
                          const selected = selectedOperator === operator;
                          return (
                            <MenuItem
                              disabled={selected}
                              key={`operator-grouping-menu-item-${operator.value}`}
                              value={operator.value}
                            >
                              <ListItemText sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: Colors.darkerGray
                              }} primary={operator.label} />
                            </MenuItem>
                          );
                        })
                      }
                    </TextField>
                    <TextField
                      select
                      fullWidth={false}
                      size='small'
                      value={(folderPathType === 'conditional' && selectedGrouping) ? selectedGrouping.attribute : ''}
                      sx={{
                        maxWidth: '200px'
                      }}
                      slotProps={{
                        select: {
                          disabled: folderPathType !== 'conditional',
                          onChange: (evt) => {
                            const {
                              target: { value: newGrouping },
                            } = evt;

                            const grouping = groupings.find((group) => group.attribute === newGrouping);
                            setSelectedGrouping({
                              ...grouping,
                              conditional: true,
                              operator: selectedOperator.value
                            });
                          },
                          multiple: false,
                          renderValue: () => (folderPathType === 'conditional' && selectedGrouping) ? selectedGrouping.label : 'Select Grouping',
                          MenuProps: {
                            PaperProps: {
                              sx: {
                                padding: 0,
                              }
                            },
                            MenuListProps: {
                              sx: {
                                padding: '16p 0px 16px 0px',
                              }
                            },
                            sx: {
                              padding: 0,
                              height: '250px',
                            },
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'center'
                            }
                          },
                          displayEmpty: true,
                        }
                      }}
                      disabled={groupings.length === 0}
                    >
                      {Object.keys(groupingsGroupedByLabel).map((key, index) => {
                        return [
                          <ListSubheader sx={{
                            fontSize: '12px',
                            fontWeight: '700',
                            color: Colors.mediumDarkerGray,
                            textTransform: 'uppercase',
                          }} key={`${key}-${index}`}>{key}</ListSubheader>,
                        ].concat(groupingsGroupedByLabel[key].map((group) => {
                          const selected = selectedGrouping === group.attribute;

                            return (
                              <MenuItem
                                disabled={selected}
                                key={`grouping-menu-item-${group.label}`}
                                value={group.attribute}
                              >
                                <ListItemText sx={{
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  color: Colors.darkerGray
                                }} primary={group.label} />
                              </MenuItem>
                            );
                          })
                        )
                      }).flat()}
                    </TextField>
                  </Box>
                </Box>
              </Box>
            )
          }

          <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'12px'} sx={{
              '&:hover': {
                cursor: 'pointer',
              }
            }} onClick={() => {
              setSelectedGrouping(undefined);
              setFolderPathType('automatic');
            }}>
              <Radio sx={{
                padding: '0px',
                color: Colors.mediumGray,
                '&.Mui-checked': {
                  color: Colors.blue7point1,
                },
              }} size='small' disableRipple disableFocusRipple disableTouchRipple checked={folderPathType === 'automatic'} onChange={(evt, checked) => {
                if (checked) {
                  setSelectedGrouping(undefined);
                  setFolderPathType('automatic');
                }
              }} />
              <Typography typestyle="m bold" sx={{ color: Colors.darkerGray }}>Automatic Folder</Typography>

            </Box>
            <Box display={'flex'} flexDirection={'column'} paddingLeft={'32px'} gap={'12px'} marginTop={'4px'}>
              <Typography typestyle="xs" sx={{ color: Colors.darkishGray}}>The folder name will be determined by the selected project, tool, or item attribute.</Typography>
              <TextField
                select
                fullWidth={false}
                size='small'
                value={(folderPathType === 'automatic' && selectedGrouping) ? selectedGrouping.attribute : ''}
                sx={{
                  maxWidth: '200px'
                }}
                slotProps={{
                  select: {
                    disabled: folderPathType !== 'automatic',
                  onChange: (evt) => {
                    const {
                      target: { value: newGrouping },
                    } = evt;

                    const grouping = groupings.find((group) => group.attribute === newGrouping);
                    setSelectedGrouping(grouping);
                  },
                  multiple: false,
                  renderValue: () => (folderPathType === 'automatic' && selectedGrouping) ? selectedGrouping.label : 'Select Grouping',
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        padding: 0,
                      }
                    },
                    MenuListProps: {
                      sx: {
                        padding: '16p 0px 16px 0px',
                      }
                    },
                    sx: {
                      padding: 0,
                      height: '250px',
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                    }
                  },
                  displayEmpty: true,
                  }
                }}
                disabled={groupings.length === 0}
              >
                {Object.keys(groupingsGroupedByLabel).map((key, index) => {
                  return [
                    <ListSubheader sx={{
                      fontSize: '12px',
                      fontWeight: '700',
                      color: Colors.mediumDarkerGray,
                      textTransform: 'uppercase',
                    }} key={`${key}-${index}`}>{key}</ListSubheader>,
                  ].concat(groupingsGroupedByLabel[key].map((group) => {
                    const selected = selectedGrouping === group.attribute;

                      return (
                        <MenuItem
                          disabled={selected}
                          key={`grouping-menu-item-${group.label}`}
                          value={group.attribute}
                        >
                          <ListItemText sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            color: Colors.darkerGray
                          }} primary={group.label} />
                        </MenuItem>
                      );
                    })
                  )
                }).flat()}
              </TextField>
            </Box>
          </Box>

          <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} gap={'8px'}>
            <Button variant="outlined" buttonborderstyle="pill" onClick={closeModal}>
              Cancel
            </Button>
            <Button variant="contained" buttonborderstyle="pill" onClick={() => {
              if (groupingToEdit) {
                updatePath(selectedGrouping);
              } else {
                addToPath(selectedGrouping);
              }
            }} disabled={!selectedGrouping}>
              {groupingToEdit ? 'Update' : 'Add'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
