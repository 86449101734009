import React from 'react'
import ReactDOM from 'react-dom'
import App from './app/App';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import ApolloLinkTimeout from 'apollo-link-timeout';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ApolloProvider } from '@apollo/client/react';
import { LicenseInfo } from '@mui/x-license';
import * as procoreIframeHelpers from '@procore/procore-iframe-helpers';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import VendorPortalApp from './components/VendorPortalApp';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { theme } from "./styles";
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend'
import { VendorPortalContextProvider } from './contexts/VendorPortalContextProvider';
import { getCSRFToken } from './utils/utils';

LicenseInfo.setLicenseKey('b72eeb41a6ed7845524bdf76455eb57eTz04OTk4MSxFPTE3NDY4MDA4MTkwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

Bugsnag.start({
  apiKey: '730f931949e77b4b58a53eabca4edec5',
  plugins: [new BugsnagPluginReact()]
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const errorLink = onError(({ _graphQLErrors, _networkError, response }) => {
  if (response.errors && response.errors.length > 0) {
    if (response.errors[0].extensions['code'] === 401) {
      console.log('Unauthorized');
    }
  }
});

function buildApolloClient(uri: string) {
  const link = createHttpLink({
    uri: uri,
    credentials: 'same-origin',
    headers: {
      "X-CSRF-Token": getCSRFToken()
    }
  });

  const timeoutLink = new ApolloLinkTimeout(60000); // 60 second timeout
  return new ApolloClient({
    cache: new InMemoryCache({
      addTypename: true,
      resultCaching: true,
      typePolicies: {
        LogCustomField: {
          fields: {
            values: {
              merge: (existing: any[], incoming: any[], { args, readField }) => {
                const merged = existing ? existing.slice(0) : [];

                // Create a map of existing items by their ID for easy lookup
                const existingMap = new Map(merged.map(item => [readField("id", item), item]));

                incoming.forEach(item => {
                  const id = readField("id", item) as any;
                  if (id === undefined) return; // Skip items that don't have an ID

                  if (readField("_delete", item)) {
                    // Handle deletion
                    existingMap.delete(id);
                  } else {
                    // Handle insert or update
                    existingMap.set(id, item);
                  }
                });

                // Convert the map back to an array
                const newMerged = Array.from(existingMap.values());

                // If args.afterId is provided, handle insertion order based on afterId
                if (args && args.afterId) {
                  const afterIndex = merged.findIndex(
                    item => args.afterId === readField("id", item)
                  );
                  if (afterIndex >= 0) {
                    // Find the items to insert (newly added or updated)
                    const itemsToInsert = newMerged.filter(
                      item => !existingMap.has(readField("id", item))
                    );

                    // Insert the items after the afterIndex
                    newMerged.splice(afterIndex + 1, 0, ...itemsToInsert);
                  }
                }

                return newMerged;
              },
            }
          }
        },
        LogRequirement: {
          fields: {
            customFields: {
              merge: (existing: any[], incoming: any[], { args, readField }) => {
                const merged = existing ? existing.slice(0) : [];

                // Create a map of existing items by their ID for easy lookup
                const existingMap = new Map(merged.map(item => [readField("id", item), item]));

                incoming.forEach(item => {
                  const id = readField("id", item) as any;
                  if (id === undefined) return; // Skip items that don't have an ID

                  if (readField("_delete", item)) {
                    // Handle deletion
                    existingMap.delete(id);
                  } else {
                    // Handle insert or update
                    existingMap.set(id, item);
                  }
                });

                // Convert the map back to an array
                const newMerged = Array.from(existingMap.values());

                // If args.afterId is provided, handle insertion order based on afterId
                if (args && args.afterId) {
                  const afterIndex = merged.findIndex(
                    item => args.afterId === readField("id", item)
                  );
                  if (afterIndex >= 0) {
                    // Find the items to insert (newly added or updated)
                    const itemsToInsert = newMerged.filter(
                      item => !existingMap.has(readField("id", item))
                    );

                    // Insert the items after the afterIndex
                    newMerged.splice(afterIndex + 1, 0, ...itemsToInsert);
                  }
                }

                return newMerged;
              },
            }
          }
        },
        Query: {
          fields: {
            feed: {
              // Don't cache separate results based on
              // any of this field's arguments.
              keyArgs: false,
              // Concatenate the incoming list items with
              // the existing list items.
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            }
          }
        }
      }
    }),
    connectToDevTools: true,
    link: timeoutLink.concat(errorLink).concat(link)
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const root = document.getElementById('app_root');
  if (root === undefined || root === null) { return; }
  const authenticated = root.getAttribute('data-authenticated') === "true" ? true : false;
  const procoreAuthUrl = root.getAttribute('data-procore-auth-url');

  root.removeAttribute('data-authenticated');
  root.removeAttribute('data-procore-auth-url');

  const mainExtractsProClient = buildApolloClient('/graphql');
  const vendorPortalClient = buildApolloClient('/closeout/graphql');

  const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

  ReactDOM.render(
    <ErrorBoundary>
      <BrowserRouter>
        <Switch>
          <Route exact path="/closeout/vendor/:id">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
              <StyledEngineProvider injectFirst={false}>
                <CssBaseline />
                <ApolloProvider client={vendorPortalClient}>
                  <DndProvider backend={HTML5Backend}>
                    <VendorPortalContextProvider>
                      <VendorPortalApp />
                    </VendorPortalContextProvider>
                  </DndProvider>
                </ApolloProvider>
              </StyledEngineProvider>
            </ThemeProvider>
          </LocalizationProvider>
          </Route>

          <Route>
            <ApolloProvider client={mainExtractsProClient}>
              <App authenticated={authenticated} procoreAuthUrl={procoreAuthUrl} />
            </ApolloProvider>
          </Route>
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>,
    document.body.appendChild(root),
  )
});

document.addEventListener('DOMContentLoaded', () => {
  procoreIframeHelpers.initialize().authentication.notifySuccess({});
});
