import Box from '@mui/material/Box';
import React, { useContext } from 'react';
import { Colors } from '../styles';
import { BodyCardHeaderBar } from './shared/BodyCardHeaderBar';
import { BodyCard } from './shared/BodyCard';
import { CloseoutLogContext } from '../contexts/CloseoutLogContextProvider';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { CircularProgress, ListItemIcon, ListItemText, MenuItem, Popover, Tab, Tabs } from '@mui/material';
import { TabContext, TabPanel, TabPanelProps } from '@mui/lab';
import { useGetLogFileRequestDetailData } from '../graphql/queries/closeout/GetLogFileRequestDetail';
import { FileRequestEmailNotifications } from './FileRequestEmailNotifications';
import { FileRequestWorkflow } from './FileRequestWorkflow';
import { Typography } from './shared/Typography';
import idx from 'idx';
import { FileRequestDetail } from './FileRequestDetail';
import { CloseoutLogDataGridView, LogFileRequest } from '../types';
import { FileRequestNotes } from './FileRequestNotes';
import { useUpdateLogFileRequest } from '../graphql/mutations/closeout/UpdateLogFileRequest';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { EditFileRequestDetailSidebar } from './EditFileRequestDetailSidebar';
import { ModalFooter } from './shared/ModalFooter';
import { Button } from './shared/Button';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';

const ShadowScrollBox = styled(Box)(() => {
  return {
    overflow: 'auto',
    background:
      'linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top, linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom, radial-gradient(farthest-side at 50% 0,rgba(0, 0, 0, 0.15),rgba(0, 0, 0, 0)) center top, radial-gradient(farthest-side at 50% 100%,rgba(0, 0, 0, 0.15),rgba(0, 0, 0, 0)) center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 40px, 100% 40px, 100% 10px, 100% 10px',
    backgroundAttachment: 'local, local, scroll, scroll',
    marginBottom: '12px'
  };
});

const CustomTabPanel = styled(TabPanel)<TabPanelProps & { current: boolean }>(({ current }) => {
  return {
    display: current ? 'flex' : 'none',
    padding: 0,
    height: current ? '100%' : '0',
    flexGrow: 1,
  }
});

const primaryButtonText = (logFileRequest?: LogFileRequest) => {
  if (logFileRequest?.complete) {
    return logFileRequest?.translatedStatus;
  }

  if (logFileRequest?.vendorWorkflowRequest?.vendorWorkflowResponse) {
    return 'Review & Respond';
  } else {
    return logFileRequest?.translatedStatus;
  }
}

interface IFileRequestDetailSidebarProps {
  open: boolean;
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  logFileRequestId: string | number;
  dateFormat: string;
  detailDateFormat: string;
  onReviewAndRespondClick: (logFileRequest: LogFileRequest) => void;
  detailViewOptionsChanged: (options: Record<string, any>) => void;
  onArchive: (logFileRequestId: number[]) => void,
  onSubmitFilesManuallyClick: (logFileRequest: LogFileRequest) => void;
  close: () => void;
}

const getTabValue = (dataGridView?: CloseoutLogDataGridView): string => {
  return dataGridView?.detailViewOptions['file_request_sidebar_tab'] || 'detail';
}

export const FileRequestDetailSidebar = ({
  open,
  accountId,
  procoreProjectServerId,
  closeoutLogId,
  close,
  onReviewAndRespondClick,
  logFileRequestId,
  detailViewOptionsChanged,
  dateFormat,
  detailDateFormat,
  onSubmitFilesManuallyClick,
  onArchive,
}: IFileRequestDetailSidebarProps) => {
  const { state: closeoutLogState, dispatch } = useContext(CloseoutLogContext);
  const [tabValue, setTabValue] = React.useState(getTabValue(closeoutLogState.currentCloseoutLogFileRequestDataGridView));
  const [overflowMenuAnchorEl, setOverflowMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [viewMode, setViewMode] = React.useState<'view' | 'edit'>('view');

  React.useEffect(() => {
    if (closeoutLogState.currentCloseoutLogFileRequestDataGridView) {
      setTabValue(getTabValue(closeoutLogState.currentCloseoutLogFileRequestDataGridView));
    }
  }, [closeoutLogState.currentCloseoutLogFileRequestDataGridView]);

  const { loading, data } = useGetLogFileRequestDetailData(
    {
      accountId: accountId,
      procoreProjectServerId: procoreProjectServerId,
      closeoutLogId: closeoutLogId,
      logFileRequestId: logFileRequestId,
    },
    false,
    'network-only'
  );
  const logFileRequest = idx(data, _ => _.logFileRequest);

  React.useEffect(() => {
    if (loading || !data) { return; }

    setViewMode('view');
  }, [loading, data]);

  const [ updateLogFileRequest, { loading: loadingUpdateLogFileRequest } ] = useUpdateLogFileRequest(
    {
      accountId: accountId,
      procoreProjectServerId: procoreProjectServerId,
      closeoutLogId: closeoutLogId,
      logFileRequestId: logFileRequest?.id,
      attributes: {}
    }
  )

  return (
    <>
      <Popover
        open={Boolean(overflowMenuAnchorEl)}
        anchorEl={overflowMenuAnchorEl}
        onClose={()=> setOverflowMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {
            sx: {
              padding: '8px',
            }
          }
        }}>
        { logFileRequest && !logFileRequest.complete && (
          <MenuItem
            disabled={loading || !logFileRequest}
            onClick={()=> {
              setViewMode('edit');
              setOverflowMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <CreateOutlinedIcon fontSize='small' sx={{ color: Colors.darkerGray }} />
            </ListItemIcon>
            <ListItemText sx={{ color: Colors.darkerGray }} primary="Edit details" />
          </MenuItem>
        )}
        { logFileRequest && !logFileRequest.complete && !logFileRequest.vendorWorkflowRequest.vendorWorkflowResponse && (
          <MenuItem
            disabled={loading || !logFileRequest}
            onClick={()=> {
              onSubmitFilesManuallyClick(logFileRequest);
              setOverflowMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <UploadOutlinedIcon fontSize='small' sx={{ color: Colors.darkerGray }} />
            </ListItemIcon>
            <ListItemText sx={{ color: Colors.darkerGray }} primary="Submit files manually" />
          </MenuItem>
        )}
        <MenuItem
          disabled={loading || !logFileRequest}
          onClick={()=> {
            onArchive([logFileRequest?.id]);
            setOverflowMenuAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize='small' sx={{ color: Colors.warningRed }} />
          </ListItemIcon>
          <ListItemText sx={{ color: Colors.warningRed }} primary="Delete request" />
        </MenuItem>
      </Popover>
      <Box
        sx={{
          position: open ? 'static' : 'absolute',
          display: 'flex',
          transition: 'all 0.25s ease-out',
          opacity: open ? 1 : 0.7,
          marginBottom: '20px',
          width: open ? '520px' : '240px',
        }}
      >
        <BodyCard
          sx={{
            display: open ? 'flex' : 'none',
            backgroundColor: `${Colors.white}`,
            height: 'auto',
            minWidth: '520px',
          }}
        >
          <BodyCardHeaderBar title={viewMode === 'view' ? "File Request Activity" : "Edit File Request" }>
            <IconButton onClick={() => {
              setViewMode('view');
              close();
            }}>
              <Close sx={{ fontSize: '20px' }} />
            </IconButton>
          </BodyCardHeaderBar>
            {
              loading || !logFileRequest ? (
                <Box
                  height={1}
                  width={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  gap="24px"
                >
                  <CircularProgress
                    size={40}
                    thickness={2}
                    variant="indeterminate"
                  />
                  <Typography typestyle="l">Loading...</Typography>
                </Box>
              ) : (
                viewMode === "view" ? (
                  <>
                    <TabContext value={tabValue}>
                      <Box sx={{
                        display: 'flex',
                        borderBottom: `1px solid ${Colors.mediumLightGray}`,
                        borderTop: `1px solid ${Colors.mediumLightGray}`,
                      }}>
                        <Tabs value={tabValue} onChange={(_evt, newValue) => {
                          setTabValue(newValue);

                          detailViewOptionsChanged(
                            {
                              ...closeoutLogState.currentCloseoutLogFileRequestDataGridView.detailViewOptions,
                              ['file_request_sidebar_tab']: newValue
                            }
                          )
                        }}>
                          <Tab label="Detail" value="detail" />
                          <Tab label="Workflow" value="workflow" />
                          <Tab label="Email Notifications" value="emails" />
                          <Tab label="Notes" value="notes" />
                        </Tabs>
                      </Box>

                      <ShadowScrollBox
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          flex: '1 1 0',
                        }}
                      >
                        <CustomTabPanel value={"detail"} current={tabValue === "detail"}>
                          <FileRequestDetail
                            logFileRequest={logFileRequest}
                            dateFormat={dateFormat}
                            onExpansionChanged={(expansionState) => {
                              detailViewOptionsChanged({
                                ...closeoutLogState.currentCloseoutLogFileRequestDataGridView.detailViewOptions,
                                ...expansionState
                              })
                            }} />
                        </CustomTabPanel>

                        <CustomTabPanel value={"workflow"} current={tabValue === "workflow"}>
                          <FileRequestWorkflow detailDateFormat={detailDateFormat} vendorWorkflowRequests={logFileRequest?.vendorWorkflowRequests || []} />
                        </CustomTabPanel>

                        <CustomTabPanel value={"emails"} current={tabValue === "emails"}>
                          <FileRequestEmailNotifications detailDateFormat={detailDateFormat} emailNotifications={logFileRequest?.emailNotifications || []} />
                        </CustomTabPanel>

                        <CustomTabPanel value={"notes"} current={tabValue === "notes"}>
                          <FileRequestNotes
                            logFileRequest={logFileRequest}
                            onUpdateInternalNotes={(notes) => {
                              updateLogFileRequest({
                                variables: {
                                  accountId: accountId,
                                  procoreProjectServerId: procoreProjectServerId,
                                  closeoutLogId: closeoutLogId,
                                  logFileRequestId: logFileRequest?.id,
                                  attributes: {
                                    internal_notes: notes
                                  }
                                }
                              }).then(({ data }) => {
                                if (data.updateLogFileRequest.success) {
                                  dispatch({
                                    type: 'UPDATE_LOG_FILE_REQUEST',
                                    value: {
                                      logFileRequest: data.updateLogFileRequest.logFileRequest,
                                      logRequirementItems: [],
                                      triggeredFrom: 'UPDATE_LOG_FILE_REQUEST'
                                    }
                                  });
                                }
                              })
                            }}
                            onUpdatePublicNotes={(notes) => {
                              updateLogFileRequest({
                                variables: {
                                  accountId: accountId,
                                  procoreProjectServerId: procoreProjectServerId,
                                  closeoutLogId: closeoutLogId,
                                  logFileRequestId: logFileRequest?.id,
                                  attributes: {
                                    description: notes
                                  }
                                }
                              }).then(({ data }) => {
                                if (data.updateLogFileRequest.success) {
                                  dispatch({
                                    type: 'UPDATE_LOG_FILE_REQUEST',
                                    value: {
                                      logFileRequest: data.updateLogFileRequest.logFileRequest,
                                      logRequirementItems: [],
                                      triggeredFrom: 'UPDATE_LOG_FILE_REQUEST'
                                    }
                                  });
                                }
                              })
                            }}
                          />
                        </CustomTabPanel>
                      </ShadowScrollBox>
                    </TabContext>
                    <ModalFooter sx={{
                      boxShadow: '0px -1px 10px 0px rgba(26, 32, 36, 0.06), 0px -4px 15px 0px rgba(26, 32, 36, 0.06)',
                      paddingX: '28px',
                      paddingY: '24px',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '12px',
                      alignItems: 'center'
                    }}>
                      <Button
                        variant="contained"
                        buttonborderstyle="pill"
                        disabled={loading || logFileRequest && (logFileRequest?.complete || !logFileRequest?.vendorWorkflowRequest.vendorWorkflowResponse)}
                        fullWidth={true} onClick={() => {
                        onReviewAndRespondClick(logFileRequest);
                      }}>
                        {primaryButtonText(logFileRequest)}
                      </Button>
                      <Button
                        variant="outlined"
                        disabled={loading || !logFileRequest}
                        buttonborderstyle="pill"
                        startIcon={<MoreHoriz fontSize="small" />}
                        sx={{
                          minWidth: '40px',
                          paddingX: '9px',
                          '& .MuiButton-startIcon': {
                            margin: '0px',
                          },
                        }}
                        onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                          setOverflowMenuAnchorEl(evt.currentTarget);
                        }}
                      />
                    </ModalFooter>
                  </>
                ) : (
                  <EditFileRequestDetailSidebar
                    accountId={accountId}
                    procoreProjectServerId={procoreProjectServerId}
                    loading={loading || loadingUpdateLogFileRequest}
                    logFileRequest={logFileRequest}
                    onSaveClick={(attributes) => {
                      updateLogFileRequest({
                        variables: {
                          accountId: accountId,
                          procoreProjectServerId: procoreProjectServerId,
                          closeoutLogId: closeoutLogId,
                          logFileRequestId: logFileRequest?.id,
                          attributes: attributes
                        }
                      }).then(({ data }) => {
                        if (data.updateLogFileRequest.success) {
                          dispatch({
                            type: 'UPDATE_LOG_FILE_REQUEST',
                            value: {
                              logFileRequest: data.updateLogFileRequest.logFileRequest,
                              logRequirementItems: [],
                              triggeredFrom: 'UPDATE_LOG_FILE_REQUEST'
                            }
                          });
                          setViewMode('view');
                        }
                      })
                    }}
                    onCancelClick={() => setViewMode('view') } />
                )
              )
            }
        </BodyCard>
      </Box>
    </>
  );
};
