import { gql, QueryResult, useQuery } from '@apollo/client';
import { AccountProjectData } from '../../types';

interface AccountProjectVariables {
  accountId: string;
  licensed: boolean;
  procoreCompanyAdmin: boolean;
  frontendView: string;
}

export const GetAccountProjectsQuery = gql`
  query GetAccountProjects($accountId: ID!, $licensed: Boolean!, $procoreCompanyAdmin: Boolean!, $frontendView: String!) {
    procoreCompanies(accountId: $accountId) {
      procoreServerId
      name
      enabled
    }

    procoreProjects(accountId: $accountId, licensed: $licensed, procoreCompanyAdmin: $procoreCompanyAdmin) {
      id
      procoreServerId
      procoreCompanyServerId
      name
      displayName
      projectNumber
      licensed
      licenseType
      licenseEndDate
      timeZone
      canFreeTrial
      procoreCompanyName
      dmsaEnabled
      stage
      active
      closeoutLogCount
      automationCount
      completionDate
    }

    dataGridView(accountId: $accountId, frontendView: $frontendView) {
      id
      name
      columnDefinitions
      groupingColumnDefinition
      sortModel
      groupings
      columnVisibility
      filters
    }
  }
`;

export const useGetAccountProjects = (
  accountId: string,
  licensed: boolean,
  procoreCompanyAdmin: boolean = false
): QueryResult<AccountProjectData> =>
  useQuery<AccountProjectData, AccountProjectVariables>(
    GetAccountProjectsQuery,
    {
      variables: {
        accountId: accountId,
        licensed: licensed,
        procoreCompanyAdmin: procoreCompanyAdmin,
        frontendView: 'projects',
      },
      fetchPolicy: 'cache-and-network',
    }
  );
