import React from 'react';
import {
  Box,
  IconButton,
  Autocomplete,
  FormControl,
  SelectChangeEvent,
  MenuItem,
  TextField,
} from '@mui/material';
import { Typography } from './shared/Typography';
import Close from '@mui/icons-material/Close';
import { GridFilterItem } from '@mui/x-data-grid-premium';
import { FilterField, STATUS_OPTIONS, ExtractsProDataGridFilter } from './CloseoutLog';
import { CloseoutLogContext } from '../contexts/CloseoutLogContextProvider';

interface IFilterRowProps {
  handleRemoveItem: () => void;
  handleUpdateItem: (item: GridFilterItem, index: number) => void;
  filterItem: GridFilterItem;
  index: number;
  appliedfilters: GridFilterItem[];
  availableFilters: ExtractsProDataGridFilter[];
}

export const CloseoutFilterRow = ({
  handleRemoveItem,
  handleUpdateItem,
  filterItem,
  index,
  appliedfilters,
  availableFilters,
}: IFilterRowProps): JSX.Element => {
  const { state } = React.useContext(CloseoutLogContext);

  //function that gives values to list for the given field
  const getValues = (field: FilterField): Array<any> => {
    if (field.startsWith('custom_field_')) {
      return (state.logCustomFields.filter((customField) => customField.key === field)[0]?.values || []).map((value) => {
          return {
            id: value.id,
            name: value.value
          };
      });
    } else {
      switch (field) {
        case 'responsibleContractor':
          return state.responsibleContractors;
        case 'specSection':
          return state.specSections;
        case 'logRequirementType':
          return state.logRequirementTypes;
        case 'logRequirementTrade':
          return state.logRequirementTrades;
        case 'status':
          return STATUS_OPTIONS;
        default:
          return [];
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'start',
        gap: '16px',
        paddingX: '16px',
      }}
    >
      <Box
        sx={{
          height: '40px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <IconButton onClick={handleRemoveItem} size="small">
          <Close sx={{ fontSize: '16px' }} />
        </IconButton>
      </Box>
      <FormControl sx={{ width: '50%' }}>
        <TextField
          select
          size="small"
          value={
            availableFilters.filter((filter) => filter.id === filterItem.id)[0]
          }
          slotProps={{
            select: {
              onChange: (event: SelectChangeEvent<any>) => {
                const newItem = {
                  ...filterItem,
                  field: event.target.value.field,
                  id: event.target.value.id,
                  value: undefined,
                };
                handleUpdateItem(newItem, index);
              }
            }
          }}
        >
          {availableFilters.map((filterField, i) => {
            return (
              <MenuItem
                value={filterField as any}
                key={`filter-field-menu-item-${i}`}
                disabled={
                  appliedfilters.filter((filter) => filter.id === filterField.id)
                    .length > 0
                }
              >
                {filterField.label}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
      <Box
        sx={{
          height: '40px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography>is</Typography>
      </Box>
      <FormControl sx={{ width: '50%' }}>
        <Autocomplete
          multiple
          value={filterItem.value || []}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={getValues(filterItem.field as FilterField)}
          getOptionLabel={(item) => {
            if (item?.name) {
              return item.name;
            } else {
              return '';
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="" autoFocus size="small" />
          )}
          onChange={(evt, value) => {
            const newItem = {
              ...filterItem,
              value: value.length === 0 ? undefined : value,
            };
            handleUpdateItem(newItem, index);
          }}
        />
      </FormControl>
    </Box>
  );
};
