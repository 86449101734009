import { Box } from "@mui/material";
import React from "react";
import { Colors } from "../../styles";
import { Button } from "../shared/Button";
import { Typography } from "../shared/Typography";

const ProjectsListEmptyState = (props: { onClickGettingStarted: () => void, hasLicensedProjects: boolean, hasLoadedProjects: boolean }): JSX.Element => {
  const { onClickGettingStarted } = props;
  return (
    <Box
      display="flex"
      height={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{
        gap: '20px',
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        border: `1px solid ${Colors.mediumLightGray}`,
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
      }}>
        <Typography typestyle="xxl">Get Started</Typography>
        <Typography typestyle="m" style={{ color: "#596A75", textAlign: 'center' }}>
          {
            !props.hasLoadedProjects
              ? "Looks like we haven't loaded your Procore projects. Click on Load Projects to get started."
                : "Looks like your account doesn't have any licenses projects. Contact your account admin to get started."
          }
        </Typography>
      </Box>
      <Button
          size="medium"
          buttonborderstyle="pill"
          variant="contained"
          onClick={onClickGettingStarted}
        >
          Load Projects
      </Button>
    </Box>
  );
};

export default ProjectsListEmptyState;
