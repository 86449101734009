import React, { useState, useContext } from 'react';
import {
  Box, List, styled, Link, Skeleton,
  FormControlLabel
} from '@mui/material';
import { TabPanel, TabContext, TabPanelProps } from '@mui/lab';
import { Typography } from './shared/Typography';
import { Button } from './shared/Button';
import { Colors, ExtractCloseoutLogContainer, ExtractsCheckbox, GrayDivider, StyledVerticalTab, StyledVerticalTabs } from '../styles';
import { AccountProjectContext } from '../contexts/AccountProjectContextProvider';
import { CloseoutLogContext } from '../contexts/CloseoutLogContextProvider';
import useNavigationHook from '../hooks/UseNavigationHook';
import { useCreateCloseoutLogPackage } from '../graphql/mutations/CreateCloseoutLogPackage';
import { PackageHistoryContext } from '../contexts/PackageContextProvider';
import { CloseoutGroupBy } from './shared/CloseoutGroupBy';
import { AttachmentOption, SelectFilterValue, SubmittalExtractOptions } from '../types/submittal';
import { BlueSwitch, ExtractsRadio } from './package/styles';
import { procoreGroupingsToInputGroupings } from '../utils/utils';
import { CheckoutToolTabsListItem } from './package/NewPackage';
import ExportOptionToggle from './package/ExportOptionToggle';
import { TOOL_ENGINE_NAMES } from '../utils/utils';
import { ManageVendorListModal } from './ManageVendorListModal';
import { useUpdateCloseoutLogExportOption } from '../graphql/mutations/closeout/UpdateCloseoutLogExportOption';
import { UnderlinedHeader } from './shared/UnderlinedHeader';
import { useGetCloseoutLogDirectory } from '../graphql/queries/closeout/GetCloseoutLogDirectory';
import { FilterAutocomplete } from './shared/FilterAutocomplete';
import { RfiExtractOptions } from '../types/rfi';
import { ExtractToolOptions } from '../contexts/CloseoutLogContext';
import { ObservationExtractOptions } from '../types/observation';
import { InspectionExtractOptions } from '../types/inspection';
import { PunchItemExtractOptions } from '../types/punch_item';
import { ActionPlanExtractOptions } from '../types/action_plan';
import { ProcoreToolGroupings } from '../types';
import { FormExtractOptions } from '../types/form';

const CustomTabPanel = styled(TabPanel)<TabPanelProps & { current: boolean }>(({ current }) => {
  return {
    display: current ? 'flex' : 'none',
    height: current ? '100%' : '0',
  }
});

const attachmentContentOptions = [
  {
    value: 'all',
    label: 'All attachments'
  },
  {
    value: 'distributed',
    label: 'Distributed attachments only'
  },
  {
    value: 'last_workflow_response',
    label: 'Last workflow response with status'
  }
];

const SUPPORTED_PDF_OPTION_TOOL_ENGINES = [
  TOOL_ENGINE_NAMES.SUBMITTALS,
  TOOL_ENGINE_NAMES.RFIS,
  TOOL_ENGINE_NAMES.DRAWINGS,
  TOOL_ENGINE_NAMES.OBSERVATIONS,
  TOOL_ENGINE_NAMES.INSPECTIONS,
  TOOL_ENGINE_NAMES.PUNCH_LIST,
  TOOL_ENGINE_NAMES.ACTION_PLANS,
  TOOL_ENGINE_NAMES.FORMS,
];

const ExtractCloseoutLog = (): JSX.Element => {
  const { state, dispatch } = useContext(CloseoutLogContext);
  const { state: accountProjectState } = useContext(AccountProjectContext);
  const { fetchPackages, dispatch: dispatchPackageHistory } = useContext(PackageHistoryContext);
  const [loading, setLoading] = useState(false);
  const navigateTo = useNavigationHook();
  const [currentToolTab, setCurrentToolTab] = useState(state.procoreTools.filter(t => SUPPORTED_PDF_OPTION_TOOL_ENGINES.includes(t.engineName))[0].engineName);
  const [isManageVendorListModalOpen, setIsManageVendorListModalOpen] = useState(false);

  const [createCloseoutLogPackage] = useCreateCloseoutLogPackage({
    accountId: accountProjectState.accountId,
    procoreProjectServerId: accountProjectState.procoreProjectServerId,
    closeoutLogId: state.id,
    attributes: {
      groupings: procoreGroupingsToInputGroupings(state.extractOptions.selectedGroupings),
      exportTo: 'zip_file',
      options: state.extractOptions,
      logRequirementIds: state.selectedLogRequirementIds,
    }
  });

  const [updateCloseoutLogExportOption] = useUpdateCloseoutLogExportOption({
    accountId: accountProjectState.accountId,
    procoreProjectServerId: accountProjectState.procoreProjectServerId,
    closeoutLogId: state.id,
    attributes: {
      includeTableOfContents: state.extractOptions.include_table_of_contents,
      includeVendorList: state.extractOptions.include_vendor_list,
      groupings: state.extractOptions.selectedGroupings.map((grouping) => grouping.attribute),
      importSubmittalDescriptionFrom: state.extractOptions.import_submittal_description_from,
      toolExtractOptions: state.extractOptions.toolExtractOptions,
    }
  });

  const [directoryContent, setDirectoryContent] = useState<{vendorIds: number[], userIds: number[]}>({vendorIds: undefined, userIds: undefined});

  const { loading: closeoutLogDirectoryLoading, data: closeoutLogDirectoryData } = useGetCloseoutLogDirectory({
    accountId: accountProjectState.accountId,
    procoreProjectServerId: accountProjectState.procoreProjectServerId,
    closeoutLogId: state.id
  }, false, 'network-only')

  React.useEffect(()=> {
    if (closeoutLogDirectoryLoading || !closeoutLogDirectoryData) {return}
    setDirectoryContent({vendorIds: closeoutLogDirectoryData.closeoutLogDirectory.directoryVendorProcoreServerIds, userIds: closeoutLogDirectoryData.closeoutLogDirectory.directoryUserProcoreServerIds})
  }, [closeoutLogDirectoryLoading]);

  const onUpdateCloseoutLogExportOption = (params: {
      includeTableOfContents?: boolean,
      includeProjectPhotoOnCoverSheet?: boolean,
      includeCustomCoverSheet?: boolean,
      includeVendorList?: boolean,
      groupings?: string[],
      toolExtractOptions?: Record<any, any>,
    }) => {
    const {
      includeTableOfContents,
      includeVendorList,
      groupings,
      toolExtractOptions,
      includeProjectPhotoOnCoverSheet,
      includeCustomCoverSheet
    } = params;

    updateCloseoutLogExportOption({
      variables: {
          accountId: accountProjectState.accountId,
        procoreProjectServerId: accountProjectState.procoreProjectServerId,
        closeoutLogId: state.id,
        attributes: {
          includeTableOfContents: includeTableOfContents ===  undefined ? state.extractOptions.include_table_of_contents : includeTableOfContents,
          includeProjectPhotoOnCoverSheet: includeProjectPhotoOnCoverSheet === undefined ? state.extractOptions.include_project_photo_on_cover_sheet : includeProjectPhotoOnCoverSheet,
          includeCustomCoverSheet: includeCustomCoverSheet === undefined ? state.extractOptions.include_custom_cover_sheet : includeCustomCoverSheet,
          includeVendorList: includeVendorList === undefined ? state.extractOptions.include_vendor_list : includeVendorList,
          groupings: groupings === undefined ? state.extractOptions.selectedGroupings.map((grouping) => grouping.attribute) : groupings,
          importSubmittalDescriptionFrom: state.extractOptions.import_submittal_description_from,
          toolExtractOptions: toolExtractOptions === undefined ? state.extractOptions.toolExtractOptions : toolExtractOptions,
        }
      }
    })
  }

  const handleSubmittalAttachmentContentOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const attachmentOption = event.target.value as AttachmentOption;

    const newToolExtractOptions = {
      ...state.extractOptions.toolExtractOptions,
     submittals: {
        ...state.extractOptions.toolExtractOptions.submittals,
        attachment_option: attachmentOption,
        workflow_responses: [],
      },
    };

    onUpdateCloseoutLogExportOption({
      includeTableOfContents: undefined,
      includeVendorList: undefined,
      includeCustomCoverSheet: undefined,
      includeProjectPhotoOnCoverSheet: undefined,
      groupings: undefined,
      toolExtractOptions: newToolExtractOptions
    });


    dispatch({
      type: 'SET_EXTRACT_OPTIONS',
      value: {
        ...state.extractOptions,
        toolExtractOptions: newToolExtractOptions,
      }
    });
  };

  const updateAndDispatchExtractOptions = (newExtractOptions: ExtractToolOptions) => {
    onUpdateCloseoutLogExportOption({
      includeTableOfContents: undefined,
      includeVendorList: undefined,
      includeCustomCoverSheet: undefined,
      includeProjectPhotoOnCoverSheet: undefined,
      groupings: undefined,
      toolExtractOptions: newExtractOptions
    });


    dispatch({
      type: 'SET_EXTRACT_OPTIONS',
      value: {
        ...state.extractOptions,
        toolExtractOptions: newExtractOptions,
      }
    });
  }

  const onExtractToZip = () => {
    setLoading(true);

    createCloseoutLogPackage().then(({ data }) => {
      if (data.createCloseoutLogPackage.success) {
        setLoading(false);

        fetchPackages();

        dispatchPackageHistory({ type: 'SET_PACKAGES_TAB_VALUE', value: 'history' });
        dispatchPackageHistory({ type: 'SET_DISPLAY_TOAST', value: true });

        navigateTo(`/teams/${accountProjectState.accountId}/projects/${accountProjectState.procoreProjectServerId}/packages`);
      }
    }).catch((error) => {
      console.log(error);

      setLoading(false);
    });
  }

  const logRequirementTypeCounts = state.logRequirements.filter(
    (logRequirement) => {
      if (state.selectedLogRequirementIds.length === 0) { return true; }

      return state.selectedLogRequirementIds.includes(logRequirement.id);
    }
  ).reduce((acc, logRequirement) => {
    const logRequirementType = logRequirement.logRequirementType?.name;
    if (!logRequirementType || (state.logRequirementItems[logRequirement.id] || []).length === 0) return acc;

    const logRequirementTypeCount = acc[logRequirementType] || 0;

    acc[logRequirementType] = logRequirementTypeCount + 1;

    return acc;
  }, {});

  const menuOptions: SelectFilterValue[] = state.submittalResponses.map((response) => {
    return {
      id: response.id.toString(),
      key: response.id,
      value: response.name
    } as SelectFilterValue;
  });

  const submittalToolSelectedOptions = state.extractOptions.toolExtractOptions.submittals.workflow_responses.map((response) => {
    return {
      id: response.id.toString(),
      key: response.id,
      value: response.name
    } as SelectFilterValue
  });

  const drawingsSubmittalMarkupSelectedOptions = state.extractOptions.toolExtractOptions.drawings.submittals.options.workflow_responses.map((response) => {
    return {
      id: response.id.toString(),
      key: response.id,
      value: response.name
    } as SelectFilterValue
  });

  return (
    <>
      <ManageVendorListModal
        accountId={accountProjectState.accountId}
        procoreProjectServerId={accountProjectState.procoreProjectServerId}
        closeoutLogId={state.id}
        selectedResponsibleContractorServerIds={state.logRequirements.reduce((acc, logRequirement) => {
          if (logRequirement.responsibleContractor) {
            acc.push(logRequirement.responsibleContractor.procoreServerId);
          }

          return acc;
        }, [])
        }
        responsibleContractors={state.responsibleContractors}
        closeModal={() => {
          setIsManageVendorListModalOpen(false);
        }}
        isOpen={isManageVendorListModalOpen}
        setDirectoryContent={ (directory) => setDirectoryContent(directory)}
        directoryContent={directoryContent}
        />
      <ExtractCloseoutLogContainer maxWidth="lg" sx={{ overflow: 'hidden' }}>
        <Box
          display="flex"
          width={1}
          height={1}
          flexDirection="row"
          borderRadius="4px"
          marginTop="20px"
        >
          <Box
            display="flex"
            height={1}
            width={1}
            flexDirection="column"
            borderRadius="8px"
            padding="32px 36px"
            boxShadow="0px 1px 10px rgba(26, 32, 36, 0.06), 0px 2px 4px 1px rgba(26, 32, 36, 0.04)"
            gap="16px"
            sx={{
              background: 'white',
              overflowY: 'auto',
            }}
          >
            <Typography typestyle="xl">Configurations</Typography>
            <Box display="flex" flexDirection="column" width={1} gap="16px">
              <UnderlinedHeader>
                Group Items By <span style={{color: Colors.newAccentBlue}}>*</span>
              </UnderlinedHeader>
              <CloseoutGroupBy
                groupings={state.groupings}
                selectedGroupings={state.extractOptions.selectedGroupings.map(
                  (g) => g.attribute
                )}
                setSelectedGroupings={(selectedGroupings) => {
                  const newSelectingGroupings: ProcoreToolGroupings[] =
                    selectedGroupings
                      .map((groupingAttribute) => {
                        return state.groupings.find(
                          (g) => g.attribute === groupingAttribute
                        );
                      })
                      .filter((g) => g !== undefined);

                  onUpdateCloseoutLogExportOption({
                    includeTableOfContents: undefined,
                    includeVendorList: undefined,
                    includeCustomCoverSheet: undefined,
                    includeProjectPhotoOnCoverSheet: undefined,
                    groupings: newSelectingGroupings.map((g) => g.attribute),
                    toolExtractOptions: undefined
                  });

                  dispatch({
                    type: 'SET_SELECTED_GROUPINGS',
                    value: newSelectingGroupings,
                  });
                }}
              />
            </Box>
            <Box display="flex" flexDirection="column" width={1} gap="24px">
              <UnderlinedHeader>Add-Ons</UnderlinedHeader>
              <Box
                display="flex"
                flexDirection="row"
                gap="10px"
                alignItems="center"
              >
                <BlueSwitch
                  size="small"
                  checked={state.extractOptions.include_table_of_contents}
                  onChange={(event) => {
                    onUpdateCloseoutLogExportOption({
                      includeTableOfContents: event.target.checked,
                      includeVendorList: undefined,
                      includeCustomCoverSheet: undefined,
                      includeProjectPhotoOnCoverSheet: undefined,
                      groupings: undefined,
                      toolExtractOptions: undefined
                    });

                    dispatch({
                      type: 'SET_EXTRACT_OPTIONS',
                      value: {
                        ...state.extractOptions,
                        include_table_of_contents: event.target.checked,
                      },
                    });
                  }}
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <Box display="flex" flexDirection="column" gap="8px">
                  <Typography typestyle="m bold">Table of Contents</Typography>
                  <Typography typestyle="xs" color={Colors.darkishGray}>
                    A hyperlinked overview of your extracted items
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                gap="10px"
                alignItems="center"
              >
                <BlueSwitch
                  size="small"
                  checked={state.extractOptions.include_vendor_list}
                  onChange={(event) => {
                    onUpdateCloseoutLogExportOption({
                      includeTableOfContents: undefined,
                      includeVendorList: event.target.checked,
                      includeCustomCoverSheet: undefined,
                      includeProjectPhotoOnCoverSheet: undefined,
                      groupings: undefined,
                      toolExtractOptions: undefined
                    });

                    if (event.target.checked) {
                      setIsManageVendorListModalOpen(true);
                    } else {
                      // TODO: Clearn existing vendor list selection
                    }

                    dispatch({
                      type: 'SET_EXTRACT_OPTIONS',
                      value: {
                        ...state.extractOptions,
                        include_vendor_list: event.target.checked,
                      },
                    });
                  }}
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <Box display="flex" flexDirection="column" gap="8px">
                  <Typography typestyle="m bold">Directory</Typography>
                  {
                    closeoutLogDirectoryLoading
                    ? <Skeleton variant='text' width={406} height={14} />
                    : (
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"left"} gap="8px">
                          <Typography typestyle="xs" color={Colors.darkishGray}>
                            {
                            (state.extractOptions.include_vendor_list && directoryContent.vendorIds)?
                            `Information for ${directoryContent.vendorIds.length} companies ${ directoryContent.userIds?.length ? `and ${directoryContent.userIds.length} users` : ''} will be included`
                            : "A page detailing contact information for the companies/users you choose"
                            }
                          </Typography>
                          {
                            state.extractOptions.include_vendor_list && (
                              <Link style={{ color: Colors.newAccentBlue, textDecoration: 'underline', cursor: 'pointer' }} underline='hover' onClick={() => setIsManageVendorListModalOpen(true)}>
                                <Typography typestyle='xs'>Manage</Typography>
                              </Link>
                            )
                          }
                        </Box>
                      )
                  }
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                gap="10px"
                alignItems="center"
              >
                <BlueSwitch
                  size="small"
                  checked={state.extractOptions.include_project_photo_on_cover_sheet}
                  onChange={(event) => {
                    onUpdateCloseoutLogExportOption({
                      includeTableOfContents: undefined,
                      includeProjectPhotoOnCoverSheet: event.target.checked,
                      includeVendorList: undefined,
                      groupings: undefined,
                      toolExtractOptions: undefined
                    });

                    dispatch({
                      type: 'SET_EXTRACT_OPTIONS',
                      value: {
                        ...state.extractOptions,
                        include_project_photo_on_cover_sheet: event.target.checked,
                      },
                    });
                  }}
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <Box display="flex" flexDirection="column" gap="8px">
                  <Typography typestyle="m bold">Include project photo on cover sheet</Typography>
                  <Typography typestyle="xs" color={Colors.darkishGray}>
                    The project image from Procore will be embedded on the cover sheet
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" width={1} gap="24px">
              <UnderlinedHeader>Tool PDF Options</UnderlinedHeader>
            </Box>
            <Box display="flex" width="100%" gap="20px">
              <TabContext value={currentToolTab}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="14px"
                  sx={{
                    height: '100%',
                  }}
                >
                  <Typography color={Colors.darkishGray} typestyle="xs bold">
                    Tools
                  </Typography>
                  <StyledVerticalTabs
                    orientation="vertical"
                    value={currentToolTab}
                    onChange={(_evt, value) => setCurrentToolTab(value)}
                  >
                    {state.procoreTools.filter(t => {
                      return SUPPORTED_PDF_OPTION_TOOL_ENGINES.includes(t.engineName)
                    }).map((tool, i)=>{
                    return (
                      <StyledVerticalTab
                        key={`vertical-tab-${i}`}
                        value={tool.engineName}
                        label={tool.title}
                      />
                    )
                  })}
                  </StyledVerticalTabs>
                </Box>
                <Box
                  sx={{
                    borderRadius: '6px',
                    border: `1px solid ${Colors.mediumLightGray}`,
                    height: '400px',
                    overflowY: 'auto',
                    flexGrow: 1,
                  }}
                >
                  <CustomTabPanel
                      current={currentToolTab === TOOL_ENGINE_NAMES.SUBMITTALS}
                      value={TOOL_ENGINE_NAMES.SUBMITTALS}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      width={1}
                      height={1}
                    >
                      <Typography typestyle="m bold">
                        Attachments Content
                      </Typography>
                      {
                        attachmentContentOptions.map(option => {
                          if (option.value === 'last_workflow_response') {
                            return (
                              <>
                                <FormControlLabel
                                  key={option.value}
                                  checked={state.extractOptions.toolExtractOptions.submittals.attachment_option === option.value}
                                  onChange={handleSubmittalAttachmentContentOptionChange}
                                  color="primary"
                                  control={<ExtractsRadio disableFocusRipple={true} disableRipple={true} />}
                                  value={option.value}
                                  label={<Typography typestyle="s">{option.label}</Typography>}
                                />
                                {
                                  state.extractOptions.toolExtractOptions.submittals.attachment_option === 'last_workflow_response' &&
                                  (
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      justifyContent="flex-start"
                                      alignItems="flex-start"
                                      flexWrap="wrap"
                                      borderLeft={`4px solid ${Colors.mediumLightGray}`}
                                      paddingLeft={'20px'}
                                      margin={1}
                                      marginLeft={'30px'}>
                                      <FilterAutocomplete
                                        disabled={state.loadingLogData}
                                        key={`select-single-submittal-response-status`}
                                        filterLabel={'Response Status'}
                                        open={false}
                                        onOpen={() => {}}
                                        onClose={() => {}}
                                        onChange={function (values): void {
                                          const keys = values.map((value) => value.key);

                                          updateAndDispatchExtractOptions(
                                            {
                                              ...state.extractOptions.toolExtractOptions,
                                             submittals: {
                                                ...state.extractOptions.toolExtractOptions.submittals,
                                                attachment_option: 'last_workflow_response',
                                                workflow_responses: state.submittalResponses.filter((response) => {
                                                    return keys.includes(response.id);
                                                }),
                                              } as SubmittalExtractOptions,
                                            }
                                          );
                                        }}
                                        options={menuOptions}
                                        selectedOptions={submittalToolSelectedOptions}
                                      />
                                    </Box>
                                  )
                                }
                              </>
                            );
                          } else {
                            return (
                              <FormControlLabel
                                key={option.value}
                                checked={state.extractOptions.toolExtractOptions.submittals.attachment_option === option.value}
                                onChange={handleSubmittalAttachmentContentOptionChange}
                                color="primary"
                                control={<ExtractsRadio disableFocusRipple={true} disableRipple={true} />}
                                value={option.value}
                                label={<Typography typestyle="s">{option.label}</Typography>}
                              />
                            );
                          }
                        })
                      }
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={TOOL_ENGINE_NAMES.RFIS} current={currentToolTab === TOOL_ENGINE_NAMES.RFIS}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      width={1}
                      height={1}
                      gap="16px"
                    >
                      <Typography typestyle="m bold">
                        Attachments Content
                      </Typography>
                      <ExportOptionToggle
                        label={'Include official RFI responses only'}
                        checked={
                          state.extractOptions.toolExtractOptions.rfis
                            .response_option==='official_only'
                        }
                        onChange={(event) => {
                          updateAndDispatchExtractOptions({
                            ...state.extractOptions.toolExtractOptions,
                            rfis: {
                              response_option: event.target.checked ? 'official_only' : 'all',
                            } as RfiExtractOptions,
                          });
                        }}
                      />
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={TOOL_ENGINE_NAMES.OBSERVATIONS} current={currentToolTab === TOOL_ENGINE_NAMES.OBSERVATIONS}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      width={1}
                      height={1}
                      gap="16px"
                    >
                      <Typography typestyle="m bold">
                        Attachments Content
                      </Typography>
                      <ExportOptionToggle
                        label={'Include thumbnails and full sized images.'}
                        checked={
                          state.extractOptions.toolExtractOptions.observations
                            .image_attachment_option === 'thumbnails_and_full_size'
                        }
                        onChange={(event) => {
                          updateAndDispatchExtractOptions({
                            ...state.extractOptions.toolExtractOptions,
                            observations: {
                              ...state.extractOptions.toolExtractOptions.observations,
                              image_attachment_option: event.target.checked ? 'thumbnails_and_full_size' : 'thumbnails_only',
                            } as ObservationExtractOptions,
                          });
                        }}
                      />
                      <Typography typestyle="m bold">
                        Drawings
                      </Typography>
                      <ExportOptionToggle
                        label={'Include markup.'}
                        checked={state.extractOptions.toolExtractOptions.observations.include_linked_drawing_markup}
                        onChange={(event) => {
                          updateAndDispatchExtractOptions({
                            ...state.extractOptions.toolExtractOptions,
                            observations: {
                              ...state.extractOptions.toolExtractOptions.observations,
                              include_linked_drawing_markup: event.target.checked,
                            } as ObservationExtractOptions,
                          });
                        }}
                      />
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={TOOL_ENGINE_NAMES.INSPECTIONS} current={currentToolTab === TOOL_ENGINE_NAMES.INSPECTIONS}>
                  <Box
                      display="flex"
                      flexDirection="column"
                      width={1}
                      height={1}
                      gap="16px"
                    >
                      <ExportOptionToggle
                        label={'Show N/A'}
                        checked={state.extractOptions.toolExtractOptions.inspections.show_not_applicable_items}
                        onChange={(event) => {
                          updateAndDispatchExtractOptions({
                            ...state.extractOptions.toolExtractOptions,
                            inspections: {
                              ...state.extractOptions.toolExtractOptions.inspections,
                              show_not_applicable_items: event.target.checked,
                              collapse_not_applicable_sections: !event.target.checked ? false : state.extractOptions.toolExtractOptions.inspections.collapse_not_applicable_sections,
                            } as InspectionExtractOptions,
                          });
                        }}
                      />

                      {
                        state.extractOptions.toolExtractOptions.inspections.show_not_applicable_items && (
                          <ExportOptionToggle
                            sx={{
                              marginLeft: '12px',
                            }}
                            labelFontSize='12px'
                            label={'Collapse N/A Sections'}
                            checked={state.extractOptions.toolExtractOptions.inspections.collapse_not_applicable_sections}
                            onChange={(event) => {
                              updateAndDispatchExtractOptions({
                                ...state.extractOptions.toolExtractOptions,
                                inspections: {
                                  ...state.extractOptions.toolExtractOptions.inspections,
                                  collapse_not_applicable_sections: event.target.checked,
                                } as InspectionExtractOptions,
                              });
                            }}
                          />
                        )
                      }

                      <ExportOptionToggle
                        label={'Include thumbnails and full sized images.'}
                        checked={
                          state.extractOptions.toolExtractOptions.inspections
                            .image_attachment_option === 'thumbnails_and_full_size'
                        }
                        onChange={(event) => {
                          updateAndDispatchExtractOptions({
                            ...state.extractOptions.toolExtractOptions,
                            inspections: {
                              ...state.extractOptions.toolExtractOptions.inspections,
                              image_attachment_option: event.target.checked ? 'thumbnails_and_full_size' : 'thumbnails_only',
                            } as InspectionExtractOptions,
                          });
                        }}
                      />

                      <ExportOptionToggle
                        label={'Include linked Drawings.'}
                        checked={state.extractOptions.toolExtractOptions.inspections.include_linked_drawings}
                        onChange={(event) => {
                          updateAndDispatchExtractOptions({
                            ...state.extractOptions.toolExtractOptions,
                            inspections: {
                              ...state.extractOptions.toolExtractOptions.inspections,
                              include_linked_drawings: event.target.checked,
                              include_linked_drawing_markup: !event.target.checked ? false : state.extractOptions.toolExtractOptions.inspections.include_linked_drawing_markup,
                            } as InspectionExtractOptions,
                          });
                        }}
                      />

                      {
                        state.extractOptions.toolExtractOptions.inspections.include_linked_drawings && (
                          <ExportOptionToggle
                            label={'Include markup.'}
                            sx={{
                              marginLeft: '12px',
                            }}
                            labelFontSize='12px'
                            checked={state.extractOptions.toolExtractOptions.inspections.include_linked_drawing_markup}
                            onChange={(event) => {
                              updateAndDispatchExtractOptions({
                                ...state.extractOptions.toolExtractOptions,
                                inspections: {
                                  ...state.extractOptions.toolExtractOptions.inspections,
                                  include_linked_drawing_markup: event.target.checked,
                                } as InspectionExtractOptions,
                              });
                            }}
                          />
                        )
                      }

                      <ExportOptionToggle
                        label={'Include linked Observations.'}
                        checked={state.extractOptions.toolExtractOptions.inspections.include_observations}
                        onChange={(event) => {
                          updateAndDispatchExtractOptions({
                            ...state.extractOptions.toolExtractOptions,
                            inspections: {
                              ...state.extractOptions.toolExtractOptions.inspections,
                              include_observations: event.target.checked
                            } as InspectionExtractOptions,
                          });
                        }}
                      />
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={TOOL_ENGINE_NAMES.DRAWINGS} current={currentToolTab === TOOL_ENGINE_NAMES.DRAWINGS}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      width={1}
                      height={1}
                      gap="16px"
                    >
                      <Typography typestyle="m bold">
                        Public Markups to Include
                      </Typography>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ width: 'fit-content' }}
                        onClick={()=>{
                          Object.values(
                            state.extractOptions.toolExtractOptions.drawings
                          ).includes(true)
                            ? updateAndDispatchExtractOptions({
                                ...state.extractOptions.toolExtractOptions,
                                drawings: {
                                  ...state.extractOptions.toolExtractOptions.drawings,
                                  include_rfis: false,
                                  include_photos: false,
                                  include_sketches: false,
                                  include_submittals: false,
                                  include_sheet_links: false,
                                  include_other_markup: false,
                                },
                              },
                              )
                            : updateAndDispatchExtractOptions({
                                  ...state.extractOptions.toolExtractOptions,
                                  drawings: {
                                    ...state.extractOptions.toolExtractOptions.drawings,
                                    include_rfis: true,
                                    include_photos: true,
                                    include_sketches: true,
                                    include_submittals: true,
                                    include_sheet_links: true,
                                    include_other_markup: true,
                                  },
                                },
                              );

                        }}
                      >
                        {Object.values(
                          state.extractOptions.toolExtractOptions.drawings
                        ).includes(true)
                          ? 'Deselect All'
                          : 'Select All'}
                      </Button>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems={'center'}
                      >
                        <ExtractsCheckbox
                          disableRipple
                          sx={{
                            padding: 0,
                          }}
                          checked={
                            state.extractOptions.toolExtractOptions.drawings.include_photos
                          }
                          onChange={(event) => {
                            updateAndDispatchExtractOptions({
                                ...state.extractOptions.toolExtractOptions,
                                drawings: {
                                  ...state.extractOptions.toolExtractOptions.drawings,
                                  include_photos: event.target.checked,
                                },
                              },
                            );
                          }}
                          style={{ marginRight: 8 }}
                        />
                        <Typography>Photos</Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={'12px'}
                      >
                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                          <ExtractsCheckbox
                            disableRipple
                            sx={{
                              padding: 0,
                            }}
                            checked={
                              state.extractOptions.toolExtractOptions.drawings.include_rfis
                            }
                            onChange={(event) => {
                              updateAndDispatchExtractOptions({
                                  ...state.extractOptions.toolExtractOptions,
                                  drawings: {
                                    ...state.extractOptions.toolExtractOptions.drawings,
                                    include_rfis: event.target.checked,
                                  },
                                },
                              );
                            }}
                            style={{ marginRight: 8 }}
                          />
                          <Typography>RFIs</Typography>
                        </Box>

                        {
                          state.extractOptions.toolExtractOptions.drawings.include_rfis && (
                            <Box display={'flex'} flexDirection={'column'} sx={{
                              marginLeft: '20px'
                            }}>
                              <ExportOptionToggle
                                label={'Include official RFI responses only'}
                                checked={state.extractOptions.toolExtractOptions.drawings.rfis.options.response_option == 'official_only'}
                                onChange={(event) => {
                                  updateAndDispatchExtractOptions(
                                    {
                                      ...state.extractOptions.toolExtractOptions,
                                      drawings: {
                                        ...state.extractOptions.toolExtractOptions.drawings,
                                        rfis: {
                                          options: {
                                            ...state.extractOptions.toolExtractOptions.drawings.rfis.options,
                                            response_option: event.target.checked ? 'official_only' : 'all'
                                          }
                                        }
                                      },
                                    },
                                  );
                                }} />
                            </Box>
                          )
                        }
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems={'center'}
                      >
                        <ExtractsCheckbox
                          disableRipple
                          sx={{
                            padding: 0,
                          }}
                          checked={
                            state.extractOptions.toolExtractOptions.drawings.include_sheet_links
                          }
                          onChange={(event) => {
                            updateAndDispatchExtractOptions({
                                ...state.extractOptions.toolExtractOptions,
                                drawings: {
                                  ...state.extractOptions.toolExtractOptions.drawings,
                                  include_sheet_links: event.target.checked,
                                },
                              },
                            );
                          }}
                          style={{ marginRight: 8 }}
                        />
                        <Typography>Sheet Links</Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems={'center'}
                      >
                        <ExtractsCheckbox
                          disableRipple
                          sx={{
                            padding: 0,
                          }}
                          checked={
                            state.extractOptions.toolExtractOptions.drawings.include_sketches
                          }
                          onChange={(event) => {
                            updateAndDispatchExtractOptions({
                                ...state.extractOptions.toolExtractOptions,
                                drawings: {
                                  ...state.extractOptions.toolExtractOptions.drawings,
                                  include_sketches: event.target.checked
                                },
                              },
                            );
                          }}
                          style={{ marginRight: 8 }}
                        />
                        <Typography>Sketches</Typography>
                      </Box>
                      <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems={'center'}
                        >
                          <ExtractsCheckbox
                            disableRipple
                            sx={{
                              padding: 0,
                            }}
                            checked={
                              state.extractOptions.toolExtractOptions.drawings.include_submittals
                            }
                            onChange={(event) => {
                              updateAndDispatchExtractOptions({
                                ...state.extractOptions.toolExtractOptions,
                                drawings: {
                                  ...state.extractOptions.toolExtractOptions.drawings,
                                  include_submittals: event.target.checked,
                                },
                              });
                            }}
                            style={{ marginRight: 8 }}
                          />
                          <Typography>Submittals</Typography>
                        </Box>

                        {
                          state.extractOptions.toolExtractOptions.drawings.include_submittals && (
                            <Box display={'flex'} flexDirection={'column'} sx={{
                              marginLeft: '20px'
                            }}>
                              {
                                attachmentContentOptions.map(option => {
                                  if (option.value === 'last_workflow_response') {
                                    return (
                                      <>
                                        <FormControlLabel
                                          key={option.value}
                                          sx={{
                                            "& .MuiFormControlLabel-label": {
                                              fontSize: "14px",
                                            },
                                          }}
                                          checked={state.extractOptions.toolExtractOptions.drawings.submittals.options.attachment_option === option.value}
                                          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                            updateAndDispatchExtractOptions({
                                              ...state.extractOptions.toolExtractOptions,
                                              drawings: {
                                                ...state.extractOptions.toolExtractOptions.drawings,
                                                submittals: {
                                                  options: {
                                                    ...state.extractOptions.toolExtractOptions.drawings.submittals.options,
                                                    attachment_option: evt.target.value as AttachmentOption,
                                                    workflow_responses: []
                                                  }
                                                }
                                              },
                                            });
                                          }}
                                          color="primary"
                                          control={<ExtractsRadio disableFocusRipple={true} disableRipple={true} />}
                                          value={option.value}
                                          label={option.label}
                                        />
                                        {
                                          state.extractOptions.toolExtractOptions.drawings.submittals.options.attachment_option === 'last_workflow_response' &&
                                          (
                                            <Box
                                              display="flex"
                                              flexDirection="row"
                                              justifyContent="flex-start"
                                              alignItems="flex-start"
                                              flexWrap="wrap"
                                              borderLeft={`4px solid ${Colors.mediumLightGray}`}
                                              paddingLeft={'20px'}
                                              margin={1}
                                              marginLeft={'30px'}>
                                              <FilterAutocomplete
                                                disabled={state.loadingLogData}
                                                key={`select-single-submittal-response-status`}
                                                filterLabel={'Response Status'}
                                                open={false}
                                                onOpen={() => {}}
                                                onClose={() => {}}
                                                onChange={function (values): void {
                                                  const keys = values.map((value) => value.key);

                                                  updateAndDispatchExtractOptions({
                                                    ...state.extractOptions.toolExtractOptions,
                                                    drawings: {
                                                      ...state.extractOptions.toolExtractOptions.drawings,
                                                      submittals: {
                                                        options: {
                                                          ...state.extractOptions.toolExtractOptions.drawings.submittals.options,
                                                          attachment_option: 'last_workflow_response',
                                                          workflow_responses: state.submittalResponses.filter((response) => {
                                                              return keys.includes(response.id)
                                                          })
                                                        }
                                                      }
                                                    },
                                                  });
                                                }}
                                                options={menuOptions}
                                                selectedOptions={drawingsSubmittalMarkupSelectedOptions}
                                              />
                                            </Box>
                                          )
                                        }
                                      </>
                                    );
                                  } else {
                                    return (
                                      <FormControlLabel
                                        key={option.value}
                                        sx={{
                                          "& .MuiFormControlLabel-label": {
                                            fontSize: "14px",
                                          },
                                        }}
                                        checked={state.extractOptions.toolExtractOptions.drawings.submittals.options.attachment_option === option.value}
                                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                          updateAndDispatchExtractOptions({
                                            ...state.extractOptions.toolExtractOptions,
                                            drawings: {
                                              ...state.extractOptions.toolExtractOptions.drawings,
                                              submittals: {
                                                options: {
                                                  ...state.extractOptions.toolExtractOptions.drawings.submittals.options,
                                                  attachment_option: evt.target.value as AttachmentOption,
                                                  workflow_responses: []
                                                }
                                              }
                                            },
                                          });
                                        }}
                                        color="primary"
                                        control={<ExtractsRadio disableFocusRipple={true} disableRipple={true} />}
                                        value={option.value}
                                        label={option.label}
                                      />
                                    );
                                  }
                                })
                              }
                            </Box>
                          )
                        }
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems={'center'}
                      >
                        <ExtractsCheckbox
                          disableRipple
                          sx={{
                            padding: 0,
                          }}
                          checked={
                            state.extractOptions.toolExtractOptions.drawings.include_other_markup
                          }
                          onChange={(event) => {
                            updateAndDispatchExtractOptions({
                              ...state.extractOptions.toolExtractOptions,
                              drawings: {
                                ...state.extractOptions.toolExtractOptions.drawings,
                                include_other_markup: event.target.checked,
                              },
                            });
                          }}
                          style={{ marginRight: 8 }}
                        />
                        <Typography>Other markups</Typography>
                      </Box>
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={TOOL_ENGINE_NAMES.PUNCH_LIST} current={currentToolTab === TOOL_ENGINE_NAMES.PUNCH_LIST}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      width={1}
                      height={1}
                      gap="16px"
                    >
                      <Typography typestyle="m bold">
                        Image Attachments
                      </Typography>
                      <ExportOptionToggle
                        label={'Include thumbnails and full sized images.'}
                        checked={
                          state.extractOptions.toolExtractOptions.punch_items
                            .image_attachment_option === 'thumbnails_and_full_size'
                        }
                        onChange={(event) => {
                          updateAndDispatchExtractOptions({
                            ...state.extractOptions.toolExtractOptions,
                            punch_items: {
                              ...state.extractOptions.toolExtractOptions.punch_items,
                              image_attachment_option: event.target.checked ? 'thumbnails_and_full_size' : 'thumbnails_only',
                            } as PunchItemExtractOptions,
                          });
                        }}
                      />
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={TOOL_ENGINE_NAMES.ACTION_PLANS} current={currentToolTab === TOOL_ENGINE_NAMES.ACTION_PLANS}>
                    <Box display="flex" flexDirection={"column"} width={1} gap="32px">
                      <Box
                        display="flex"
                        flexDirection="column"
                        width={1}
                        gap="16px"
                      >
                        <Typography typestyle="m bold">
                          General
                        </Typography>
                        <ExportOptionToggle
                          label={'Include References.'}
                          checked={state.extractOptions.toolExtractOptions.action_plans.include_references}
                          onChange={(event) => {
                            updateAndDispatchExtractOptions({
                              ...state.extractOptions.toolExtractOptions,
                              action_plans: {
                                ...state.extractOptions.toolExtractOptions.action_plans,
                                include_references: event.target.checked,
                              } as ActionPlanExtractOptions,
                            });
                          }}
                        />
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        width={1}
                        gap="16px"
                      >
                        <Typography typestyle="m bold">
                          Inspections
                        </Typography>
                        <ExportOptionToggle
                          label={'Include full-sized image files as well as thumbnails'}
                          checked={state.extractOptions.toolExtractOptions.action_plans.inspections.options.image_attachment_option === 'thumbnails_and_full_size'}
                          onChange={(event) => {
                            updateAndDispatchExtractOptions({
                              ...state.extractOptions.toolExtractOptions,
                              action_plans: {
                                ...state.extractOptions.toolExtractOptions.action_plans,
                                inspections: {
                                  options: {
                                    ...state.extractOptions.toolExtractOptions.action_plans.inspections.options,
                                    image_attachment_option: event.target.checked ? 'thumbnails_and_full_size' : 'thumbnails_only',
                                  }
                                }
                              } as ActionPlanExtractOptions,
                            });
                          }}
                        />
                        <ExportOptionToggle
                          label={'Include markup on Drawings'}
                          checked={state.extractOptions.toolExtractOptions.action_plans.inspections.options.include_linked_drawing_markup}
                          onChange={(event) => {
                            updateAndDispatchExtractOptions({
                              ...state.extractOptions.toolExtractOptions,
                              action_plans: {
                                ...state.extractOptions.toolExtractOptions.action_plans,
                                inspections: {
                                  options: {
                                    ...state.extractOptions.toolExtractOptions.action_plans.inspections.options,
                                    include_linked_drawing_markup: event.target.checked,
                                    include_other_markup: !event.target.checked ? false : state.extractOptions.toolExtractOptions.action_plans.inspections.options.include_other_markup,
                                  }
                                }
                              } as ActionPlanExtractOptions,
                            });
                          }}
                        />
                        {
                          state.extractOptions.toolExtractOptions.action_plans.inspections.options.include_linked_drawing_markup && (
                            <ExportOptionToggle
                              sx={{
                                paddingLeft: '12px'
                              }}
                              label={'Include other markup'}
                              checked={state.extractOptions.toolExtractOptions.action_plans.inspections.options.include_other_markup}
                              onChange={(event) => {
                                updateAndDispatchExtractOptions({
                                  ...state.extractOptions.toolExtractOptions,
                                  action_plans: {
                                    ...state.extractOptions.toolExtractOptions.action_plans,
                                    inspections: {
                                      options: {
                                        ...state.extractOptions.toolExtractOptions.action_plans.inspections.options,
                                        include_other_markup: event.target.checked,
                                      }
                                    }
                                  } as ActionPlanExtractOptions,
                                });
                              }}
                            />
                          )
                        }
                        <ExportOptionToggle
                          label={'Include linked Observations'}
                          checked={state.extractOptions.toolExtractOptions.action_plans.inspections.options.include_observations}
                          onChange={(event) => {
                            updateAndDispatchExtractOptions({
                              ...state.extractOptions.toolExtractOptions,
                              action_plans: {
                                ...state.extractOptions.toolExtractOptions.action_plans,
                                inspections: {
                                  options: {
                                    ...state.extractOptions.toolExtractOptions.action_plans.inspections.options,
                                    include_observations: event.target.checked,
                                  }
                                }
                              } as ActionPlanExtractOptions,
                            });
                          }}
                        />
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        width={1}
                        gap="16px"
                      >
                        <Typography typestyle="m bold">
                          Submittals
                        </Typography>
                        <ExportOptionToggle
                          label={'Include distributed attachments only'}
                          checked={state.extractOptions.toolExtractOptions.action_plans.submittals.options.attachment_option === 'distributed'}
                          onChange={(event) => {
                            updateAndDispatchExtractOptions({
                              ...state.extractOptions.toolExtractOptions,
                              action_plans: {
                                ...state.extractOptions.toolExtractOptions.action_plans,
                                submittals: {
                                  options: {
                                    ...state.extractOptions.toolExtractOptions.action_plans.submittals.options,
                                    attachment_option: event.target.checked ? 'distributed' : 'all',
                                  }
                                }
                              } as ActionPlanExtractOptions,
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={TOOL_ENGINE_NAMES.FORMS} current={currentToolTab === TOOL_ENGINE_NAMES.FORMS}>
                    <Box display="flex" flexDirection={"column"} width={1} gap="32px">
                      <Box
                        display="flex"
                        flexDirection="column"
                        width={1}
                        gap="16px"
                      >
                        <Typography typestyle="m bold">
                          General
                        </Typography>
                        <ExportOptionToggle
                          label={'Include the Form’s attachments in its cover sheet PDF'}
                          checked={state.extractOptions.toolExtractOptions.forms.single_pdf}
                          onChange={(event) => {
                            updateAndDispatchExtractOptions({
                              ...state.extractOptions.toolExtractOptions,
                              forms: {
                                ...state.extractOptions.toolExtractOptions.forms,
                                single_pdf: event.target.checked,
                              } as FormExtractOptions,
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  </CustomTabPanel>
                </Box>
              </TabContext>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap="8px" width={'300px'}>
            <Box
              sx={{
                height: 'fit-content',
                marginLeft: '20px',
                background: 'white',
                width: '300px',
                borderRadius: '8px',
                padding: '20px',
                overflow: 'hidden',
                boxShadow:
                  '0px 1px 10px rgba(26, 32, 36, 0.06), 0px 2px 4px 1px rgba(26, 32, 36, 0.04)',
              }}
            >
              <Box
                display={'flex'}
                flexDirection="column"
                height={1}
                width={1}
                gap="16px"
              >
                <Typography typestyle="l">Summary</Typography>
                <List
                  style={{
                    maxHeight: '200px',
                    overflowY: 'auto',
                    padding: 0,
                  }}
                >
                  {Object.keys(logRequirementTypeCounts)
                    .sort((a, b) =>
                      a.localeCompare(b, undefined, { numeric: true })
                    )
                    .map((requirementType, index) => {
                      const count = logRequirementTypeCounts[requirementType] || 0;

                      return (
                        <CheckoutToolTabsListItem
                          key={`checkout-tool-tabs-${index}`}
                          disableGutters={false}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            width={1}
                          >
                            <Typography
                              style={{
                                fontSize: '13px',
                                fontWeight: 400,
                                color: Colors.darkerGray,
                              }}
                            >
                              {requirementType}
                            </Typography>
                            <Box
                              display="inline-block"
                              style={{
                                fontSize: '10px',
                                color: Colors.darkerGray,
                                backgroundColor: Colors.lightishGray,
                                borderRadius: '20px',
                                padding: '4px 10px',
                                textAlign: 'center',
                                fontWeight: 500,
                                margin: 0,
                              }}
                            >
                              {count}
                            </Box>
                          </Box>
                        </CheckoutToolTabsListItem>
                      );
                    })}
                </List>
                <GrayDivider />
                <Box display={'flex'} flexDirection={'column'} gap={'8px'}>
                  <Button
                    buttonborderstyle="pill"
                    size="medium"
                    fullWidth={true}
                    variant="contained"
                    disabled={
                      loading || state.extractOptions.selectedGroupings.length === 0 || Object.values(state.logRequirementItems).flat().length === 0
                    }
                    onClick={onExtractToZip}
                  >
                    {'Extract .ZIP File'}
                  </Button>
                  <Button
                    buttonborderstyle="pill"
                    size="medium"
                    fullWidth={true}
                    variant="outlined"
                    disabled={loading}
                    onClick={() => {
                      navigateTo(
                        `/teams/${accountProjectState.accountId}/projects/${accountProjectState.procoreProjectServerId}/closeout_logs/${state.id}`
                      );
                    }}
                  >
                    {'Cancel'}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Typography typestyle='xs' sx={{ width: '100%', padding: '10px', color: Colors.mediumDarkerGray, marginLeft: '20px', textAlign: 'center' }}>{'Note: Extracts Pro can only extract linked records that you have access to in Procore. If you do not have access to a linked record, it will not be included in the extract.'}</Typography>
          </Box>
        </Box>
      </ExtractCloseoutLogContainer>
    </>
  );
};

export default ExtractCloseoutLog;
