import React from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  InputBase,
  ListItemButton,
  styled,
  DialogProps,
  DialogTitleProps,
  alpha,
} from "@mui/material";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import idx from "idx";
import { Colors } from "../../styles";
import {
  CheckedCheckBox,
  ManualSelectCheckbox,
  UncheckedCheckBox,
  IndeterminateCheckBox,
  SearchCloseIconButton,
  SearchCloseIcon
} from "./styles";
import { Account, Project } from "../../types";
import SearchIcon from "@mui/icons-material/Search";
import { useGetAccountProjects } from "../../graphql/queries/GetAccountProjects";
import { useBuyTrialProjectLicense } from "../../graphql/mutations/BuyFreeTrialProjectLicense";
import { Button } from "../shared/Button";
import { searchAndSortProjects } from "../../utils/utils";

export const FeedbackDialog = styled(Dialog)<DialogProps>(() => {
  return {
    '& .MuiDialog-paper': {
      width: "705px",
      height: "675px",
    }
  }
});

export const ModalDialogTitle = styled(DialogTitle)<DialogTitleProps>(() => {
  return {
    padding: "16px 32px 0px 32px",
  };
});

const AddProjectFreeTrialLicenseModal = (props: {
  account: Account;
  handleOnClose: (refresh: boolean) => void;
  open: boolean;
}): JSX.Element => {
  const { account, handleOnClose, open } = props;

  const { loading, data } = useGetAccountProjects(account.id, false);
  const [
    buyTrialProjectLicense,
    {
      loading: loadingBuyTrialProjectLicense,
      data: buyTrialProjectLicenseData,
    },
  ] = useBuyTrialProjectLicense({
    accountId: account.id,
    procoreProjectServerId: -1,
  });

  const [projectsToLicense, setProjectsToLicense] = React.useState<
    Record<number, number>
  >({});

  const [searchValue, setSearchValue] = React.useState<string>("");

  const handleProjectClick = (procoreServerId: number) => {
    const add = projectsToLicense[procoreServerId] || 0;

    setProjectsToLicense({
      ...projectsToLicense,
      [procoreServerId]: add === 0 ? 1 : 0,
    });
  };

  function renderRow(props: ListChildComponentProps<Project[]>) {
    const { index, style, data } = props;

    const project = data[index] as Project;

    const checked = (projectsToLicense[project.procoreServerId] || 0) > 0;
    return (
      <div style={style}>
        <ListItemButton
          sx={{
            paddingLeft: '16px'
          }}
          key={`user-list-item-${project.procoreServerId}`}
          onClick={() => handleProjectClick(project.procoreServerId)}
        >
          <ManualSelectCheckbox
            disableFocusRipple
            disableRipple
            disableTouchRipple
            color="primary"
            indeterminateIcon={<IndeterminateCheckBox />}
            icon={<UncheckedCheckBox />}
            checkedIcon={<CheckedCheckBox />}
            checked={checked}
          />
          <Box display={"flex"} flexDirection={"column"} width={1}>
            <Typography style={{ fontSize: "14px", fontWeight: 500 }}>
              {project.displayName || project.name}
            </Typography>
            <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
              {project.procoreCompanyName}
            </Typography>
          </Box>
        </ListItemButton>
        <Divider
          key={`grouping-list-item-divider-${project.procoreServerId}`}
        />
      </div>
    );
  }

  const itemData: Project[] = (idx(data, (data) => data.procoreProjects) || [])

  const filteredItemData = searchAndSortProjects(itemData, searchValue, {});

  const licensesToPurchaseCount = Object.values(projectsToLicense).filter(
    (v) => v > 0
  ).length;

  return (
    <FeedbackDialog
      maxWidth={"sm"}
      aria-labelledby="help-dialog-title"
      open={open}
    >
      <ModalDialogTitle>
        {"Add Projects"}
      </ModalDialogTitle>
      <DialogContent
        dividers={false}
        style={{
          marginBottom: "20px",
          height: "475px",
          padding: "16px 32px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading ? (
          <Box
            display={"flex"}
            flex={"1 0"}
            borderRadius={"4px"}
            sx={{ border: `1px solid ${Colors.mediumLightGray}` }}
            flexDirection="row"
            alignItems={"center"}
            justifyContent="center"
          >
            <CircularProgress size={12} style={{ margin: "5px" }} />
            <Typography>Loading projects...</Typography>
          </Box>
        ) : (
          <>
            <Typography
              style={{
                fontSize: "13px",
                fontWeight: 400,
                color: Colors.darkishGray,
                marginBottom: "16px",
              }}
            >
              Select the projects you want to add a license to.
            </Typography>
            <Box
              display="flex"
              flexDirection={"row"}
              alignContent="center"
              marginBottom={"10px"}
            >
              <Box sx={{
                position: 'relative',
                minHeight: '39px',
                maxHeight: 'unset',
                flex: '1 0',
                borderRadius: '6px',
                paddingRight: '2px',
                border: '1px solid #D5DFE6',
                backgroundColor: alpha('#FFFFFF', 0.15),
                '&:hover': {
                  backgroundColor: alpha('#FFFFFF', 0.25),
                },
                marginLeft: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: '12px'
              }}>
                <SearchIcon />
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  fullWidth={true}
                  placeholder="Search..."
                  autoFocus={true}
                  value={searchValue}
                  endAdornment={
                    (((searchValue || '').trim())) ? (
                      <SearchCloseIconButton
                          size={'small'}
                          onClick={() => {
                            setSearchValue('');
                          }}
                          style={{ visibility: ((searchValue || '').trim()) ? 'visible' : 'hidden' }}>
                        <SearchCloseIcon />
                      </SearchCloseIconButton>
                    ) : null
                  }
                  onChange={(evt) => {
                    setSearchValue(evt.target.value)
                  }}
                  inputProps={{ 'aria-label': 'search users' }}
                />
              </Box>
            </Box>
            <div
              style={{
                display: "flex",
                height: "425px",
                width: "100%",
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: "6px",
                borderColor: Colors.mediumLightGray,
              }}
            >
              <FixedSizeList
                height={425}
                width={"100%"}
                itemData={filteredItemData}
                itemCount={filteredItemData.length}
                itemSize={55}
              >
                {renderRow}
              </FixedSizeList>
            </div>
          </>
        )}
        <Box
            display={"flex"}
            flexDirection="row"
            alignItems={"center"}
            justifyContent="flex-end"
            paddingTop={"10px"}
          >
            <Button
              disabled={loading || loadingBuyTrialProjectLicense}
              onClick={() => handleOnClose(false)}
              variant="outlined"
              buttonborderstyle="pill"
              disableElevation={true}
              size="medium"
              style={{
                minHeight: "39px",
                marginRight: "12px",
              }}
            >
              {"Cancel"}
            </Button>
            <Button
              size="medium"
              variant="contained"
              buttonborderstyle="pill"
              disableElevation={true}
              disabled={
                loading ||
                loadingBuyTrialProjectLicense ||
                licensesToPurchaseCount <= 0 ||
                licensesToPurchaseCount > 1
              }
              title="Start Free Trial"
              style={{
                minHeight: "39px",
              }}
              onClick={() => {
                buyTrialProjectLicense({
                  variables: {
                    accountId: account.id,
                    procoreProjectServerId: Number.parseInt(
                      Object.entries(projectsToLicense).filter(
                        ([key, value]) => value > 0
                      )[0]?.[0]
                    ),
                  },
                }).then(() => {
                  handleOnClose(true);
                });
              }}
            >
              {loadingBuyTrialProjectLicense ? (
                <Box
                  display={"flex"}
                  flexDirection="row"
                  alignItems={"center"}
                  justifyContent="center"
                >
                  <CircularProgress size={12} style={{ margin: "5px" }} />
                  <Typography style={{ fontWeight: 700, fontSize: "12px" }}>
                    Start Free Trial
                  </Typography>
                </Box>
              ) : (
                "Start Free Trial"
              )}
            </Button>
          </Box>
      </DialogContent>
    </FeedbackDialog>
  );
};

export default AddProjectFreeTrialLicenseModal;
