import React from 'react';


import { EmailNotification, EmailNotificationActivity } from '../types';
import { Box, List, SvgIcon } from '@mui/material';
import { Typography } from './shared/Typography';
import { Colors } from '../styles';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import { format } from 'date-fns';

interface IFileRequestEmailNotificationsProps {
emailNotifications: EmailNotification[];
detailDateFormat: string;
}

const activitySortOrder: Record<string, number> = {
  'Click': 1,
  'Open': 2,
  'Delivery': 3,
  'HardBounce': 4,
  'SoftBounce': 4,
};

const getIconForActivityEventType = (eventType: string): React.ReactNode => {
  switch(eventType) {
    case 'Delivery': {
      return <MarkEmailReadOutlinedIcon />
    }

    case 'SoftBounce':
    case 'HardBounce': {
      return <SvgIcon>
        <svg width="24" height="24" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_18_3040)">
          <path d="M16.5 4.5C16.5 3.675 15.825 3 15 3H3C2.175 3 1.5 3.675 1.5 4.5V13.5C1.5 14.325 2.175 15 3 15H15C15.825 15 16.5 14.325 16.5 13.5V4.5ZM15 4.5L9 8.25L3 4.5H15ZM15 13.5H3V6L9 9.75L15 6V13.5Z" fill="#E83C56"/>
          </g>
          <rect x="9" y="8" width="10" height="10" rx="5" fill="#F7FAFC"/>
          <rect x="16.084" y="10.083" width="1.1785" height="7.07102" transform="rotate(45 16.084 10.083)" fill="#E83C56"/>
          <rect x="16.916" y="15.083" width="1.1785" height="7.07102" transform="rotate(135 16.916 15.083)" fill="#E83C56"/>
          <defs>
          <clipPath id="clip0_18_3040">
          <rect width="18" height="18" fill="white"/>
          </clipPath>
          </defs>
        </svg>
      </SvgIcon>
    }

    case 'Open': {
      return <DraftsOutlinedIcon />
    }

    case 'Click': {
      return <TouchAppOutlinedIcon />
    }
  }
}

const EmailNotificationActivity = ({
  emailNotificationActivity,
  detailDateFormat,
}: { emailNotificationActivity: EmailNotificationActivity, detailDateFormat: string }) => {
  return (
    <Box display="flex" flexDirection={"row"} alignItems={'center'} gap={'12px'}
      sx={{
        background: Colors.lightestGray2,
        borderRadius: '12px',
        paddingX: '20px',
        paddingY: '8px'
      }}>
      {getIconForActivityEventType(emailNotificationActivity.eventType)}
      <Box display="flex" flexDirection={"column"} flexGrow={1} gap={'4px'}>
        <Typography typestyle='s' sx={{ color: Colors.darkerGray }}>{emailNotificationActivity.eventType}</Typography>
        <Typography typestyle='xs' sx={{ color: Colors.darkishGray }}>{format(new Date(emailNotificationActivity.lastEvent), detailDateFormat)}</Typography>
      </Box>
      <Box sx={{
          visibility: emailNotificationActivity.count > 0 ? 'visible' : 'hidden',
          display: 'inline-block',
          padding: '0.5em 1em', /* Adjust padding for height and width */
          backgroundColor: Colors.lightishGray, /* Background color */
          borderRadius: '50px', /* Ensures rounded edges */
          textAlign: 'center',
          whiteSpace: 'nowrap', /* Prevents text wrapping */
        }}>
        <Typography typestyle='xs bold'>{emailNotificationActivity.count}</Typography>
      </Box>
    </Box>
  )
};

export const FileRequestEmailNotifications = ({
  emailNotifications,
  detailDateFormat,
}: IFileRequestEmailNotificationsProps) => {
  return (
    (<List sx={{ padding: 0, width: '100%' }}>
      {emailNotifications.map((emailNotification, index) => (
        <Box
          sx={{
            paddingX: '28px',
            paddingY: '24px',
          }}
          borderBottom={(index < emailNotifications.length - 1) ? `1px solid ${Colors.mediumLightGray}` : 'unset'}
          gap={'20px'}
          width={1}
          key={`email-notification-${emailNotification.id}`} display={"flex"} flexDirection={"column"}>
          <Box display="flex" flexDirection={"column"} gap={'4px'}>
            <Typography typestyle='s'><span style={{ color: Colors.darkerGray, fontWeight: 700, fontSize: '13px' }}>Subject:</span> {emailNotification.subject}</Typography>
            <Typography typestyle='s'><span style={{ color: Colors.darkerGray, fontWeight: 700, fontSize: '13px' }}>To:</span> {emailNotification.recipients.join(',')}</Typography>
            <Typography typestyle='s'><span style={{ color: Colors.darkerGray, fontWeight: 700, fontSize: '13px' }}>Sent:</span> {emailNotification.sentAt && format(new Date(emailNotification.sentAt), detailDateFormat)}</Typography>
          </Box>

          {
            (emailNotification.activities || []).length > 0 && (
              <Box display={"flex"} flexDirection={"column"} gap={'12px'}>
                {([...(emailNotification?.activities || [])]).sort((a, b) => {
                  const priorityA = activitySortOrder[a.eventType];
                  const priorityB = activitySortOrder[b.eventType];
                  return priorityA - priorityB;
                }).map((emailNotificationActivity) => (
                  <EmailNotificationActivity
                    key={`email-activity-${emailNotificationActivity.id}`}
                    detailDateFormat={detailDateFormat}
                    emailNotificationActivity={emailNotificationActivity} />
                ))}
              </Box>
            )
          }
        </Box>
      ))}
    </List>)
  );
};
