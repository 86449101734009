import { gql } from "@apollo/client";
import { createQueryHook } from "../../GraphqlHelpers";
import { LogFileRequest } from "../../../types";

export type LogFileRequestDetailData = {
  logFileRequest: LogFileRequest;
};

export type LogFileRequestDetailDataVariables = {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  logFileRequestId: string | number;
};

export const GetLogFileRequestDetailDataQuery = gql`
  query GetLogFileRequestDetailData(
    $accountId: ID!
    $procoreProjectServerId: ID!
    $closeoutLogId: ID!
    $logFileRequestId: ID!
  ) {
    logFileRequest(
      accountId: $accountId
      procoreProjectServerId: $procoreProjectServerId
      closeoutLogId: $closeoutLogId
      logFileRequestId: $logFileRequestId
    ) {
      id
      title
      logRequirementId
      description
      internalNotes
      dueDate
      complete
      status
      translatedStatus
      createdAt
      updatedAt
      assignees {
        id
        logFileRequestId
        procoreServerId
        procoreUserName
        procoreUserEmail
      }
      closeoutManager {
        id
        procoreUserName
      }
      responsibleContractor {
        id
        name
        procoreServerId
      }
      vendorWorkflowRequest {
        id
        logFileRequestId
        status
        translatedStatus
        comment
        createdAt
        title
        vendorWorkflowResponse {
          id
          logFileRequestId
          status
          comment
          translatedStatus
          createdAt
          title
          attachments {
            id
            procoreFileServerId
            filename
            vendorWorkflowResponseId
            procoreCreatedAt
            procoreRecordUrl
            procoreDownloadUrl
            procoreDocumentViewerUrl
            viewableInProcore
            omitted
          }
        }
      }
      logRequirementTrade {
        id
        name
      }
      specSection {
        id
        name
        divisionName
        procoreServerId
      }
      logRequirementDescription
      vendorWorkflowRequests {
        id
        logFileRequestId
        status
        translatedStatus
        comment
        createdAt
        title
        vendorWorkflowResponse {
          id
          logFileRequestId
          status
          comment
          translatedStatus
          createdAt
          title
          attachments {
            id
            procoreFileServerId
            filename
            vendorWorkflowResponseId
            procoreCreatedAt
            procoreRecordUrl
            procoreDownloadUrl
            procoreDocumentViewerUrl
            viewableInProcore
            omitted
          }
        }
      }
      emailNotifications {
        id
        subject
        recipients
        sentAt
        activities {
          id
          eventType
          count
          lastEvent
        }
      }
    }
  }
`;

export const useGetLogFileRequestDetailData = createQueryHook<
  LogFileRequestDetailData,
  LogFileRequestDetailDataVariables
>(GetLogFileRequestDetailDataQuery);
