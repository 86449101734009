import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/system/Box";
import { Typography } from "./shared/Typography";
import Close from "@mui/icons-material/Close";
import { Colors, StyledDataGridPremium } from "../styles";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useGetExtractAutomationRecords } from "../graphql/queries/automation/GetExtractAutomationRecords";
import idx from "idx";
import { IconButton, Tooltip } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ProcoreIcon from './shared/ProcoreIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface IAutomationAnalyticsProps {
  accountId: string;
  extractAutomationId: number;
  openModal: boolean;
  closeModal: () => void;
}

const DEFAULT_COL_DEF = {
  editable: false,
  sortable: false,
  groupable: false,
  flex: 1,
};

const AutomationActivityModal = (props: IAutomationAnalyticsProps): JSX.Element => {
  const containerRef = React.useRef(null);
  const [hoveredRowId, setHoveredRowId] = useState<string>(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });

  const dataGridColumnDefinitions: GridColDef[] = [
    {
      field: 'procoreCompanyName',
      headerName: 'Company',
      ...DEFAULT_COL_DEF,
      type: 'string'
    },
    {
      field: 'procoreProjectName',
      headerName: 'Project',
      ...DEFAULT_COL_DEF,
      type: 'string'
    },
    {
      field: 'procoreItemTypeTranslation',
      headerName: 'Procore Type',
      ...DEFAULT_COL_DEF,
      type: 'string'
    },
    {
      field: 'filename',
      headerName: 'Filename',
      ...DEFAULT_COL_DEF,
      type: 'string'
    },
    {
      field: 'status',
      headerName: 'Status',
      ...DEFAULT_COL_DEF,
      type: 'custom',
      renderCell: (params) => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            gap="6px"
            width={1}
            height={1}
            alignItems="center"
            justifyContent="center"
            fontSize="0.9rem"
          >
            <Typography typestyle="s">
              {params.row.statusTranslation}
            </Typography>
            {params.row.status !== 'pending' &&
              <CheckCircleIcon htmlColor={Colors.green} fontSize="inherit" />
            }
          </Box>
        );
      }
    },
    {
      ...DEFAULT_COL_DEF,
      field: 'actions',
      type: 'actions',
      headerName: '',
      flex: 0,
      width: 120,
      editable: false,
      resizable: false,
      sortable: false,
      groupable: false,
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems={'center'} width={1} height={1}>
            {params.row.procoreRecordUrl && params.row.id === hoveredRowId && (
              <Tooltip title={`View ${params.row.procoreItemTypeTranslation} in Procore`} placement="top">
                <IconButton sx={{
                  "&:hover": {
                    background: 'transparent',
                  },
                }} onClick={() => {
                  window.open(params.row.procoreRecordUrl, "_blank");
                }}>
                  <ProcoreIcon sx={{width: '16px'}}/>
                </IconButton>
              </Tooltip>
            )}

            {params.row.id === hoveredRowId && (
              <Tooltip title={params.row.destinationUrl ? "" : "View file in Procore"} placement="top">
                <IconButton disabled={!params.row.destinationUrl} onClick={() => {
                  window.open(params.row.destinationUrl, "_blank");
                }}>
                  <OpenInNewIcon sx={{fontSize: '18px'}} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  const { loading, data } = useGetExtractAutomationRecords({
    accountId: props.accountId,
    extractAutomationId: props.extractAutomationId,
    limit: paginationModel.pageSize,
    offset: paginationModel.page * paginationModel.pageSize,
  },
  false,
  "network-only");

  const [rowCountState, setRowCountState] = useState(0);

  React.useEffect(() => {
    if (loading || !data) { return; }

    setRowCountState(idx(data, (_) => _.extractAutomationRecordsCount.count) || 0);
  }, [loading, data]);

  return (
    <Dialog
      disableScrollLock
      ref={containerRef}
      open={props.openModal}
      onClose={() => props.closeModal()}
      PaperProps={{
        sx: {
          maxHeight: "800px",
          minWidth: "950px",
          padding: "24px 28px 28px 28px",
        },
      }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '24px',
        justifyContent: 'space-between'
      }}>
        <Typography typestyle="xl">
          Automation Activity
        </Typography>
        <IconButton onClick={() => props.closeModal()}>
          <Close sx={{fontSize: '18px'}}/>
        </IconButton>
      </Box>

      <Box height={1} width={1} display={'flex'}>
        <StyledDataGridPremium
          columns={dataGridColumnDefinitions}
          disableColumnMenu
          rowCount={rowCountState}
          slotProps={{
            row: {
              onMouseEnter: (
                event: React.MouseEvent<HTMLDivElement, MouseEvent>
              ) => setHoveredRowId(event.currentTarget.dataset.id),
              onMouseLeave: () => setHoveredRowId(null),
            },
          }}
          rows={idx(data, (_) => _.extractAutomationRecords) || []}
          loading={loading}
          paginationMode="server"
          pagination={true}
          onPaginationModelChange={setPaginationModel}
          paginationModel={paginationModel}
          pageSizeOptions={[25, 50, 100]}
          rowSelection={false}
          initialState={{
            density: 'standard',
          }}
          columnHeaderHeight={40}
          sx={{
            '&.MuiDataGrid-root': {
              borderRadius: '8px 8px 0px 0px',
              height: '600px',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'inherit',
            },
          }}
        />
      </Box>
    </Dialog>
  );
};

export default AutomationActivityModal;
