import Box from '@mui/material/Box';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { useState } from 'react';
import { Typography } from './shared/Typography';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import { FieldContainer } from '../styles';
import { Button } from './shared/Button';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { MenuItem, Popover } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { userUpdateUserRoles } from '../graphql/mutations/UpdateUserRoles';

interface UpdateUserRoleModal {
  accountId: string;
  loading: boolean;
  rowSelectionModel: GridRowSelectionModel;
  onClose: (refresh: boolean) => void;
}

export const UpdateUserRoleModal = ({
  accountId,
  loading,
  rowSelectionModel,
  onClose,
  ...rest
}: UpdateUserRoleModal & DialogProps): JSX.Element => {
  const [role, setRole] = useState<'member' | 'admin'>('member');
  const [roleMenuAnchorEl, setRoleMenuAnchorEl] = useState<HTMLButtonElement>(null);

  const handleOnClose = (refresh: boolean) => {
    onClose(refresh);
  }

  const updateRole = (role: 'member' | 'admin') => {
    setRole(role);
    setRoleMenuAnchorEl(null);
  }

  const [updateUserRoles, { loading: loadingUpdateUserRoles }] = userUpdateUserRoles({
    accountId,
    procoreLoginInformationIds: [],
    role: role
  })

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      disableScrollLock
      {...rest}
      PaperProps={{
        sx: {
          maxWidth: '550px',
          maxHeight: '580px',
        },
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          padding: '24px 28px 32px 28px',
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography typestyle="xl">Update Roles</Typography>
          <IconButton disabled={loading || loadingUpdateUserRoles} onClick={() => handleOnClose(false)}>
            <Close sx={{ fontSize: '18px' }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            height: '100%',
          }}
        >
          <FieldContainer sx={{
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
            gap: '6px'
          }}>
            <Typography typestyle="m">{`Update ${rowSelectionModel.length} user roles to: `}</Typography>
            <Button
              buttonborderstyle="pill"
              variant='outlined'
              fullWidth={false}
              onClick={(event) => {
                setRoleMenuAnchorEl(event.currentTarget);
              }}
              endIcon={<ArrowDropDown fontSize="small" />}>
              {role === 'admin' ? 'Admin' : 'Member'}
            </Button>
          </FieldContainer>

          <Box display={'flex'} width={1} justifyContent={'flex-end'} alignContent={'center'} gap={'12px'}>
            <Button
              variant="outlined"
              buttonborderstyle="pill"
              disabled={loading || loadingUpdateUserRoles}
              size="medium"
              onClick={() => handleOnClose(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={loading || loadingUpdateUserRoles}
              variant="contained"
              onClick={() => {
                updateUserRoles({
                  variables: {
                    accountId: accountId,
                    procoreLoginInformationIds: rowSelectionModel as number[],
                    role: role
                  }
                }).then(() => {
                  handleOnClose(true);
                });
              }}
              buttonborderstyle="pill"
              size="medium"
            >
              Save
            </Button>
          </Box>
        </Box>

        <Popover
          id="update-user-role"
          open={Boolean(roleMenuAnchorEl)}
          onClose={() => setRoleMenuAnchorEl(null)}
          anchorEl={roleMenuAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              sx: {
                padding: '16px 12px',
              }
            }
          }}
        >
          <MenuItem onClick={() => {
            updateRole('member');
          }}>Member</MenuItem>
          <MenuItem onClick={() => {
            updateRole('admin');
          }}>Admin</MenuItem>
        </Popover>
      </DialogContent>
    </Dialog>
  );
};
