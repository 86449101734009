import { Box, Typography } from '@mui/material';
import React from 'react';
import { NewPackageContext } from '../../contexts/NewPackageContextProvider';
import { ToolTabData } from '../../contexts/NewPackageContext';
import { Colors } from '../../styles';
import { BlueSwitch } from './styles';
import { FormExtractOptions } from '../../types/form';

const FormOptions = (props: { toolTabIndex: number, toolTabData: ToolTabData }): JSX.Element => {
  const { toolTabIndex, toolTabData } = props;

  const { dispatch } = React.useContext(NewPackageContext);

  const formExtractOptions = toolTabData.extractOptions as FormExtractOptions;
  return (
    <Box display="flex" flexDirection="column" width={1} height={1} padding={'20px 40px'} style={{ overflowY: 'auto' }}>
      <h2 style={{ margin: '0px 0px 20px 0px', fontSize: '18px'}}>General</h2>
      <Box display={"flex"} flexDirection={"column"} style={{ gap: '24px'}}>
        <Box display="flex" flexDirection="row" style={{ gap: '8px'}} width={1} alignItems={'center'}>
          <BlueSwitch
            size="small"
            checked={formExtractOptions.single_pdf}
            onChange={(event) => {
              dispatch({
                type: 'SET_TOOL_OPTIONS',
                value: {
                  toolTabIndex: toolTabIndex,
                  option: {
                    ...formExtractOptions,
                    single_pdf: event.target.checked
                  }
                }
              });
            }}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <Typography style={{ fontSize: '14px', fontWeight: 400, color: Colors.darkerGray }}>{'Include the Form’s attachments in its cover sheet PDF'}</Typography>
        </Box>
      </Box>
    </Box>
  )
};

export default FormOptions;