import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { Typography } from "./shared/Typography";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Button } from "./shared/Button";

const items = [
  {
    title: [
    {
      text: 'Missed ',
      span: false,
     },
     {
      text: 'Groundbreak 2024?',
      span: true,
     },
    ],
    date: 'NOVEMBER 20, 2024',
    description: 'Catch the session recording to see <span>Cherie Walsh</span> (Senior Applications Analyst, Skanska USA) and <span>TIffanie Artigas</span> (Director of Operational Excellence, Verdex Construction) unpack why closeout can be so difficult - and share some <span>practical tips to streamline your processes</span> and finish every project strong!',
    cta: {
      text: 'Watch the Session',
      url: 'https://hubs.ly/Q031X7Yc0',
    },
  },
];

const MarketingCarousel = (): JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const interval = 7000;
  const intervalRef = React.useRef(null);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    resetInterval();
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
    resetInterval();
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
    resetInterval();
  };

  const resetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Clear the current interval
    }

    // Set a new interval
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, interval);
  };

  // Start the auto-transition effect
  React.useEffect(() => {
    if (items.length > 1) {
      resetInterval(); // Start the auto-slide interval

      return () => clearInterval(intervalRef.current); // Clean up the interval
    }
  }, [items, interval]);


  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100vh', // Full viewport height
        overflow: 'hidden',
        paddingLeft: '120px',
      }}
    >
      {/* Carousel Content */}
      <Box sx={{
        display: 'flex',
        height: 'calc(100% - 40px)', // Full height minus space for arrows (100px)
        transition: 'transform 0.5s ease-in-out',
        transform: `translateX(-${currentIndex * 100}%)`,
      }}>
        {items.map((item, index) => (
          <Box key={index}
              sx={{
                minWidth: '100%',
                height: '100%', // Take full available height
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}>
                <Typography style={{
                  color: 'black',
                  fontFamily: '"Inter", sans-serif',
                  maxWidth: '75%',
                  fontSize: '60px',
                  fontWeight: 400,
                  marginBottom: '38px',
                  lineHeight: 'normal',
              }} sx={{
                '& span': {
                  position: "relative",
                  display: 'inline-block',
                  fontWeight: 900,
                  zIndex: 1,
                },
                '& span::before': {
                  content: '""',
                  position: 'absolute',
                  width: '100%',
                  height: '13px',
                  backgroundColor: '#FF845D',
                  borderRadius: "4px",
                  bottom: '8px', /* Adjust this value to control the vertical offset */
                  left: 0,
                  transformOrigin: 'left',
                  zIndex: -1,
                }
              }}>
              {item.title.map((title, index) => (
                title.span ? <span
                  key={`span-${index}`}>{title.text}</span> : title.text
              ))}
            </Typography>
            <Typography style={{
              marginBottom: '18px',
              fontSize: '18px',
              color: 'black',
              textTransform: 'uppercase',
              fontWeight: 700,
            }}>{item.date}</Typography>
            <Typography style={{
              color: 'black',
              fontFamily: '"Inter", sans-serif',
              lineHeight: '24px',
              maxWidth: '70%',
              fontSize: '20px',
              fontWeight: 400,
              marginBottom: '42px',
              letterSpacing: '1.2px',
            }}
            sx={{
            '& span': {
                fontWeight: 700,
              },
            }}
            dangerouslySetInnerHTML={{ __html: item.description }}>
            </Typography>

            <Button variant="outlined" size="large"
              onClick={() => window.open(item.cta.url, '_blank')}
              style={{
                fontFamily: '"Inter", sans-serif',
                textTransform: 'uppercase',
                border: '1px solid #0053F4',
                color: '#0053F4',
                padding: '12px 40px',
                height: 'unset',
                fontStyle: 'bold',
                fontWeight: 700,
                letterSpacing: '1.2px',
              }}>
              {
                item.cta.text
              }
            </Button>
          </Box>
        ))}
      </Box>

      { items.length > 1 && (
        <>
          <IconButton
            onClick={handlePrev}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '40px',
              transform: 'translateY(-50%)',
              zIndex: 1,
              padding: '16px',
              '&:hover': {
                backgroundColor: 'white',
                border: '1px solid white'
              }
            }}
          >
            <ArrowBackIosNewOutlinedIcon />
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{
              position: 'absolute',
              top: '50%',
              right: '40px',
              transform: 'translateY(-50%)',
              padding: '16px',
              zIndex: 1,
              '&:hover': {
                backgroundColor: 'white',
                border: '1px solid white'
              }
            }}
          >
            <ArrowForwardIosOutlinedIcon />
          </IconButton>
        </>
      )}

      {/* Pagination Dots */}
      { items.length > 1 && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, position: 'absolute', bottom: '32px', left: 0, right: 0 }}>
            {items.map((_, index) => (
              <Box
                key={index}
                onClick={() => handleDotClick(index)}
                sx={{
                  width: 16,
                  height: 16,
                  borderRadius: '50%',
                  border: '1px solid #333E45',
                  backgroundColor: currentIndex === index ? '#333E45' : 'white',
                  margin: '0 8px',
                  cursor: 'pointer',
                }}
              />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default MarketingCarousel;
