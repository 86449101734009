import React from 'react';
import {
  Box,
  IconButton,
  Autocomplete,
  FormControl,
  SelectChangeEvent,
  MenuItem,
  TextField,
} from '@mui/material';
import { Typography } from './shared/Typography';
import Close from '@mui/icons-material/Close';
import { ProcoreToolFilter } from '../contexts/NewPackageContext';
import { SelectFilterValue } from '../types/submittal';

interface IFilterRowProps {
  onRemoveFilter: (key: string) => void;
  onChangeSelectedFilter: (oldKey: string, newKey: string) => void;
  onUpdateFilterValues: (key: string, values: SelectFilterValue[]) => void;
  unusedFilterKeys: string[];
  filters: ProcoreToolFilter[];
  filter: ProcoreToolFilter;
  values: SelectFilterValue[];
}

export const ManageUsersFilterRow = ({
  onRemoveFilter,
  onUpdateFilterValues,
  onChangeSelectedFilter,
  unusedFilterKeys,
  filters,
  filter,
  values,
}: IFilterRowProps): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'start',
        gap: '16px',
        paddingX: '16px',
      }}
    >
      <Box
        sx={{
          height: '40px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <IconButton onClick={() => onRemoveFilter(filter.key)} size="small">
          <Close sx={{ fontSize: '16px' }} />
        </IconButton>
      </Box>
      <FormControl sx={{ width: '50%' }}>
        <TextField
          select
          size="small"
          value={filter.key}
          slotProps={{
            select: {
              onChange: (event: SelectChangeEvent<any>) => {
                onChangeSelectedFilter(filter.key, event.target.value);
              }
            }
          }}
        >
          {filters.map((_filter, i) => {
            return (
              <MenuItem
                value={_filter.key}
                key={`filter-field-menu-item-${i}`}
                disabled={!unusedFilterKeys.includes(_filter.key)}
              >
                {_filter.label}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
      <Box
        sx={{
          height: '40px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography>is</Typography>
      </Box>
      <FormControl sx={{ width: '50%' }}>
        <Autocomplete
          multiple
          value={values}
          options={(filter.values || []).reduce((acc, next) => {
            if (!values.map((value) => value.id).includes(next.id)) {
              acc.push(next);
            }

            return acc;
          }, [])}
          getOptionLabel={(item) => {
            if (item?.value) {
              return item.value;
            } else {
              return '';
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="" autoFocus size="small" />
          )}
          onChange={(evt, value) => {
            onUpdateFilterValues(filter.key, value);
          }}
        />
      </FormControl>
    </Box>
  );
};
