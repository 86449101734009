import { CloseoutLogDataGridView, ProcoreToolGroupings, TemplateCloseoutLogDataGridView, TemplateCloseoutLogExportOption, TemplateCloseoutLogTool, TemplateLogCustomField, TemplateLogRequirement, TemplateLogRequirementTrade, TemplateLogRequirementType } from "../types";
import { SubmittalExtractOptions } from "../types/submittal";
import { RfiExtractOptions } from "../types/rfi";
import { TOOL_ENGINE_NAMES, booleanValueOrFallback, getExtractOptionsForToolEngineName, sortTemplateLogRequirementTrades, sortTemplateLogRequirementTypes } from "../utils/utils";
import { DrawingExtractOptions } from "../types/drawing";
import { InspectionExtractOptions } from "../types/inspection";
import { ObservationExtractOptions } from "../types/observation";
import { FormExtractOptions } from "../types/form";
import { MeetingExtractOptions } from "../types/meeting";
import { PunchItemExtractOptions } from "../types/punch_item";
import { ActionPlanExtractOptions } from "../types/action_plan";
import { ExtractToolOptions } from "./CloseoutLogContext";

export type TemplateCloseoutExtractOptions = {
  include_table_of_contents: boolean,
  selectedGroupings: ProcoreToolGroupings[],
  include_description_in_table_of_contents: boolean;
  include_project_photo_on_cover_sheet: boolean;
  include_custom_cover_sheet: boolean;
  external_storage_options?: {
    document_storage_system: string,
    storage_parent_id?: number
  };
  toolExtractOptions: ExtractToolOptions
};

export type TemplateCloseoutLogState = {
  id: number | null;
  name: string;
  templateLogRequirements: TemplateLogRequirement[];
  templateLogRequirementTypes: TemplateLogRequirementType[];
  templateLogRequirementTrades: TemplateLogRequirementTrade[];
  templateCloseoutLogTools: TemplateCloseoutLogTool[];
  templateLogCustomFields: TemplateLogCustomField[];
  loadingLogData: boolean;
  groupings: ProcoreToolGroupings[];
  displaySuccessToast: { isOpen: boolean, message: string };
  displayFailureToast: { isOpen: boolean, message: string };
  extractOptions: TemplateCloseoutExtractOptions;
  currentTemplateCloseoutLogDataGridView?: CloseoutLogDataGridView;
};

export const initialState: TemplateCloseoutLogState = {
  id: null,
  name: null,
  templateLogRequirements: [],
  templateLogRequirementTypes: [],
  templateLogRequirementTrades: [],
  templateCloseoutLogTools: [],
  templateLogCustomFields: [],
  loadingLogData: true,
  groupings: [],
  displaySuccessToast: { isOpen: false, message: '' },
  displayFailureToast: { isOpen: false, message: '' },
  extractOptions: {
    selectedGroupings: [],
    include_table_of_contents: true,
    include_description_in_table_of_contents: false,
    include_project_photo_on_cover_sheet: false,
    include_custom_cover_sheet: false,
    external_storage_options: null,
    toolExtractOptions: {
      submittals: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.SUBMITTALS, {}) as SubmittalExtractOptions,
      rfis: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.RFIS, {}) as RfiExtractOptions,
      drawings: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.DRAWINGS, {}) as DrawingExtractOptions,
      meetings: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.MEETINGS, {}) as MeetingExtractOptions,
      forms: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.FORMS, {}) as FormExtractOptions,
      observations: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.OBSERVATIONS, {}) as ObservationExtractOptions,
      inspections: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.INSPECTIONS, {}) as InspectionExtractOptions,
      punch_items: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.PUNCH_LIST, {}) as PunchItemExtractOptions,
      action_plans: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.ACTION_PLANS, {}) as ActionPlanExtractOptions,
    },
  },
  currentTemplateCloseoutLogDataGridView: null,
};

export type TOAST_TRIGGERS = 'UPDATE_TEMPLATE_LOG_REQUIREMENT'
  | 'APPEND_TEMPLATE_LOG_REQUIREMENTS'
  | 'DELETE_TEMPLATE_LOG_REQUIREMENT'
  ;

const getDisplaySuccessToast = (current: { isOpen: boolean, message: string }, triggeredFrom?: TOAST_TRIGGERS, message?: string, ) => {
  if (!triggeredFrom) return current;

  switch (triggeredFrom) {
    case 'UPDATE_TEMPLATE_LOG_REQUIREMENT':
      return { isOpen: true, message: 'Changes saved' };

    case 'APPEND_TEMPLATE_LOG_REQUIREMENTS':
      return { isOpen: true, message: message };

    case 'DELETE_TEMPLATE_LOG_REQUIREMENT':
      return { isOpen: true, message: 'Requirement successfully deleted'}

    default:
      return current;
  }
}

export type Action =
  { type: "SET_TEMPLATE_CLOSEOUT_LOG"; value: { id: number; name: string }; }
  | { type: "SET_TEMPLATE_CLOSEOUT_LOG_DATA"; value: {
    templateLogRequirementTypes: TemplateLogRequirementType[];
    templateLogRequirementTrades: TemplateLogRequirementTrade[];
    templateLogRequirements: TemplateLogRequirement[],
    templateLogCustomFields: TemplateLogCustomField[];
    groupings: ProcoreToolGroupings[],
    templateCloseoutLogTools: TemplateCloseoutLogTool[],
    currentTemplateCloseoutLogDataGridView: TemplateCloseoutLogDataGridView,
    templateCloseoutLogExportOption: TemplateCloseoutLogExportOption
  } }
  | { type: "SET_TEMPLATE_CLOSEOUT_LOG_DATA_LOADING"; value: boolean }
  | { type: "APPEND_TEMPLATE_LOG_REQUIREMENTS"; value: { templateLogRequirements: TemplateLogRequirement[]; triggeredFrom: TOAST_TRIGGERS } }
  | { type: "UPDATE_TEMPLATE_LOG_REQUIREMENT"; value: { templateLogRequirement: TemplateLogRequirement; triggeredFrom?: TOAST_TRIGGERS } }
  | { type: "DELETE_TEMPLATE_LOG_REQUIREMENTS"; value: { templateLogRequirementIds: number[] } }
  | { type: 'SET_SELECTED_GROUPINGS'; value: ProcoreToolGroupings[] }
  | { type: 'SET_TEMPLATE_EXTRACT_OPTIONS'; value: TemplateCloseoutExtractOptions }
  | { type: 'PREPEND_TEMPLATE_LOG_REQUIREMENT_TYPE'; value: TemplateLogRequirementType }
  | { type: 'PREPEND_TEMPLATE_LOG_REQUIREMENT_TRADE'; value: TemplateLogRequirementTrade }
  | { type: 'ADD_AND_SORT_TEMPLATE_LOG_REQUIREMENT_TYPE'; value: TemplateLogRequirementType }
  | { type: 'ADD_AND_SORT_TEMPLATE_LOG_REQUIREMENT_TRADE'; value: TemplateLogRequirementTrade }
  | { type: 'DELETE_TEMPLATE_LOG_REQUIREMENT_TYPE'; value: number }
  | { type: 'DELETE_TEMPLATE_LOG_REQUIREMENT_TRADE'; value: number }
  | { type: 'UPDATE_TEMPLATE_LOG_REQUIREMENT_TYPE'; value: TemplateLogRequirementType }
  | { type: 'UPDATE_TEMPLATE_LOG_REQUIREMENT_TRADE'; value: TemplateLogRequirementType }
  | { type: 'SET_DISPLAY_SUCCESS_TOAST'; value: { isOpen: boolean, message: string } }
  | { type: 'SET_DISPLAY_FAILURE_TOAST'; value: { isOpen: boolean, message: string } }
  | { type: 'RENAME_TEMPLATE_CLOSEOUT_LOG'; value: string }
  | { type: 'UPDATE_TEMPLATE_CLOSEOUT_LOG'; value: { name: string; } }
  | { type: 'UPDATE_TEMPLATE_LOG_REQUIREMENTS'; value: { templateLogRequirements: TemplateLogRequirement[] } }
  | { type: 'UPDATE_TEMPLATE_CLOSEOUT_LOG_DATA_GRID_VIEW', value: TemplateCloseoutLogDataGridView }
  | { type: 'APPEND_LOG_CUSTOM_FIELD'; value: TemplateLogCustomField }
  | { type: 'UPDATE_LOG_CUSTOM_FIELD'; value: TemplateLogCustomField }
  | { type: 'DELETE_LOG_CUSTOM_FIELD'; value: number }
  ;

export const rootReducer: React.Reducer<TemplateCloseoutLogState, Action> = (
  state,
  action
) => {
  switch (action.type) {
    case "SET_TEMPLATE_CLOSEOUT_LOG":
      return {
        ...state,
        id: action.value.id,
        name: action.value.name,
        templateLogRequirements: [],
        templateLogRequirementTypes: [],
        templateLogRequirementTrades: [],
        loadingLogData: true,
        groupings: [],
        displaySuccessToast: { isOpen: false, message: '' },
        displayFailureToast: { isOpen: false, message: '' },
        extractOptions: {
          selectedGroupings: [],
          include_table_of_contents: true,
          include_description_in_table_of_contents: false,
          include_custom_cover_sheet: false,
          include_project_photo_on_cover_sheet: false,
          external_storage_options: null,
          toolExtractOptions: {
            submittals: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.SUBMITTALS, {}) as SubmittalExtractOptions,
            rfis: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.RFIS, {}) as RfiExtractOptions,
            drawings: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.DRAWINGS, {}) as DrawingExtractOptions,
            meetings: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.MEETINGS, {}) as MeetingExtractOptions,
            forms: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.FORMS, {}) as FormExtractOptions,
            observations: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.OBSERVATIONS, {}) as ObservationExtractOptions,
            inspections: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.INSPECTIONS, {}) as InspectionExtractOptions,
            punch_items: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.PUNCH_LIST, {}) as PunchItemExtractOptions,
            action_plans: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.ACTION_PLANS, {}) as ActionPlanExtractOptions,
          }
        },
      };

    case 'ADD_AND_SORT_TEMPLATE_LOG_REQUIREMENT_TRADE': {
      const typeIds = state.templateLogRequirementTrades.map((type) => type.id);

      if (typeIds.includes(action.value.id)) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          templateLogRequirementTrades: sortTemplateLogRequirementTrades([
            action.value,
            ...state.templateLogRequirementTrades,
          ])
        }
      }
    }

    case 'SET_TEMPLATE_CLOSEOUT_LOG_DATA_LOADING': {
      return {
        ...state,
        loadingLogData: action.value,
      }
    }

    case 'PREPEND_TEMPLATE_LOG_REQUIREMENT_TYPE': {
      return {
        ...state,
        templateLogRequirementTypes: sortTemplateLogRequirementTypes([
          action.value,
          ...state.templateLogRequirementTypes,
        ])
      }
    }

    case 'PREPEND_TEMPLATE_LOG_REQUIREMENT_TRADE': {
      return {
        ...state,
        templateLogRequirementTrades: sortTemplateLogRequirementTrades([
          action.value,
          ...state.templateLogRequirementTrades,
        ])
      }
    }

    case 'ADD_AND_SORT_TEMPLATE_LOG_REQUIREMENT_TYPE': {
      const typeIds = state.templateLogRequirementTypes.map((type) => type.id);

      if (typeIds.includes(action.value.id)) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          templateLogRequirementTypes: sortTemplateLogRequirementTypes([
            action.value,
            ...state.templateLogRequirementTypes,
          ])
        }
      }
    }

    case 'UPDATE_TEMPLATE_LOG_REQUIREMENT_TYPE': {
      return {
        ...state,
        templateLogRequirementTypes: sortTemplateLogRequirementTypes(state.templateLogRequirementTypes.reduce((acc, item) => {
          if (item.id === action.value.id) {
            acc.push(action.value);
          } else {
            acc.push(item);
          }
          return acc;
        }, []))
      }
    }

    case 'UPDATE_TEMPLATE_LOG_REQUIREMENT_TRADE': {
      return {
        ...state,
        templateLogRequirementTrades: sortTemplateLogRequirementTrades(state.templateLogRequirementTrades.reduce((acc, item) => {
          if (item.id === action.value.id) {
            acc.push(action.value);
          } else {
            acc.push(item);
          }
          return acc;
        }, []))
      }
    }

    case 'RENAME_TEMPLATE_CLOSEOUT_LOG': {
      return {
        ...state,
        name: action.value,
      }
    }

    case 'UPDATE_TEMPLATE_CLOSEOUT_LOG': {
      return {
        ...state,
        name: action.value.name,
      }
    }

    case 'DELETE_TEMPLATE_LOG_REQUIREMENT_TYPE': {
      return {
        ...state,
        templateLogRequirementTypes: sortTemplateLogRequirementTypes([
          ...state.templateLogRequirementTypes.filter((TemplateLogRequirementType) => TemplateLogRequirementType.id != action.value)
        ])
      }
    }

    case 'DELETE_TEMPLATE_LOG_REQUIREMENT_TRADE': {
      return {
        ...state,
        templateLogRequirementTrades: sortTemplateLogRequirementTrades([
          ...state.templateLogRequirementTrades.filter((trade) => trade.id !== action.value)
        ])
      }
    }

    case 'UPDATE_TEMPLATE_CLOSEOUT_LOG_DATA_GRID_VIEW': {
      return {
        ...state,
        currentTemplateCloseoutLogDataGridView: action.value,
      }
    }

    case "SET_TEMPLATE_CLOSEOUT_LOG_DATA": {
      const submittalExtractOptions = ((action.value.templateCloseoutLogExportOption.toolExtractOptions || {})['submittals'] || {});
      const rfiExtractOptions = ((action.value.templateCloseoutLogExportOption.toolExtractOptions || {})['rfis'] || {});
      const drawingExtractOptions = ((action.value.templateCloseoutLogExportOption.toolExtractOptions || {})['drawings'] || {});
      const meetingExtractOptions = ((action.value.templateCloseoutLogExportOption.toolExtractOptions || {})['meetings'] || {});
      const observationExtractOptions = ((action.value.templateCloseoutLogExportOption.toolExtractOptions || {})['observations'] || {});
      const inspectionExtractOptions = ((action.value.templateCloseoutLogExportOption.toolExtractOptions || {})['inspections'] || {});
      const punchListExtractOptions = ((action.value.templateCloseoutLogExportOption.toolExtractOptions || {})['punch_items'] || {});
      const actionPlanExtractOptions = ((action.value.templateCloseoutLogExportOption.toolExtractOptions || {})['action_plans'] || {});
      const formExtractsOptions = ((action.value.templateCloseoutLogExportOption.toolExtractOptions || {})['forms'] || {});

      return {
        ...state,
        templateLogRequirementTypes: sortTemplateLogRequirementTypes(action.value.templateLogRequirementTypes),
        templateLogRequirementTrades: sortTemplateLogRequirementTrades(action.value.templateLogRequirementTrades),
        templateLogRequirements: action.value.templateLogRequirements,
        groupings: action.value.groupings,
        templateCloseoutLogTools: action.value.templateCloseoutLogTools,
        loadingLogData: false,
        templateLogCustomFields: action.value.templateLogCustomFields,
        currentTemplateCloseoutLogDataGridView: action.value.currentTemplateCloseoutLogDataGridView,
        extractOptions: {
          ...state.extractOptions,
          include_project_photo_on_cover_sheet: action.value.templateCloseoutLogExportOption.includeProjectPhotoOnCoverSheet,
          include_custom_cover_sheet: action.value.templateCloseoutLogExportOption.includeCustomCoverSheet,
          include_table_of_contents: action.value.templateCloseoutLogExportOption.includeTableOfContents,
          //selectedGroupings: for each selected groupings string, return the grouping that has the matching attribute string. Filter out undefined
          selectedGroupings: action.value.templateCloseoutLogExportOption.groupings.map( (selectedGrouping) => {
            return action.value.groupings.find( grouping => grouping.attribute === selectedGrouping )
          }).filter(selectedGrouping => selectedGrouping),
          toolExtractOptions: {
            submittals: {
              attachment_option: submittalExtractOptions['attachment_option'] || 'distributed',
              workflow_responses: submittalExtractOptions['workflow_responses'] || [],
              single_pdf: true,
            },
            rfis: {
              response_option: rfiExtractOptions['response_option'] || 'official_only',
            },
            drawings: {
              include_correspondence: booleanValueOrFallback(drawingExtractOptions['include_correspondence'], false),
              include_rfis: booleanValueOrFallback(drawingExtractOptions['include_rfis'], false),
              include_documents: booleanValueOrFallback(drawingExtractOptions['include_documents'], false),
              include_inspections: booleanValueOrFallback(drawingExtractOptions['include_inspections'], false),
              include_observations: booleanValueOrFallback(drawingExtractOptions['include_observations'], false),
              include_photos: booleanValueOrFallback(drawingExtractOptions['include_photos'], false),
              include_punch_items: booleanValueOrFallback(drawingExtractOptions['include_punch_items'], false),
              include_sketches: booleanValueOrFallback(drawingExtractOptions['include_sketches'], false),
              include_submittals: booleanValueOrFallback(drawingExtractOptions['include_submittals'], false),
              include_sheet_links: booleanValueOrFallback(drawingExtractOptions['include_sheet_links'], false),
              include_other_markup: booleanValueOrFallback(drawingExtractOptions['include_other_markup'], false),
            },
            meetings: {
              single_pdf: booleanValueOrFallback(meetingExtractOptions['single_pdf'], true),
              image_attachment_option: meetingExtractOptions['image_attachment_option'] || 'thumbnails_and_full_size',
            },
            forms: booleanValueOrFallback(formExtractsOptions['single_pdf'], true),
            observations: {
              include_linked_drawing_markup: booleanValueOrFallback(observationExtractOptions['include_linked_drawing_markup'], false),
              image_attachment_option: observationExtractOptions['image_attachment_option'] || 'thumbnails_and_full_size',
            },
            inspections: {
              single_pdf: booleanValueOrFallback(inspectionExtractOptions['single_pdf'], true),
              attachment_option: 'all',
              include_linked_drawings: booleanValueOrFallback(inspectionExtractOptions['include_linked_drawings'], true),
              show_item_response_change_activity: booleanValueOrFallback(inspectionExtractOptions['show_item_response_change_activity'], false),
              show_only_latest_response_change_activity: booleanValueOrFallback(inspectionExtractOptions['show_only_latest_response_change_activity'], true),
              include_linked_drawing_markup: booleanValueOrFallback(inspectionExtractOptions['include_linked_drawing_markup'], false),
              include_other_markup: booleanValueOrFallback(inspectionExtractOptions['include_other_markup'], false),
              include_observations: booleanValueOrFallback(inspectionExtractOptions['include_observations'], false),
              show_not_applicable_items: booleanValueOrFallback(inspectionExtractOptions['show_not_applicable_items'], true),
              collapse_not_applicable_sections: booleanValueOrFallback(inspectionExtractOptions['collapse_not_applicable_sections'], false),
              selected_attachment_options: {},
              image_attachment_option: inspectionExtractOptions['image_attachment_option'] || 'thumbnails_and_full_size',
              grouping_options: {},
            },
            punch_items: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.PUNCH_LIST, punchListExtractOptions) as PunchItemExtractOptions,
            action_plans: getExtractOptionsForToolEngineName([], TOOL_ENGINE_NAMES.ACTION_PLANS, actionPlanExtractOptions) as ActionPlanExtractOptions,
          }
        }
      };
    }

    case "UPDATE_TEMPLATE_LOG_REQUIREMENT": {
      return {
        ...state,
        templateLogRequirements: state.templateLogRequirements.reduce((acc, templateLogRequirement) => {
          if (templateLogRequirement.id === action.value.templateLogRequirement.id) {
            acc.push(action.value.templateLogRequirement);
          } else {
            acc.push(templateLogRequirement);
          }
          return acc;
        }, [] as TemplateLogRequirement[]),
        displaySuccessToast: getDisplaySuccessToast(state.displaySuccessToast, action.value.triggeredFrom),
      };
    }

    case "DELETE_TEMPLATE_LOG_REQUIREMENTS": {
      const idsToDelete = action.value.templateLogRequirementIds.reduce((acc, logRequirementId)=> {
        acc.set(logRequirementId, true);

        return acc;
      }, new Map)

      return {
        ...state,
        templateLogRequirements: state.templateLogRequirements.filter(templateLogRequirement => !idsToDelete.has(templateLogRequirement.id)),
        displaySuccessToast: {isOpen: true, message: `Successfully deleted ${action.value.templateLogRequirementIds.length} requirement${action.value.templateLogRequirementIds.length===1 ? '' : 's'}.`}
      };
    }

    case "APPEND_TEMPLATE_LOG_REQUIREMENTS": {
      if (action.value.templateLogRequirements.length > 0) {
        const newLogRequirementTypes: TemplateLogRequirementType[] = action.value.templateLogRequirements.reduce((acc, templateLogRequirement) => {
            if (templateLogRequirement.templateLogRequirementType) {
              acc.push(templateLogRequirement.templateLogRequirementType);
            }

            return acc;
          }, []);

        return {
          ...state,
          templateLogRequirements: [...state.templateLogRequirements, ...action.value.templateLogRequirements],
          templateLogRequirementTypes: sortTemplateLogRequirementTypes(Object.values([
            ...state.templateLogRequirementTypes,
            ...newLogRequirementTypes
          ].reduce((acc, item) => {
                if (!acc[item.id]) {
                  acc[item.id] = item;
                }

                return acc;
          }, {}))),
          displaySuccessToast: getDisplaySuccessToast(
            state.displaySuccessToast,
            action.value.triggeredFrom,
            `${action.value.templateLogRequirements.length} requirement(s) successfully created`

          ),
        };
      } else {
        return state;
      }
    }

    case "UPDATE_TEMPLATE_LOG_REQUIREMENTS":
      {
        //Goes through new logReqs and adds them to a new object by id
        const newReqsById = action.value.templateLogRequirements.reduce((acc, templateLogRequirement)=> {
          acc.set(templateLogRequirement.id, templateLogRequirement);
          return acc;
        }, new Map)

        //Goes through logReqs in state and adds either the newReq if it exists in newReqsById or it keeps the existing log req
        const reqsById = state.templateLogRequirements.reduce((acc, templateLogRequirement) => {
          acc.set(templateLogRequirement.id, newReqsById.get(templateLogRequirement.id) || templateLogRequirement);
          return acc;
        }, new Map)

        return {
          ...state,
          templateLogRequirements: Array.from(reqsById.values()),
          displaySuccessToast: {
            isOpen: true,
            message: `Successfully updated ${action.value.templateLogRequirements.length} requirements.`
          }
        }
      }

    case "SET_DISPLAY_SUCCESS_TOAST":
      return {
        ...state,
        displaySuccessToast: action.value
      };

    case "SET_DISPLAY_FAILURE_TOAST":
      return {
        ...state,
        displayFailureToast: action.value
      };

    case "SET_SELECTED_GROUPINGS":
      return {
        ...state,
        extractOptions: {
          ...state.extractOptions,
          selectedGroupings: action.value
        }
      };

    case 'SET_TEMPLATE_EXTRACT_OPTIONS': {
      return {
        ...state,
        extractOptions: {
          ...state.extractOptions,
          ...action.value
        }
      };
    }

    case 'APPEND_LOG_CUSTOM_FIELD': {
      return {
        ...state,
        templateLogCustomFields: [
          ...state.templateLogCustomFields,
          action.value,
        ]
      }
    }

    case 'UPDATE_LOG_CUSTOM_FIELD': {
      return {
        ...state,
        templateLogCustomFields: state.templateLogCustomFields.reduce((acc, item) => {
          if (item.id === action.value.id) {
            acc.push(action.value);
          } else {
            acc.push(item);
          }
          return acc;
        }, [])
      }
    }

    case 'DELETE_LOG_CUSTOM_FIELD': {
      return {
        ...state,
        templateLogCustomFields: [
          ...state.templateLogCustomFields.filter((logCustomField) => logCustomField.id != action.value)
        ]
      }
    }

    default:
      return state;
  }
};

export type TemplateCloseoutLogContextState = {
  state: TemplateCloseoutLogState;
  dispatch: (action: Action) => void;
};
