import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { TemplateLogRequirement } from "../../../types";

export interface TemplateLogRequirementsUpdate {
  templateLogRequirements: TemplateLogRequirement[];
  success: boolean;
}

export interface TemplateLogRequirementsUpdateData {
  bulkUpdateTemplateLogRequirements: TemplateLogRequirementsUpdate | null;
}

export interface TemplateLogRequirementsUpdateVariables {
  accountId: string;
  templateCloseoutLogId: number;
  templateLogRequirementIds: number[];
  attributes: Record<string, any>;
}

export const useBulkUpdateTemplateLogRequirements = createMutationHook<
  TemplateLogRequirementsUpdateData,
  TemplateLogRequirementsUpdateVariables
>(gql`
  mutation BulkUpdateTemplateLogRequirements(
    $accountId: ID!,
    $templateCloseoutLogId: ID!,
    $templateLogRequirementIds: [ID!]!,
    $attributes: JSON!
  ) {
    bulkUpdateTemplateLogRequirements(
      input: { accountId: $accountId, templateCloseoutLogId: $templateCloseoutLogId, templateLogRequirementIds: $templateLogRequirementIds, attributes: $attributes }
    ) {
      templateLogRequirements {
        id
        description
        templateLogRequirementType {
          id
          name
        }
        templateLogRequirementTrade {
          id
          name
        }
        templateLogCustomFields {
          id
          key
          fieldType
          value {
            id
            value
          }
        }
      }
      success
    }
  }
`);
