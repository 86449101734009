import React from 'react';
import { GridRowGroupingModel } from '@mui/x-data-grid-premium';
import {
  Popover,
  MenuItem,
  styled,
  IconButton,
  IconButtonProps,
  Tooltip,
} from '@mui/material';
import SegmentOutlined from '@mui/icons-material/SegmentOutlined';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Typography } from './Typography';
import { Button } from './Button';

const CheckedIconButton = styled(IconButton)<IconButtonProps>(() => {
  return {
    height: '20px',
    width: '20px',
    background: '#007BFF',
    color: 'white',
    borderRadius: '2px',
    marginRight: '8px',
    padding: '9px',
    '&:hover': {
      background: '#007BFF',
    },
  };
});

const UncheckedIconButton = styled(IconButton)<IconButtonProps>(() => {
  return {
    height: '20px',
    width: '20px',
    background: 'white',
    border: '2px solid #CED4DA',
    marginRight: '8px',
    borderRadius: '2px',
    padding: '9px',
    '&:hover': {
      background: 'white',
    },
  };
});

export type GroupMenuOption = {
  field: string;
  headerName: string;
}

export interface IGroupSelectProps {
  groupMenuOptions: GroupMenuOption[];
  gridRowGroupingModel: GridRowGroupingModel;
  setGridRowGroupingModel: (selectedGroupings: string[]) => void;
  disabled: boolean;
  showLabel?: boolean;
}

export const GroupingSelect = ({
  groupMenuOptions,
  gridRowGroupingModel,
  setGridRowGroupingModel,
  disabled,
  showLabel = true,
}: IGroupSelectProps) => {
  const handleOnClick = (newValue: GridRowGroupingModel, exists: boolean) => {
    if (!exists) {
      setGridRowGroupingModel(gridRowGroupingModel.concat(newValue));
    } else {
      setGridRowGroupingModel(
        gridRowGroupingModel.filter((val) => val !== newValue.toString())
      );
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>(null);

  return (
    <>
      { showLabel ? (
        <Button
          variant="outlined"
          buttonborderstyle="pill"
          disabled={disabled}
          onClick={(evt: React.MouseEvent<HTMLButtonElement>) =>
            setAnchorEl(evt.currentTarget)
          }
          startIcon= {<SegmentOutlined fontSize="small"/>}
          endIcon= {<ArrowDropDown fontSize="small"/>}
        >
          {gridRowGroupingModel.length === 0
            ? 'Group'
            : `Group (${gridRowGroupingModel.length})`}
        </Button>
      ) : (
        <Tooltip title="Group" placement="top">
          <Button
            sx={{
              '& .MuiButton-startIcon': {
                marginRight: 0,
              },
              '& .MuiButton-endIcon': {
                marginLeft: 0,
              }
            }}
            variant="outlined"
            buttonborderstyle="pill"
            disabled={disabled}
            onClick={(evt: React.MouseEvent<HTMLButtonElement>) =>
              setAnchorEl(evt.currentTarget)
            }
            startIcon= {<SegmentOutlined fontSize="small"/>}
            endIcon= {<ArrowDropDown fontSize="small"/>}
          />
        </Tooltip>
      )}

      <Popover
        slotProps={{ paper: { sx: { padding: '12px 0px' } } }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {groupMenuOptions.map((groupMenuOption) => {
          const indexOf = gridRowGroupingModel.indexOf(groupMenuOption.field);
          const exists = indexOf !== -1;

          if (exists) {
            return (
              <MenuItem
                onClick={() =>
                  handleOnClick(
                    [groupMenuOption.field],
                    exists
                  )
                }
                key={`grouping-menu-item-${groupMenuOption.field}`}
                value={groupMenuOption.field}
              >
                <CheckedIconButton
                  key={`grouping-icon-button-${groupMenuOption.field}`}
                  disableFocusRipple={true}
                  disableRipple={true}
                >
                  <Typography variant="body2">{indexOf + 1}</Typography>
                </CheckedIconButton>
                <Typography typestyle='m'>{groupMenuOption.headerName}</Typography>
              </MenuItem>
            );
          } else {
            return (
              <MenuItem
                onClick={() =>
                  handleOnClick(
                    [groupMenuOption.field],
                    exists
                  )
                }
                key={`grouping-menu-item-${groupMenuOption.field}`}
                value={groupMenuOption.field}
              >
                <UncheckedIconButton
                  key={`grouping-icon-button-${groupMenuOption.field}`}
                  disableFocusRipple={true}
                  disableRipple={true}
                ></UncheckedIconButton>
                <Typography typestyle='m'>{groupMenuOption.headerName}</Typography>
              </MenuItem>
            );
          }
        })}
      </Popover>
    </>
  );
};
