import React, { useState } from 'react';
import {
  NewLogRequirementTrade,
  NewLogRequirementType,
} from '../types';
import { AccountProjectContext } from '../contexts/AccountProjectContextProvider';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box'
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Typography } from './shared/Typography';
import { LoadingButton } from './shared/Button';
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close';
import AddCircle from '@mui/icons-material/AddCircle';
import { Button } from './shared/Button';
import { ExtractsCheckbox } from '../styles';
import { CircularProgress } from '@mui/material';
import { isEmpty, templateLogRequirementFrom } from '../utils/utils';
import { TemplateCloseoutLogContext } from '../contexts/TemplateCloseoutLogContextProvider';
import { useCreateTemplateLogRequirements } from '../graphql/mutations/closeout/CreateTemplateLogRequirements';
import { useCreateTemplateLogRequirementType } from '../graphql/mutations/closeout/CreateTemplateLogRequirementType';
import { useCreateTemplateLogRequirementTrade } from '../graphql/mutations/closeout/CreateTemplateLogRequirementTrade';

interface INewTemplateRequirementModalProps {
  isOpen: boolean;
  closeAddReqModal: () => void;
}

const getAddButtonText = (bulkCount: number) => {
  if (bulkCount > 1) {
    return 'Add ' + bulkCount + ' items';
  } else {
    return 'Add';
  }
};

const reqTypeFilter = createFilterOptions<NewLogRequirementType>();
const reqTradeFilter = createFilterOptions<NewLogRequirementTrade>();

const NewTemplateRequirementModal = (props: INewTemplateRequirementModalProps): JSX.Element => {
  const { state: templateCloseoutLogState, dispatch } = React.useContext(TemplateCloseoutLogContext);
  const { state } = React.useContext(AccountProjectContext);

  const [selectedTemplateLogReqTypes, setSelectedTemplateLogReqTypes] = useState<NewLogRequirementType[]>([]);
  const [selectedTemplateLogReqTrades, setSelectedTemplateLogReqTrades] = useState<NewLogRequirementTrade[]>([]);
  const [templateLogReqDescription, setTemplateLogReqDescription] = useState<string>('');
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [confirmCloseModal, setConfirmCloseModal] = useState<boolean>(false);

  const [createTemplateLogRequirements, { loading }] = useCreateTemplateLogRequirements({
    accountId: state.selectedAccount,
    templateCloseoutLogId: templateCloseoutLogState.id,
    templateLogRequirementAttributes: [],
  });

  const [ createTemplateType, { loading: loadingCreateTemplateType } ] = useCreateTemplateLogRequirementType({
    accountId: state.selectedAccount,
    templateCloseoutLogId: templateCloseoutLogState.id,
    name: ''
  });

  const [ createTemplateTrade, { loading: loadingCreateTemplateTrade } ] = useCreateTemplateLogRequirementTrade({
    accountId: state.selectedAccount,
    templateCloseoutLogId: templateCloseoutLogState.id,
    name: ''
  });

  const createAndSelectLogRequirementType = (name: string) => {
    createTemplateType({
      variables: {
        accountId: state.selectedAccount,
        templateCloseoutLogId: templateCloseoutLogState.id,
        name: name,
      }
    }).then(({ data }) => {
      if (data.createTemplateLogRequirementType.success) {
        setSelectedTemplateLogReqTypes([
          ...selectedTemplateLogReqTypes,
          data.createTemplateLogRequirementType.templateLogRequirementType
        ]);

        dispatch({
          type: 'ADD_AND_SORT_TEMPLATE_LOG_REQUIREMENT_TYPE',
          value: data.createTemplateLogRequirementType.templateLogRequirementType
        });
      }
    });
  }

  const createAndSelectTrade = (name: string) => {
    createTemplateTrade({
      variables: {
        accountId: state.selectedAccount,
        templateCloseoutLogId: templateCloseoutLogState.id,
        name: name,
      }
    }).then(({ data }) => {
      if (data.createTemplateLogRequirementTrade.success) {
        setSelectedTemplateLogReqTrades([
          ...selectedTemplateLogReqTrades,
          data.createTemplateLogRequirementTrade.templateLogRequirementTrade
        ]);

        dispatch({
          type: 'ADD_AND_SORT_TEMPLATE_LOG_REQUIREMENT_TRADE',
          value: data.createTemplateLogRequirementTrade.templateLogRequirementTrade
        });
      }
    });
  }

  const handleOnAddRequirementsClick = () => {
    if (requestInProgress) { return; }

    setRequestInProgress(true);

    const logRequirementAttributes = selectedTemplateLogReqTypes.reduce((acc, logReqType) => {
      selectedTemplateLogReqTrades.forEach(logReqTrade => {
        acc.push(
          templateLogRequirementFrom({
            type: logReqType,
            trade: logReqTrade,
            description: templateLogReqDescription,
          })
        )
      });

      if (selectedTemplateLogReqTrades.length === 0) {
        acc.push(
          templateLogRequirementFrom({
            type: logReqType,
            trade: null,
            description: templateLogReqDescription,
          })
        );
      }

      return acc;
    }, []);

    createTemplateLogRequirements({
      variables: {
        accountId: state.selectedAccount,
        templateCloseoutLogId: templateCloseoutLogState.id,
        templateLogRequirementAttributes: logRequirementAttributes,
      }
    }).then(({ data }) => {
        if (data.createTemplateLogRequirements.success) {
          dispatch({
            type:'APPEND_TEMPLATE_LOG_REQUIREMENTS',
            value: {
              templateLogRequirements: data.createTemplateLogRequirements.templateLogRequirements,
              triggeredFrom: 'APPEND_TEMPLATE_LOG_REQUIREMENTS'
            }
          });
          setSelectedTemplateLogReqTypes([]);
          setTemplateLogReqDescription('');
          handleOnClose();
        }

      setRequestInProgress(false);
    }).catch(() => {
      setRequestInProgress(false);
    })
  };

  const handleOnClose = () => {
    // TODO: Reset other state

    props.closeAddReqModal();
  }

  const checkFormDirty = () => {
    return (selectedTemplateLogReqTypes.length > 0 || !isEmpty(templateLogReqDescription))
  }

  const checkDirtyAndClose = () => {
    if (checkFormDirty()) {
      setConfirmCloseModal(true);
    }
    else {
      handleOnClose();
    }
  }

  return (
    <>
      <Dialog
          fullWidth
          disableScrollLock
          maxWidth="xs"
          open={confirmCloseModal}
          onClose={() => setConfirmCloseModal(false)}
        >
        <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
      >
        <Typography typestyle="xl">Unsaved Data</Typography>
          <Typography>{`If you leave this form the data you will lose any data you've entered. Would you like to leave anyway?`}</Typography>
        <Box display="flex" justifyContent="end" gap="8px">
          <Button
            variant="outlined"
            buttonborderstyle="pill"
            onClick={() => setConfirmCloseModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            buttonborderstyle="pill"
            onClick={handleOnClose}
          >
            Leave Anyway
          </Button>
        </Box>
      </DialogContent>
    </Dialog>

      <Dialog
        maxWidth={false}
        fullScreen
        open={props.isOpen}
        onClose={checkDirtyAndClose}
        PaperProps={{
          sx: {
            position: 'absolute',
            width: 500,
            bottom: '0px',
            top: '0px',
            right: '0px',
          },
        }}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '4px 0px 8px 0px',
          }}>
            <Typography typestyle="xl">{'Add Requirements'}</Typography>
            <IconButton onClick={checkDirtyAndClose}>
                <Close sx={{fontSize: '18px'}}/>
              </IconButton>
          </Box>
          <Autocomplete
            freeSolo
            value={selectedTemplateLogReqTypes}
            disableCloseOnSelect
            loading={loadingCreateTemplateType}
            isOptionEqualToValue={(option, value) => (option.name || '').toLowerCase() === (value.name || '').toLowerCase()}
            disabled={requestInProgress}
            renderTags={() => { return null; }}
            renderOption={(props, option, { selected })=>{
              const isNew = !templateCloseoutLogState.templateLogRequirementTypes.some(type => type.name.localeCompare(option.name, undefined, { numeric: true, sensitivity: 'base' }) === 0)
              if (isNew) {
                return (
                  <li {...props}>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}>
                      <AddCircle fontSize='small'/>
                      {`Add new type: "${option.name}"`}
                    </Box>
                  </li>
                )
              }
              else {
                return (
                  <li {...props}>
                    <ExtractsCheckbox
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '1.2rem',
                        },
                      }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )
              }
            }}
            filterOptions={(options, params) => {
              const filtered = reqTypeFilter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value if not blank and no exact match
              const isExisting = options.some((option) => inputValue.localeCompare(option.name, undefined, { numeric: true, sensitivity: 'base' }) === 0);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  name: inputValue,
                  id: undefined,
                } as NewLogRequirementType);
              }

              return filtered;
            }}
            onChange={(evt, values, reason) => {
              switch (reason) {
                case 'clear':{
                  setSelectedTemplateLogReqTypes([]);
                  break;
                }

                case 'createOption':
                case 'removeOption':
                case 'selectOption': {
                  const newValues = values.reduce((acc, value) => {
                    if (typeof value === 'string') {
                      createAndSelectLogRequirementType(value);
                    } else if (value.id === undefined) {
                      createAndSelectLogRequirementType(value.name);
                    } else {
                      acc.push(value);
                    }

                    return acc;
                  }, []);

                  setSelectedTemplateLogReqTypes(newValues);
                  break;
                }

                default:
                  break;
              }
            }}
            clearOnBlur
            handleHomeEndKeys
            multiple={true}
            slotProps={{
              listbox: {
                sx: {
                  maxHeight: '200px',
                },
              }
            }}
            renderInput={(params) => {
              const count = selectedTemplateLogReqTypes.length;

              const label = (count > 0) ? `Type (${count})` : 'Type';

              return (
                <TextField
                  {...params}
                  label={label}
                  required
                  autoFocus
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingCreateTemplateType ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }
                  }} />
              );
            }}
            getOptionLabel={(option) => {
              return (option as NewLogRequirementType).name;
            }}
            options={templateCloseoutLogState.templateLogRequirementTypes}
          />
          <TextField
            disabled={requestInProgress}
            size="medium"
            multiline
            rows={8}
            autoComplete="off"
            label="Description"
            value={templateLogReqDescription}
            onChange={(event) =>
              setTemplateLogReqDescription(event.currentTarget.value)
            }
          />
          <Autocomplete
            freeSolo
            value={selectedTemplateLogReqTrades}
            disableCloseOnSelect
            loading={loadingCreateTemplateTrade}
            isOptionEqualToValue={(option, value) => (option.name || '').toLowerCase() === (value.name || '').toLowerCase()}
            disabled={requestInProgress}
            renderTags={() => { return null; }}
            renderOption={(props, option, { selected })=>{
              const isNew = !templateCloseoutLogState.templateLogRequirementTrades.some(trade => trade.name.localeCompare(option.name, undefined, { numeric: true, sensitivity: 'base' }) === 0)
              if (isNew) {
                return (
                  <li {...props}>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}>
                      <AddCircle fontSize='small'/>
                      {`Add new trade: "${option.name}"`}
                    </Box>
                  </li>
                )
              }
              else {
                return (
                  <li {...props}>
                    <ExtractsCheckbox
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: '1.2rem',
                        },
                      }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )
              }
            }}
            filterOptions={(options, params) => {
              const filtered = reqTradeFilter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value if not blank and no exact match
              const isExisting = options.some((option) => inputValue.localeCompare(option.name, undefined, { numeric: true, sensitivity: 'base' }) === 0);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  name: inputValue,
                  id: undefined,
                } as NewLogRequirementTrade);
              }

              return filtered;
            }}
            onChange={(evt, values, reason) => {
              switch (reason) {
                case 'clear':{
                  setSelectedTemplateLogReqTrades([]);
                  break;
                }

                case 'createOption':
                case 'removeOption':
                case 'selectOption': {
                  const newValues = values.reduce((acc, value) => {
                    if (typeof value === 'string') {
                      createAndSelectTrade(value);
                    } else if (value.id === undefined) {
                      createAndSelectTrade(value.name);
                    } else {
                      acc.push(value);
                    }

                    return acc;
                  }, []);

                  setSelectedTemplateLogReqTrades(newValues);
                  break;
                }

                default:
                  break;
              }
            }}
            clearOnBlur
            handleHomeEndKeys
            multiple={true}
            slotProps={{
              listbox: {
                sx: {
                  maxHeight: '200px',
                },
              }
            }}
            renderInput={(params) => {
              const count = selectedTemplateLogReqTrades.length;

              const label = (count > 0) ? `Trade (${count})` : 'Trade';

              return (
                <TextField
                  {...params}
                  label={label}
                  required
                  autoFocus
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingCreateTemplateTrade ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }
                  }}
                />
              );
            }}
            getOptionLabel={(option) => {
              return (option as NewLogRequirementTrade).name;
            }}
            options={templateCloseoutLogState.templateLogRequirementTrades}
          />
          <LoadingButton
            size="large"
            buttonborderstyle="pill"
            variant="contained"
            disabled={selectedTemplateLogReqTypes.length === 0}
            onClick={handleOnAddRequirementsClick}
            loading={requestInProgress}
          >
            <span>
              {!requestInProgress && getAddButtonText(selectedTemplateLogReqTypes.length * (selectedTemplateLogReqTrades.length || 1))}
            </span>
          </LoadingButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewTemplateRequirementModal;
