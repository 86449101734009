import {
  Box,
  IconButton,
  InputBase,
  Tooltip,
} from "@mui/material";
import React, { useRef, useState, KeyboardEvent } from "react";
import Loading from "../shared/Loading";
import { Account } from "../../types";
import idx from "idx";
import { Colors, ExtractsCheckbox, StyledDataGridPremium } from "../../styles";
import { gridFilteredSortedRowIdsSelector, GRID_CHECKBOX_SELECTION_COL_DEF, GRID_CHECKBOX_SELECTION_FIELD, GridColDef, useGridApiRef, GridCellParams, GridRowId, GridRow, GridCell, GridColumnHeaders } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { AccountUserDataGridSelectionPopup } from "../shared/AccountUserDataGridSelectionPopup";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useGetProcoreCompanyUsersWithAccess } from "../../graphql/queries/GetProcoreCompanyUsers";
import { Typography } from "../shared/Typography";
import { UpdateUserRoleModal } from "../UpdateUserRoleModal";
import { ManageUsersContextProvider } from "../../contexts/ManageUsersContextProvider";
import ManageUsersDataGridModal from "./ManageUsersDataGridModal";
import { useRemoveUsersFromAccount } from "../../graphql/mutations/RemoveUsersFromAccount";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import SearchIcon from "@mui/icons-material/Search";
import { SearchCloseIcon, SearchCloseIconButton } from "./styles";
import AddCircle from "@mui/icons-material/AddCircle";
import { Button } from "../shared/Button";
import FilterList from '@mui/icons-material/FilterList';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useRefreshCompanyUsers } from "../../graphql/mutations/RefreshCompanyUsers";
import { ManageUsersFilterPopover } from "../ManageUsersFilterPopover";
import { SelectFilterValue } from "../../types/submittal";
import { procoreFilterToInputFilters } from "../../utils/utils";
import { useGetProcoreCompanyUserFilters } from "../../graphql/queries/GetProcoreCompanyUserFilters";

const DEFAULT_COL_DEF = {
  editable: false,
  sortable: false,
  groupable: false,
  resizable: false,
  hideSortIcons: true,
  pinnable: false,
  disableColumnMenu: true,
  flex: 1,
};

const ActiveDirectoryUserPill = (props: { active: boolean }): JSX.Element => {
  const { active } = props;

  return (
    <Box
      margin={0}
      fontWeight={'bold'}
      style={{
        color: active ? Colors.darkGreen : Colors.darkerRed,
        backgroundColor: active ? "#DCF5EE" : Colors.lightRed,
        fontSize: '14px',
        textAlign: 'center',
        padding: '4px 10px',
        borderRadius: '12px',
        maxWidth: 'fit-content'
      }}
    >
      <Typography typestyle="s bold">
        {active ? 'Active' : 'Inactive'}
      </Typography>
    </Box>
  )
};

const transformColumns = (
  hoveredRowId: string,
  accountUserRowSelectionModel: GridRowId[],
  getApiRef: () => React.MutableRefObject<GridApiPremium>,
  onSelectedRowsChange: (selectedRows: GridRowId[]) => void,
  onRemoveUserAccess: (id: number) => void): GridColDef[] => {

    const dataGridApiRef = getApiRef();
    const selectedRowsMap = accountUserRowSelectionModel.reduce((acc, rowId) => {
      acc[rowId] = true;
      return acc;
    }, {});

    return [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        field: GRID_CHECKBOX_SELECTION_FIELD,
        headerName: 'Select',
        type: 'custom',
        renderHeader: () => {
          const filteredAndSortedRowIds = gridFilteredSortedRowIdsSelector(dataGridApiRef.current.state, dataGridApiRef.current.instanceId);
          const visibleIds = dataGridApiRef.current.getAllRowIds().filter(rowId => filteredAndSortedRowIds.includes(rowId));
          return (
            <ExtractsCheckbox
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: '1.2rem',
                },
              }}
              checked={
                visibleIds.every(id => selectedRowsMap[id])
              }
              indeterminate={
                visibleIds.some(id => selectedRowsMap[id]) && !visibleIds.every(id => selectedRowsMap[id])
              }
              onChange={(e) => {
                if (e.target.checked) {
                  onSelectedRowsChange(
                    Array.from(new Set([...accountUserRowSelectionModel, ...visibleIds]))
                  )
                } else {
                  onSelectedRowsChange(
                    accountUserRowSelectionModel.filter(id => !visibleIds.includes(id))
                  )
                }
              }}
            />
          )
        },
        renderCell: (params: GridCellParams) => {
          return (
            <ExtractsCheckbox
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: '1.2rem',
                },
              }}
              checked={accountUserRowSelectionModel.includes(params.row.id as GridRowId)}
              onChange={(e) => {
                if (e.target.checked) {
                  onSelectedRowsChange([...accountUserRowSelectionModel, params.row.id]);
                } else {
                  onSelectedRowsChange(accountUserRowSelectionModel.filter( (id) => id !== params.row.id));
                }
              }}
            />
          )
        },
      },
      {
        field: 'name',
        headerName: 'Name',
        ...DEFAULT_COL_DEF,
        type: 'string',
      },
      {
        field: 'vendorName',
        headerName: 'Company',
        ...DEFAULT_COL_DEF,
        type: 'string',
      },
      {
        field: 'isActive',
        headerName: 'Procore Status',
        ...DEFAULT_COL_DEF,
        type: 'custom',
        renderCell: (params) => {
          return (
            <Box display={'flex'} flexDirection={'row'} width={1} height={1} alignItems={'center'}>
              <ActiveDirectoryUserPill active={params.row.isActive} />
            </Box>
          );
        }
      },
      {
        field: 'formattedRole',
        headerName: 'Role',
        ...DEFAULT_COL_DEF,
        type: 'string',
      },
      {
        ...DEFAULT_COL_DEF,
        field: 'actions',
        type: 'actions',
        headerName: '',
        flex: 0,
        width: 120,
        editable: false,
        resizable: false,
        sortable: false,
        groupable: false,
        renderCell: (params) => {
          return (
            <Box display="flex" alignItems={'center'}>
              { params.row.id === hoveredRowId && (
                <Tooltip title={`Remove Access`} placement="top">
                  <IconButton sx={{
                    "&:hover": {
                      background: 'transparent',
                    },
                  }} onClick={() => {
                    onRemoveUserAccess(params.row.id as number);
                  }}>
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          );
        },
      },
    ];
}

const AccountUsersDataGrid = (props: {
  account: Account,
}): JSX.Element => {
  let timeout;
  const { account } = props;

  const isMounted = useRef(false);
  const [hoveredRowId, setHoveredRowId] = useState<string>(null);
  const accountUsersDataGridApiRef = useGridApiRef();
  const [accountUserRowSelectionModel, setAccountUserRowSelectionModel] =  useState<GridRowId[]>([]);
  const [openBulkUpdateUserRoleModal, setOpenBulkUpdateUserRoleModal] = useState(false);
  const [searchUsersValue, setSearchUsersValue] = useState("");
  const [pendingSearchUserValue, setPendingSearchUserValue] = useState("");
  const [removeUserModal, setRemoveUserModal] = useState<{isOpen: boolean, ids: number[] }>({ isOpen: false, ids: [] });
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });
  const [rowCountState, setRowCountState] = useState(0);
  const [filterPopoverAnchorEl, setFilterPopoverAnchorEl] = useState<HTMLButtonElement>(null);
  const [isManageUsersModalOpen, setIsManageUsersModalOpen] = useState(false);
  const [isRefreshingCompanyUsers, setIsRefreshingCompanyUsers] = useState(false);
  const [pendingFilterValues, setPendingFilterValues] = useState<Record<string, SelectFilterValue[]>>({});
  const [selectedFilterValues, setSelectedFilterValues] = useState<Record<string, SelectFilterValue[]>>({});

  const [
    loadProcoreCompanyUserFilters,
    { data: procoreCompanyUserFiltersData }
  ] = useGetProcoreCompanyUserFilters({
    accountId: account.id,
  });

  const filters = (procoreCompanyUserFiltersData && procoreCompanyUserFiltersData.filters || []).filter((f) => {
    return (f.values || []).length > 0 && f.key === "is_active"
  })

  const { loading, data, refetch } = useGetProcoreCompanyUsersWithAccess(
    account.id,
    procoreFilterToInputFilters(
      filters.filter((f) => selectedFilterValues[f.key] && selectedFilterValues[f.key].length > 0).map((f) =>{
        return {
          ...f,
          values: selectedFilterValues[f.key],
        }
      })
    ),
    searchUsersValue,
    paginationModel.pageSize,
    paginationModel.page * paginationModel.pageSize,
  );

  const [
    refreshCompanyUsers, { loading: loadingRefreshCompanyUsers }
  ] = useRefreshCompanyUsers({ accountId: account.id });

  React.useEffect(() => {
    if (isMounted && !setFilterPopoverAnchorEl) {
      refetch();
    }

  }, [setFilterPopoverAnchorEl]);

  React.useEffect(() => {
    if (isRefreshingCompanyUsers) {
      timeout = setTimeout(() => {
        setIsRefreshingCompanyUsers(false);
      }, 5000);
    } else {
      setIsRefreshingCompanyUsers(false);
      clearTimeout(timeout);
    }
  }, [isRefreshingCompanyUsers]);

  React.useEffect(() => {
    if (loading === true || !data) {
      return;
    }

    setRowCountState(data.procoreCompanyUserRecordServerIds.procoreServerIds.length);
  }, [data]);

  React.useEffect(() => {
    loadProcoreCompanyUserFilters();
  }, []);

  const MemoizedRow = React.memo(GridRow);
  const MemoizedCell = React.memo(GridCell);
  const MemoizedColumnHeaders = React.memo(GridColumnHeaders);

  const slots = React.useMemo(() => {
    return {
      row: MemoizedRow,
      cell: MemoizedCell,
      columnHeaders: MemoizedColumnHeaders,
    };
  }, []);

  const [
    removeUsersFromAccount,
    { loading: loadingRemoveUsersFromAccount, data: removeUsersFromAccountData },
  ] = useRemoveUsersFromAccount({
    accountId: account.id,
    procoreLoginInformationIds: [],
  });

  React.useEffect(() => {
    if (
      loadingRemoveUsersFromAccount === undefined ||
      loadingRemoveUsersFromAccount === true ||
      !removeUsersFromAccountData
    ) {
      return;
    }

    if (removeUsersFromAccountData.removeUsersFromAccount.success) {
      refetch();
    }
  }, [removeUsersFromAccountData]);

  React.useEffect(() => {
    if (isMounted.current) {
      refetch();
    } else {
      isMounted.current = true;
    }
  }, []);

  const columns = React.useMemo(() => { return transformColumns(
    hoveredRowId,
    accountUserRowSelectionModel,
    () => accountUsersDataGridApiRef,
    (gridRowIds) => { setAccountUserRowSelectionModel(gridRowIds) },
    (id) => { setRemoveUserModal({isOpen: true, ids: [id] }) },
    ) }, [hoveredRowId, accountUserRowSelectionModel]);

  if (loading) {
    return <Loading loadingLabel="Loading users..." />;
  }

  const usersWithAccess = idx(data, d => d.procoreCompanyUsers) || [];

  const onHandleRemoveUsersFromAccount = () => {
    removeUsersFromAccount({
      variables: {
        accountId: account.id,
        procoreLoginInformationIds: removeUserModal.ids,
      }
    }).then(({ data }) => {
      if (data.removeUsersFromAccount.success) {
        refetch();
        setAccountUserRowSelectionModel([]);
        setRemoveUserModal({isOpen: false, ids: [] });
      }
    })
  }

  return (
    <>
      <Box
        height={1}
        width={1}
        display={"flex"}
        flexDirection={"column"}
      >
        <Box display="flex" gap="8px" flexDirection={"row"} alignItems={"center"} justifyContent={'space-between'} borderTop={`1px solid ${Colors.lightishGray}`} padding={'20px 0px'}>
          <Box display={'flex'} flexDirection={'row'} gap={'8px'} alignItems={'center'} justifyContent={'flex-start'}>
            <Box
              sx={{
                minHeight: '40px',
                maxHeight: '40px',
                borderRadius: '20px',
                paddingRight: '4px',
                border: `1px solid ${Colors.mediumGray}`,
                '&:hover': {
                  border: `1px solid ${Colors.mediumDarkGray}`,
                },
                marginLeft: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: '12px'
              }}>
              <SearchIcon sx={{fontSize: '18px'}}/>
              <InputBase
                sx={{
                  ml: 1,
                  flex: 1,
                  fontSize: '0.875rem',
                }}
                fullWidth={false}
                placeholder="Search users..."
                value={pendingSearchUserValue}
                endAdornment={
                  (pendingSearchUserValue || "").trim() ? (
                    <SearchCloseIconButton
                      size={"small"}
                      onClick={() => {
                        setSearchUsersValue("");
                        setPendingSearchUserValue("");
                      }}
                      style={{
                        visibility: (pendingSearchUserValue || "").trim()
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      <SearchCloseIcon />
                    </SearchCloseIconButton>
                  ) : null
                }
                onKeyDown={(evt: KeyboardEvent<HTMLInputElement>) => {
                  if (evt.key === "Enter") {
                    evt.preventDefault();
                    setSearchUsersValue(pendingSearchUserValue);
                  }
                }}
                onChange={(evt) => {
                  setPendingSearchUserValue(evt.target.value);
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </Box>
            <Button
              buttonborderstyle="pill"
              variant="outlined"
              startIcon={<FilterList fontSize="small" />}
              endIcon={<ArrowDropDown fontSize="small" />}
              disabled={loading}
              onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                setFilterPopoverAnchorEl(evt.currentTarget)
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                {Object.keys(selectedFilterValues).length > 0 ? `Filter (${Object.keys(selectedFilterValues).length})` : 'Filter' }
              </Box>
            </Button>
          </Box>
          <Box display={'flex'} flexDirection={'row'} gap={'8px'} alignItems={'center'} justifyContent={'flex-end'}>
            <Button
              buttonborderstyle='pill'
              startIcon={<AddCircle/>}
              disabled={loading}
              variant="outlined"
              onClick={() => {
                setIsManageUsersModalOpen(true);
              }}
              disableElevation={true}
            >
              Add Users
            </Button>
            <Button
              buttonborderstyle='pill'
              startIcon={<RefreshIcon />}
              disabled={loading || loadingRefreshCompanyUsers || isRefreshingCompanyUsers}
              variant="outlined"
              onClick={() => {
                setIsRefreshingCompanyUsers(true);
                refreshCompanyUsers();
              }}
              disableElevation={true}
            >
              Refresh Company Users
            </Button>
          </Box>
        </Box>
        <StyledDataGridPremium
          apiRef={accountUsersDataGridApiRef}
          checkboxSelection={true}
          disableColumnMenu
          rowCount={rowCountState}
          paginationMode="server"
          pagination={true}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[25, 50, 100]}
          paginationModel={paginationModel}
          slotProps={{
            row: {
              onMouseEnter: (
                event: React.MouseEvent<HTMLDivElement, MouseEvent>
              ) => setHoveredRowId(event.currentTarget.dataset.id),
              onMouseLeave: () => setHoveredRowId(null),
            },
          }}
          slots={slots}
          rows={usersWithAccess}
          rowSelection={false}
          columns={columns}
          initialState={{
            density: 'standard',
          }}
          columnHeaderHeight={40}
          sx={{
            '&.MuiDataGrid-root': {
              borderRadius: '8px 8px 0px 0px',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'inherit',
            },
            '& .MuiDataGrid-columnHeaderCheckbox': {
              padding: '0px',
            },
          }}
        />
      </Box>

      <AccountUserDataGridSelectionPopup
        open={accountUserRowSelectionModel.length > 0}
        rowSelectionModel={accountUserRowSelectionModel}
        onBulkChangeRole={() => {
          setOpenBulkUpdateUserRoleModal(true);
        }}
        onDeselect={() => {
          setAccountUserRowSelectionModel([]);
        }}
        onDelete={() => {
          setRemoveUserModal({
            isOpen: true,
            ids: accountUserRowSelectionModel as number[],
          })
        }}
      />

      {
        openBulkUpdateUserRoleModal && (
          <UpdateUserRoleModal
            accountId={account.id}
            rowSelectionModel={accountUserRowSelectionModel}
            loading={loading}
            onClose={(refresh) => {
              if (refresh) {
                refetch();
                setAccountUserRowSelectionModel([]);
              }

              setOpenBulkUpdateUserRoleModal(false);
            }}
            open={openBulkUpdateUserRoleModal} />
        )
      }

      {isManageUsersModalOpen && (
        <ManageUsersContextProvider>
          <ManageUsersDataGridModal
            account={account}
            handleOnClose={(refresh) => {
              if (refresh) {
                refetch();
              }

              setIsManageUsersModalOpen(false);
            }}
            open={isManageUsersModalOpen} />
        </ManageUsersContextProvider>
      )}

      <ConfirmationModal
        open={removeUserModal.isOpen}
        headerText='Confirm Remove Access'
        bodyText={`Are you sure you want to remove access?`}
        submitText="Delete"
        onSubmit={onHandleRemoveUsersFromAccount}
        onClose={ ()=> setRemoveUserModal({isOpen: false, ids: [] })}
        submitLoading={loadingRemoveUsersFromAccount}
      />

      <ManageUsersFilterPopover
        allFilters={filters}
        selectedFilterValues={pendingFilterValues}
        onResetFilters={() => {
          setPendingFilterValues({});
        }}
        onRemoveFilter={(key) => {
          setPendingFilterValues((prev) => {
            const newFilters = { ...prev };
            delete newFilters[key];
            return newFilters;
          });
        }}
        onChangeSelectedFilter={(oldKey, newKey) => {
          setPendingFilterValues((prev) => {
            const newFilters = { ...prev };
            delete newFilters[oldKey];
            newFilters[newKey] = [];
            return newFilters;
          });
        }}
        onAppendNewFilter={(key) => {
          setPendingFilterValues((prev) => {
            return {
              ...prev,
              [key]: [],
            }
          });
        }}
        onUpdateFilterValues={(key, values) => {
          setPendingFilterValues((prev) => {
            return {
              ...prev,
              [key]: values,
            }
          });
        }}
        open={Boolean(filterPopoverAnchorEl)}
        onClose={() => {
          setFilterPopoverAnchorEl(null);
          setSelectedFilterValues(pendingFilterValues);
        }}
        anchorEl={filterPopoverAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
    </>
  );
};

export default AccountUsersDataGrid;
