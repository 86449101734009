import { gql } from "@apollo/client";
import { ProcoreToolFilter } from "../../contexts/NewPackageContext";
import { createLazyQueryHook } from "../GraphqlHelpers";

export type ProcoreProjectFilterData = {
  filters: ProcoreToolFilter[];
  defaultFilters: ProcoreToolFilter[];
};

export type ProcoreProjectFilterVariables = {};

export const getProcoreProjectFiltersQuery = gql`
query GetProcoreProjectFilters {
  filters: procoreProjectFilters {
    id
    label
    type
    multiple
    dataType
    values {
      id
      key
      value
    }
    value {
      startDate
      endDate
    }
    key
  }
}
`;

export const useGetProcoreProjectFilters = createLazyQueryHook<ProcoreProjectFilterData, ProcoreProjectFilterVariables>(getProcoreProjectFiltersQuery);