import React, { useState } from 'react';
import { AccountProjectContext } from '../contexts/AccountProjectContextProvider';
import { Box, Dialog, DialogContent, IconButton, TextField, Tooltip, List, Divider } from '@mui/material';
import { Colors, FieldContainer, StyledDataGridPremium } from '../styles';
import { LogCustomField } from '../types';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Button } from './shared/Button';
import AddCircle from '@mui/icons-material/AddCircle';
import { Typography } from './shared/Typography';
import Close from '@mui/icons-material/Close';
import { isEmpty } from '../utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { useUpsertTemplateLogCustomField } from '../graphql/mutations/closeout/UpsertTemplateLogCustomField';
import { TemplateCloseoutLogContext } from '../contexts/TemplateCloseoutLogContextProvider';
import { useDeleteTemplateLogCustomField } from '../graphql/mutations/closeout/DeleteTemplateLogCustomField';

const DEFAULT_COL_DEF = {
  editable: false,
  resizable: false,
  disableReorder: true,
  sortable: false,
  groupable: false,
  flex: 1,
};

interface ICustomFieldLovEntry {
  id: number;
  value: string;
}

interface ICustomFieldLovEntryRowProps {
  item: ICustomFieldLovEntry;
  disableDelete: boolean;
  onDelete: (id: number) => void;
  onUpdate: (id: number, name: string) => void;
}

const TemplateCustomFieldLovEntryRow = ({ item, disableDelete, onDelete, onUpdate }: ICustomFieldLovEntryRowProps): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(item.value);
  return (
    <Box
      display="flex"
      gap="2px"
      padding="12px 12px 12px 16px"
      borderBottom={`1px solid ${Colors.mediumLightGray}`}
      fontSize={'14px'}
      alignItems={'center'}
    >
      {
        isEditing ? (
        <TextField
          sx={{
            '& .MuiOutlinedInput-input': {
                padding: "2px 12px",
            },
          }}
            variant='outlined'
          fullWidth
          autoFocus
          inputRef={(input) => input && input.focus()}
          onFocus={(evt) => setNewName(item.value)}
          onBlur={() => {
            if (!isEmpty(newName) && newName !== item.value) {
              onUpdate(item.id, newName);
              setIsEditing(false);
                setNewName('')
            } else {
              setIsEditing(false);
              setNewName('');
            }
          }}
          value={newName}
          onKeyDown={(evt: any) => {
              if (evt.key === 'Enter' || evt.key === 'Escape' || evt.key === 'Tab') {
              evt.stopPropagation();
              evt.preventDefault();
              evt.target?.blur();
            }
          }}
          onChange={(evt) => setNewName(evt.target.value) }
        />
      ) : (
        <Typography typestyle="s" flexGrow="1">
          {item.value}
        </Typography>
      )}
      <IconButton
        size="small"
        sx={{
          '& .MuiSvgIcon-root': {
            color: Colors.mediumDarkerGray,
          }
        }}
        onClick={() => setIsEditing(true)}
      >
        <EditOutlined fontSize="inherit" />
      </IconButton>
      <Tooltip
        placement='right'
        title={
          disableDelete
            ? 'This custom field value cannot be deleted because it is currently referenced by at least one closeout requirement'
            : ''
        }
      >
        <span>
          <IconButton
            size="small"
            sx={{
              '& .MuiSvgIcon-root': {
                color: Colors.mediumDarkerGray,
              }
            }}
            disabled={disableDelete}
            onClick={() => onDelete(item.id)}
          >
            <Close fontSize="inherit" />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export const ManageTemplateCloseoutLogCustomFields = (): JSX.Element => {
  const { state: accountProjectState } = React.useContext(AccountProjectContext);
  const { state, dispatch } = React.useContext(TemplateCloseoutLogContext);
  const [hoveredRowId, setHoveredRowId] = React.useState<string>(null);
  const [value, setValue] = React.useState<string>('');
  const [openCustomFieldModal, setOpenCustomFieldModal] = React.useState<{ id?: number; name: string; isOpen: boolean, values: ICustomFieldLovEntry[] }>({ id: null, name: '', isOpen: false, values: [] });

  const [ upsertTemplateLogCustomField, { loading: loadingUpsertTemplateLogCustomField } ] = useUpsertTemplateLogCustomField({
    accountId: accountProjectState.selectedAccount,
    templateCloseoutLogId: state.id,
    customField: {
      id: undefined,
      name: '',
      values: []
    }
  });

  const [ deleteTemplateLogCustomField, { loading: loadingDeleteTemplateLogCustomField } ] = useDeleteTemplateLogCustomField({
    accountId: accountProjectState.selectedAccount,
    templateCloseoutLogId: state.id,
    id: -1
  });

  const usedCustomFieldKeys = React.useMemo(() => {
    return state.templateLogRequirements.reduce((acc, currentVal) => {
      currentVal.templateLogCustomFields.forEach((customField) => {
        acc.add(customField.key);
      });

      return acc;
    }, new Set<string>())
}, [state.templateLogRequirements]);

  const usedCustomFieldLovEntryIds = React.useMemo(() => {
    return state.templateLogRequirements.reduce((acc, currentVal) => {
      currentVal.templateLogCustomFields.forEach((customField) => {
        if (customField.value && customField.value.id) {
          acc.add(customField.value.id);
        }
      });

      return acc;
    }, new Set<number>())
}, [state.templateLogRequirements]);

  const dataGridColumnDefinitions: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      ...DEFAULT_COL_DEF,
    },
    {
      field: 'values',
      headerName: 'Values',
      type: 'string',
      ...DEFAULT_COL_DEF,
      valueFormatter: (value, row, column, apiRef) => {
        return value.map((v) => v.value).join(', ');
      },
    },
    {
      ...DEFAULT_COL_DEF,
      field: 'actions',
      type: 'actions',
      headerName: '',
      flex: 0,
      width: 100,
      editable: false,
      resizable: false,
      sortable: false,
      groupable: false,
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems={'center'}>
            {params.row.id === hoveredRowId && (
              <Tooltip title="Edit Custom Field" placement="top">
                <IconButton
                  onClick={() => {
                    const logCustomField: LogCustomField = params.row;

                    setOpenCustomFieldModal({ id: logCustomField.id, name: logCustomField.name, isOpen: true, values: logCustomField.values as ICustomFieldLovEntry[] });
                  }}
                >
                  <EditOutlined sx={{ fontSize: '20px' }} />
                </IconButton>
              </Tooltip>
            )}

            {params.row.id === hoveredRowId && (
              <Tooltip title={"Delete Custom Field"} placement="top">
                <IconButton
                  disabled={usedCustomFieldKeys.has(params.row.key)}
                  onClick={() => {
                  deleteTemplateLogCustomField({
                    variables: {
                      accountId: accountProjectState.selectedAccount,
                      templateCloseoutLogId: state.id,
                      id: params.row.id
                    },
                    fetchPolicy: 'network-only'
                  }).then(({ data }) => {
                    if (data.deleteTemplateLogCustomField.success) {
                      dispatch({ type: 'DELETE_LOG_CUSTOM_FIELD', value: params.row.id });
                    }
                  });
                }}>
                  <DeleteOutline sx={{ fontSize: '20px' }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  const appendNewValue = () => {
    if (isEmpty(value) || openCustomFieldModal.values.some((lovEntry) => {
      return (lovEntry.value || '').trim().localeCompare((value || '').trim(), undefined, { numeric: true, sensitivity: 'base' }) === 0
    })) { return; }

    setOpenCustomFieldModal({
      ...openCustomFieldModal,
      values: [...openCustomFieldModal.values, { id: uuidv4(), value: value }]
    });

    setValue('');
  }

  return (
    <>
      <Box display={'flex'} flexDirection={'column'} height={1} width={1} gap={'16px'} alignItems={'self-start'}>
        <Button
          variant="outlined"
          fullWidth={false}
          startIcon={<AddCircle />}
          size="medium"
          onClick={() => {
            setOpenCustomFieldModal({ id: undefined, name: '', isOpen: true, values: [] });
          }}>
            New Custom Field
        </Button>
        <StyledDataGridPremium
          columns={dataGridColumnDefinitions}
          disableColumnMenu
          slotProps={{
            row: {
              onMouseEnter: (
                event: React.MouseEvent<HTMLDivElement, MouseEvent>
              ) => setHoveredRowId(event.currentTarget.dataset.id),
              onMouseLeave: () => setHoveredRowId(null),
            },
          }}
          rows={state.templateLogCustomFields}
          rowSelection={false}
          hideFooter={true}
          initialState={{
            density: 'standard',
          }}
          columnHeaderHeight={40}
          sx={{
            '&.MuiDataGrid-root': {
              borderRadius: '8px 8px 0px 0px',
              width: '100%',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'inherit',
            },
          }}
        />
      </Box>

      <Dialog
          disableScrollLock
          open={openCustomFieldModal.isOpen}
          PaperProps={{
            sx: {
              maxHeight: "675px",
              maxWidth: "700px",
              padding: 0,
            },
          }}
        >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '675px',
            width: '700px',
            padding: '28px',
            gap: '24px',
          }}
        >
          <Box display={'flex'} flexDirection={'row'} paddingBottom={'24px'} justifyContent={'space-between'} borderBottom={`1px solid ${Colors.mediumLightGray}`}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Typography typestyle="xl">
                {
                  openCustomFieldModal.id ? 'Edit Custom Field' : 'New Custom Field'
                }
              </Typography>
            </Box>

            <Box display={'flex'} flexDirection={'row'} gap={'20px'}>
              <Divider orientation='vertical' sx={{ height: '100%' }}/>
              <IconButton disabled={loadingUpsertTemplateLogCustomField} onClick={() => {
                setOpenCustomFieldModal({ id: undefined, name: '', isOpen: false, values: [] });
                setValue('');
              }}>
                <Close sx={{fontSize: '18px'}}/>
              </IconButton>
            </Box>
          </Box>

          <FieldContainer sx={{
            gap: '16px'
          }}>
            <Typography typestyle="l">{'Name'}</Typography>
            <TextField
              value={openCustomFieldModal.name}
              autoComplete="off"
              sx={{
                flexGrow: 1,
                '& .MuiInputBase-root': {
                  height: '44px',
                  fontSize: '0.875rem',
              },
                '& .MuiOutlinedInput-root': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: Colors.mediumGray,
                  }
                }
              }}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                setOpenCustomFieldModal({ ...openCustomFieldModal, name: evt.target.value })
              }
            />
          </FieldContainer>

          <Box display={'flex'} flexDirection={'column'} gap={'16px'} height={1}>
            <Typography typestyle="l">{'Values'}</Typography>
            <Box display="flex" gap="8px" flex={'0 0 auto'}>
              <TextField
                value={value}
                autoComplete='off'
                onChange={(e) => setValue(e.target.value)}
                placeholder="Enter a new value..."
                onKeyDown={(evt) => {
                  if (evt.key === 'Enter') {
                    appendNewValue();
                  }
                }}
                sx={{
                  flexGrow: 1,
                  '& .MuiInputBase-root': {
                    height: '44px',
                    fontSize: '0.875rem',
                },
                  '& .MuiOutlinedInput-root': {
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: Colors.mediumGray,
                    }
                  }
                }}
              />
              <Button
                variant="outlined"
                size="large"
                startIcon={<AddCircle fontSize="small" />}
                disabled={isEmpty(value) || openCustomFieldModal.values.some((lovEntry) => {
                  return (lovEntry.value || '').trim().localeCompare((value || '').trim(), undefined, { numeric: true, sensitivity: 'base' }) === 0
                })}
                onClick={() => {
                  appendNewValue();
                }}
              >
                Add
              </Button>
            </Box>
            <List
              sx={{
                flex: '1 1 0px',
                overflowY: 'auto',
                border: `1px solid ${Colors.mediumLightGray}`,
                borderRadius: '6px',
                padding: '0px',
              }}
            >
              {openCustomFieldModal.values.map((item, i) => {
                return (
                  <TemplateCustomFieldLovEntryRow key={`template-custom-field-lov-entry-row-${i}`}
                    disableDelete={usedCustomFieldLovEntryIds.has(item.id)}
                    item={item}
                    onUpdate={(id, value) => {
                      setOpenCustomFieldModal({
                        ...openCustomFieldModal,
                        values: openCustomFieldModal.values.reduce((acc, v) => {
                          if (v.id === id) {
                            return [...acc, { id, value: value }];
                          } else {
                            return [...acc, v];
                          }
                        }, [])
                      });
                    }}
                    onDelete={(id) => {
                      setOpenCustomFieldModal({
                        ...openCustomFieldModal,
                        values: openCustomFieldModal.values.reduce((acc, v) => {
                          if (v.id === id) {
                            return acc;
                          } else {
                            return [...acc, v];
                          }
                        }, [])
                      });
                    }} />
                );
              })}
            </List>
          </Box>

          <Box
            display={'flex'}
            flexDirection={'row'}
            gap={'8px'}
            alignItems={'center'}
            justifyContent={'right'}>
            <Button
              variant="outlined"
              buttonborderstyle="pill"
              disabled={loadingUpsertTemplateLogCustomField}
              onClick={() => {
              setOpenCustomFieldModal({ id: undefined, name: '', isOpen: false, values: [] });
              setValue('');
            }}>
              Cancel
            </Button>
            <Button
              disabled={isEmpty(openCustomFieldModal.name) || loadingUpsertTemplateLogCustomField}
              variant="contained"
              onClick={() => {
                upsertTemplateLogCustomField({
                  variables: {
                    accountId: accountProjectState.selectedAccount,
                    templateCloseoutLogId: state.id,
                    customField: {
                      id: openCustomFieldModal.id,
                      name: openCustomFieldModal.name,
                      values: openCustomFieldModal.values.map((v) => {
                        if (isNaN(v.id) || v.id === undefined || v.id === null) {
                          return {
                            id: undefined,
                            value: v.value
                          }
                        } else {
                          return {
                            id: v.id,
                            value: v.value
                          }
                        }
                      })
                    }
                  },
                  fetchPolicy: 'network-only'
                }).then(({ data }) => {
                  if (data.upsertTemplateLogCustomField.success) {
                    if (openCustomFieldModal.id) {
                      dispatch({ type: 'UPDATE_LOG_CUSTOM_FIELD', value: data.upsertTemplateLogCustomField.templateLogCustomField });
                    } else {
                      dispatch({ type: 'APPEND_LOG_CUSTOM_FIELD', value: data.upsertTemplateLogCustomField.templateLogCustomField });
                    }

                    setOpenCustomFieldModal({ id: undefined, name: '', isOpen: false, values: [] });
                    setValue('');
                  }
                })
              }}
              buttonborderstyle="pill">
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );

};
