import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";

export interface CloseoutLogExportOptionUpdateData {
  updateCloseoutLogExportOption: {
    success: boolean;
  }
}

interface CloseoutLogExportOptionUpdateAttributes {
  includeTableOfContents: boolean;
  includeVendorList: boolean;
  includeProjectPhotoOnCoverSheet: boolean;
  includeCustomCoverSheet: boolean;
  groupings: string[];
  importSubmittalDescriptionFrom: string;
  toolExtractOptions: Record<any, any>;
}

export interface CloseoutLogExportOptionUpdateVariables {
  accountId: string;
  procoreProjectServerId: number;
  closeoutLogId: number;
  attributes: CloseoutLogExportOptionUpdateAttributes;
}

export const useUpdateCloseoutLogExportOption = createMutationHook<
CloseoutLogExportOptionUpdateData,
CloseoutLogExportOptionUpdateVariables
>(gql`
  mutation UpdateCloseoutLogExportOption(
    $accountId: ID!,
    $procoreProjectServerId: ID!,
    $closeoutLogId: ID!,
    $attributes: CloseoutLogExportOptionUpdateAttributes!
  ) {
    updateCloseoutLogExportOption(
      input: { accountId: $accountId, procoreProjectServerId: $procoreProjectServerId, closeoutLogId: $closeoutLogId, attributes: $attributes }
    ) {
      success
    }
  }
`);
