import React from 'react';


import { TOOL_ENGINE_NAMES } from '../utils/utils';
import { GridActionsCellItem, GridColDef, GridRowsProp, gridStringOrNumberComparator } from '@mui/x-data-grid-premium';
import Close from '@mui/icons-material/Close';
import { ProcoreTool } from '../types';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Colors } from '../styles';
import { Tooltip } from '@mui/material';

export type FormattedModalItem = {
  id: number;
  recordType: string;
  recordServerId: number;
  procoreTool: ProcoreTool;
  payload: FormattedPayload;
}

type FormattedPayload =
  SubmittalPayload
  | RfiPayload
  | PunchItemPayload
  | DrawingPayload
  | DocumentPayload
  | PhotoPayload
  | FormPayload
  | InspectionPayload
  | ObservationPayload
  | MeetingPayload
  | ActionPlanPayload
  | Record<string, never>;

export type ActionPlanPayload = {
  formattedTitle: string;
  number: string;
}

export type DrawingPayload = {
  number: string;
  title: string;
  current: boolean;
  revision: string;
  drawingAreaId: number;
  drawingArea: string;
  drawingRevisionId: number;
  drawingDiscipline: string;
  drawingSet: string;
}

type DocumentPayload = {
  name: string;
  size: number;
  parentId: number;
  isDeleted: boolean;
  latestFileVersionInfo: {
    size: number;
    number: number;
    createdBy: string;
  }
}

export type PhotoPayload = {
  title: string;
  album: string;
  uploadedBy: string;
}

//Formats the payload of the item it's passed
export const formatPayload = (
  engineName: string,
  payload: Record<string, any>,
): FormattedPayload => {

  switch (engineName) {
    case TOOL_ENGINE_NAMES.SUBMITTALS: {
      return {
        distributed: payload['distributed'] || false,
        title: payload['title'] || '',
        revision: payload['revision'] || '',
        formattedTitle: payload['formatted_title'] || '',
        formattedNumber: payload['formatted_number'] || '',
        specSection: payload['spec_section'] || '',
        responsibleContractor: payload['responsible_contractor'] || '',
        status: payload['status'] || '',
        submittalType: payload['submittal_type'] || '',
        dueDate: payload['due_date'] || '',
        approvers: payload['approvers'] || [],
      };
    }

    case TOOL_ENGINE_NAMES.RFIS: {
      return {
        number: payload['number'] || '',
        subject: payload['subject'] || '',
        status: payload['status'] || '',
        formattedTitle: payload['formatted_title'] || '',
        responsibleContractor: payload['responsible_contractor'] || '',
        assignees: payload['assignees'] || [],
        ballInCourts: payload['ball_in_courts'] || [],
        dueDate: payload['due_date'] || '',
        projectStage: payload['project_stage'] || '',
        overdue: payload['overdue'] || false,
      }
    }

    case TOOL_ENGINE_NAMES.PUNCH_LIST: {
      return {
        number: payload['number'],
        title: payload['title'],
        status: payload['status'],
        assignees: payload['assignees'],
        ballInCourts: payload['ball_in_courts'],
      }
    }

    case TOOL_ENGINE_NAMES.DRAWINGS: {
      return {
        number: payload['number'],
        title: payload['title'],
        current: payload['current'],
        revision: payload['revision'],
        drawingAreaId: payload['drawing_area_id'],
        drawingArea: payload['drawing_area'],
        drawingDiscipline: payload['drawing_discipline'],
        drawingRevisionId: payload['drawing_revision_id'],
        drawingSet: payload['drawing_set'],
      }
    }

    case TOOL_ENGINE_NAMES.DOCUMENTS: {
      const latestFileVersionInfo = payload['latest_file_version_info'] || {};
      return {
        name: payload['name'],
        size: payload['size'],
        parentId: payload['parent_id'],
        isDeleted: payload['is_deleted'],
        latestFileVersionInfo: {
          size: latestFileVersionInfo['size'],
          number: latestFileVersionInfo['number'],
          createdBy: latestFileVersionInfo['created_by'],
        }
      }
    }

    case TOOL_ENGINE_NAMES.CORRESPONDENCE: {
      return {};
    }

    case TOOL_ENGINE_NAMES.FORMS: {
      return {
        name: payload['name'],
        formTemplateName: payload['form_template_name'],
        description: payload['description'],
        createdBy: payload['created_by'],
      }
    }

    case TOOL_ENGINE_NAMES.INSPECTIONS: {
      return {
        listTemplateName: payload['list_template_name'],
        identifier: payload['identifier'],
        name: payload['name'],
        status: payload['status'],
        description: payload['description'],
        inspectionType: payload['inspection_type'],
      };
    }

    case TOOL_ENGINE_NAMES.MEETINGS: {
      return {
        title: payload['title'],
        position: payload['position'],
        description: payload['description'],
      };
    }

    case TOOL_ENGINE_NAMES.ACTION_PLANS: {
      return {
        formattedTitle: payload['formatted_title'],
        number: payload['number'],
      };
    }

    case TOOL_ENGINE_NAMES.OBSERVATIONS: {
      return {
        number: payload['number'],
        typeName: payload['type_name'],
        trade: payload['trade'],
        title: payload['title'],
        description: payload['description'],
        status: payload['status'],
        createdBy: payload['created_by'],
      }
    }

    case TOOL_ENGINE_NAMES.PHOTOS: {
      return {
        title: payload['title'] || '',
        uploadedBy: payload['uploaded_by'] || '',
        album: payload['album'] || '',
      };
    }

    default: {
      return {};
    }
  }
};

export const rowsFor = (formattedItems: FormattedModalItem[]): GridRowsProp[] => {
  if (formattedItems.length === 0) {
    return [];
  } else {
    const procoreTool = formattedItems[0].procoreTool;

    switch (procoreTool.engineName) {
      case TOOL_ENGINE_NAMES.DRAWINGS: {
        return formattedItems.map((item) => {
          const payload = item.payload as DrawingPayload;

          return {
            id: item.id,
            engineName: item.procoreTool.engineName,
            recordServerId: item.recordServerId,
            ...payload
          } as unknown as GridRowsProp
        }) as GridRowsProp[];
      }

      case TOOL_ENGINE_NAMES.SUBMITTALS: {
        return formattedItems.map((item) => {
          const payload = item.payload as SubmittalPayload;

          return {
            id: item.id,
            engineName: item.procoreTool.engineName,
            recordServerId: item.recordServerId,
            ...payload
          } as unknown as GridRowsProp
        }) as GridRowsProp[];
      }

      case TOOL_ENGINE_NAMES.PHOTOS: {
        return formattedItems.map((item) => {
          const payload = item.payload as PhotoPayload;

          return {
            id: item.id,
            engineName: item.procoreTool.engineName,
            recordServerId: item.recordServerId,
            ...payload
          } as unknown as GridRowsProp
        }) as GridRowsProp[];
      }

      case TOOL_ENGINE_NAMES.RFIS: {
        return formattedItems.map((item) => {
          const payload = item.payload as RfiPayload;

          return {
            id: item.id,
            engineName: item.procoreTool.engineName,
            recordServerId: item.recordServerId,
            ...payload
          } as unknown as GridRowsProp
        }) as GridRowsProp[];
      }

      case TOOL_ENGINE_NAMES.PUNCH_LIST: {
        return formattedItems.map((item) => {
          const payload = item.payload as PunchItemPayload;

          return {
            id: item.id,
            engineName: item.procoreTool.engineName,
            recordServerId: item.recordServerId,
            ...payload
          } as unknown as GridRowsProp
        }) as GridRowsProp[];
      }

      case TOOL_ENGINE_NAMES.DOCUMENTS: {
        return formattedItems.map((item) => {
          const payload = item.payload as DocumentPayload;

          return {
            id: item.id,
            engineName: item.procoreTool.engineName,
            recordServerId: item.recordServerId,
            ...payload
          } as unknown as GridRowsProp
        }) as GridRowsProp[];
      }

      case TOOL_ENGINE_NAMES.FORMS: {
        return formattedItems.map((item) => {
          const payload = item.payload as FormPayload;

          return {
            id: item.id,
            engineName: item.procoreTool.engineName,
            recordServerId: item.recordServerId,
            ...payload
          } as unknown as GridRowsProp
        }) as GridRowsProp[];
      }

      case TOOL_ENGINE_NAMES.INSPECTIONS: {
        return formattedItems.map((item) => {
          const payload = item.payload as InspectionPayload;

          return {
            id: item.id,
            engineName: item.procoreTool.engineName,
            recordServerId: item.recordServerId,
            ...payload
          } as unknown as GridRowsProp
        }) as GridRowsProp[];
      }

      case TOOL_ENGINE_NAMES.OBSERVATIONS: {
        return formattedItems.map((item) => {
          const payload = item.payload as ObservationPayload;

          return {
            id: item.id,
            engineName: item.procoreTool.engineName,
            recordServerId: item.recordServerId,
            ...payload
          } as unknown as GridRowsProp
        }) as GridRowsProp[];
      }

      case TOOL_ENGINE_NAMES.MEETINGS: {
        return formattedItems.map((item) => {
          const payload = item.payload as MeetingPayload;

          return {
            id: item.id,
            engineName: item.procoreTool.engineName,
            recordServerId: item.recordServerId,
            ...payload
          } as unknown as GridRowsProp
        }) as GridRowsProp[];
      }

      case TOOL_ENGINE_NAMES.ACTION_PLANS: {
        return formattedItems.map((item) => {
          const payload = item.payload as ActionPlanPayload;

          return {
            id: item.id,
            engineName: item.procoreTool.engineName,
            recordServerId: item.recordServerId,
            ...payload
          } as unknown as GridRowsProp
        }) as GridRowsProp[];
      }

      default: {
        return [];
      }
    }
  }
}

export const columnsFor = (formattedItems: FormattedModalItem[], onViewInProcore: (engineName: string, recordServerId: number, query: Record<string, string>) => void, onDelete: (id: number, title: string) => void): GridColDef[] => {
  if (formattedItems.length === 0) {
    return [];
  } else {
    const procoreTool = formattedItems[0].procoreTool;

    switch (procoreTool.engineName) {
      case TOOL_ENGINE_NAMES.DRAWINGS: {
        return drawingColumns(onViewInProcore, onDelete);
      }

      case TOOL_ENGINE_NAMES.SUBMITTALS: {
        return submittalColumns(onViewInProcore, onDelete);
      }

      case TOOL_ENGINE_NAMES.RFIS: {
        return rfiColumns(onViewInProcore, onDelete);
      }

      case TOOL_ENGINE_NAMES.PUNCH_LIST: {
        return punchListColumns(onViewInProcore, onDelete);
      }

      case TOOL_ENGINE_NAMES.PHOTOS: {
        return photoColumns(onViewInProcore, onDelete);
      }

      case TOOL_ENGINE_NAMES.DOCUMENTS: {
        return documentColumns(onViewInProcore, onDelete);
      }

      case TOOL_ENGINE_NAMES.FORMS: {
        return formColumns(onViewInProcore, onDelete);
      }

      case TOOL_ENGINE_NAMES.INSPECTIONS: {
        return inspectionColumns(onViewInProcore, onDelete);
      }

      case TOOL_ENGINE_NAMES.OBSERVATIONS: {
        return observationColumns(onViewInProcore, onDelete);
      }

      case TOOL_ENGINE_NAMES.MEETINGS: {
        return meetingColumns(onViewInProcore, onDelete);
      }

      case TOOL_ENGINE_NAMES.ACTION_PLANS: {
        return actionPlanColumns(onViewInProcore, onDelete);
      }

      default: {
        return [];
      }
    }
  }
}

const drawingColumns = (onViewInProcore: (engineName: string, recordServerId: number, query: Record<string, string>) => void, onDelete: (id: number, title: string) => void): GridColDef[] => [
  {
    field: 'number',
    headerName: 'Drawing No.',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'title',
    headerName: 'Drawing Title',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'revision',
    headerName: 'Revision',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'drawingArea',
    headerName: 'Area',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'drawingDiscipline',
    headerName: 'Discipline',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'drawingSet',
    headerName: 'Set',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    sortable: false,
    resizable: false,
    editable: false,
    maxWidth: 80,
    minWidth: 80,
    disableColumnMenu: true,
    getActions: ({ id, row }) => [
      <GridActionsCellItem
        key={`view-in-procore-${id}`}
        onClick={() => onViewInProcore(row.engineName, row.drawingRevisionId, { drawing_area_id: row.drawingAreaId })}
        icon={
          <Tooltip title="View in Procore" placement="top">
            <OpenInNewIcon sx={{fontSize: '16px'}} />
          </Tooltip>
        }
        label="View in Procore"
        className="actionButton"
      />,
      <GridActionsCellItem
        key={`delete-${id}`}
        onClick={() => onDelete(row.id, row['title'] || '')}
        icon={
          <Tooltip title="Delete linked item" placement="top">
            <Close fontSize="inherit" sx={{ color: Colors.darkerGray }} />
          </Tooltip>
        }
        label="Delete"
        className="actionButton"
      />,
    ]
  },
];

//Determines what fields we store for each item type
export type SubmittalPayload = {
  distributed: boolean;
  title: string;
  revision: string,
  formattedTitle: string;
  formattedNumber: string;
  specSection?: string;
  responsibleContractor?: string;
  status: string;
  submittalType?: string;
  dueDate?: Date;
  approvers: {
    name: string,
    response: string,
  }[]
};

const submittalColumns = (onViewInProcore: (engineName: string, recordServerId: number, query: Record<string, string>) => void, onDelete: (id: number, title: string) => void): GridColDef[] => [
  {
    field: 'specSection',
    headerName: 'Spec Section',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'formattedNumber',
    headerName: '#',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'revision',
    headerName: 'Rev.',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'title',
    headerName: 'Title',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'submittalType',
    headerName: 'Type',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'responsibleContractor',
    headerName: 'Responsible Contractor',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    sortable: false,
    resizable: false,
    editable: false,
    maxWidth: 80,
    minWidth: 80,
    disableColumnMenu: true,
    getActions: ({ id, row }) => [
      <GridActionsCellItem
        key={`view-in-procore-${id}`}
        onClick={() => onViewInProcore(row.engineName, row.recordServerId,  {})}
        icon={
          <Tooltip title="View in Procore" placement="top">
            <OpenInNewIcon sx={{fontSize: '16px'}} />
          </Tooltip>
        }
        label="View in Procore"
        className="actionButton"
      />,
      <GridActionsCellItem
        key={`delete-${id}`}
        onClick={() => onDelete(row.id, row['title'] || '')}
        icon={
          <Tooltip title="Delete linked item" placement="top">
            <Close fontSize="inherit" sx={{ color: Colors.darkerGray }} />
          </Tooltip>
        }
        label="Delete"
        className="actionButton"
      />,
    ]
  },
]

const photoColumns = (onViewInProcore: (engineName: string, recordServerId: number, query: Record<string, string>) => void, onDelete: (id: number, title: string) => void): GridColDef[] => [
  {
    field: 'title',
    headerName: 'Name',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'album',
    headerName: 'Album',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'uploadedBy',
    headerName: 'Uploaded By',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    sortable: false,
    resizable: false,
    editable: false,
    maxWidth: 42,
    minWidth: 42,
    disableColumnMenu: true,
    getActions: ({ id, row }) => [
      <GridActionsCellItem
        key={`delete-${id}`}
        onClick={() => onDelete(row.id, row['title'] || '')}
        icon={
          <Tooltip title="Delete linked item" placement="top">
            <Close fontSize="inherit" sx={{ color: Colors.darkerGray }} />
          </Tooltip>
        }
        label="Delete"
        className="actionButton"
      />,
      // <GridActionsCellItem
      //   key={`view-in-procore-${id}`}
      //   onClick={() => onViewInProcore(row.engineName, row.recordServerId)}
      //   icon={
      //     <Tooltip title="View in Procore" placement="top">
      //       <OpenInNewIcon fontSize="inherit" />
      //     </Tooltip>
      //   }
      //   label="View in Procore"
      //   className="actionButton"
      // />
    ]
  },
]

const documentColumns = (onViewInProcore: (engineName: string, recordServerId: number, query: Record<string, string>) => void, onDelete: (id: number, title: string) => void): GridColDef[] => [
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'size',
    headerName: 'Size',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    sortable: false,
    resizable: false,
    editable: false,
    maxWidth: 80,
    minWidth: 80,
    disableColumnMenu: true,
    getActions: ({ id, row }) => [
      <GridActionsCellItem
        key={`view-in-procore-${id}`}
        onClick={() => onViewInProcore(row.engineName, row.recordServerId, {})}
        icon={
          <Tooltip title="View in Procore" placement="top">
            <OpenInNewIcon sx={{fontSize: '16px'}} />
          </Tooltip>
        }
        label="View in Procore"
        className="actionButton"
      />,
      <GridActionsCellItem
        key={`delete-${id}`}
        onClick={() => onDelete(row.id, row['title'] || '')}
        icon={
          <Tooltip title="Delete linked item" placement="top">
            <Close fontSize="inherit" sx={{ color: Colors.darkerGray }} />
          </Tooltip>
        }
        label="Delete"
        className="actionButton"
      />,
    ]
  },
]

export type PunchItemPayload = {
  number: string;
  title: string;
  status: string;
  assignees: [{
    name: string,
    company: string,
    response: string,
  }]
  ballInCourts: string[];
}

const punchListColumns = (onViewInProcore: (engineName: string, recordServerId: number, query: Record<string, string>) => void, onDelete: (id: number, title: string) => void): GridColDef[] => [
  {
    field: 'number',
    headerName: '#',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'title',
    headerName: 'Title',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    sortable: false,
    resizable: false,
    editable: false,
    maxWidth: 80,
    minWidth: 80,
    disableColumnMenu: true,
    getActions: ({ id, row }) => [
      <GridActionsCellItem
        key={`view-in-procore-${id}`}
        onClick={() => onViewInProcore(row.engineName, row.recordServerId, {})}
        icon={
          <Tooltip title="View in Procore" placement="top">
            <OpenInNewIcon sx={{fontSize: '16px'}} />
          </Tooltip>
        }
        label="View in Procore"
        className="actionButton"
      />,
      <GridActionsCellItem
        key={`delete-${id}`}
        onClick={() => onDelete(row.id, row['title'] || '')}
        icon={
          <Tooltip title="Delete linked item" placement="top">
            <Close fontSize="inherit" sx={{ color: Colors.darkerGray }} />
          </Tooltip>
        }
        label="Delete"
        className="actionButton"
      />,
    ]
  },
]

export type RfiPayload = {
  number: string;
  subject: string;
  status: string;
  formattedTitle: string;
  responsibleContractor: string;
  assignees: string[];
  ballInCourts: string[];
  dueDate: string;
  projectStage: string;
  overdue: boolean;
}

const rfiColumns = (onViewInProcore: (engineName: string, recordServerId: number, query: Record<string, string>) => void, onDelete: (id: number, title: string) => void): GridColDef[] => [
  {
    field: 'number',
    headerName: '#',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'subject',
    headerName: 'Subject',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'responsibleContractor',
    headerName: 'Responsible Contractor',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'projectStage',
    headerName: 'RFI Stage',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    sortable: false,
    resizable: false,
    editable: false,
    maxWidth: 80,
    minWidth: 80,
    disableColumnMenu: true,
    getActions: ({ id, row }) => [
      <GridActionsCellItem
        key={`view-in-procore-${id}`}
        onClick={() => onViewInProcore(row.engineName, row.recordServerId, {})}
        icon={
          <Tooltip title="View in Procore" placement="top">
            <OpenInNewIcon sx={{fontSize: '16px'}} />
          </Tooltip>
        }
        label="View in Procore"
        className="actionButton"
      />,
      <GridActionsCellItem
        key={`delete-${id}`}
        onClick={() => onDelete(row.id, row['title'] || '')}
        icon={
          <Tooltip title="Delete linked item" placement="top">
            <Close fontSize="inherit" sx={{ color: Colors.darkerGray }} />
          </Tooltip>
        }
        label="Delete"
        className="actionButton"
      />,
    ]
  },
]

export type FormPayload = {
  formTemplateName: string;
  name: string;
  description: string;
  createdBy: string;
}

const formColumns = (onViewInProcore: (engineName: string, recordServerId: number, query: Record<string, string>) => void, onDelete: (id: number, title: string) => void): GridColDef[] => [
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'description',
    headerName: 'Description',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    sortable: false,
    resizable: false,
    editable: false,
    maxWidth: 80,
    minWidth: 80,
    disableColumnMenu: true,
    getActions: ({ id, row }) => [
      <GridActionsCellItem
        key={`view-in-procore-${id}`}
        onClick={() => onViewInProcore(row.engineName, row.recordServerId, {})}
        icon={
          <Tooltip title="View in Procore" placement="top">
            <OpenInNewIcon sx={{fontSize: '16px'}} />
          </Tooltip>
        }
        label="View in Procore"
        className="actionButton"
      />,
      <GridActionsCellItem
        key={`delete-${id}`}
        onClick={() => onDelete(row.id, row['title'] || '')}
        icon={
          <Tooltip title="Delete linked item" placement="top">
            <Close fontSize="inherit" sx={{ color: Colors.darkerGray }} />
          </Tooltip>
        }
        label="Delete"
        className="actionButton"
      />,
    ]
  },
]

export type InspectionPayload = {
  listTemplateName: string;
  identifier: string;
  name: string;
  status: string;
  description: string;
  inspectionType: string;
}

const inspectionColumns = (onViewInProcore: (engineName: string, recordServerId: number, query: Record<string, string>) => void, onDelete: (id: number, title: string) => void): GridColDef[] => [
  {
    field: 'listTemplateName',
    headerName: 'Template',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'identifier',
    headerName: '#',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'description',
    headerName: 'Description',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'inspectionType',
    headerName: 'Type',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    sortable: false,
    resizable: false,
    editable: false,
    maxWidth: 80,
    minWidth: 80,
    disableColumnMenu: true,
    getActions: ({ id, row }) => [
      <GridActionsCellItem
        key={`view-in-procore-${id}`}
        onClick={() => onViewInProcore(row.engineName, row.recordServerId, {})}
        icon={
          <Tooltip title="View in Procore" placement="top">
            <OpenInNewIcon sx={{fontSize: '16px'}} />
          </Tooltip>
        }
        label="View in Procore"
        className="actionButton"
      />,
      <GridActionsCellItem
        key={`delete-${id}`}
        onClick={() => onDelete(row.id, row['title'] || '')}
        icon={
          <Tooltip title="Delete linked item" placement="top">
            <Close fontSize="inherit" sx={{ color: Colors.darkerGray }} />
          </Tooltip>
        }
        label="Delete"
        className="actionButton"
      />,
    ]
  },
]

export type ObservationPayload = {
  number: string;
  typeName: string;
  trade: string;
  title: string;
  description: string;
  status: string;
  createdBy: string;
}

const observationColumns = (onViewInProcore: (engineName: string, recordServerId: number, query: Record<string, string>) => void, onDelete: (id: number, title: string) => void): GridColDef[] => [
  {
    field: 'number',
    headerName: '#',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'typeName',
    headerName: 'Type',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'trade',
    headerName: 'Trade',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'title',
    headerName: 'Title',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'description',
    headerName: 'Description',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    sortable: false,
    resizable: false,
    editable: false,
    maxWidth: 80,
    minWidth: 80,
    disableColumnMenu: true,
    getActions: ({ id, row }) => [
      <GridActionsCellItem
        key={`view-in-procore-${id}`}
        onClick={() => onViewInProcore(row.engineName, row.recordServerId, {})}
        icon={
          <Tooltip title="View in Procore" placement="top">
            <OpenInNewIcon sx={{fontSize: '16px'}} />
          </Tooltip>
        }
        label="View in Procore"
        className="actionButton"
      />,
      <GridActionsCellItem
        key={`delete-${id}`}
        onClick={() => onDelete(row.id, row['title'] || '')}
        icon={
          <Tooltip title="Delete linked item" placement="top">
            <Close fontSize="inherit" sx={{ color: Colors.darkerGray }} />
          </Tooltip>
        }
        label="Delete"
        className="actionButton"
      />,
    ]
  },
]

export type MeetingPayload = {
  title: string;
  position: string;
  description: string;
}

const meetingColumns = (onViewInProcore: (engineName: string, recordServerId: number, query: Record<string, string>) => void, onDelete: (id: number, title: string) => void): GridColDef[] => [
  {
    field: 'title',
    headerName: 'Title',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'position',
    headerName: 'Number',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'description',
    headerName: 'Overview',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    sortable: false,
    resizable: false,
    editable: false,
    maxWidth: 80,
    minWidth: 80,
    disableColumnMenu: true,
    getActions: ({ id, row }) => [
      <GridActionsCellItem
        key={`view-in-procore-${id}`}
        onClick={() => onViewInProcore(row.engineName, row.recordServerId, {})}
        icon={
          <Tooltip title="View in Procore" placement="top">
            <OpenInNewIcon sx={{fontSize: '16px'}} />
          </Tooltip>
        }
        label="View in Procore"
        className="actionButton"
      />,
      <GridActionsCellItem
        key={`delete-${id}`}
        onClick={() => onDelete(row.id, row['title'] || '')}
        icon={
          <Tooltip title="Delete linked item" placement="top">
            <Close fontSize="inherit" sx={{ color: Colors.darkerGray }} />
          </Tooltip>
        }
        label="Delete"
        className="actionButton"
      />,
    ]
  },
]

const actionPlanColumns = (onViewInProcore: (engineName: string, recordServerId: number, query: Record<string, string>) => void, onDelete: (id: number, title: string) => void): GridColDef[] => [
  {
    field: 'number',
    headerName: 'Number',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'formattedTitle',
    headerName: 'Title',
    type: 'string',
    disableColumnMenu: true,
    sortComparator: (v1, v2, cellParams1, cellParams2) => {
      return gridStringOrNumberComparator(v1 || '', v2 || '', cellParams1, cellParams2);
    },
    minWidth: 150,
    sortable: true,
    editable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    sortable: false,
    resizable: false,
    editable: false,
    maxWidth: 80,
    minWidth: 80,
    disableColumnMenu: true,
    getActions: ({ id, row }) => [
      <GridActionsCellItem
        key={`view-in-procore-${id}`}
        onClick={() => onViewInProcore(row.engineName, row.recordServerId, {})}
        icon={
          <Tooltip title="View in Procore" placement="top">
            <OpenInNewIcon sx={{fontSize: '16px'}} />
          </Tooltip>
        }
        label="View in Procore"
        className="actionButton"
      />,
      <GridActionsCellItem
        key={`delete-${id}`}
        onClick={() => onDelete(row.id, row['title'] || '')}
        icon={
          <Tooltip title="Delete linked item" placement="top">
            <Close fontSize="inherit" sx={{ color: Colors.darkerGray }} />
          </Tooltip>
        }
        label="Delete"
        className="actionButton"
      />,
    ]
  },
]
