import React from "react";
import * as procoreIframeHelpers from "@procore/procore-iframe-helpers";
import { AuthContext } from "../contexts/AuthContext";
import { Box, Typography } from "@mui/material";
import { Colors } from "../styles";
import ProcoreIconWhite from "./shared/ProcoreIconWhite";
import { Button } from "./shared/Button";
import MarketingCarousel from "./MarketingCarousel";

interface LoginProps {
  procoreAuthUrl: string;
}

const Login = ({ procoreAuthUrl }: LoginProps): JSX.Element => {
  const authState = React.useContext(AuthContext);

  function authenticate() {
    const context = procoreIframeHelpers.initialize();

    context.authentication.authenticate({
      url: procoreAuthUrl,
      onSuccess: function (_payload) {
        authState.setAuthenticated(true);
      },
      onFailure: function (error) {
        console.log(error);
      },
    });
  }

  return (
    <Box display="flex" height={1} width={1} flexDirection={'row'} sx={{
      background: 'white',
    }} justifyContent={'space-evenly'}>
      <Box display={'flex'} sx={{
        flex: 1,
        background: '#F7FAFC',
        zIndex: 1,
        boxShadow: "1px 1px 16px 0px rgba(26, 32, 36, 0.06), 4px 4px 18px 1px rgba(26, 32, 36, 0.07), 2px 2px 6px 1px rgba(26, 32, 36, 0.04)",
        '@media (max-width: 900px)': {
          display: 'none'
        }
      }}>
        <MarketingCarousel />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minWidth={"420px"}
        marginBottom={'40px'}
        style={{
          padding: '0px 80px',
        }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginBottom="30px"
          style={{ gap: "4px" }}
        >
          <img src="/images/extracts-pro-logo-banner.svg" alt="Extracts Pro Logo Banner" />
          <Typography
            style={{
              fontSize: 14,
              color: Colors.darkishGray,
              fontWeight: 400,
              fontFamily: '"Inter", sans-serif',
            }}
          >
            The best way to do closeout & data extraction
          </Typography>
        </Box>
        <Button
          onClick={authenticate}
          size="large"
          variant="contained"
          fullWidth={true}
          sx={{
            '&:hover': {
              opacity: 0.9,
            }
          }}
          style={{ height: "48px", backgroundColor: '#0053F4', textTransform: 'uppercase', fontFamily: '"Inter", sans-serif', letterSpacing: '0.8px', fontWeight: 700 }}
          startIcon={
            <ProcoreIconWhite style={{ marginRight: "4px", height: "16px" }} />
          }
        >
          Login with Procore
        </Button>
        <Typography
          style={{
            fontSize: 11,
            color: Colors.darkishGray,
            textAlign: "center",
            lineHeight: "16px",
            marginTop: "16px",
            fontFamily: '"Inter", sans-serif',
          }}
        >
          By logging in with Procore, you agree to the{" "}
          <a
            href="https://extractspro.com/terms-of-use"
            style={{ color: Colors.newAccentBlue }}
            target="_blank" rel="noreferrer"
          >
            Terms of Use
          </a>{" "}
          and{" "}
          <a
            href="https://extractspro.com/privacy-policy"
            style={{ color: Colors.newAccentBlue }}
            target="_blank" rel="noreferrer"
          >
            Privacy Policy
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
