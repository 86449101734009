import { gql } from '@apollo/client';
import { createMutationHook } from '../GraphqlHelpers';
import { VendorPortalLogFileRequest } from '../../types';

export interface VendorPortalCreateLogFileRequestResponses {
  success: boolean;
  logFileRequests: VendorPortalLogFileRequest[];
}

export interface VendorPortalCreateLogFileRequestResponsesData {
  createLogFileRequestResponses: VendorPortalCreateLogFileRequestResponses
}

export interface VendorPortalLogFileRequestResponseAttachmentInputType {
  procoreFileServerId: number;
  filename: string;
  createdAt: string;
  createData: Record<string, any>;
}

export interface VendorPortalLogFileRequestResponseInputType {
  logFileRequestId: number;
  attachments: VendorPortalLogFileRequestResponseAttachmentInputType[];
}

export interface VendorPortalCreateLogFileRequestResponsesVariables {
  vendorId: string;
  responses: VendorPortalLogFileRequestResponseInputType[];
}

export const useVendorPortalCreateLogFileRequestResponses = createMutationHook<VendorPortalCreateLogFileRequestResponsesData, VendorPortalCreateLogFileRequestResponsesVariables>(gql`
mutation CreateLogFileRequestResponses($vendorId: ID!, $responses: [VendorPortalLogFileRequestResponseInput!]!) {
  createLogFileRequestResponses(input: { vendorId: $vendorId, responses: $responses }) {
    success
    logFileRequests {
      id
      title
      description
      dueDate
      complete
      status
      translatedStatus
      createdAt
      updatedAt
      vendorWorkflowRequest {
        id
        logFileRequestId
        status
        translatedStatus
        comment
        title
        vendorWorkflowResponse {
          id
          logFileRequestId
          status
          translatedStatus
          comment
          vendorWorkflowRequestId
          title
        }
      }
      logRequirementTrade {
        id
        name
      }
      specSection {
        id
        name
        divisionName
        procoreServerId
      }
      logRequirementDescription
    }
  }
}
`);