import { gql } from "@apollo/client";
import { createMutationHook } from "../../GraphqlHelpers";
import { TemplateLogCustomField } from "../../../types";

export interface TemplateLogCustomFieldUpsert {
  templateLogCustomField?: TemplateLogCustomField;
  success: boolean;
}

export interface TemplateLogCustomFieldUpsertData {
  upsertTemplateLogCustomField: TemplateLogCustomFieldUpsert | null;
}
export interface TemplateLogCustomFieldLovEntryAttributes {
  id?: number;
  value: string;
}

export interface TemplateLogCustomFieldUpsertAttributes {
  id?: number;
  name: string;
  values: TemplateLogCustomFieldLovEntryAttributes[];
}

export interface TemplateLogCustomFieldUpsertVariables {
  accountId: string;
  templateCloseoutLogId: number;
  customField: TemplateLogCustomFieldUpsertAttributes;
}

export const useUpsertTemplateLogCustomField = createMutationHook<
TemplateLogCustomFieldUpsertData,
TemplateLogCustomFieldUpsertVariables
>(gql`
  mutation UpsertTemplateLogCustomField(
    $accountId: ID!,
    $templateCloseoutLogId: ID!,
    $customField: TemplateLogCustomFieldUpsertAttributes!
  ) {
    upsertTemplateLogCustomField(
      input: { accountId: $accountId, templateCloseoutLogId: $templateCloseoutLogId, customField: $customField }
    ) {
      templateLogCustomField {
        id
        name
        fieldType
        key
        values {
          id
          value
        }
      }
      success
    }
  }
`);
